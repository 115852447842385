import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../firebase";
import useCookie from "./useCookie";
import { config } from "../config";
import { addGallery, deleteGallery, updateGalleryName, updateGalleryImage } from "../api/gallery";

const useAddGallery = () => {

	const { cookie } = useCookie(config.key, "");

	const storage = getStorage(app);

	const [loadingAddGallery, setLoadingAddGallery] = useState(false);
	const [removeAddGalleryModal, setRemoveAddGalleryModal] = useState(null);
	const [name, setName] = useState(null);
	const [selectedAddGallery, setSelectedAddGallery] = useState("");
	const [uploadingAddGalleryPercentage, setUploadingAddGalleryPercentage] = useState(0);

	const [errorAddGallery, setErrorAddGallery] = useState(null);
	const [successAddGallery, setSuccessAddGallery] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/webp", "image/WEBP"];
	const maximum_file_size = 20 * 1024 * 1024;

	const filterBytes = (bytes) => {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
	};

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };

	const handleAddGallery = (e) => {
		e.preventDefault();

		if (!loadingAddGallery) {
			if (!name) {
				setErrorAddGallery(null);
				setSuccessAddGallery(null);
				setErrorAddGallery("Name is required");
				setTimeout(function () {
					setErrorAddGallery(null);
				}, 2500)
			} else if (name.length > 300) {
				setErrorAddGallery("Name maximum characters - 300");
				setTimeout(function () {
					setErrorAddGallery(null);
				}, 2500)
			} else if (!allowed_extensions.includes(selectedAddGallery.type)) {
				setErrorAddGallery("Invalid image format (.png, .jpg, .jpeg & .webp)");
				setTimeout(function () {
					setErrorAddGallery(null);
				}, 2000)
			} else if (selectedAddGallery.size > maximum_file_size) {
				setErrorAddGallery("File too large (max 20mb)");
				setTimeout(function () {
					setErrorAddGallery(null);
				}, 2000)
			} else {
				setLoadingAddGallery(true);

				const file_rename = uuidv4();
				let lastDot = selectedAddGallery.name.lastIndexOf('.');
				let ext = selectedAddGallery.name.substring(lastDot + 1);

				const filePath = "/gallery/" + file_rename + "." + ext;

				const storageRef = ref(storage, filePath);
				const uploadTask = uploadBytesResumable(storageRef, selectedAddGallery);

				uploadTask.on('state_changed',
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadingAddGalleryPercentage(progress);
					},
					(error) => {
						setUploadingAddGalleryPercentage(0);
						setLoadingAddGallery(false);
						setErrorAddGallery("An error occured while uploading");
						setTimeout(function () {
							setErrorAddGallery(null);
						}, 3000)
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

							const addGalleryRes = addGallery(cookie, {
								name,
								image: downloadURL,
								image_file_ext: filePath,
								image_size: filterBytes(selectedAddGallery.size)
							})

							addGalleryRes.then(res => {
								setLoadingAddGallery(false);
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setErrorAddGallery(error);
										setTimeout(function () {
											setErrorAddGallery(null);
										}, 2000)
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setErrorAddGallery(error);
										setTimeout(function () {
											setErrorAddGallery(null);
										}, 2000)
									}
								} else {
									setErrorAddGallery(null);
									setUploadingAddGalleryPercentage(0);
									setSuccessAddGallery(`Gallery added successfully!`);

									setTimeout(function () {
										setSuccessAddGallery(null);
										setRemoveAddGalleryModal(true);
									}, 2500)
								}
							}).catch(err => {
								setUploadingAddGalleryPercentage(0);
								setLoadingAddGallery(false);
							})
						});

					}
				)

			}
		}
	};

	return {
		cookie, name, loadingAddGallery, setRemoveAddGalleryModal, errorAddGallery, successAddGallery, removeAddGalleryModal, setSelectedAddGallery,
		handleAddGallery, handleName, setName, uploadingAddGalleryPercentage, selectedAddGallery,
	};
};

const useUpdateGalleryName = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateGalleryName, setLoadingUpdateGalleryName] = useState(false);
	const [removeUpdateGalleryNameModal, setRemoveUpdateGalleryNameModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [name, setName] = useState(null);

	const [errorUpdateGalleryName, setErrorUpdateGalleryName] = useState(null);
	const [successUpdateGalleryName, setSuccessUpdateGalleryName] = useState(null);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };

	const handleUpdateGalleryName = (e) => {
		e.preventDefault();

		if (!loadingUpdateGalleryName) {
			if (!uniqueId) {
				setErrorUpdateGalleryName(null);
				setSuccessUpdateGalleryName(null);
				setErrorUpdateGalleryName("Unique ID is required");
				setTimeout(function () {
					setErrorUpdateGalleryName(null);
				}, 2500)
			} else if (!name) {
				setErrorUpdateGalleryName("Name is required");
				setTimeout(function () {
					setErrorUpdateGalleryName(null);
				}, 2500)
			} else if (name.length > 300) {
				setErrorUpdateGalleryName("Name maximum characters - 300");
				setTimeout(function () {
					setErrorUpdateGalleryName(null);
				}, 2500)
			} else {
				setLoadingUpdateGalleryName(true);

				const updateGalleryNameRes = updateGalleryName(cookie, {
					unique_id: uniqueId,
					name
				})

				updateGalleryNameRes.then(res => {
					setLoadingUpdateGalleryName(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateGalleryName(error);
							setTimeout(function () {
								setErrorUpdateGalleryName(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateGalleryName(error);
							setTimeout(function () {
								setErrorUpdateGalleryName(null);
							}, 2000)
						}
					} else {
						setErrorUpdateGalleryName(null);
						setSuccessUpdateGalleryName(`Gallery name updated!`);

						setTimeout(function () {
							setSuccessUpdateGalleryName(null);
							setRemoveUpdateGalleryNameModal(true);
							setUniqueId(null);
							setName(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateGalleryName(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateGalleryName, removeUpdateGalleryNameModal, errorUpdateGalleryName, successUpdateGalleryName, handleUpdateGalleryName,
		setRemoveUpdateGalleryNameModal, setUniqueId, setName, name, handleName
	};
};

const useUploadGalleryImage = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingGalleryImage, setLoadingGalleryImage] = useState(false);
	const [uniqueId, setUniqueId] = useState(null);
	const [removeGalleryImageModal, setRemoveGalleryImageModal] = useState(null);
	const [selectedGalleryImage, setSelectedGalleryImage] = useState("");
	const [uploadingGalleryImagePercentage, setUploadingGalleryImagePercentage] = useState(0);

	const [errorGalleryImage, setErrorGalleryImage] = useState(null);
	const [successGalleryImage, setSuccessGalleryImage] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/webp", "image/WEBP"];
	const maximum_file_size = 20 * 1024 * 1024;

	const filterBytes = (bytes) => {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
	};

	const handleUploadGalleryImage = (e) => {
		e.preventDefault();

		if (!loadingGalleryImage) {
			if (!uniqueId) {
				setErrorGalleryImage(null);
				setSuccessGalleryImage(null);
				setErrorGalleryImage("Unique ID is required");
				setTimeout(function () {
					setErrorGalleryImage(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedGalleryImage.type)) {
				setErrorGalleryImage("Invalid image format (.png, .jpg, .jpeg & .webp)");
				setTimeout(function () {
					setErrorGalleryImage(null);
				}, 2000)
			} else if (selectedGalleryImage.size > maximum_file_size) {
				setErrorGalleryImage("File too large (max 20mb)");
				setTimeout(function () {
					setErrorGalleryImage(null);
				}, 2000)
			} else {
				setLoadingGalleryImage(true);

				const file_rename = uuidv4();
				let lastDot = selectedGalleryImage.name.lastIndexOf('.');
				let ext = selectedGalleryImage.name.substring(lastDot + 1);

				const filePath = "/gallery/" + file_rename + "." + ext;

				const storageRef = ref(storage, filePath);
				const uploadTask = uploadBytesResumable(storageRef, selectedGalleryImage);

				uploadTask.on('state_changed',
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadingGalleryImagePercentage(progress);
					},
					(error) => {
						setUploadingGalleryImagePercentage(0);
						setLoadingGalleryImage(false);
						setErrorGalleryImage("An error occured while uploading");
						setTimeout(function () {
							setErrorGalleryImage(null);
						}, 3000)
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

							const updateGalleryImageRes = updateGalleryImage(cookie, {
								unique_id: uniqueId,
								image: downloadURL,
								image_file_ext: filePath,
								image_size: filterBytes(selectedGalleryImage.size)
							})

							updateGalleryImageRes.then(res => {
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setUploadingGalleryImagePercentage(0);
										setLoadingGalleryImage(false);
										setErrorGalleryImage(error);
										setTimeout(function () {
											setErrorGalleryImage(null);
										}, 2000)
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setUploadingGalleryImagePercentage(0);
										setLoadingGalleryImage(false);
										setErrorGalleryImage(error);
										setTimeout(function () {
											setErrorGalleryImage(null);
										}, 2000)
									}
								} else {
									setErrorGalleryImage(null);
									setUploadingGalleryImagePercentage(0);
									setSuccessGalleryImage(`Gallery Image updated successfully!`);

									setTimeout(function () {
										setLoadingGalleryImage(false);
										setSuccessGalleryImage(null);
										setRemoveGalleryImageModal(true);
										setUniqueId(null);
									}, 3000)
								}
							}).catch(err => {
								setUploadingGalleryImagePercentage(0);
								setLoadingGalleryImage(false);
							})
						});

					}
				)
			}
		}
	};

	return {
		cookie, loadingGalleryImage, errorGalleryImage, successGalleryImage, handleUploadGalleryImage, uniqueId, setSelectedGalleryImage,
		setUniqueId, uploadingGalleryImagePercentage, selectedGalleryImage, removeGalleryImageModal, setRemoveGalleryImageModal
	};
};

const useDeleteGallery = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteGallery, setLoadingDeleteGallery] = useState(false);
	const [removeDeleteGalleryModal, setRemoveDeleteGalleryModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDeleteGallery, setErrorDeleteGallery] = useState(null);
	const [successDeleteGallery, setSuccessDeleteGallery] = useState(null);

	const handleDeleteGallery = () => {

		if (!loadingDeleteGallery) {
			if (!uniqueId) {
				setErrorDeleteGallery(null);
				setSuccessDeleteGallery(null);
				setErrorDeleteGallery("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteGallery(null);
				}, 2500)
			} else {
				setLoadingDeleteGallery(true);

				const deleteGalleryRes = deleteGallery(cookie, {
					unique_id: uniqueId
				})

				deleteGalleryRes.then(res => {
					setLoadingDeleteGallery(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteGallery(error);
							setTimeout(function () {
								setErrorDeleteGallery(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteGallery(error);
							setTimeout(function () {
								setErrorDeleteGallery(null);
							}, 2000)
						}
					} else {
						setErrorDeleteGallery(null);
						setSuccessDeleteGallery(`Gallery deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteGallery(null);
							setRemoveDeleteGalleryModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteGallery(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteGallery, removeDeleteGalleryModal, errorDeleteGallery, successDeleteGallery, handleDeleteGallery,
		setRemoveDeleteGalleryModal, setUniqueId
	};
};

export { useAddGallery, useUpdateGalleryName, useUploadGalleryImage, useDeleteGallery };
