import { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Screen from '../components/Screen';
import Content from '../components/Content';
import GalleryAdd from "../assets/images/gallery-add.png";
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import { getPartner, getPartnerViaEmail, getPartners } from "../api/partners";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import {
	useGrantPartnerAccess, useUpdateWebsiteURL, useRevokePartnerAccess, useSuspendPartnerAccess, useUnverifyPartner, useUpdateComplianceDetails,
	useUploadPartnerComplianceCertificate, useUploadPartnerComplianceDocument, useVerifyPartner
} from "../hooks/usePartners";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Edit from "../icons/Edit";
import KeyAlt from "../icons/KeyAlt";
import Check from "../icons/Check";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import EyeOpen from "../icons/EyeOpen";
import Search from "../icons/Search";
import Copy from "../icons/Copy";
import Key from "../icons/Key";
import CancelAlt from "../icons/CancelAlt";

export default function Partners() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorGrantPartnerAccess, handleGrantPartnerAccess, loadingGrantPartnerAccess, removeGrantPartnerAccessModal, setRemoveGrantPartnerAccessModal,
		setPartnerUniqueId: GrantAccessPartnerUniqueId, successGrantPartnerAccess
	} = useGrantPartnerAccess();

	const {
		errorSuspendPartnerAccess, handleSuspendPartnerAccess, loadingSuspendPartnerAccess, removeSuspendPartnerAccessModal, setRemoveSuspendPartnerAccessModal,
		setPartnerUniqueId: SuspendAccessPartnerUniqueId, successSuspendPartnerAccess
	} = useSuspendPartnerAccess();

	const {
		errorRevokePartnerAccess, handleRevokePartnerAccess, loadingRevokePartnerAccess, removeRevokePartnerAccessModal, setRemoveRevokePartnerAccessModal,
		setPartnerUniqueId: RevokeAccessPartnerUniqueId, successRevokePartnerAccess
	} = useRevokePartnerAccess();

	const {
		errorUpdateWebsiteURL, handleUpdateWebsiteURL, loadingUpdateWebsiteURL, removeUpdateWebsiteURLModal, setRemoveUpdateWebsiteURLModal, setPartnerUniqueId: UpdateWebsiteURLPartnerUniqueId, 
		successUpdateWebsiteURL, setWebsiteURL, websiteURL, handleWebsiteURL
	} = useUpdateWebsiteURL();

	const {
		errorVerifyPartner, handleVerifyPartner, loadingVerifyPartner, removeVerifyPartnerModal, setRemoveVerifyPartnerModal,
		setPartnerUniqueId: VerifyPartnerUniqueId, successVerifyPartner
	} = useVerifyPartner();

	const {
		errorUnverifyPartner, handleUnverifyPartner, loadingUnverifyPartner, removeUnverifyPartnerModal, setRemoveUnverifyPartnerModal,
		setPartnerUniqueId: UnverifyPartnerUniqueId, successUnverifyPartner
	} = useUnverifyPartner();

	const {
		companyAddress, companyEmail, companyName, companyRcNumber, companyType, companyWebsiteUrl, errorUpdateComplianceDetails,
		handleCompanyAddress, handleCompanyEmail, handleCompanyName, handleCompanyRcNumber, handleCompanyType, handleCompanyWebsiteUrl,
		handleUpdateComplianceDetails, loadingUpdateComplianceDetails, successUpdateComplianceDetails, removeComplianceDetailsModal,
		setCompanyAddress, setCompanyEmail, setCompanyName, setCompanyRcNumber, setCompanyWebsiteUrl, setCompanyType,
		setPartnerUniqueId: ComplianceDetailsPartnerUniqueId, setRemoveComplianceDetailsModal
	} = useUpdateComplianceDetails();

	const {
		errorComplianceDocument, handleUploadComplianceDocument, loadingComplianceDocument, setPartnerUniqueId: DocumentSetPartnerUniqueId, setSelectedComplianceDocument, successComplianceDocument,
		uploadingComplianceDocumentPercentage, selectedComplianceDocument
	} = useUploadPartnerComplianceDocument();

	const {
		errorComplianceCertificate, handleUploadComplianceCertificate, loadingComplianceCertificate, setPartnerUniqueId: CertificateSetPartnerUniqueId, setSelectedComplianceCertificate, successComplianceCertificate,
		uploadingComplianceCertificatePercentage, selectedComplianceCertificate
	} = useUploadPartnerComplianceCertificate();

	const handleSelectComplianceDocument = (e) => {
		const el = e.target.files[0];
		setSelectedComplianceDocument("");
		setSelectedComplianceDocument(el);
	}

	const handleSelectComplianceCertificate = (e) => {
		const el = e.target.files[0];
		setSelectedComplianceCertificate("");
		setSelectedComplianceCertificate(el);
	}

	const getFileExtension = (filename) => {
		let lastDot = filename.lastIndexOf('.');
		let ext = filename.substring(lastDot + 1);
		return ext;
	}

	const getFileNameAlone = (filename) => {
		let _filename = filename.split("/");
		return _filename[_filename.length - 1];
	}

	const [allPartners, setAllPartners] = useState(null);
	const [errorAllPartners, setErrorAllPartners] = useState(null);
	const [loadingAllPartners, setLoadingAllPartners] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getAllPartners(page, e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); getAllPartners(parseInt(e.target.value), size); };

	async function previousPartners() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getAllPartners(page - 1, size);
	};

	async function nextPartners() {
		if (page < allPartners.data.pages) setPage(page + 1);
		if (page < allPartners.data.pages) getAllPartners(page + 1, size);
	};

	async function getAllPartners(_page, _size) {
		setLoadingAllPartners(true);
		const response = await getPartners(cookie, (_page || page), (_size || size));
		setAllPartners(response.data);
		if (response.error) setErrorAllPartners(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllPartners(false);
	};

	useEffect(() => {
		if (allPartners === null) {
			getAllPartners();
		}
	}, [allPartners]);

	const [loadingViewPartner, setLoadingViewPartner] = useState(false);
	const [errorViewPartner, setErrorViewPartner] = useState(null);
	const [viewPartnerDetails, setViewPartnerDetails] = useState(null);

	async function getPartnerDetails(partner_unique_id) {
		setLoadingViewPartner(true);
		const response = await getPartner(cookie, { partner_unique_id });
		if (!response.err) { setViewPartnerDetails(response.data); UpdateWebsiteURLPartnerUniqueId(response.data.data.partner_unique_id); }
		else { setErrorViewPartner(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
		setLoadingViewPartner(false);
	};

	async function getPartnerDetailsViaEmail(email) {
		const modalResponse = document.querySelector("#partnerSearchModal");
		modalResponse.setAttribute("display", false);
		setLoadingViewPartner(true);
		const response = await getPartnerViaEmail(cookie, { email });
		if (!response.err) { setViewPartnerDetails(response.data); UpdateWebsiteURLPartnerUniqueId(response.data.data.partner_unique_id); }
		else { setViewPartnerDetails(null); setErrorViewPartner(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
		setLoadingViewPartner(false);
		setPartnerEmailSearch("");
	};

	const [showWebsiteURLUpdate, setShowWebsiteURLUpdate] = useState(false);

	const [partnerEmailSearch, setPartnerEmailSearch] = useState("");
	const handlePartnerEmailSearch = (e) => { e.preventDefault(); setPartnerEmailSearch(e.target.value); };

	if (removeGrantPartnerAccessModal) {
		const modalResponse = document.querySelector("#grantPartnerAccessModal");
		modalResponse.setAttribute("display", false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveGrantPartnerAccessModal(null);
	}
	if (removeSuspendPartnerAccessModal) {
		const modalResponse = document.querySelector("#suspendPartnerAccessModal");
		modalResponse.setAttribute("display", false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveSuspendPartnerAccessModal(null);
	}
	if (removeRevokePartnerAccessModal) {
		const modalResponse = document.querySelector("#revokePartnerAccessModal");
		modalResponse.setAttribute("display", false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveRevokePartnerAccessModal(null);
	}
	if (removeUpdateWebsiteURLModal) {
		const modalResponse = document.querySelector("#updateWebsiteURLModal");
		modalResponse.setAttribute("display", false);
		setShowWebsiteURLUpdate(false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveUpdateWebsiteURLModal(null);
	}
	if (removeVerifyPartnerModal) {
		const modalResponse = document.querySelector("#verifyPartnerModal");
		modalResponse.setAttribute("display", false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveVerifyPartnerModal(null);
	}
	if (removeUnverifyPartnerModal) {
		const modalResponse = document.querySelector("#unverifyPartnerModal");
		modalResponse.setAttribute("display", false);
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveUnverifyPartnerModal(null);
	}
	if (removeComplianceDetailsModal) {
		getPartnerDetails(viewPartnerDetails.data.unique_id);
		setRemoveComplianceDetailsModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const pageSelectArray = new Array(allPartners ? allPartners.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Partners</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all partners details</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="partnerSearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllPartners ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allPartners && allPartners.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-300'>Name</th>
														<th className='xui-min-w-300'>Email</th>
														{/* <th className='xui-min-w-100'>Country</th> */}
														<th className='xui-min-w-150'>Balance</th>
														<th className='xui-min-w-250'>Sector</th>
														<th className='xui-min-w-100'>Verified</th>
														<th className='xui-min-w-100'>Access</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allPartners.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.name}</span>
																	<span title="Copy Partner Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === (data.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																<span>
																	{
																		data.email_verified ?
																			<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span>{data.email}</span>
																					<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.email); setTextCopied(data.email); }}>
																						{copiedText && textCopied === data.email ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div>
																			</span> :
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.email}</span>
																	}
																</span>
															</td>
															{/* <td className='xui-opacity-5'>
																<span>{data.country}</span>
															</td> */}
															<td className='xui-opacity-5'>
																<span>{data.balance.toLocaleString()}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.sector}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	data.verified ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Unverified</span>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	data.access === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Granted</span> : (
																			data.access === 2 ?
																				<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Suspended</span> : (
																					data.access === 3 ?
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Revoked</span> :
																						<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>No Idea</span>
																				)
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	data.status === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																}
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="View Partner Details" onClick={() => { getPartnerDetails(data.unique_id) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewPartnerDetailsModal">
																		<EyeOpenAlt width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllPartners}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllPartners ?
								<Loading width="12" height="12" /> :
								(
									allPartners && allPartners.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allPartners ? allPartners.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousPartners}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextPartners}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewPartnerDetailsModal" id="viewPartnerDetailsModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewPartnerDetailsModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewPartner ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewPartnerDetails && viewPartnerDetails.success ?
									<>
										<h1>{viewPartnerDetails.data.name}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Details of this partner below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className={`xui-w-200 xui-h-200 xui-m-1-half`}>
												<img className={`xui-img-200 xui-max-h-200 xui-bdr-rad-circle`} src={viewPartnerDetails.data.photo} alt={viewPartnerDetails.data.name + ", " + viewPartnerDetails.data.city + " Partner Profile Image"} />
											</div>
											<div className={`xui-m-1-half xui-lg-ml--10 xui-md-ml--7`}>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Access URL - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span><a href={viewPartnerDetails.data.access_url} target="_blank">Click to go to link</a></span>
														<span title="Copy Access URL" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewPartnerDetails.data.access_url); setTextCopied(viewPartnerDetails.data.access_url); }}>
															{copiedText && textCopied === (viewPartnerDetails.data.access_url) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Partner Unique ID - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span>{viewPartnerDetails.data.unique_id}</span>
														<span title="Copy Partner Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewPartnerDetails.data.unique_id); setTextCopied(viewPartnerDetails.data.unique_id); }}>
															{copiedText && textCopied === (viewPartnerDetails.data.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Name -</span> {viewPartnerDetails.data.name}</p>
												{/* <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Country -</span> {viewPartnerDetails.data.country}</p> */}
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Email - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														{
															viewPartnerDetails.data.email_verified ?
																<span className='xui-badge xui-badge-success xui-font-sz-100 xui-bdr-rad-half'>{viewPartnerDetails.data.email}</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-100 xui-bdr-rad-half'>{viewPartnerDetails.data.email}</span>
														}
														<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewPartnerDetails.data.email); setTextCopied(viewPartnerDetails.data.email); }}>
															{copiedText && textCopied === (viewPartnerDetails.data.email) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Balance -</span> {viewPartnerDetails.data.balance.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Hospitality - </span> 
													{
														viewPartnerDetails.data.hospitality ?
															<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>Yes</span> :
															<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
													}
												</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Name -</span> {viewPartnerDetails.data.company_name ? viewPartnerDetails.data.company_name : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Email -</span> {viewPartnerDetails.data.company_email ? viewPartnerDetails.data.company_email : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company RC Number -</span> {viewPartnerDetails.data.company_rc_number ? viewPartnerDetails.data.company_rc_number : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Type -</span> {viewPartnerDetails.data.company_type ? viewPartnerDetails.data.company_type : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Address -</span> {viewPartnerDetails.data.company_address ? viewPartnerDetails.data.company_address : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Website URL -</span> {viewPartnerDetails.data.website_url ? viewPartnerDetails.data.website_url : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Access -</span> {
													viewPartnerDetails.data.access === 1 ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Granted</span> : (
															viewPartnerDetails.data.access === 2 ?
																<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Suspended</span> : (
																	viewPartnerDetails.data.access === 3 ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Revoked</span> :
																		<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>No Idea</span>
																)
														)
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Verification - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														{
															viewPartnerDetails.data.verified ?
																<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>Verified</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
														}
														{
															viewPartnerDetails.data.verified ?
																<span title="Unverify Partner" className="xui-cursor-pointer xui-ml-1" onClick={() => { UnverifyPartnerUniqueId(viewPartnerDetails.data.unique_id); }} xui-modal-open="unverifyPartnerModal">
																	<CancelAlt width="20" height="20" />
																</span> :
																<span title="Verify Partner" className="xui-cursor-pointer xui-ml-1" onClick={() => { VerifyPartnerUniqueId(viewPartnerDetails.data.unique_id); }} xui-modal-open="verifyPartnerModal">
																	<Check width="20" height="20" />
																</span>
														}
													</div>
												</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Account Name -</span> {viewPartnerDetails.data.account_name ? viewPartnerDetails.data.account_name : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Account Number -</span> {viewPartnerDetails.data.account_number ? viewPartnerDetails.data.account_number : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Bank -</span> {viewPartnerDetails.data.bank ? viewPartnerDetails.data.bank : "None"}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewPartnerDetails.data.createdAt.fulldate} | Last Updated - {viewPartnerDetails.data.updatedAt.fulldate}</p>
											<div className="xui-m-2">
												{
													viewPartnerDetails.data.verified ?
														<button title="Unverify Platform" onClick={() => { UnverifyPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-70" xui-modal-open="unverifyPartnerModal">
															<span className="xui-mr-half">Unverify Partner</span>
															<Close width="16" height="16" />
														</button> :
														<button title="Verify Platform" onClick={() => { VerifyPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-70" xui-modal-open="verifyPartnerModal">
															<span className="xui-mr-half">Verify Partner</span>
															<Check width="16" height="16" />
														</button>
												}
											</div>
											<div className="xui-m-2">
												<button title="Grant Partner Access" onClick={() => { GrantAccessPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="grantPartnerAccessModal">
													<Check width="16" height="16" />
												</button>
												<button title="Suspend Partner Access" onClick={() => { SuspendAccessPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-ml-3 xui-mr-3 xui-btn xui-bg-warning xui-text-white xui-bdr-rad-half xui-font-sz-50" xui-modal-open="suspendPartnerAccessModal">
													<KeyAlt width="16" height="16" />
												</button>
												<button title="Revoke Partner Access" onClick={() => { RevokeAccessPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="revokePartnerAccessModal">
													<Close width="16" height="16" />
												</button>
											</div>
											{
												showWebsiteURLUpdate ?
													<form className="xui-form" onSubmit={(e) => e.preventDefault()}>
														<div className="xui-w-300 xui-lg-w-300">
															<label>Update Partner Website URL</label>
															<div className="xui-d-flex xui-flex-ai-center">
																<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-red xui-text-white xui-mr-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" onClick={() => setShowWebsiteURLUpdate(false)}>
																	<Close width="16" height="16" />
																</div>
																<input style={{ width: "calc(100% - 100px)" }} type={"url"} value={websiteURL} onChange={handleWebsiteURL} required />
																<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle psc-btn-green xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" xui-modal-open="updateWebsiteURLModal">
																	<Check width="16" height="16" />
																</div>
															</div>
														</div>
													</form> :
													<button onClick={() => { setShowWebsiteURLUpdate(true); UpdateWebsiteURLPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
														<span className="xui-mr-half">Update Website URL</span>
														<Edit width="16" height="16" />
													</button>
											}
											<div className="xui-m-2">
												<button onClick={() => { ComplianceDetailsPartnerUniqueId(viewPartnerDetails.data.unique_id); DocumentSetPartnerUniqueId(viewPartnerDetails.data.unique_id); CertificateSetPartnerUniqueId(viewPartnerDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70" xui-modal-close="viewPartnerDetailsModal" xui-modal-open="complianceDetailsModal">
													<span className="xui-mr-half">Compliance Details</span>
													<EyeOpenAlt width="16" height="16" />
												</button>
											</div>

										</center>
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewPartner}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="complianceDetailsModal" id="complianceDetailsModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="complianceDetailsModal" xui-modal-open="viewPartnerDetailsModal">
						<Close width="24" height="24" />
					</div>
					<h1>Compliance Details for {viewPartnerDetails && viewPartnerDetails.success ? viewPartnerDetails.data.name : ""}</h1>
					<span className="xui-font-sz-90 xui-opacity-5">View / Edit partner compliance details</span>
					<form className="xui-form xui-mt-4" onSubmit={handleUpdateComplianceDetails}>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1">
							<div className="xui-w-fluid-100">
								<label>Company Name</label>
								<input type={"text"} disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} placeholder={viewPartnerDetails ? viewPartnerDetails.data.company_name : ""} readOnly={viewPartnerDetails ? false : true} value={companyName} onClick={() => { if (viewPartnerDetails) setCompanyName(viewPartnerDetails.data.company_name) }} onChange={handleCompanyName} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Company Email</label>
								<input type={"email"} disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} placeholder={viewPartnerDetails ? viewPartnerDetails.data.company_email : ""} readOnly={viewPartnerDetails ? false : true} value={companyEmail} onClick={() => { if (viewPartnerDetails) setCompanyEmail(viewPartnerDetails.data.company_email) }} onChange={handleCompanyEmail} required />
							</div>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1 xui-mt-2">
							<div className="xui-w-fluid-100">
								<label>Company RC Number</label>
								<input type={"text"} disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} placeholder={viewPartnerDetails ? viewPartnerDetails.data.company_rc_number : ""} readOnly={viewPartnerDetails ? false : true} value={companyRcNumber} onClick={() => { if (viewPartnerDetails) setCompanyRcNumber(viewPartnerDetails.data.company_rc_number) }} onChange={handleCompanyRcNumber} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Company Type</label>
								<select disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} onChange={handleCompanyType} value={companyType} required>
									<option selected disabled>Select Company Type</option>
									<option selected={viewPartnerDetails ? (viewPartnerDetails.data.company_type === "BN" ? true : false) : false} value={"BN"}>BN</option>
									<option selected={viewPartnerDetails ? (viewPartnerDetails.data.company_type === "RC" ? true : false) : false} value={"RC"}>RC</option>
									<option selected={viewPartnerDetails ? (viewPartnerDetails.data.company_type === "IT" ? true : false) : false} value={"IT"}>IT</option>
									<option selected={viewPartnerDetails ? (viewPartnerDetails.data.company_type === "LL" ? true : false) : false} value={"LL"}>LL</option>
									<option selected={viewPartnerDetails ? (viewPartnerDetails.data.company_type === "LLP" ? true : false) : false} value={"LLP"}>LLP</option>
								</select>
							</div>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1 xui-mt-2 xui-mb-2">
							<div className="xui-w-fluid-100">
								<label>Company Address</label>
								<input type={"text"} disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} placeholder={viewPartnerDetails ? viewPartnerDetails.data.company_address : ""} readOnly={viewPartnerDetails ? false : true} value={companyAddress} onClick={() => { if (viewPartnerDetails) setCompanyAddress(viewPartnerDetails.data.company_address) }} onChange={handleCompanyAddress} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Website URL</label>
								<input type={"text"} disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} placeholder={viewPartnerDetails ? viewPartnerDetails.data.website_url : ""} readOnly={viewPartnerDetails ? false : true} value={companyWebsiteUrl} onClick={() => { if (viewPartnerDetails) setCompanyWebsiteUrl(viewPartnerDetails.data.website_url) }} onChange={handleCompanyWebsiteUrl} />
							</div>
						</div>
						<div className="xui-d-flex">
							{
								viewPartnerDetails ?
									(
										viewPartnerDetails.data.registration_document === null || viewPartnerDetails.data.registration_certificate === null ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												Upload Documents
											</button> : (
												viewPartnerDetails.data.verified ?
													<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
														Verified
													</button> :
													<button className="xui-btn psc-btn-blue xui-font-sz-80">
														{
															loadingUpdateComplianceDetails ?
																<Loading width="16" height="16" />
																: "Save Changes"
														}
													</button>
											)
									) :
									<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
										Loading ...
									</button>
							}
						</div>
						<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateComplianceDetails}</span></p>
						<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateComplianceDetails}</span></p>
					</form>
					<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-2 xui-mt-2 xui-mb-2">
						<form className="xui-form" onSubmit={handleUploadComplianceDocument}>
							<div className="xui-form-box xui-w-fluid-100 xui-mt-3">
								<label>Registration Document</label>
								<label htmlFor="registrationDocument">
									<div className="xui-w-fluid-100 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
										{
											selectedComplianceDocument ?
												<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{selectedComplianceDocument.name}</span> :
												<>
													{
														viewPartnerDetails ?
															(
																viewPartnerDetails.data.registration_document === null ?
																	<img className="xui-img-50" src={GalleryAdd} alt="" /> :
																	(
																		getFileExtension(viewPartnerDetails.data.registration_document) === "pdf" || getFileExtension(viewPartnerDetails.data.registration_document) === "PDF" ?
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{getFileNameAlone(viewPartnerDetails.data.registration_document)}</span>
																				<span title="View File" className="xui-cursor-pointer xui-mr-1" onClick={() => { showPreview(viewPartnerDetails.data.registration_document); }}>
																					<EyeOpen width="20" height="20" />
																				</span>
																			</div> :
																			<img className="xui-img-200" src={viewPartnerDetails.data.registration_document} alt="Registration Document" />
																	)
															) :
															<img className="xui-img-50" src={GalleryAdd} alt="" />
													}
													<span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
												</>
										}
									</div>
								</label>
								<input disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} onChange={handleSelectComplianceDocument} type={"file"} id="registrationDocument" style={{ display: "none" }} required />
								<div className="xui-mt-1">
									{
										uploadingComplianceDocumentPercentage > 0 ?
											<>
												<label htmlFor="uploader">Uploading</label>
												<progress className="xui-h-30" value={uploadingComplianceDocumentPercentage} id="uploader" max="100">{uploadingComplianceDocumentPercentage + "%"}</progress><br /><br></br>
											</> :
											""
									}
									{
										loadingComplianceDocument ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												<Loading width="16" height="16" />
											</button> :
											viewPartnerDetails ?
												(
													<>
														{
															viewPartnerDetails.data.verified ?
																<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
																	Verified
																</button> :
																<button type="submit" className="xui-btn psc-btn-blue xui-font-sz-80">
																	Upload
																</button>
														}
													</>
												) :
												<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
													Loading ...
												</button>
									}
								</div>
								<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorComplianceDocument}</span></p>
								<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successComplianceDocument}</span></p>
							</div>
						</form>
						<form className="xui-form" onSubmit={handleUploadComplianceCertificate}>
							<div className="xui-form-box xui-w-fluid-100 xui-mt-3">
								<label>Registration Certificate</label>
								<label htmlFor="registrationCertificate">
									<div className="xui-w-fluid-100 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
										{
											selectedComplianceCertificate ?
												<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{selectedComplianceCertificate.name}</span> :
												<>
													{
														viewPartnerDetails ?
															(
																viewPartnerDetails.data.registration_certificate === null ?
																	<img className="xui-img-50" src={GalleryAdd} alt="" /> :
																	(
																		getFileExtension(viewPartnerDetails.data.registration_certificate) === "pdf" || getFileExtension(viewPartnerDetails.data.registration_certificate) === "PDF" ?
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{getFileNameAlone(viewPartnerDetails.data.registration_certificate)}</span>
																				<span title="View File" className="xui-cursor-pointer xui-mr-1" onClick={() => { showPreview(viewPartnerDetails.data.registration_certificate); }}>
																					<EyeOpen width="20" height="20" />
																				</span>
																			</div> :
																			<img className="xui-img-200" src={viewPartnerDetails.data.registration_certificate} alt="Registration Certificate" />
																	)
															) :
															<img className="xui-img-50" src={GalleryAdd} alt="" />
													}
													<span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
												</>
										}
									</div>
								</label>
								<input disabled={viewPartnerDetails ? viewPartnerDetails.data.verified : false} onChange={handleSelectComplianceCertificate} type={"file"} id="registrationCertificate" style={{ display: "none" }} required />
								<div className="xui-mt-1">
									{
										uploadingComplianceCertificatePercentage > 0 ?
											<>
												<label htmlFor="uploader">Uploading</label>
												<progress className="xui-h-30" value={uploadingComplianceCertificatePercentage} id="uploader" max="100">{uploadingComplianceCertificatePercentage + "%"}</progress><br /><br></br>
											</> :
											""
									}
									{
										loadingComplianceCertificate ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												<Loading width="16" height="16" />
											</button> :
											viewPartnerDetails ?
												(
													<>
														{
															viewPartnerDetails.data.verified ?
																<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
																	Verified
																</button> :
																<button type="submit" className="xui-btn psc-btn-blue xui-font-sz-80">
																	Upload
																</button>
														}
													</>
												) :
												<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
													Loading ...
												</button>
									}
								</div>
								<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorComplianceCertificate}</span></p>
								<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successComplianceCertificate}</span></p>
							</div>
						</form>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="grantPartnerAccessModal" id="grantPartnerAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Grant Partner Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorGrantPartnerAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successGrantPartnerAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleGrantPartnerAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingGrantPartnerAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingGrantPartnerAccess ? "" : "grantPartnerAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>	
			<section className='xui-modal' xui-modal="suspendPartnerAccessModal" id="suspendPartnerAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Suspend Partner Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSuspendPartnerAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSuspendPartnerAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleSuspendPartnerAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingSuspendPartnerAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingSuspendPartnerAccess ? "" : "suspendPartnerAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="revokePartnerAccessModal" id="revokePartnerAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Revoke Partner Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRevokePartnerAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRevokePartnerAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleRevokePartnerAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingRevokePartnerAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingRevokePartnerAccess ? "" : "revokePartnerAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="updateWebsiteURLModal" id="updateWebsiteURLModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Update {viewPartnerDetails && viewPartnerDetails.success ? (viewPartnerDetails.data.name) : ""} website URL</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateWebsiteURL}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateWebsiteURL}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleUpdateWebsiteURL} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingUpdateWebsiteURL ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUpdateWebsiteURL ? "" : "updateWebsiteURLModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="verifyPartnerModal" id="verifyPartnerModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify {viewPartnerDetails && viewPartnerDetails.success ? (viewPartnerDetails.data.name) : ""} Partner</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyPartner}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyPartner}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleVerifyPartner} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingVerifyPartner ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyPartner ? "" : "verifyPartnerModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="unverifyPartnerModal" id="unverifyPartnerModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Unverify {viewPartnerDetails && viewPartnerDetails.success ? (viewPartnerDetails.data.name) : ""} Partner</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUnverifyPartner}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUnverifyPartner}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleUnverifyPartner} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingUnverifyPartner ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUnverifyPartner ? "" : "unverifyPartnerModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="partnerSearchModal" id="partnerSearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="partnerSearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for Partner</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getPartnerDetailsViaEmail(partnerEmailSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Email</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"email"} placeholder={"Enter email address"} value={partnerEmailSearch} onChange={handlePartnerEmailSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open={`${partnerEmailSearch ? "viewPartnerDetailsModal" : ""}`}>
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	);
}
