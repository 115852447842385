import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { config } from "./config";
import useCookie from "./hooks/useCookie";
import Layout from "./pages/Layout";
import Access from "./pages/Access";
import Dashboard from "./pages/Dashboard";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import Businesses from "./pages/Businesses";
import Platforms from "./pages/Partners";
import SignIn from "./pages/auth/SignIn";
import ApiKeys from "./pages/ApiKeys";
import ApiCriteria from "./pages/ApiCriteria";
import Address from "./pages/Address";
import BVN from "./pages/BVN";
import NIN from "./pages/NIN";
import AdditionalQualification from "./pages/AdditionalQualifications";
import PrimarySchool from "./pages/PrimarySchool";
import SecondarySchool from "./pages/SecondarySchool";
import TertiaryInstitution from "./pages/TertiaryInstitution";
import Passports from "./pages/Passports";
import DriverLicences from "./pages/DriverLicences";
import VoterCards from "./pages/VoterCards";
import TaxRecords from "./pages/TaxRecords";
import CACs from "./pages/CACs";
import WorkHistories from "./pages/WorkHistories";
import PurplepayTransactions from "./pages/PurplepayTransactions";
import PurplepayLogs from "./pages/PurplepayLogs";
import TransferLogs from "./pages/TransferLogs";
import BillLogs from "./pages/BillLogs";
import AppDefaults from "./pages/AppDefaults";
import Mails from "./pages/Mails";
import MailingTemplates from "./pages/MailingTemplates";
import Resources from "./pages/Resources";
import Gallery from "./pages/Gallery";
import Newsletter from "./pages/Newsletter";

export default function App(){
  const {cookie} = useCookie(config.key, "");

  return(
    <BrowserRouter>
      <Routes>
        <Route path='/internal' element={<Layout />}>
          <Route path="dashboard" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Dashboard />)
          } />
          <Route path="api/keys" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<ApiKeys />)
          } />
          <Route path="api/criteria" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<ApiCriteria />)
          } />
          <Route path="app/defaults" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<AppDefaults />)
          } />
          <Route path="transactions" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Transactions />)
          } />
          <Route path="purplepay-transactions" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<PurplepayTransactions />)
          } />
          <Route path="purplepay-logs" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<PurplepayLogs />)
          } />
          <Route path="transfer-logs" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<TransferLogs />)
          } />
          <Route path="bill-logs" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<BillLogs />)
          } />
          <Route path="nin" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
            (<Navigate replace to={"/signin"} />) : 
            (<NIN />)
          } />
          <Route path="bvn" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
            (<Navigate replace to={"/signin"} />) : 
            (<BVN />)
          } />
          <Route path="address" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
            (<Navigate replace to={"/signin"} />) : 
            (<Address />)
          } />
          <Route path="users" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Users />)
          } />
          <Route path="additional-qualifications" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<AdditionalQualification />)
          } />
          <Route path="primary-school" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<PrimarySchool />)
          } />
          <Route path="secondary-school" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<SecondarySchool />)
          } />
          <Route path="tertiary-institution" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<TertiaryInstitution />)
          } />
          <Route path="work-history" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<WorkHistories />)
          } />
          <Route path="passport" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<Passports />)
          } />
          <Route path="driver-licence" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<DriverLicences />)
          } />
          <Route path="voter-card" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<VoterCards />)
          } />
          <Route path="tax-record" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<TaxRecords />)
          } />
          <Route path="cac" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
             (<Navigate replace to={"/signin"} />) :
             (<CACs />)
          } />
          <Route path="partners" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Platforms />)
          } />
          <Route path="businesses" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Businesses />)
          } />
          <Route path="mails" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Mails />)
          } />
          <Route path="mailing/templates" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<MailingTemplates />)
          } />
          <Route path="resources" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Resources />)
          } />
          <Route path="gallery" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Gallery />)
          } />
          <Route path="newsletter" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Newsletter />)
          } />
          <Route path="settings" element={
            !cookie || cookie === '' || cookie === '[object Object]' ?
              (<Navigate replace to={"/signin"} />) :
              (<Dashboard />)
          } />
          <Route path="*" element={<Navigate replace to={"dashboard"} />} />
        </Route>
        <Route path='/' element={<Access />}>
          <Route index element={<SignIn />} />
          <Route path="signin" element={
            cookie && cookie !== '' && cookie !== '[object Object]' ?
              (<Navigate replace to={"/internal/dashboard"} />) :
              (<SignIn />)
          } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}