import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addApiCriteria, deleteApiCriteria, disableApiCriteria, editApiCriteria, enableApiCriteria } from "../api/apiCriteria";

const useAddApiCriteria = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingAddApiCriteria, setLoadingAddApiCriteria] = useState(false);
	const [removeAddApiCriteriaModal, setRemoveAddApiCriteriaModal] = useState(null);
	const [criteria, setCriteria] = useState(null);
	const [details, setDetails] = useState(null);
	const [amount, setAmount] = useState(null);

	const [errorAddApiCriteria, setErrorAddApiCriteria] = useState(null);
	const [successAddApiCriteria, setSuccessAddApiCriteria] = useState(null);

	const handleCriteria = (e) => { e.preventDefault(); setCriteria(e.target.value.toLocaleUpperCase()); };
	const handleDetails = (e) => { e.preventDefault(); setDetails(e.target.value); };
	const handleAmount = (e) => { e.preventDefault(); setAmount(e.target.value); };

	const handleAddApiCriteria = (e) => {
		e.preventDefault();

		if (!loadingAddApiCriteria) {
			if (!criteria) {
				setErrorAddApiCriteria(null);
				setSuccessAddApiCriteria(null);
				setErrorAddApiCriteria("Criteria is required");
				setTimeout(function () {
					setErrorAddApiCriteria(null);
				}, 2500)
			} else if (details && details.length > 200) {
				setErrorAddApiCriteria("Details maximum characters - 200");
				setTimeout(function () {
					setErrorAddApiCriteria(null);
				}, 2500)
			} else if (!amount) {
				setErrorAddApiCriteria("Amount is required");
				setTimeout(function () {
					setErrorAddApiCriteria(null);
				}, 2500)
			} else if (amount < 0) {
				setErrorAddApiCriteria("Minimum amount is 0");
				setTimeout(function () {
					setErrorAddApiCriteria(null);
				}, 2500)
			} else {
				setLoadingAddApiCriteria(true);

				const addApiCriteriaRes = addApiCriteria(cookie, {
					criteria: criteria,
					details: details === null ? undefined : details,
					amount: parseInt(amount)
				})

				addApiCriteriaRes.then(res => {
					setLoadingAddApiCriteria(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddApiCriteria(error);
							setTimeout(function () {
								setErrorAddApiCriteria(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddApiCriteria(error);
							setTimeout(function () {
								setErrorAddApiCriteria(null);
							}, 2000)
						}
					} else {
						setErrorAddApiCriteria(null);
						setSuccessAddApiCriteria(`API Criteria added successfully!`);

						setTimeout(function () {
							setSuccessAddApiCriteria(null);
							setRemoveAddApiCriteriaModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingAddApiCriteria(false);
				})

			}
		}
	};

	return {
		cookie, criteria, details, amount, loadingAddApiCriteria, setRemoveAddApiCriteriaModal, errorAddApiCriteria, successAddApiCriteria, 
		setDetails, setAmount, handleAddApiCriteria, handleCriteria, handleDetails, handleAmount, setCriteria, removeAddApiCriteriaModal
	};
};

const useEditApiCriteria = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingEditApiCriteria, setLoadingEditApiCriteria] = useState(false);
	const [removeEditApiCriteriaModal, setRemoveEditApiCriteriaModal] = useState(null);
	const [criteria, setCriteria] = useState(null);
	const [details, setDetails] = useState(null);
	const [amount, setAmount] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorEditApiCriteria, setErrorEditApiCriteria] = useState(null);
	const [successEditApiCriteria, setSuccessEditApiCriteria] = useState(null);

	const handleCriteria = (e) => { e.preventDefault(); setCriteria(e.target.value.toLocaleUpperCase()); };
	const handleDetails = (e) => { e.preventDefault(); setDetails(e.target.value); };
	const handleAmount = (e) => { e.preventDefault(); setAmount(e.target.value); };

	const handleEditApiCriteria = (e) => {
		e.preventDefault();

		if (!loadingEditApiCriteria) {
			if (!uniqueId) {
				setErrorEditApiCriteria(null);
				setSuccessEditApiCriteria(null);
				setErrorEditApiCriteria("Unique ID is required");
				setTimeout(function () {
					setErrorEditApiCriteria(null);
				}, 2500)
			} else if (!criteria) {
				setErrorEditApiCriteria("Criteria is required");
				setTimeout(function () {
					setErrorEditApiCriteria(null);
				}, 2500)
			} else if (details && details.length > 200) {
				setErrorEditApiCriteria("Details maximum characters - 200");
				setTimeout(function () {
					setErrorEditApiCriteria(null);
				}, 2500)
			} else if (!amount) {
				setErrorEditApiCriteria("Amount is required");
				setTimeout(function () {
					setErrorEditApiCriteria(null);
				}, 2500)
			} else if (amount < 0) {
				setErrorEditApiCriteria("Minimum amount is 0");
				setTimeout(function () {
					setErrorEditApiCriteria(null);
				}, 2500)
			} else {
				setLoadingEditApiCriteria(true);

				const editApiCriteriaRes = editApiCriteria(cookie, {
					unique_id: uniqueId,
					criteria: criteria,
					details: details === null ? undefined : details,
					amount: parseInt(amount)
				})

				editApiCriteriaRes.then(res => {
					setLoadingEditApiCriteria(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditApiCriteria(error);
							setTimeout(function () {
								setErrorEditApiCriteria(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditApiCriteria(error);
							setTimeout(function () {
								setErrorEditApiCriteria(null);
							}, 2000)
						}
					} else {
						setErrorEditApiCriteria(null);
						setSuccessEditApiCriteria(`API Criteria edited successfully!`);

						setTimeout(function () {
							setSuccessEditApiCriteria(null);
							setRemoveEditApiCriteriaModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditApiCriteria(false);
				})

			}
		}
	};

	return {
		cookie, criteria, details, amount, loadingEditApiCriteria, setRemoveEditApiCriteriaModal, errorEditApiCriteria, successEditApiCriteria,
		setDetails, setAmount, handleEditApiCriteria, handleCriteria, handleDetails, handleAmount, setCriteria, removeEditApiCriteriaModal, setUniqueId
	};
};

const useDisableApiCriteria = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDisableApiCriteria, setLoadingDisableApiCriteria] = useState(false);
	const [removeDisableApiCriteriaModal, setRemoveDisableApiCriteriaModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDisableApiCriteria, setErrorDisableApiCriteria] = useState(null);
	const [successDisableApiCriteria, setSuccessDisableApiCriteria] = useState(null);

	const handleDisableApiCriteria = () => {

		if (!loadingDisableApiCriteria) {
			if (!uniqueId) {
				setErrorDisableApiCriteria(null);
				setSuccessDisableApiCriteria(null);
				setErrorDisableApiCriteria("Unique ID is required");
				setTimeout(function () {
					setErrorDisableApiCriteria(null);
				}, 2500)
			} else {
				setLoadingDisableApiCriteria(true);

				const disableApiCriteriaRes = disableApiCriteria(cookie, {
					unique_id: uniqueId
				})

				disableApiCriteriaRes.then(res => {
					setLoadingDisableApiCriteria(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDisableApiCriteria(error);
							setTimeout(function () {
								setErrorDisableApiCriteria(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDisableApiCriteria(error);
							setTimeout(function () {
								setErrorDisableApiCriteria(null);
							}, 2000)
						}
					} else {
						setErrorDisableApiCriteria(null);
						setSuccessDisableApiCriteria(`API Criteria Disabled successfully!`);

						setTimeout(function () {
							setSuccessDisableApiCriteria(null);
							setRemoveDisableApiCriteriaModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDisableApiCriteria(false);
				})

			}
		}
	};

	return {
		cookie, loadingDisableApiCriteria, removeDisableApiCriteriaModal, errorDisableApiCriteria, successDisableApiCriteria, handleDisableApiCriteria,
		setRemoveDisableApiCriteriaModal, setUniqueId
	};
};

const useEnableApiCriteria = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingEnableApiCriteria, setLoadingEnableApiCriteria] = useState(false);
	const [removeEnableApiCriteriaModal, setRemoveEnableApiCriteriaModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorEnableApiCriteria, setErrorEnableApiCriteria] = useState(null);
	const [successEnableApiCriteria, setSuccessEnableApiCriteria] = useState(null);

	const handleEnableApiCriteria = () => {

		if (!loadingEnableApiCriteria) {
			if (!uniqueId) {
				setErrorEnableApiCriteria(null);
				setSuccessEnableApiCriteria(null);
				setErrorEnableApiCriteria("Unique ID is required");
				setTimeout(function () {
					setErrorEnableApiCriteria(null);
				}, 2500)
			} else {
				setLoadingEnableApiCriteria(true);

				const enableApiCriteriaRes = enableApiCriteria(cookie, {
					unique_id: uniqueId
				})

				enableApiCriteriaRes.then(res => {
					setLoadingEnableApiCriteria(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEnableApiCriteria(error);
							setTimeout(function () {
								setErrorEnableApiCriteria(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEnableApiCriteria(error);
							setTimeout(function () {
								setErrorEnableApiCriteria(null);
							}, 2000)
						}
					} else {
						setErrorEnableApiCriteria(null);
						setSuccessEnableApiCriteria(`API Criteria Enabled successfully!`);

						setTimeout(function () {
							setSuccessEnableApiCriteria(null);
							setRemoveEnableApiCriteriaModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEnableApiCriteria(false);
				})

			}
		}
	};

	return {
		cookie, loadingEnableApiCriteria, removeEnableApiCriteriaModal, errorEnableApiCriteria, successEnableApiCriteria, handleEnableApiCriteria,
		setRemoveEnableApiCriteriaModal, setUniqueId
	};
};

const useDeleteApiCriteria = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteApiCriteria, setLoadingDeleteApiCriteria] = useState(false);
	const [removeDeleteApiCriteriaModal, setRemoveDeleteApiCriteriaModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDeleteApiCriteria, setErrorDeleteApiCriteria] = useState(null);
	const [successDeleteApiCriteria, setSuccessDeleteApiCriteria] = useState(null);

	const handleDeleteApiCriteria = () => {

		if (!loadingDeleteApiCriteria) {
			if (!uniqueId) {
				setErrorDeleteApiCriteria(null);
				setSuccessDeleteApiCriteria(null);
				setErrorDeleteApiCriteria("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteApiCriteria(null);
				}, 2500)
			} else {
				setLoadingDeleteApiCriteria(true);

				const deleteApiCriteriaRes = deleteApiCriteria(cookie, {
					unique_id: uniqueId
				})

				deleteApiCriteriaRes.then(res => {
					setLoadingDeleteApiCriteria(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteApiCriteria(error);
							setTimeout(function () {
								setErrorDeleteApiCriteria(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteApiCriteria(error);
							setTimeout(function () {
								setErrorDeleteApiCriteria(null);
							}, 2000)
						}
					} else {
						setErrorDeleteApiCriteria(null);
						setSuccessDeleteApiCriteria(`API Criteria deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteApiCriteria(null);
							setRemoveDeleteApiCriteriaModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteApiCriteria(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteApiCriteria, removeDeleteApiCriteriaModal, errorDeleteApiCriteria, successDeleteApiCriteria, handleDeleteApiCriteria,
		setRemoveDeleteApiCriteriaModal, setUniqueId
	};
};

export { useAddApiCriteria, useEditApiCriteria, useDisableApiCriteria, useEnableApiCriteria, useDeleteApiCriteria };