import axios from 'axios'
import { config } from '../config'

async function getPschools(key, page, size){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/primary/schools`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/primary/school/via/user`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserPschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/primary/school/verify`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserPschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/primary/school/decline`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPschoolViaPID(key, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/primary/school/via/user`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPschoolsViaVerifiedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/primary/schools/via/verification`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPschoolsViaProofType(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/addresses/via/type`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
    getPschools, getPschool, verifyUserPschool,declineUserPschool, 
	getPschoolViaPID, getPschoolsViaVerifiedStatus, getPschoolsViaProofType
}