import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { sendNewsletter, retryEmail } from "../api/mailing";

const useSendNewsletter = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingSendNewsletter, setLoadingSendNewsletter] = useState(false);
	const [removeSendNewsletterModal, setRemoveSendNewsletterModal] = useState(null);
	const [email, setEmail] = useState("");
	const [template, setTemplate] = useState("");
	const [subject, setSubject] = useState("");
	const [details, setDetails] = useState("");

	const [errorSendNewsletter, setErrorSendNewsletter] = useState(null);
	const [successSendNewsletter, setSuccessSendNewsletter] = useState(null);

	const handleEmail = (e) => { e.preventDefault(); setEmail(e.target.value.toLocaleLowerCase()); };
	const handleTemplate = (e) => { e.preventDefault(); setTemplate(e.target.value); };
	const handleSubject = (e) => { e.preventDefault(); setSubject(e.target.value); };
	const handleDetails = (contents) => { setDetails(contents); };

	const handleSubmitSendNewsletter = (e) => {
		e.preventDefault();

		if (!loadingSendNewsletter) {
			if (email.length < 1) {
				setErrorSendNewsletter(null);
				setSuccessSendNewsletter(null);
				setErrorSendNewsletter("Email is required");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else if (template.length < 1) {
				setErrorSendNewsletter("Template is required");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else if (subject.length < 3) {
				setErrorSendNewsletter("Subject is required | Min character - 3");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else if (subject.length > 50) {
				setErrorSendNewsletter("Invalid Subject | Max character - 50");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else if (details.length < 3) {
				setErrorSendNewsletter("Details is required | Min character - 3");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else if (details.length > 65535) {
				setErrorSendNewsletter("Invalid Details | Max length reached");
				setTimeout(function () {
					setErrorSendNewsletter(null);
				}, 2500)
			} else {
				setLoadingSendNewsletter(true);

				const sendNewsletterRes = sendNewsletter(cookie, {
					email,
					template,
					subject,
					details
				})

				sendNewsletterRes.then(res => {
					setLoadingSendNewsletter(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorSendNewsletter(error);
							setTimeout(function () {
								setErrorSendNewsletter(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorSendNewsletter(error);
							setTimeout(function () {
								setErrorSendNewsletter(null);
							}, 2000)
						}
					} else {
						setErrorSendNewsletter(null);
						setSuccessSendNewsletter(`${res.data.message}`);

						setTimeout(function () {
							setSuccessSendNewsletter(null);
							setRemoveSendNewsletterModal(true);
							setEmail(""); setTemplate(""); setSubject(""); setDetails("");
						}, 2500)
					}
				}).catch(err => {
					setLoadingSendNewsletter(false);
				})

			}
		}
	};

	return {
		cookie, loadingSendNewsletter, removeSendNewsletterModal, email, subject, template, details, errorSendNewsletter, successSendNewsletter,
		handleEmail, handleSubject, handleTemplate, handleDetails, handleSubmitSendNewsletter, setRemoveSendNewsletterModal
	};
};

const useRetryEmail = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRetryEmail, setLoadingRetryEmail] = useState(false);
	const [removeRetryEmailModal, setRemoveRetryEmailModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorRetryEmail, setErrorRetryEmail] = useState(null);
	const [successRetryEmail, setSuccessRetryEmail] = useState(null);

	const handleRetryEmail = () => {

		if (!loadingRetryEmail) {
			if (!uniqueId) {
				setErrorRetryEmail(null);
				setSuccessRetryEmail(null);
				setErrorRetryEmail("Unique ID is required");
				setTimeout(function () {
					setErrorRetryEmail(null);
				}, 2500)
			} else {
				setLoadingRetryEmail(true);

				const retryEmailRes = retryEmail(cookie, {
					unique_id: uniqueId
				})

				retryEmailRes.then(res => {
					setLoadingRetryEmail(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRetryEmail(error);
							setTimeout(function () {
								setErrorRetryEmail(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRetryEmail(error);
							setTimeout(function () {
								setErrorRetryEmail(null);
							}, 2000)
						}
					} else {
						setErrorRetryEmail(null);
						setSuccessRetryEmail(`Email retried successfully!`);

						setTimeout(function () {
							setSuccessRetryEmail(null);
							setRemoveRetryEmailModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRetryEmail(false);
				})

			}
		}
	};

	return {
		cookie, loadingRetryEmail, removeRetryEmailModal, errorRetryEmail, successRetryEmail, handleRetryEmail,
		setRemoveRetryEmailModal, setUniqueId
	};
};

export { useSendNewsletter, useRetryEmail };