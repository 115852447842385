import axios from 'axios'
import { config } from '../config'

async function getPassports(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/passports`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPassport(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/passport/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserPassport(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/passport/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserPassport(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/passport/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPassportViaPID(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/passport/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getPassportsViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/passports/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getPassports, getPassport, verifyUserPassport, declineUserPassport, getPassportViaPID, getPassportsViaVerifiedStatus,
}