import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserSschool, verifyUserSschool } from "../api/secondarySchool";
import { config } from "../config";

function useVerifyUserSschool(){
    const { cookie } = useCookie(config.key, "");

    const [loadingVerifyUserSschool, setLoadingVerifyUserSschool] = useState(false)
    const [removeVeriyUserSschoolModal, setRemoveVerifyUserSschoolModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [sschoolUniqueId, setSschoolUniqueId] = useState(null)

    const [errorVerifyUserSschool, setErrorVerifyUserSschool] = useState(null)
    const [successVerifyUserSschool, setSuccessVerifyUserSschool] = useState(null)

    const handleVerifyUserSschool = ()=>{
        if(!loadingVerifyUserSschool){
            if(!userPID || !sschoolUniqueId){
                setErrorVerifyUserSschool(null)
                setSuccessVerifyUserSschool(null)
                setErrorVerifyUserSschool(userPID ? "Unique id is required" : "User PID is required")
                setTimeout(function(){
                    setErrorVerifyUserSschool(null)
                }, 2500)
            }else{
                setLoadingVerifyUserSschool(true)

                const verifyUserSschoolRes = verifyUserSschool(cookie, {pid: userPID, unique_id: sschoolUniqueId})

                verifyUserSschoolRes.then((res)=>{
                
                    setLoadingVerifyUserSschool(false)
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorVerifyUserSschool(error)
                            setTimeout(()=>{
                                setErrorVerifyUserSschool(null)
                            }, 2000)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorVerifyUserSschool(error)
                            setTimeout(()=>{
                                setErrorVerifyUserSschool(null)
                            }, 2000)
                        }
                    } else {
                        setErrorVerifyUserSschool(null)
                        setSuccessVerifyUserSschool("User secondary school verified")

                        setTimeout(()=>{
                            setSuccessVerifyUserSschool(null)
                            setRemoveVerifyUserSschoolModal(true)
                            setUserPID(null)
                            setSschoolUniqueId(null)
                        }, 2500)
                    }
                }).catch(err=>{
                    setLoadingVerifyUserSschool(false)
                })
            }

        }
    }

    return{
        cookie, loadingVerifyUserSschool, successVerifyUserSschool, errorVerifyUserSschool, removeVeriyUserSschoolModal, setRemoveVerifyUserSschoolModal, handleVerifyUserSschool,
        userPID, setUserPID, sschoolUniqueId, setSschoolUniqueId
    }
}

function useDeclineUserSschool(){
    const{cookie}= useCookie(config.key, "");

    const [loadingDeclineSschool, setLoadingDeclineSschool] = useState(false)
    const [removeDeclineSschoolModal, setRemoveDeclineSschoolModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [sschoolUniqueId, setSschoolUniqueId] = useState(null)
    const [declineMsg, setDeclineMsg] = useState(null)

    const [errorDeclineSschool, setErrorDeclineSschool] = useState(null)
    const [successDeclineSschool, setSuccessDeclineSschool] = useState(null)

    const handleDeclineSschool = ()=>{
        if(!loadingDeclineSschool){
            if(!userPID || !sschoolUniqueId){
                setErrorDeclineSschool(null)
                setSuccessDeclineSschool(null)
                setErrorDeclineSschool(userPID ? "Sschool uniqueId is required": "User PID is required")
                setTimeout(()=>{
                    setErrorDeclineSschool(null)
                }, 2500)
            }else{
                setLoadingDeclineSschool(true)

                const declineUserSschoolRes = declineUserSschool(cookie, {pid: userPID, unique_id: sschoolUniqueId, message: declineMsg})

                declineUserSschoolRes.then((res)=>{
                    setLoadingDeclineSschool(false)
                
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorDeclineSschool(error)
                            setTimeout(()=>{
                                setErrorDeclineSschool(null)
                            }, 2500)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorDeclineSschool(error)
                            setTimeout(()=>{
                                setErrorDeclineSschool(null)
                            }, 2500)
                        }
                    }else{
                        setErrorDeclineSschool(null)
                        setDeclineMsg("")
                        setSuccessDeclineSschool("User secondary school declined")

                        setTimeout(()=>{
                            setSuccessDeclineSschool(null)
                            setRemoveDeclineSschoolModal(true)
                            setUserPID(null)
                            setSschoolUniqueId(null)
                        }, 2500)
                    }
                })
            }
        }
    }

    return {
        errorDeclineSschool, successDeclineSschool, loadingDeclineSschool, 
        userPID, setUserPID, sschoolUniqueId, setSschoolUniqueId, declineMsg, setDeclineMsg, handleDeclineSschool,
        removeDeclineSschoolModal, setRemoveDeclineSschoolModal
    }
}

export {useVerifyUserSschool, useDeclineUserSschool}