import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getAllApiCriteria } from "../api/apiCriteria";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";
import { useAddApiCriteria, useDeleteApiCriteria, useDisableApiCriteria, useEditApiCriteria, useEnableApiCriteria } from "../hooks/useApiCriteria";
import Edit from "../icons/Edit";

export default function ApiCriteria() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		amount, criteria, details, errorAddApiCriteria, handleAddApiCriteria, handleAmount, handleCriteria, handleDetails, loadingAddApiCriteria, 
		removeAddApiCriteriaModal, setRemoveAddApiCriteriaModal, successAddApiCriteria
	} = useAddApiCriteria();

	const {
		amount: amountEdit, criteria: criteriaEdit, details: detailsEdit, errorEditApiCriteria, handleEditApiCriteria, handleAmount: handleAmountEdit, 
		handleCriteria: handleCriteriaEdit, handleDetails: handleDetailsEdit, loadingEditApiCriteria, removeEditApiCriteriaModal, setRemoveEditApiCriteriaModal, 
		successEditApiCriteria, setAmount: setAmountEdit, setCriteria: setCriteriaEdit, setDetails: setDetailsEdit, setUniqueId: EditUniqueId
	} = useEditApiCriteria();

	const {
		errorDeleteApiCriteria, handleDeleteApiCriteria, loadingDeleteApiCriteria, removeDeleteApiCriteriaModal, setUniqueId: DeleteUniqueId,
		setRemoveDeleteApiCriteriaModal, successDeleteApiCriteria
	} = useDeleteApiCriteria();

	const {
		errorDisableApiCriteria, handleDisableApiCriteria, loadingDisableApiCriteria, removeDisableApiCriteriaModal, setUniqueId: DisableUniqueId,
		setRemoveDisableApiCriteriaModal, successDisableApiCriteria
	} = useDisableApiCriteria();

	const {
		errorEnableApiCriteria, handleEnableApiCriteria, loadingEnableApiCriteria, removeEnableApiCriteriaModal, setUniqueId: EnableUniqueId,
		setRemoveEnableApiCriteriaModal, successEnableApiCriteria
	} = useEnableApiCriteria();

	const [allApiCriteria, setAllApiCriteria] = useState(null);
	const [errorApiCriteria, setErrorApiCriteria] = useState(null);
	const [loadingAllApiCriteria, setLoadingAllApiCriteria] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getApiCriteria(page, e.target.value); };

	async function previousApiCriteria() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getApiCriteria(page - 1, size);
	};

	async function nextApiCriteria() {
		if (page < allApiCriteria.data.pages) setPage(page + 1);
		if (page < allApiCriteria.data.pages) getApiCriteria(page + 1, size);
	};

	async function getApiCriteria(_page, _size) {
		setLoadingAllApiCriteria(true);
		const response = await getAllApiCriteria(cookie, (_page || page), (_size || size));
		setAllApiCriteria(response.data);
		if (response.error) setErrorApiCriteria(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllApiCriteria(false);
	};

	useEffect(() => {
		if (allApiCriteria === null) {
			getApiCriteria();
		}
	}, [allApiCriteria]);

	if (removeAddApiCriteriaModal) {
		const modalResponse = document.querySelector("#addApiCriteriaModal");
		modalResponse.setAttribute("display", false);
		getApiCriteria();
		setRemoveAddApiCriteriaModal(null);
	}
	if (removeEditApiCriteriaModal) {
		const modalResponse = document.querySelector("#editApiCriteriaModal");
		modalResponse.setAttribute("display", false);
		getApiCriteria();
		setRemoveEditApiCriteriaModal(null);
	}
	if (removeDeleteApiCriteriaModal) {
		const modalResponse = document.querySelector("#deleteApiCriteriaModal");
		modalResponse.setAttribute("display", false);
		getApiCriteria();
		setRemoveDeleteApiCriteriaModal(null);
	}
	if (removeEnableApiCriteriaModal) {
		const modalResponse = document.querySelector("#enableApiCriteriaModal");
		modalResponse.setAttribute("display", false);
		getApiCriteria();
		setRemoveEnableApiCriteriaModal(null);
	}
	if (removeDisableApiCriteriaModal) {
		const modalResponse = document.querySelector("#disableApiCriteriaModal");
		modalResponse.setAttribute("display", false);
		getApiCriteria();
		setRemoveDisableApiCriteriaModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All API Criteria</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all API criteria</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="addApiCriteriaModal">
										<span className="xui-mr-half">Add Criteria</span>
										<Plus width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllApiCriteria ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allApiCriteria && allApiCriteria.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Criteria</th>
														<th className='xui-min-w-150'>Amount</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-200'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allApiCriteria.data.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.criteria}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.amount === 0 ? "Free" : "NGN " + data.amount.toLocaleString()}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	data.status === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Edit API Criteria" onClick={() => { EditUniqueId(data.unique_id); setCriteriaEdit(data.criteria); setAmountEdit(data.amount); setDetailsEdit(data.details === null ? "" : data.details) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="editApiCriteriaModal">
																		<Edit width="20" height="20" />
																	</button>
																	{
																		data.status === 1 ? 
																			<button title="Disable API Criteria" onClick={() => { DisableUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn xui-bg-warning xui-text-white xui-bdr-rad-half xui-font-sz-50" xui-modal-open="disableApiCriteriaModal">
																				<Close width="16" height="16" />
																			</button> : 
																			<button title="Enable API Criteria" onClick={() => { EnableUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="enableApiCriteriaModal">
																				<Check width="16" height="16" />
																			</button>
																	}
																	<button title="Delete API Criteria" onClick={() => { DeleteUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteApiCriteriaModal">
																		<Delete width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorApiCriteria}</h3>
												</center>
											</div>
										</div>
								)
						}
						{/* {
							loadingAllApiCriteria ?
								<Loading width="12" height="12" /> :
								(
									allApiCriteria && allApiCriteria.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'>{page}</span> of {allApiCriteria ? allApiCriteria.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousApiCriteria}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextApiCriteria}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						} */}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="enableApiCriteriaModal" id="enableApiCriteriaModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Enable API Criteria</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEnableApiCriteria}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEnableApiCriteria}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleEnableApiCriteria} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingEnableApiCriteria ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingEnableApiCriteria ? "" : "enableApiCriteriaModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="disableApiCriteriaModal" id="disableApiCriteriaModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Disable API Criteria</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDisableApiCriteria}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDisableApiCriteria}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDisableApiCriteria} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDisableApiCriteria ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDisableApiCriteria ? "" : "disableApiCriteriaModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="deleteApiCriteriaModal" id="deleteApiCriteriaModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete API Criteria</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteApiCriteria}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteApiCriteria}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteApiCriteria} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteApiCriteria ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteApiCriteria ? "" : "deleteApiCriteriaModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="addApiCriteriaModal" id="addApiCriteriaModal">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="addApiCriteriaModal">
						<Close width="24" height="24" />
					</div>
					<h1>Create new API Criteria</h1>
					<form className="xui-form" layout="2" onSubmit={handleAddApiCriteria}>
						<div className="xui-form-box xui-mt-2">
							<label>Criteria</label>
							<input className="xui-font-sz-90" type="text" value={criteria} onChange={handleCriteria} required placeholder="Enter a valid criteria"></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Amount</label>
							<input className="xui-font-sz-90" min={0} required onChange={handleAmount} value={amount} type={"number"} />
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Type</label>
							<textarea className="xui-font-sz-90" value={details} onChange={handleDetails} cols={20} rows={20} placeholder="Details ?"></textarea>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save Criteria</span>
								{
									loadingAddApiCriteria ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddApiCriteria}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddApiCriteria}</span></p>
				</div>
			</section>
			<section className='xui-modal' xui-modal="editApiCriteriaModal" id="editApiCriteriaModal">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="editApiCriteriaModal">
						<Close width="24" height="24" />
					</div>
					<h1>Edit API Criteria</h1>
					<form className="xui-form" layout="2" onSubmit={handleEditApiCriteria}>
						<div className="xui-form-box xui-mt-2">
							<label>Criteria</label>
							<input className="xui-font-sz-90" type="text" value={criteriaEdit} disabled onChange={handleCriteriaEdit} required placeholder="Enter a valid criteria"></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Amount</label>
							<input className="xui-font-sz-90" min={0} required onChange={handleAmountEdit} value={amountEdit} type={"number"} />
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Details (optional)</label>
							<textarea className="xui-font-sz-90" value={detailsEdit} onChange={handleDetailsEdit} cols={20} rows={20} placeholder="Details ?"></textarea>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save Changes</span>
								{
									loadingEditApiCriteria ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditApiCriteria}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditApiCriteria}</span></p>
				</div>
			</section>
		</>
	);

}
