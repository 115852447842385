import axios from 'axios';
import { config } from '../config';

const getPurplepayLogs = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/logs`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayLogsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/logs/via/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayLogsViaSender = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/logs/via/sender`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayLogsViaReceiver = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/logs/via/receiver`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayLogsViaReference = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/logs/via/reference`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getPurplepayLogs, getPurplepayLogsViaStatus, getPurplepayLogsViaSender, getPurplepayLogsViaReceiver, getPurplepayLogsViaReference };