import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { 
	completeBusinessDeposit, completePartnerDeposit, completeBusinessWithdrawal, completePartnerWithdrawal, 
	completeUserDeposit, completeUserWithdrawal, reverseUserTransfer, deleteTransaction, reverseUserBill
} from "../api/transactions";

const useCompletePartnerDeposit = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompletePartnerDeposit, setLoadingCompletePartnerDeposit] = useState(false);
	const [removeCompletePartnerDepositModal, setRemoveCompletePartnerDepositModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [partnerDepositUniqueId, setPartnerDepositUniqueId] = useState(null);

	const [errorCompletePartnerDeposit, setErrorCompletePartnerDeposit] = useState(null);
	const [successCompletePartnerDeposit, setSuccessCompletePartnerDeposit] = useState(null);

	const handleCompletePartnerDeposit = () => {

		if (!loadingCompletePartnerDeposit) {
			if (!partnerUniqueId) {
				setErrorCompletePartnerDeposit(null);
				setSuccessCompletePartnerDeposit(null);
				setErrorCompletePartnerDeposit("Partner Unique ID is required");
				setTimeout(function () {
					setErrorCompletePartnerDeposit(null);
				}, 2500)
			} else if (!partnerDepositUniqueId) {
				setErrorCompletePartnerDeposit("Unique ID is required");
				setTimeout(function () {
					setErrorCompletePartnerDeposit(null);
				}, 2500)
			} else {
				setLoadingCompletePartnerDeposit(true);

				const completePartnerDepositRes = completePartnerDeposit(cookie, {
					partner_unique_id: partnerUniqueId,
					unique_id: partnerDepositUniqueId
				})

				completePartnerDepositRes.then(res => {
					setLoadingCompletePartnerDeposit(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompletePartnerDeposit(error);
							setTimeout(function () {
								setErrorCompletePartnerDeposit(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompletePartnerDeposit(error);
							setTimeout(function () {
								setErrorCompletePartnerDeposit(null);
							}, 2000)
						}
					} else {
						setErrorCompletePartnerDeposit(null);
						setSuccessCompletePartnerDeposit(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompletePartnerDeposit(null);
							setRemoveCompletePartnerDepositModal(true);
							setPartnerUniqueId(null);
							setPartnerDepositUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompletePartnerDeposit(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompletePartnerDeposit, removeCompletePartnerDepositModal, partnerDepositUniqueId, errorCompletePartnerDeposit, successCompletePartnerDeposit,
		handleCompletePartnerDeposit, setRemoveCompletePartnerDepositModal, setPartnerDepositUniqueId, setPartnerUniqueId
	};
};

const useCompletePartnerWithdrawal = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompletePartnerWithdrawal, setLoadingCompletePartnerWithdrawal] = useState(false);
	const [removeCompletePartnerWithdrawalModal, setRemoveCompletePartnerWithdrawalModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [partnerWithdrawalUniqueId, setPartnerWithdrawalUniqueId] = useState(null);

	const [errorCompletePartnerWithdrawal, setErrorCompletePartnerWithdrawal] = useState(null);
	const [successCompletePartnerWithdrawal, setSuccessCompletePartnerWithdrawal] = useState(null);

	const handleCompletePartnerWithdrawal = () => {

		if (!loadingCompletePartnerWithdrawal) {
			if (!partnerUniqueId) {
				setErrorCompletePartnerWithdrawal(null);
				setSuccessCompletePartnerWithdrawal(null);
				setErrorCompletePartnerWithdrawal("Partner Unique ID is required");
				setTimeout(function () {
					setErrorCompletePartnerWithdrawal(null);
				}, 2500)
			} else if (!partnerWithdrawalUniqueId) {
				setErrorCompletePartnerWithdrawal("Unique ID is required");
				setTimeout(function () {
					setErrorCompletePartnerWithdrawal(null);
				}, 2500)
			} else {
				setLoadingCompletePartnerWithdrawal(true);

				const completePartnerWithdrawalRes = completePartnerWithdrawal(cookie, {
					partner_unique_id: partnerUniqueId,
					unique_id: partnerWithdrawalUniqueId
				})

				completePartnerWithdrawalRes.then(res => {
					setLoadingCompletePartnerWithdrawal(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompletePartnerWithdrawal(error);
							setTimeout(function () {
								setErrorCompletePartnerWithdrawal(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompletePartnerWithdrawal(error);
							setTimeout(function () {
								setErrorCompletePartnerWithdrawal(null);
							}, 2000)
						}
					} else {
						setErrorCompletePartnerWithdrawal(null);
						setSuccessCompletePartnerWithdrawal(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompletePartnerWithdrawal(null);
							setRemoveCompletePartnerWithdrawalModal(true);
							setPartnerUniqueId(null);
							setPartnerWithdrawalUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompletePartnerWithdrawal(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompletePartnerWithdrawal, removeCompletePartnerWithdrawalModal, partnerWithdrawalUniqueId, errorCompletePartnerWithdrawal, successCompletePartnerWithdrawal,
		handleCompletePartnerWithdrawal, setRemoveCompletePartnerWithdrawalModal, setPartnerWithdrawalUniqueId, setPartnerUniqueId
	};
};

const useCompleteBusinessDeposit = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompleteBusinessDeposit, setLoadingCompleteBusinessDeposit] = useState(false);
	const [removeCompleteBusinessDepositModal, setRemoveCompleteBusinessDepositModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [businessDepositUniqueId, setBusinessDepositUniqueId] = useState(null);

	const [errorCompleteBusinessDeposit, setErrorCompleteBusinessDeposit] = useState(null);
	const [successCompleteBusinessDeposit, setSuccessCompleteBusinessDeposit] = useState(null);

	const handleCompleteBusinessDeposit = () => {

		if (!loadingCompleteBusinessDeposit) {
			if (!businessUniqueId) {
				setErrorCompleteBusinessDeposit(null);
				setSuccessCompleteBusinessDeposit(null);
				setErrorCompleteBusinessDeposit("Business Unique ID is required");
				setTimeout(function () {
					setErrorCompleteBusinessDeposit(null);
				}, 2500)
			} else if (!businessDepositUniqueId) {
				setErrorCompleteBusinessDeposit("Unique ID is required");
				setTimeout(function () {
					setErrorCompleteBusinessDeposit(null);
				}, 2500)
			} else {
				setLoadingCompleteBusinessDeposit(true);

				const completeBusinessDepositRes = completeBusinessDeposit(cookie, {
					business_unique_id: businessUniqueId,
					unique_id: businessDepositUniqueId
				})

				completeBusinessDepositRes.then(res => {
					setLoadingCompleteBusinessDeposit(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompleteBusinessDeposit(error);
							setTimeout(function () {
								setErrorCompleteBusinessDeposit(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompleteBusinessDeposit(error);
							setTimeout(function () {
								setErrorCompleteBusinessDeposit(null);
							}, 2000)
						}
					} else {
						setErrorCompleteBusinessDeposit(null);
						setSuccessCompleteBusinessDeposit(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompleteBusinessDeposit(null);
							setRemoveCompleteBusinessDepositModal(true);
							setBusinessUniqueId(null);
							setBusinessDepositUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompleteBusinessDeposit(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompleteBusinessDeposit, removeCompleteBusinessDepositModal, businessDepositUniqueId, errorCompleteBusinessDeposit, successCompleteBusinessDeposit,
		handleCompleteBusinessDeposit, setRemoveCompleteBusinessDepositModal, setBusinessDepositUniqueId, setBusinessUniqueId
	};
};

const useCompleteBusinessWithdrawal = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompleteBusinessWithdrawal, setLoadingCompleteBusinessWithdrawal] = useState(false);
	const [removeCompleteBusinessWithdrawalModal, setRemoveCompleteBusinessWithdrawalModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [businessWithdrawalUniqueId, setBusinessWithdrawalUniqueId] = useState(null);

	const [errorCompleteBusinessWithdrawal, setErrorCompleteBusinessWithdrawal] = useState(null);
	const [successCompleteBusinessWithdrawal, setSuccessCompleteBusinessWithdrawal] = useState(null);

	const handleCompleteBusinessWithdrawal = () => {

		if (!loadingCompleteBusinessWithdrawal) {
			if (!businessUniqueId) {
				setErrorCompleteBusinessWithdrawal(null);
				setSuccessCompleteBusinessWithdrawal(null);
				setErrorCompleteBusinessWithdrawal("Business Unique ID is required");
				setTimeout(function () {
					setErrorCompleteBusinessWithdrawal(null);
				}, 2500)
			} else if (!businessWithdrawalUniqueId) {
				setErrorCompleteBusinessWithdrawal("Unique ID is required");
				setTimeout(function () {
					setErrorCompleteBusinessWithdrawal(null);
				}, 2500)
			} else {
				setLoadingCompleteBusinessWithdrawal(true);

				const completeBusinessWithdrawalRes = completeBusinessWithdrawal(cookie, {
					business_unique_id: businessUniqueId,
					unique_id: businessWithdrawalUniqueId
				})

				completeBusinessWithdrawalRes.then(res => {
					setLoadingCompleteBusinessWithdrawal(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompleteBusinessWithdrawal(error);
							setTimeout(function () {
								setErrorCompleteBusinessWithdrawal(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompleteBusinessWithdrawal(error);
							setTimeout(function () {
								setErrorCompleteBusinessWithdrawal(null);
							}, 2000)
						}
					} else {
						setErrorCompleteBusinessWithdrawal(null);
						setSuccessCompleteBusinessWithdrawal(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompleteBusinessWithdrawal(null);
							setRemoveCompleteBusinessWithdrawalModal(true);
							setBusinessUniqueId(null);
							setBusinessWithdrawalUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompleteBusinessWithdrawal(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompleteBusinessWithdrawal, removeCompleteBusinessWithdrawalModal, businessWithdrawalUniqueId, errorCompleteBusinessWithdrawal, successCompleteBusinessWithdrawal,
		handleCompleteBusinessWithdrawal, setRemoveCompleteBusinessWithdrawalModal, setBusinessWithdrawalUniqueId, setBusinessUniqueId
	};
};

const useCompleteUserDeposit = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompleteUserDeposit, setLoadingCompleteUserDeposit] = useState(false);
	const [removeCompleteUserDepositModal, setRemoveCompleteUserDepositModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [userDepositUniqueId, setUserDepositUniqueId] = useState(null);

	const [errorCompleteUserDeposit, setErrorCompleteUserDeposit] = useState(null);
	const [successCompleteUserDeposit, setSuccessCompleteUserDeposit] = useState(null);

	const handleCompleteUserDeposit = () => {

		if (!loadingCompleteUserDeposit) {
			if (!userPID) {
				setErrorCompleteUserDeposit(null);
				setSuccessCompleteUserDeposit(null);
				setErrorCompleteUserDeposit("User Unique ID is required");
				setTimeout(function () {
					setErrorCompleteUserDeposit(null);
				}, 2500)
			} else if (!userDepositUniqueId) {
				setErrorCompleteUserDeposit("Unique ID is required");
				setTimeout(function () {
					setErrorCompleteUserDeposit(null);
				}, 2500)
			} else {
				setLoadingCompleteUserDeposit(true);

				const completeUserDepositRes = completeUserDeposit(cookie, {
					pid: userPID,
					unique_id: userDepositUniqueId
				})

				completeUserDepositRes.then(res => {
					setLoadingCompleteUserDeposit(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompleteUserDeposit(error);
							setTimeout(function () {
								setErrorCompleteUserDeposit(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompleteUserDeposit(error);
							setTimeout(function () {
								setErrorCompleteUserDeposit(null);
							}, 2000)
						}
					} else {
						setErrorCompleteUserDeposit(null);
						setSuccessCompleteUserDeposit(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompleteUserDeposit(null);
							setRemoveCompleteUserDepositModal(true);
							setUserPID(null);
							setUserDepositUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompleteUserDeposit(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompleteUserDeposit, removeCompleteUserDepositModal, userDepositUniqueId, errorCompleteUserDeposit, successCompleteUserDeposit,
		handleCompleteUserDeposit, setRemoveCompleteUserDepositModal, setUserDepositUniqueId, setUserPID
	};
};

const useCompleteUserWithdrawal = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCompleteUserWithdrawal, setLoadingCompleteUserWithdrawal] = useState(false);
	const [removeCompleteUserWithdrawalModal, setRemoveCompleteUserWithdrawalModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [userWithdrawalUniqueId, setUserWithdrawalUniqueId] = useState(null);

	const [errorCompleteUserWithdrawal, setErrorCompleteUserWithdrawal] = useState(null);
	const [successCompleteUserWithdrawal, setSuccessCompleteUserWithdrawal] = useState(null);

	const handleCompleteUserWithdrawal = () => {

		if (!loadingCompleteUserWithdrawal) {
			if (!userPID) {
				setErrorCompleteUserWithdrawal(null);
				setSuccessCompleteUserWithdrawal(null);
				setErrorCompleteUserWithdrawal("User Unique ID is required");
				setTimeout(function () {
					setErrorCompleteUserWithdrawal(null);
				}, 2500)
			} else if (!userWithdrawalUniqueId) {
				setErrorCompleteUserWithdrawal("Unique ID is required");
				setTimeout(function () {
					setErrorCompleteUserWithdrawal(null);
				}, 2500)
			} else {
				setLoadingCompleteUserWithdrawal(true);

				const completeUserWithdrawalRes = completeUserWithdrawal(cookie, {
					pid: userPID,
					unique_id: userWithdrawalUniqueId
				})

				completeUserWithdrawalRes.then(res => {
					setLoadingCompleteUserWithdrawal(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCompleteUserWithdrawal(error);
							setTimeout(function () {
								setErrorCompleteUserWithdrawal(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCompleteUserWithdrawal(error);
							setTimeout(function () {
								setErrorCompleteUserWithdrawal(null);
							}, 2000)
						}
					} else {
						setErrorCompleteUserWithdrawal(null);
						setSuccessCompleteUserWithdrawal(`Transaction completed successfully!`);

						setTimeout(function () {
							setSuccessCompleteUserWithdrawal(null);
							setRemoveCompleteUserWithdrawalModal(true);
							setUserPID(null);
							setUserWithdrawalUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCompleteUserWithdrawal(false);
				})

			}
		}
	};

	return {
		cookie, loadingCompleteUserWithdrawal, removeCompleteUserWithdrawalModal, userWithdrawalUniqueId, errorCompleteUserWithdrawal, successCompleteUserWithdrawal,
		handleCompleteUserWithdrawal, setRemoveCompleteUserWithdrawalModal, setUserWithdrawalUniqueId, setUserPID
	};
};

const useReverseUserTransfer = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingReverseUserTransfer, setLoadingReverseUserTransfer] = useState(false);
	const [removeReverseUserTransferModal, setRemoveReverseUserTransferModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [userTransferUniqueId, setUserTransferUniqueId] = useState(null);

	const [errorReverseUserTransfer, setErrorReverseUserTransfer] = useState(null);
	const [successReverseUserTransfer, setSuccessReverseUserTransfer] = useState(null);

	const handleReverseUserTransfer = () => {

		if (!loadingReverseUserTransfer) {
			if (!userPID) {
				setErrorReverseUserTransfer(null);
				setSuccessReverseUserTransfer(null);
				setErrorReverseUserTransfer("User Unique ID is required");
				setTimeout(function () {
					setErrorReverseUserTransfer(null);
				}, 2500)
			} else if (!userTransferUniqueId) {
				setErrorReverseUserTransfer("Unique ID is required");
				setTimeout(function () {
					setErrorReverseUserTransfer(null);
				}, 2500)
			} else {
				setLoadingReverseUserTransfer(true);

				const reverseUserTransferRes = reverseUserTransfer(cookie, {
					pid: userPID,
					unique_id: userTransferUniqueId
				})

				reverseUserTransferRes.then(res => {
					setLoadingReverseUserTransfer(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorReverseUserTransfer(error);
							setTimeout(function () {
								setErrorReverseUserTransfer(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorReverseUserTransfer(error);
							setTimeout(function () {
								setErrorReverseUserTransfer(null);
							}, 2000)
						}
					} else {
						setErrorReverseUserTransfer(null);
						setSuccessReverseUserTransfer(`Transaction reversed successfully!`);

						setTimeout(function () {
							setSuccessReverseUserTransfer(null);
							setRemoveReverseUserTransferModal(true);
							setUserPID(null);
							setUserTransferUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingReverseUserTransfer(false);
				})

			}
		}
	};

	return {
		cookie, loadingReverseUserTransfer, removeReverseUserTransferModal, userTransferUniqueId, errorReverseUserTransfer, successReverseUserTransfer,
		handleReverseUserTransfer, setRemoveReverseUserTransferModal, setUserTransferUniqueId, setUserPID
	};
};

const useReverseUserBill = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingReverseUserBill, setLoadingReverseUserBill] = useState(false);
	const [removeReverseUserBillModal, setRemoveReverseUserBillModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [userBillUniqueId, setUserBillUniqueId] = useState(null);

	const [errorReverseUserBill, setErrorReverseUserBill] = useState(null);
	const [successReverseUserBill, setSuccessReverseUserBill] = useState(null);

	const handleReverseUserBill = () => {

		if (!loadingReverseUserBill) {
			if (!userPID) {
				setErrorReverseUserBill(null);
				setSuccessReverseUserBill(null);
				setErrorReverseUserBill("User Unique ID is required");
				setTimeout(function () {
					setErrorReverseUserBill(null);
				}, 2500)
			} else if (!userBillUniqueId) {
				setErrorReverseUserBill("Unique ID is required");
				setTimeout(function () {
					setErrorReverseUserBill(null);
				}, 2500)
			} else {
				setLoadingReverseUserBill(true);

				const reverseUserBillRes = reverseUserBill(cookie, {
					pid: userPID,
					unique_id: userBillUniqueId
				})

				reverseUserBillRes.then(res => {
					setLoadingReverseUserBill(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorReverseUserBill(error);
							setTimeout(function () {
								setErrorReverseUserBill(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorReverseUserBill(error);
							setTimeout(function () {
								setErrorReverseUserBill(null);
							}, 2000)
						}
					} else {
						setErrorReverseUserBill(null);
						setSuccessReverseUserBill(`Bill reversed successfully!`);

						setTimeout(function () {
							setSuccessReverseUserBill(null);
							setRemoveReverseUserBillModal(true);
							setUserPID(null);
							setUserBillUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingReverseUserBill(false);
				})

			}
		}
	};

	return {
		cookie, loadingReverseUserBill, removeReverseUserBillModal, userBillUniqueId, errorReverseUserBill, successReverseUserBill,
		handleReverseUserBill, setRemoveReverseUserBillModal, setUserBillUniqueId, setUserPID
	};
};

const useDeleteTransaction = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteTransaction, setLoadingDeleteTransaction] = useState(false);
	const [removeDeleteTransactionModal, setRemoveDeleteTransactionModal] = useState(null);
	const [deleteTransactionUniqueId, setDeleteTransactionUniqueId] = useState(null);

	const [errorDeleteTransaction, setErrorDeleteTransaction] = useState(null);
	const [successDeleteTransaction, setSuccessDeleteTransaction] = useState(null);

	const handleDeleteTransaction = () => {

		if (!loadingDeleteTransaction) {
			if (!deleteTransactionUniqueId) {
				setErrorDeleteTransaction(null);
				setSuccessDeleteTransaction(null);
				setErrorDeleteTransaction("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteTransaction(null);
				}, 2500)
			} else {
				setLoadingDeleteTransaction(true);

				const deleteTransactionRes = deleteTransaction(cookie, {
					unique_id: deleteTransactionUniqueId
				})

				deleteTransactionRes.then(res => {
					setLoadingDeleteTransaction(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteTransaction(error);
							setTimeout(function () {
								setErrorDeleteTransaction(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteTransaction(error);
							setTimeout(function () {
								setErrorDeleteTransaction(null);
							}, 2000)
						}
					} else {
						setErrorDeleteTransaction(null);
						setSuccessDeleteTransaction(`Transaction deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteTransaction(null);
							setRemoveDeleteTransactionModal(true);
							setDeleteTransactionUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteTransaction(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteTransaction, removeDeleteTransactionModal, deleteTransactionUniqueId, errorDeleteTransaction, successDeleteTransaction,
		handleDeleteTransaction, setRemoveDeleteTransactionModal, setDeleteTransactionUniqueId, 
	};
};

export { 
	useCompletePartnerDeposit, useCompletePartnerWithdrawal, useCompleteBusinessDeposit, useCompleteBusinessWithdrawal, 
	useCompleteUserDeposit, useCompleteUserWithdrawal, useReverseUserTransfer, useDeleteTransaction, useReverseUserBill
};