import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserPschool, verifyUserPschool } from "../api/primarySchool";
import { config } from "../config";

function useVerifyUserPschool(){
    const { cookie } = useCookie(config.key, "");

    const [loadingVerifyUserPschool, setLoadingVerifyUserPschool] = useState(false)
    const [removeVeriyUserPschoolModal, setRemoveVerifyUserPschoolModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [pschoolUniqueId, setPschoolUniqueId] = useState(null)

    const [errorVerifyUserPschool, setErrorVerifyUserPschool] = useState(null)
    const [successVerifyUserPschool, setSuccessVerifyUserPschool] = useState(null)

    const handleVerifyUserPschool = ()=>{
        if(!loadingVerifyUserPschool){
            if(!userPID || !pschoolUniqueId){
                setErrorVerifyUserPschool(null)
                setSuccessVerifyUserPschool(null)
                setErrorVerifyUserPschool(userPID ? "Unique id is required" : "User PID is required")
                setTimeout(function(){
                    setErrorVerifyUserPschool(null)
                }, 2500)
            }else{
                setLoadingVerifyUserPschool(true)

                const verifyUserPschoolRes = verifyUserPschool(cookie, {pid: userPID, unique_id: pschoolUniqueId})

                verifyUserPschoolRes.then((res)=>{
                
                    setLoadingVerifyUserPschool(false)
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorVerifyUserPschool(error)
                            setTimeout(()=>{
                                setErrorVerifyUserPschool(null)
                            }, 2000)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorVerifyUserPschool(error)
                            setTimeout(()=>{
                                setErrorVerifyUserPschool(null)
                            }, 2000)
                        }
                    } else {
                        setErrorVerifyUserPschool(null)
                        setSuccessVerifyUserPschool("User primary school verified")

                        setTimeout(()=>{
                            setSuccessVerifyUserPschool(null)
                            setRemoveVerifyUserPschoolModal(true)
                            setUserPID(null)
                            setPschoolUniqueId(null)
                        }, 2500)
                    }
                }).catch(err=>{
                    setLoadingVerifyUserPschool(false)
                })
            }

        }
    }

    return{
        cookie, loadingVerifyUserPschool, successVerifyUserPschool, errorVerifyUserPschool, removeVeriyUserPschoolModal, setRemoveVerifyUserPschoolModal, handleVerifyUserPschool,
        userPID, setUserPID, pschoolUniqueId, setPschoolUniqueId
    }
}

function useDeclineUserPschool(){
    const{cookie}= useCookie(config.key, "");

    const [loadingDeclinePschool, setLoadingDeclinePschool] = useState(false)
    const [removeDeclinePschoolModal, setRemoveDeclinePschoolModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [pschoolUniqueId, setPschoolUniqueId] = useState(null)
    const [declineMsg, setDeclineMsg] = useState(null)

    const [errorDeclinePschool, setErrorDeclinePschool] = useState(null)
    const [successDeclinePschool, setSuccessDeclinePschool] = useState(null)

    const handleDeclinePschool = ()=>{
        if(!loadingDeclinePschool){
            if(!userPID || !pschoolUniqueId){
                setErrorDeclinePschool(null)
                setSuccessDeclinePschool(null)
                setErrorDeclinePschool(userPID ? "Pschool uniqueId is required": "User PID is required")
                setTimeout(()=>{
                    setErrorDeclinePschool(null)
                }, 2500)
            }else{
                setLoadingDeclinePschool(true)

                const declineUserPschoolRes = declineUserPschool(cookie, {pid: userPID, unique_id: pschoolUniqueId, message: declineMsg})

                declineUserPschoolRes.then((res)=>{
                    setLoadingDeclinePschool(false)
                
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorDeclinePschool(error)
                            setTimeout(()=>{
                                setErrorDeclinePschool(null)
                            }, 2500)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorDeclinePschool(error)
                            setTimeout(()=>{
                                setErrorDeclinePschool(null)
                            }, 2500)
                        }
                    }else{
                        setErrorDeclinePschool(null)
                        setDeclineMsg("")
                        setSuccessDeclinePschool("User primary school declined")

                        setTimeout(()=>{
                            setSuccessDeclinePschool(null)
                            setRemoveDeclinePschoolModal(true)
                            setUserPID(null)
                            setPschoolUniqueId(null)
                        }, 2500)
                    }
                })
            }
        }
    }

    return {
        errorDeclinePschool, successDeclinePschool, loadingDeclinePschool, 
        userPID, setUserPID, pschoolUniqueId, setPschoolUniqueId, declineMsg, setDeclineMsg, handleDeclinePschool,
        removeDeclinePschoolModal, setRemoveDeclinePschoolModal
    }
}

export {useVerifyUserPschool, useDeclineUserPschool}