import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Filter from "../icons/Filter";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getTransferLogs, getTransferLogsViaReceiver, getTransferLogsViaReference, getTransferLogsViaEmail, getTransferLogsViaPhone, getTransferLogsViaStatus } from "../api/transferLogs";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";

export default function TransferLogs() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const [allTransferLogs, setAllTransferLogs] = useState(null);
	const [errorTransferLogs, setErrorTransferLogs] = useState(null);
	const [loadingAllTransferLogs, setLoadingAllTransferLogs] = useState(false);

	const [currentFunction, setCurrentFunction] = useState("getAllTransferLogs");

	const [filterByReference, setFilterByReference] = useState("");
	const [removeReferenceFilterModal, setRemoveReferenceFilterModal] = useState(null);

	const [filterByReceiver, setFilterByReceiver] = useState("");
	const [removeReceiverFilterModal, setRemoveReceiverFilterModal] = useState(null);

	const [filterByEmail, setFilterByEmail] = useState("");
	const [removeEmailFilterModal, setRemoveEmailFilterModal] = useState(null);

	const [filterByPhone, setFilterByPhone] = useState("");
	const [removePhoneFilterModal, setRemovePhoneFilterModal] = useState(null);

	const [transactionStatus, setTransactionStatus] = useState(null);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleTransactionStatus = (e) => { e.preventDefault(); setTransactionStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset"); continueFilterByStatus(e.target.value); };
	const handleFilterByReference = (e) => { e.preventDefault(); setFilterByReference(e.target.value); };
	const handleFilterByReceiver = (e) => { e.preventDefault(); if (e.target.value.toString().length < 11) setFilterByReceiver(e.target.value.toString()); };
	const handleFilterByEmail = (e) => { e.preventDefault(); setFilterByEmail(e.target.value); };
	const handleFilterByPhone = (e) => { e.preventDefault(); setFilterByPhone(e.target.value); };

	const resetReferenceFilterParameters = () => {
		setFilterByReference("");
		setCurrentFunction("getAllTransferLogs");
	};

	const resetReceiverFilterParameters = () => {
		setFilterByReceiver("");
		setCurrentFunction("getAllTransferLogs");
	};

	const resetEmailFilterParameters = () => {
		setFilterByEmail("");
		setCurrentFunction("getAllTransferLogs");
	};

	const resetPhoneFilterParameters = () => {
		setFilterByPhone("");
		setCurrentFunction("getAllTransferLogs");
	};

	const continueFilterByReference = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllTransferLogsViaReference");
		getAllTransferLogsViaReference(filterByReference, page, size);
		setRemoveReferenceFilterModal(true);
	};

	const continueFilterByReceiver = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllTransferLogsViaReceiver");
		getAllTransferLogsViaReceiver(filterByReceiver, page, size);
		setRemoveReceiverFilterModal(true);
	};

	const continueFilterByEmail = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllTransferLogsViaEmail");
		getAllTransferLogsViaEmail(filterByEmail, page, size);
		setRemoveEmailFilterModal(true);
	};

	const continueFilterByPhone = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllTransferLogsViaPhone");
		getAllTransferLogsViaPhone(filterByPhone, page, size);
		setRemovePhoneFilterModal(true);
	};

	const continueFilterByStatus = (status) => {

		setPage(1);
		setCurrentFunction("getAllTransferLogsViaStatus");
		getAllTransferLogsViaStatus((status === "TRUE" || status === "FALSE" ? (status === "TRUE" ? true : false) : parseInt(status)), page, size);
	};

	async function callLastLogFunction() {
		switch (currentFunction) {
			case "getAllTransferLogs":
				getAllTransferLogs(page, size);
				break;
			case "getAllTransferLogsViaReference":
				getAllTransferLogsViaReference(filterByReference, page, size);
				break;
			case "getAllTransferLogsViaReceiver":
				getAllTransferLogsViaReceiver(filterByReceiver, page, size);
				break;
			case "getAllTransferLogsViaEmail":
				getAllTransferLogsViaEmail(filterByEmail, page, size);
				break;
			case "getAllTransferLogsViaPhone":
				getAllTransferLogsViaPhone(filterByPhone, page, size);
				break;
			case "getAllTransferLogsViaStatus":
				getAllTransferLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			// default:
			// 	getAllTransferLogs(page, size);
		}
	};

	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllTransferLogs":
				getAllTransferLogs(page, size);
				break;
			case "getAllTransferLogsViaReference":
				getAllTransferLogsViaReference(filterByReference, page, size);
				break;
			case "getAllTransferLogsViaReceiver":
				getAllTransferLogsViaReceiver(filterByReceiver, page, size);
				break;
			case "getAllTransferLogsViaEmail":
				getAllTransferLogsViaEmail(filterByEmail, page, size);
				break;
			case "getAllTransferLogsViaPhone":
				getAllTransferLogsViaPhone(filterByPhone, page, size);
				break;
			case "getAllTransferLogsViaStatus":
				getAllTransferLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			default:
				getAllTransferLogs(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllTransferLogs":
				getAllTransferLogs(page, size);
				break;
			case "getAllTransferLogsViaReference":
				getAllTransferLogsViaReference(filterByReference, page, size);
				break;
			case "getAllTransferLogsViaReceiver":
				getAllTransferLogsViaReceiver(filterByReceiver, page, size);
				break;
			case "getAllTransferLogsViaEmail":
				getAllTransferLogsViaEmail(filterByEmail, page, size);
				break;
			case "getAllTransferLogsViaPhone":
				getAllTransferLogsViaPhone(filterByPhone, page, size);
				break;
			case "getAllTransferLogsViaStatus":
				getAllTransferLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			default:
				getAllTransferLogs(page, size);
		}
	};

	async function previousTransferLogs() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllTransferLogs":
					getAllTransferLogs(page - 1, size);
					break;
				case "getAllTransferLogsViaReference":
					getAllTransferLogsViaReference(filterByReference, page - 1, size);
					break;
				case "getAllTransferLogsViaReceiver":
					getAllTransferLogsViaReceiver(filterByReceiver, page - 1, size);
					break;
				case "getAllTransferLogsViaEmail":
					getAllTransferLogsViaEmail(filterByEmail, page - 1, size);
					break;
				case "getAllTransferLogsViaPhone":
					getAllTransferLogsViaPhone(filterByPhone, page - 1, size);
					break;
				case "getAllTransferLogsViaStatus":
					getAllTransferLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : transactionStatus), parseInt(page )- 1, size);
					break;
				default:
					getAllTransferLogs(page - 1, size);
			}
		};
	};

	async function nextTransferLogs() {
		if (page < allTransferLogs.data.pages) setPage(page + 1);
		if (page < allTransferLogs.data.pages) {
			switch (currentFunction) {
				case "getAllTransferLogs":
					getAllTransferLogs(page + 1, size);
					break;
				case "getAllTransferLogsViaReference":
					getAllTransferLogsViaReference(filterByReference, page + 1, size);
					break;
				case "getAllTransferLogsViaReceiver":
					getAllTransferLogsViaReceiver(filterByReceiver, page + 1, size);
					break;
				case "getAllTransferLogsViaEmail":
					getAllTransferLogsViaEmail(filterByEmail, page + 1, size);
					break;
				case "getAllTransferLogsViaPhone":
					getAllTransferLogsViaPhone(filterByPhone, page + 1, size);
					break;
				case "getAllTransferLogsViaStatus":
					getAllTransferLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : transactionStatus), parseInt(page )+ 1, size);
					break;
				default:
					getAllTransferLogs(page + 1, size);
			}
		}
	};

	async function getAllTransferLogs(_page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogs(cookie, (_page || page), (_size || size));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	async function getAllTransferLogsViaStatus(status, _page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogsViaStatus(cookie, (_page || page), (_size || size), ({ status: status }));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	async function getAllTransferLogsViaReference(reference, _page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogsViaReference(cookie, (_page || page), (_size || size), ({ reference: reference }));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	async function getAllTransferLogsViaReceiver(account_number, _page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogsViaReceiver(cookie, (_page || page), (_size || size), ({ account_number: account_number }));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	async function getAllTransferLogsViaEmail(email, _page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogsViaEmail(cookie, (_page || page), (_size || size), ({ email: email }));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	async function getAllTransferLogsViaPhone(phone_number, _page, _size) {
		setLoadingAllTransferLogs(true);
		const response = await getTransferLogsViaPhone(cookie, (_page || page), (_size || size), ({ phone_number: phone_number }));
		setAllTransferLogs(response.data);
		if (response.error) setErrorTransferLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransferLogs(false);
	};

	useEffect(() => {
		if (allTransferLogs === null) {
			callLastLogFunction();
		}
	}, [allTransferLogs]);

	if (removeReferenceFilterModal) {
		const modalResponse = document.querySelector("#filterByReference");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveReferenceFilterModal(null);
	}

	if (removeReceiverFilterModal) {
		const modalResponse = document.querySelector("#filterByReceiver");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveReceiverFilterModal(null);
	}

	if (removeEmailFilterModal) {
		const modalResponse = document.querySelector("#filterByEmail");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveEmailFilterModal(null);
	}

	if (removePhoneFilterModal) {
		const modalResponse = document.querySelector("#filterByPhone");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemovePhoneFilterModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allTransferLogs ? allTransferLogs.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Logs for Transfers</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allTransferLogs && allTransferLogs.success ? allTransferLogs.data.total : "..."}</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={transactionStatus} onChange={handleTransactionStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!transactionStatus ?
													<option selected disabled>Filter By Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"TRUE"}>True</option>
											<option value={"FALSE"}>False</option>
											<option value={409}>409</option>
											<option value={500}>500</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByReference">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Reference</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByReceiver">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Receiver</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByEmail">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Email</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByPhone">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Phone</span>
									</div>
								</div>
							</div>
						</div>
						{
							loadingAllTransferLogs ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allTransferLogs && allTransferLogs.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>Unique ID</th>
														<th className='xui-min-w-400'>Reference</th>
														<th className='xui-min-w-300'>Account Name</th>
														<th className='xui-min-w-150'>Account No.</th>
														<th className='xui-min-w-200'>Bank Code</th>
														<th className='xui-min-w-200'>Amount</th>
														<th className='xui-min-w-200'>Name</th>
														<th className='xui-min-w-300'>Email</th>
														<th className='xui-min-w-200'>Phone Number</th>
														<th className='xui-min-w-300'>Message</th>
														<th className='xui-min-w-200'>Response Code</th>
														<th className='xui-min-w-200'>Response Message</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
													</tr>
												</thead>
												<tbody>
													{allTransferLogs.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.unique_id}</span>
																	<span title="Copy Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ? 
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		(
																			data.reference ?
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span>{data.reference}</span>
																					<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.reference); setTextCopied(data.reference); }}>
																						{copiedText && textCopied === data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div> : 
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No reference</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<span>{data.payload.account_name}</span>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.account_number}</span>
																			<span title="Copy Account Number" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.account_number); setTextCopied(data.payload.account_number); }}>
																				{copiedText && textCopied === data.payload.account_number ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.bank_code}</span>
																			<span title="Copy Bank Code" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.bank_code); setTextCopied(data.payload.bank_code); }}>
																				{copiedText && textCopied === data.payload.bank_code ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<span className="xui-font-w-bold">NGN {parseFloat(data.payload.amount).toLocaleString()}</span>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<span>{data.payload.first_name + " " + data.payload.last_name}</span>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.email}</span>
																			<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.email); setTextCopied(data.payload.email); }}>
																				{copiedText && textCopied === data.payload.email ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.phone_number}</span>
																			<span title="Copy Phone Number" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.phone_number); setTextCopied(data.payload.phone_number); }}>
																				{copiedText && textCopied === data.payload.phone_number ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		<span>{data.payload.msg}</span>
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.code}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		(
																			data.status >= 400 && data.status < 500 ? 
																				<span>{data.data.detail}</span> : 
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Copy details</span> 
																					<span title="Copy Details" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.data.detail); setTextCopied(data.data.detail); }}>
																						{copiedText && textCopied === data.data.detail ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div>
																		) :
																		(
																			data.message === "Success" ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.message}</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.message}</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : 
																		(
																			data.status ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Success</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Failed</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorTransferLogs}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllTransferLogs ?
								<Loading width="12" height="12" /> :
								(
									allTransferLogs && allTransferLogs.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allTransferLogs ? allTransferLogs.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousTransferLogs}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextTransferLogs}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="filterByReference" id="filterByReference">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetReferenceFilterParameters()} xui-modal-close="filterByReference">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Reference</h1>
					<form className="xui-form" onSubmit={continueFilterByReference}>
						<div className="xui-form-box">
							<label>Reference</label>
							<input className="xui-font-sz-90" type="text" value={filterByReference} onChange={handleFilterByReference} required placeholder="Enter/Paste Reference"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByReceiver" id="filterByReceiver">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetReceiverFilterParameters()} xui-modal-close="filterByReceiver">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Receiver</h1>
					<form className="xui-form" onSubmit={continueFilterByReceiver}>
						<div className="xui-form-box">
							<label>Receiver</label>
							<input className="xui-font-sz-90" type="number" value={filterByReceiver} onChange={handleFilterByReceiver} required placeholder="Enter/Paste Receiving Account Number"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByEmail" id="filterByEmail">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetEmailFilterParameters()} xui-modal-close="filterByEmail">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Email</h1>
					<form className="xui-form" onSubmit={continueFilterByEmail}>
						<div className="xui-form-box">
							<label>Email</label>
							<input className="xui-font-sz-90" type="email" value={filterByEmail} onChange={handleFilterByEmail} required placeholder="Enter/Paste Email"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByPhone" id="filterByPhone">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetPhoneFilterParameters()} xui-modal-close="filterByPhone">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Phone</h1>
					<form className="xui-form" onSubmit={continueFilterByPhone}>
						<div className="xui-form-box">
							<label>Phone</label>
							<input className="xui-font-sz-90" type="tel" value={filterByPhone} onChange={handleFilterByPhone} required placeholder="Enter/Paste Phone Number"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
		</>
	);

}
