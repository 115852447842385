export default function Filter(props){
    return(
        <svg width={props.width}  height={props.height}  viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            {/* <title>Iconly/Light/Filter</title> */}
            <g id="Iconly/Light/Filter" stroke="currentColor"  stroke-width="1.5"  fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Filter" transform="translate(4.000000, 4.500000)" stroke="currentColor"  stroke-width="1.5" >
                    <line x1="6.33015655" y1="12.0929063" x2="0.0294393477" y2="12.0929063" id="Stroke-1"></line>
                    <line x1="9.14048198" y1="2.40037662" x2="15.4411992" y2="2.40037662" id="Stroke-3"></line>
                    <path d="M4.72628792,2.34625359 C4.72628792,1.05059752 3.66812728,1.79725516e-14 2.36314396,1.79725516e-14 C1.05816064,1.79725516e-14 1.25389895e-15,1.05059752 1.25389895e-15,2.34625359 C1.25389895e-15,3.64190965 1.05816064,4.69250717 2.36314396,4.69250717 C3.66812728,4.69250717 4.72628792,3.64190965 4.72628792,2.34625359 Z" id="Stroke-5"></path>
                    <path d="M16,12.0537464 C16,10.7580903 14.942654,9.70749283 13.6376706,9.70749283 C12.3318727,9.70749283 11.2737121,10.7580903 11.2737121,12.0537464 C11.2737121,13.3494025 12.3318727,14.4 13.6376706,14.4 C14.942654,14.4 16,13.3494025 16,12.0537464 Z" id="Stroke-7"></path>
                </g>
            </g>
        </svg>
    )
}