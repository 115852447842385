import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import useCookie from "../hooks/useCookie";
import Search from "../icons/Search";
import { getWorkHistory, getWorkHistoriesViaUser, getWorkHistories, getWorkHistoriesViaVerifiedStatus } from "../api/workHistory";
import { config } from "../config";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Check from "../icons/Check";
import Copy from "../icons/Copy";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import Star from "../icons/Star";
import { useVerifyUserWorkHistory, useDeclineUserWorkHistory } from "../hooks/useWorkHistories";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import Filter from "../icons/Filter";

export default function WorkHistories() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const { handleVerifyUserWorkHistory, setUserPID: verifyUserWorkHistoryPID,
		setWorkHistoryUniqueId: verifyUserWorkHistoryUniqueId, errorVerifyUserWorkHistory, successVerifyUserWorkHistory,
		loadingVerifyUserWorkHistory, removeVerifyUserWorkHistoryModal, setRemoveVerifyUserWorkHistoryModal
	} = useVerifyUserWorkHistory();

	const { errorDeclineWorkHistory, successDeclineWorkHistory, handleDeclineWorkHistory, setUserPID: declineUserWorkHistoryPID,
		setWorkHistoryUniqueId: declineUserWorkHistoryUniqueId, declineMsg, setDeclineMsg,
		loadingDeclineWorkHistory, removeDeclineWorkHistoryModal, setRemoveDeclineWorkHistoryModal } = useDeclineUserWorkHistory()

	const [currentFunction, setCurrentFunction] = useState("getAllWorkHistories");

	const [allWorkHistories, setAllWorkHistories] = useState(null);
	const [errorAllWorkHistories, setErrorAllWorkHistories] = useState(null);
	const [loadingAllWorkHistories, setLoadingAllWorkHistories] = useState(false);

	const [workHistoryVerifiedStatus, setWorkHistoryVerifiedStatus] = useState(null)

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleWorkHistoryVerifiedStatus = (e) => { e.preventDefault(); setWorkHistoryVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllWorkHistoriesByVerification"); getAllWorkHistoriesByVerification(e.target.value === 'verified' ? true : false, page, size); };

	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllWorkHistories":
				getAllWorkHistories(page, size);
				break;
			case "getAllWorkHistoriesByVerification":
				getAllWorkHistoriesByVerification(workHistoryVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllWorkHistories(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllWorkHistories":
				getAllWorkHistories(page, size);
				break;
			case "getAllWorkHistoriesByVerification":
				getAllWorkHistoriesByVerification(workHistoryVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllWorkHistories(page, size);
		}
	};

	async function callLastFunction() {
		switch (currentFunction) {
			case "getAllWorkHistories":
				getAllWorkHistories(page, size);
				break;
			case "getAllWorkHistoriesByVerification":
				getAllWorkHistoriesByVerification(workHistoryVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			// default:
			// 	getAllWorkHistories(page, size);
		}
	};

	async function previousWorkHistories() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllWorkHistories":
					getAllWorkHistories(page - 1, size);
					break;
				case "getAllWorkHistoriesByVerification":
					getAllWorkHistoriesByVerification(workHistoryVerifiedStatus === 'verified' ? true : false, page - 1, size);
					break;
				default:
					getAllWorkHistories(page - 1, size);
			}
		}
	};

	async function nextWorkHistories() {
		if (page < allWorkHistories.data.pages) setPage(page + 1);
		if (page < allWorkHistories.data.pages) {
			switch (currentFunction) {
				case "getAllWorkHistories":
					getAllWorkHistories(page + 1, size);
					break;
				case "getAllWorkHistoriesByVerification":
					getAllWorkHistoriesByVerification(workHistoryVerifiedStatus === 'verified' ? true : false, page + 1, size);
					break;
				default:
					getAllWorkHistories(page + 1, size);
			}
		}
	};

	async function getAllWorkHistories(_page, _size) {
		setLoadingAllWorkHistories(true);
		const response = await getWorkHistories(cookie, (_page | page), (_size || size));
		setAllWorkHistories(response.data);
		if (response.error) setErrorAllWorkHistories(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllWorkHistories(false);
	}

	async function getAllWorkHistoriesByVerification(verified, _page, _size) {
		setLoadingAllWorkHistories(true)
		const response = await getWorkHistoriesViaVerifiedStatus(cookie, (_page || page), (_size || size), { verified: verified })
		setAllWorkHistories(response.data)
		if (response.error) setErrorAllWorkHistories(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllWorkHistories(false);
	}

	useEffect(() => {
		if (allWorkHistories === null) {
			callLastFunction();
		}
	}, [allWorkHistories])

	const [loadingViewWorkHistory, setLoadingViewWorkHistory] = useState(false)
	const [errorViewWorkHistory, setErrorViewWorkHistory] = useState(null)
	const [viewUserWorkHistory, setViewUserWorkHistory] = useState(null)

	async function getUserWorkHistory(uniqueId, pid) {
		setLoadingViewWorkHistory(true)
		const response = await getWorkHistory(cookie, { unique_id: uniqueId, pid: pid })
		if (!response.err) {
			setViewUserWorkHistory(response.data)
		} else { setErrorViewWorkHistory(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewWorkHistory(false)
	}

	async function getUserWorkHistories(pid) {
		// const modalResponse = document.querySelector("#userWorkHistorySearchModal");
		// modalResponse.setAttribute("display", false);
		// setLoadingViewWorkHistory(true);
		// const response = await getWorkHistoriesViaUser(cookie, { pid: pid })
		// if (!response.error) {
		// 	setViewUserWorkHistory(response.data)
		// } else {
		// 	setViewUserWorkHistory(null); setErrorViewWorkHistory(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message)
		// }
		// setLoadingViewWorkHistory(false)
		// setWorkHistoryPIDSearch("")
		setLoadingAllWorkHistories(true);
		const modalResponse = document.querySelector("#userWorkHistorySearchModal");
		modalResponse.setAttribute("display", false);
		const response = await getWorkHistoriesViaUser(cookie, { pid: pid, page, size })
		setAllWorkHistories(response.data)
		if (response.error) setErrorAllWorkHistories(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllWorkHistories(false);
	}

	const [workHistoryPIDSearch, setWorkHistoryPIDSearch] = useState("")
	const handleWorkHistoryPIDSearch = (e) => { e.preventDefault(); setWorkHistoryPIDSearch(e.target.value.toUpperCase()); }

	if (removeVerifyUserWorkHistoryModal) {
		const modalResponse = document.querySelector("#verifyUserWorkHistoryModal");
		modalResponse.setAttribute('display', false);
		getUserWorkHistory(viewUserWorkHistory.data.data.unique_id, viewUserWorkHistory.data.data.pid)
		setRemoveVerifyUserWorkHistoryModal(null)
	}
	if (removeDeclineWorkHistoryModal) {
		const modalResponse = document.querySelector("#declineUserWorkHistoryModal")
		modalResponse.setAttribute('display', false)
		getUserWorkHistory(viewUserWorkHistory.data.data.unique_id, viewUserWorkHistory.data.data.pid)
		setRemoveDeclineWorkHistoryModal(null)
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allWorkHistories ? allWorkHistories.data.pages : 0).fill(0);

	return (
		<>
			<Screen>
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className='xui-mt-2'>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All User Work Histories</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allWorkHistories && allWorkHistories.success ? allWorkHistories.data.total : "..."}</p>
							</div>

							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userWorkHistorySearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={workHistoryVerifiedStatus} onChange={handleWorkHistoryVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												workHistoryVerifiedStatus === null ?
													<option selected disabled>Filter By verification Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"verified"}>Verified</option>
											<option value={"unverified"}>Unverified</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						{
							loadingAllWorkHistories
								?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
								:
								(
									allWorkHistories && allWorkHistories.success
										?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>PID</th>
														<th className='xui-min-w-300'>Organisation</th>
														<th className='xui-min-w-200'>Role</th>
														<th className='xui-min-w-200'>Start</th>
														<th className='xui-min-w-200'>End</th>
														<th className='xui-min-w-200'>Verification</th>
														<th className='xui-min-w-200'>Declined</th>
														<th className='xui-min-w-300'>Declined Timestamp</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{
														allWorkHistories.data.rows ?
															allWorkHistories.data.rows.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.organisation}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.role}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.start}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.end ? data.end : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Currently working</span>}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserWorkHistory(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserWorkHistoryModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															)) :
															allWorkHistories.data.data.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.organisation}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.role}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.start}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.end ? data.end : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Currently working</span>}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserWorkHistory(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserWorkHistoryModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															))
													}
												</tbody>
											</table>
										</div>
										:
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllWorkHistories}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllWorkHistories ?
								<Loading width="12" height="12" /> :
								(
									allWorkHistories && allWorkHistories.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allWorkHistories ? allWorkHistories.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousWorkHistories}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextWorkHistories}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewUserWorkHistoryModal" id="viewUserWorkHistoryModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserWorkHistoryModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewWorkHistory
							?
							<center>
								<Loading width="12" height="12" />
							</center>
							:
							(
								viewUserWorkHistory && viewUserWorkHistory.success
									?
									<>
										<h1>{viewUserWorkHistory.data.user_data.firstname + (viewUserWorkHistory.data.user_data.middlename ? " " + viewUserWorkHistory.data.user_data.middlename + " " : " ") + viewUserWorkHistory.data.user_data.lastname}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Work History details for this user below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserWorkHistory.data.user_data.photo} alt={viewUserWorkHistory.data.user_data.firstname + (viewUserWorkHistory.data.user_data.middlename ? " " + viewUserWorkHistory.data.user_data.middlename + " " : " ") + viewUserWorkHistory.data.user_data.lastname + " Selfie Image"} />
												<center className="xui-mt-1">
													<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserWorkHistory.data.data.pid}</b>
													<center>
														<div className="xui-d-inline-flex xui-flex-ai-center">
															<span>
																{
																	viewUserWorkHistory.data.user_data.star === 0 ?
																		<div className='xui-m-half'>
																			<p>No star</p>
																		</div>
																		: ""
																}
																{
																	viewUserWorkHistory.data.user_data.star === 1 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserWorkHistory.data.user_data.star === 2 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserWorkHistory.data.user_data.star === 3 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserWorkHistory.data.user_data.star === 4 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserWorkHistory.data.user_data.star === 5 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
															</span>
														</div>
													</center>
													<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">No: <b>{viewUserWorkHistory.data.user_data.phone_number}</b></p>
												</center>
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Organisation -</span> {viewUserWorkHistory.data.data.organisation}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Role -</span> {viewUserWorkHistory.data.data.role}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Description -</span> {viewUserWorkHistory.data.data.description}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Started -</span> {viewUserWorkHistory.data.data.start}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Ended -</span> {viewUserWorkHistory.data.data.end ? viewUserWorkHistory.data.data.end : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Currently working</span>}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
													viewUserWorkHistory.data.data.status === 1
														?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span>
														:
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
													viewUserWorkHistory.data.data.verified ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
													viewUserWorkHistory.data.data.declined === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserWorkHistory.data.data.declined ?
																<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
														)

												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
													viewUserWorkHistory.data.data.declined_timestamp === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserWorkHistory.data.data.declined_timestamp ?
																<span>{viewUserWorkHistory.data.data.declined_timestamp.date} at {viewUserWorkHistory.data.data.declined_timestamp.time}</span> :
																"No timestamp"
														)
												}</p>
												<p className="xui-opacity-10 xui-font-sz-100 xui-m-half"><span className="xui-opacity-4 xui-font-w-bold">Proof - </span> {
													viewUserWorkHistory.data.data.proof
														?
														viewUserWorkHistory.data.data.proof.split(".")[3] === 'pdf'
															?
															<a href={viewUserWorkHistory.data.data.proof} rel="noreferrer" target="_blank" > {viewUserWorkHistory.data.user_data.firstname + " " + viewUserWorkHistory.data.user_data.lastname + " pdf_proof"} </a>
															:
															<img className="xui-img-300 xui-max-h-300 xui-mt-2" src={viewUserWorkHistory.data.data.proof} alt={viewUserWorkHistory.data.user_data.firstname + (viewUserWorkHistory.data.user_data.middlename ? " " + viewUserWorkHistory.data.user_data.middlename + " " : " ") + viewUserWorkHistory.data.user_data.lastname + " Proof"} />
														:
														"Null"
												}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserWorkHistory.data.data.createdAt?.fulldate} | Last Updated - {viewUserWorkHistory.data.data.updatedAt?.fulldate}</p>
											<div className="xui-m-2">
												<button title="Verify User WorkHistory" onClick={() => { verifyUserWorkHistoryPID(viewUserWorkHistory.data.data.pid); verifyUserWorkHistoryUniqueId(viewUserWorkHistory.data.data.unique_id) }} className="xui-btn xui-mr-3 psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyUserWorkHistoryModal">
													<Check width="16" height="16" />
												</button>

												<button title="Decline User WorkHistory" onClick={() => { declineUserWorkHistoryPID(viewUserWorkHistory.data.data.pid); declineUserWorkHistoryUniqueId(viewUserWorkHistory.data.data.unique_id) }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineUserWorkHistoryModal">
													<Close width="16" height="16" />
												</button>
											</div>
										</center>
									</>
									:
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewWorkHistory}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>

			<section className='xui-modal' xui-modal="verifyUserWorkHistoryModal" id="verifyUserWorkHistoryModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify User Work History</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyUserWorkHistory}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyUserWorkHistory}</span></p>
						<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button onClick={handleVerifyUserWorkHistory} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Yes</span>
									{
										loadingVerifyUserWorkHistory ?
											<Loading width="12" height="12" />
											: <Check width="20" height="20" />
									}
								</button>
							</div>

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyUserWorkHistory ? "" : "verifyUserWorkHistoryModal"}>
									<span className="xui-mr-half">No</span>
									<Close width="20" height="20" />
								</button>
							</div>
						</div>
					</center>
				</div>
			</section>

			<section className='xui-modal' xui-modal="declineUserWorkHistoryModal" id="declineUserWorkHistoryModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Decline User Work History</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<form className="xui-form xui-mt-2">
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Feedback</label>
							<textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={declineMsg} onChange={(e) => setDeclineMsg(e.target.value)}></textarea>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineWorkHistory}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineWorkHistory}</span></p>
					</form>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeclineWorkHistory} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeclineWorkHistory ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineWorkHistory ? "" : "declineUserWorkHistoryModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>

			<section className='xui-modal' xui-modal="userWorkHistorySearchModal" id="userWorkHistorySearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userWorkHistorySearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for User</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getUserWorkHistories(workHistoryPIDSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>PID</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"text"} maxLength={6} placeholder={"Enter Passcoder ID"} value={workHistoryPIDSearch} onChange={handleWorkHistoryPIDSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}