import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../firebase";
import useCookie from "./useCookie";
import { config } from "../config";
import { addResource, deleteResource, updateResourceName, updateResourceDocument } from "../api/resources";

const useAddResource = () => {

	const { cookie } = useCookie(config.key, "");

	const storage = getStorage(app);

	const [loadingAddResource, setLoadingAddResource] = useState(false);
	const [removeAddResourceModal, setRemoveAddResourceModal] = useState(null);
	const [name, setName] = useState(null);
	const [selectedAddResource, setSelectedAddResource] = useState("");
	const [uploadingAddResourcePercentage, setUploadingAddResourcePercentage] = useState(0);

	const [errorAddResource, setErrorAddResource] = useState(null);
	const [successAddResource, setSuccessAddResource] = useState(null);

	const allowed_extensions = ["application/pdf", "application/PDF", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-zip-compressed", "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "audio/mpeg", "audio/wav", "audio/ogg", "video/x-matroska", "video/mp4", "video/x-m4v"];
	const maximum_file_size = 50 * 1024 * 1024;

	const filterBytes = (bytes) => {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
	};

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };

	const handleAddResource = (e) => {
		e.preventDefault();

		if (!loadingAddResource) {
			if (!name) {
				setErrorAddResource(null);
				setSuccessAddResource(null);
				setErrorAddResource("Name is required");
				setTimeout(function () {
					setErrorAddResource(null);
				}, 2500)
			} else if (name.length > 300) {
				setErrorAddResource("Name maximum characters - 300");
				setTimeout(function () {
					setErrorAddResource(null);
				}, 2500)
			} else if (!allowed_extensions.includes(selectedAddResource.type)) {
				setErrorAddResource("Invalid document format (accepts Texts, Docs, Powerpoint and Excel files, Audio and Video files, PDFs)");
				setTimeout(function () {
					setErrorAddResource(null);
				}, 2000)
			} else if (selectedAddResource.size > maximum_file_size) {
				setErrorAddResource("File too large (max 50mb)");
				setTimeout(function () {
					setErrorAddResource(null);
				}, 2000)
			} else {
				setLoadingAddResource(true);

				const file_rename = uuidv4();
				let lastDot = selectedAddResource.name.lastIndexOf('.');
				let ext = selectedAddResource.name.substring(lastDot + 1);

				const filePath = "/resources/" + file_rename + "." + ext;

				const storageRef = ref(storage, filePath);
				const uploadTask = uploadBytesResumable(storageRef, selectedAddResource);

				uploadTask.on('state_changed',
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadingAddResourcePercentage(progress);
					},
					(error) => {
						setUploadingAddResourcePercentage(0);
						setLoadingAddResource(false);
						setErrorAddResource("An error occured while uploading");
						setTimeout(function () {
							setErrorAddResource(null);
						}, 3000)
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

							const addResourceRes = addResource(cookie, {
								name,
								document: downloadURL,
								document_file_ext: filePath,
								document_size: filterBytes(selectedAddResource.size)
							})

							addResourceRes.then(res => {
								setLoadingAddResource(false);
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setErrorAddResource(error);
										setTimeout(function () {
											setErrorAddResource(null);
										}, 2000)
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setErrorAddResource(error);
										setTimeout(function () {
											setErrorAddResource(null);
										}, 2000)
									}
								} else {
									setErrorAddResource(null);
									setUploadingAddResourcePercentage(0);
									setSuccessAddResource(`Resource added successfully!`);

									setTimeout(function () {
										setSuccessAddResource(null);
										setRemoveAddResourceModal(true);
									}, 2500)
								}
							}).catch(err => {
								setUploadingAddResourcePercentage(0);
								setLoadingAddResource(false);
							})
						});

					}
				)

			}
		}
	};

	return {
		cookie, name, loadingAddResource, setRemoveAddResourceModal, errorAddResource, successAddResource, removeAddResourceModal, setSelectedAddResource,
		handleAddResource, handleName, setName, uploadingAddResourcePercentage, selectedAddResource,
	};
};

const useUpdateResourceName = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateResourceName, setLoadingUpdateResourceName] = useState(false);
	const [removeUpdateResourceNameModal, setRemoveUpdateResourceNameModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [name, setName] = useState(null);

	const [errorUpdateResourceName, setErrorUpdateResourceName] = useState(null);
	const [successUpdateResourceName, setSuccessUpdateResourceName] = useState(null);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };

	const handleUpdateResourceName = (e) => {
		e.preventDefault();

		if (!loadingUpdateResourceName) {
			if (!uniqueId) {
				setErrorUpdateResourceName(null);
				setSuccessUpdateResourceName(null);
				setErrorUpdateResourceName("Unique ID is required");
				setTimeout(function () {
					setErrorUpdateResourceName(null);
				}, 2500)
			} else if (!name) {
				setErrorUpdateResourceName("Name is required");
				setTimeout(function () {
					setErrorUpdateResourceName(null);
				}, 2500)
			} else if (name.length > 300) {
				setErrorUpdateResourceName("Name maximum characters - 300");
				setTimeout(function () {
					setErrorUpdateResourceName(null);
				}, 2500)
			} else {
				setLoadingUpdateResourceName(true);

				const updateResourceNameRes = updateResourceName(cookie, {
					unique_id: uniqueId,
					name
				})

				updateResourceNameRes.then(res => {
					setLoadingUpdateResourceName(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateResourceName(error);
							setTimeout(function () {
								setErrorUpdateResourceName(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateResourceName(error);
							setTimeout(function () {
								setErrorUpdateResourceName(null);
							}, 2000)
						}
					} else {
						setErrorUpdateResourceName(null);
						setSuccessUpdateResourceName(`Resource name updated!`);

						setTimeout(function () {
							setSuccessUpdateResourceName(null);
							setRemoveUpdateResourceNameModal(true);
							setUniqueId(null);
							setName(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateResourceName(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateResourceName, removeUpdateResourceNameModal, errorUpdateResourceName, successUpdateResourceName, handleUpdateResourceName,
		setRemoveUpdateResourceNameModal, setUniqueId, setName, name, handleName
	};
};

const useUploadResourceDocument = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingResourceDocument, setLoadingResourceDocument] = useState(false);
	const [uniqueId, setUniqueId] = useState(null);
	const [removeResourceDocumentModal, setRemoveResourceDocumentModal] = useState(null);
	const [selectedResourceDocument, setSelectedResourceDocument] = useState("");
	const [uploadingResourceDocumentPercentage, setUploadingResourceDocumentPercentage] = useState(0);

	const [errorResourceDocument, setErrorResourceDocument] = useState(null);
	const [successResourceDocument, setSuccessResourceDocument] = useState(null);

	const allowed_extensions = ["application/pdf", "application/PDF", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-zip-compressed", "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "audio/mpeg", "audio/wav", "audio/ogg", "video/x-matroska", "video/mp4", "video/x-m4v"];
	const maximum_file_size = 50 * 1024 * 1024;

	const filterBytes = (bytes) => {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
	};

	const handleUploadResourceDocument = (e) => {
		e.preventDefault();

		if (!loadingResourceDocument) {
			if (!uniqueId) {
				setErrorResourceDocument(null);
				setSuccessResourceDocument(null);
				setErrorResourceDocument("Unique ID is required");
				setTimeout(function () {
					setErrorResourceDocument(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedResourceDocument.type)) {
				setErrorResourceDocument("Invalid document format (accepts Texts, Docs, Powerpoint and Excel files, Audio and Video files, PDFs)");
				setTimeout(function () {
					setErrorResourceDocument(null);
				}, 2000)
			} else if (selectedResourceDocument.size > maximum_file_size) {
				setErrorResourceDocument("File too large (max 50mb)");
				setTimeout(function () {
					setErrorResourceDocument(null);
				}, 2000)
			} else {
				setLoadingResourceDocument(true);

				const file_rename = uuidv4();
				let lastDot = selectedResourceDocument.name.lastIndexOf('.');
				let ext = selectedResourceDocument.name.substring(lastDot + 1);

				const filePath = "/resources/" + file_rename + "." + ext;

				const storageRef = ref(storage, filePath);
				const uploadTask = uploadBytesResumable(storageRef, selectedResourceDocument);

				uploadTask.on('state_changed',
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadingResourceDocumentPercentage(progress);
					},
					(error) => {
						setUploadingResourceDocumentPercentage(0);
						setLoadingResourceDocument(false);
						setErrorResourceDocument("An error occured while uploading");
						setTimeout(function () {
							setErrorResourceDocument(null);
						}, 3000)
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

							const updateResourceDocumentRes = updateResourceDocument(cookie, {
								unique_id: uniqueId,
								document: downloadURL,
								document_file_ext: filePath,
								document_size: filterBytes(selectedResourceDocument.size)
							})

							updateResourceDocumentRes.then(res => {
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setUploadingResourceDocumentPercentage(0);
										setLoadingResourceDocument(false);
										setErrorResourceDocument(error);
										setTimeout(function () {
											setErrorResourceDocument(null);
										}, 2000)
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setUploadingResourceDocumentPercentage(0);
										setLoadingResourceDocument(false);
										setErrorResourceDocument(error);
										setTimeout(function () {
											setErrorResourceDocument(null);
										}, 2000)
									}
								} else {
									setErrorResourceDocument(null);
									setUploadingResourceDocumentPercentage(0);
									setSuccessResourceDocument(`Resource Document updated successfully!`);

									setTimeout(function () {
										setLoadingResourceDocument(false);
										setSuccessResourceDocument(null);
										setRemoveResourceDocumentModal(true);
										setUniqueId(null);
									}, 3000)
								}
							}).catch(err => {
								setUploadingResourceDocumentPercentage(0);
								setLoadingResourceDocument(false);
							})
						});

					}
				)
			}
		}
	};

	return {
		cookie, loadingResourceDocument, errorResourceDocument, successResourceDocument, handleUploadResourceDocument, uniqueId, setSelectedResourceDocument,
		setUniqueId, uploadingResourceDocumentPercentage, selectedResourceDocument, removeResourceDocumentModal, setRemoveResourceDocumentModal
	};
};

const useDeleteResource = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteResource, setLoadingDeleteResource] = useState(false);
	const [removeDeleteResourceModal, setRemoveDeleteResourceModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDeleteResource, setErrorDeleteResource] = useState(null);
	const [successDeleteResource, setSuccessDeleteResource] = useState(null);

	const handleDeleteResource = () => {

		if (!loadingDeleteResource) {
			if (!uniqueId) {
				setErrorDeleteResource(null);
				setSuccessDeleteResource(null);
				setErrorDeleteResource("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteResource(null);
				}, 2500)
			} else {
				setLoadingDeleteResource(true);

				const deleteResourceRes = deleteResource(cookie, {
					unique_id: uniqueId
				})

				deleteResourceRes.then(res => {
					setLoadingDeleteResource(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteResource(error);
							setTimeout(function () {
								setErrorDeleteResource(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteResource(error);
							setTimeout(function () {
								setErrorDeleteResource(null);
							}, 2000)
						}
					} else {
						setErrorDeleteResource(null);
						setSuccessDeleteResource(`Resource deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteResource(null);
							setRemoveDeleteResourceModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteResource(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteResource, removeDeleteResourceModal, errorDeleteResource, successDeleteResource, handleDeleteResource,
		setRemoveDeleteResourceModal, setUniqueId
	};
};

export { useAddResource, useUpdateResourceName, useUploadResourceDocument, useDeleteResource };
