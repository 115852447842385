import axios from 'axios';
import { config } from '../config';

const getBusinesses = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/businesses`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusiness = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessViaEmail = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/via/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPremiumPackages = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/premium/packages`,
			{

			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusinessComplianceDocumentsProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/business/compliance/documents`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateBusinessComplianceDetails = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/compliance/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updateBusinessComplianceCertificate = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/compliance/certificate`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updateBusinessComplianceDocument = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/compliance/document`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updateBusinessComplianceDocuments = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/compliance/documents`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const upgradeBusinessPremium = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/premium/upgrade`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const grantBusinessAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/access/grant`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const suspendBusinessAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/access/suspend`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const revokeBusinessAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/access/revoke`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const verifyBusiness = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const unverifyBusiness = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/business/unverify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { 
	getBusinesses, getBusiness, getBusinessViaEmail, updateBusinessComplianceDetails, updateBusinessComplianceCertificate, 
	updateBusinessComplianceDocument, updateBusinessComplianceDocuments, grantBusinessAccess, suspendBusinessAccess, 
	revokeBusinessAccess, verifyBusiness, unverifyBusiness, upgradeBusinessPremium, getPremiumPackages, getBusinessComplianceDocumentsProof
};