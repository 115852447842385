import { useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../firebase";
import useCookie from "./useCookie";
import { config } from "../config";
import {
	updatePartnerComplianceDetails, updatePartnerComplianceCertificate, updatePartnerComplianceDocument, updatePartnerComplianceDocuments,
	grantPartnerAccess, suspendPartnerAccess, revokePartnerAccess, verifyPartner, unverifyPartner, updatePartnerWebsiteURL,
	getPartnerComplianceDocumentsProof
} from "../api/partners";

const useUpdateWebsiteURL = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateWebsiteURL, setLoadingUpdateWebsiteURL] = useState(false);
	const [removeUpdateWebsiteURLModal, setRemoveUpdateWebsiteURLModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [websiteURL, setWebsiteURL] = useState(null);

	const [errorUpdateWebsiteURL, setErrorUpdateWebsiteURL] = useState(null);
	const [successUpdateWebsiteURL, setSuccessUpdateWebsiteURL] = useState(null);

	const handleWebsiteURL = (e) => { e.preventDefault(); setWebsiteURL(e.target.value); };

	const handleUpdateWebsiteURL = () => {

		if (!loadingUpdateWebsiteURL) {
			if (!partnerUniqueId) {
				setErrorUpdateWebsiteURL(null);
				setSuccessUpdateWebsiteURL(null);
				setErrorUpdateWebsiteURL("Partner Unique ID is required");
				setTimeout(function () {
					setErrorUpdateWebsiteURL(null);
				}, 2500)
			} else if (!websiteURL) {
				setErrorUpdateWebsiteURL("Website URL is required");
				setTimeout(function () {
					setErrorUpdateWebsiteURL(null);
				}, 2500)
			} else {
				setLoadingUpdateWebsiteURL(true);

				const updatePartnerWebsiteURLRes = updatePartnerWebsiteURL(cookie, {
					partner_unique_id: partnerUniqueId,
					websiteURL
				})

				updatePartnerWebsiteURLRes.then(res => {
					setLoadingUpdateWebsiteURL(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateWebsiteURL(error);
							setTimeout(function () {
								setErrorUpdateWebsiteURL(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateWebsiteURL(error);
							setTimeout(function () {
								setErrorUpdateWebsiteURL(null);
							}, 2000)
						}
					} else {
						setErrorUpdateWebsiteURL(null);
						setSuccessUpdateWebsiteURL(`Partner Website URL updated!`);

						setTimeout(function () {
							setSuccessUpdateWebsiteURL(null);
							setRemoveUpdateWebsiteURLModal(true);
							setPartnerUniqueId(null);
							setWebsiteURL(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateWebsiteURL(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateWebsiteURL, removeUpdateWebsiteURLModal, errorUpdateWebsiteURL, successUpdateWebsiteURL, handleUpdateWebsiteURL,
		setRemoveUpdateWebsiteURLModal, setPartnerUniqueId, setWebsiteURL, websiteURL, handleWebsiteURL
	};
};

const useUpdateComplianceDetails = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateComplianceDetails, setLoadingUpdateComplianceDetails] = useState(false);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [removeComplianceDetailsModal, setRemoveComplianceDetailsModal] = useState(null);
	const [companyName, setCompanyName] = useState(null);
	const [companyEmail, setCompanyEmail] = useState(null);
	const [companyRcNumber, setCompanyRcNumber] = useState(null);
	const [companyType, setCompanyType] = useState(null);
	const [companyAddress, setCompanyAddress] = useState(null);
	const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState(null);

	const [errorUpdateComplianceDetails, setErrorUpdateComplianceDetails] = useState(null);
	const [successUpdateComplianceDetails, setSuccessUpdateComplianceDetails] = useState(null);

	// validating values that need precision
	const validEmail = new RegExp(config.EMAIL_REGEX);
	const company_types = ["BN", "RC", "IT", "LL", "LLP"];

	const handleCompanyName = (e) => { e.preventDefault(); setCompanyName(e.target.value); };
	const handleCompanyEmail = (e) => { e.preventDefault(); setCompanyEmail(e.target.value); };
	const handleCompanyRcNumber = (e) => { e.preventDefault(); setCompanyRcNumber(e.target.value); };
	const handleCompanyType = (e) => { e.preventDefault(); setCompanyType(e.target.value); };
	const handleCompanyAddress = (e) => { e.preventDefault(); setCompanyAddress(e.target.value); };
	const handleCompanyWebsiteUrl = (e) => { e.preventDefault(); setCompanyWebsiteUrl(e.target.value); };

	const test_all_regex = (data, regex) => {
		if (!data) {
			return false;
		}

		const valid = regex.test(data);
		if (!valid) {
			return false;
		}

		return true;
	};

	const validate_url = (url) => {
		const tester = /^((http|https):\/\/)(www.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}((\.[a-z]{2,6})|([a-z0-9:]){2,10})\b([-a-zA-Z0-9@:%._\+~#?&//=]*)$/;
		return test_all_regex(url, tester);
	};

	const validate_rc_number = (number) => {
		const tester = /^([0-9]{6,14})$/;
		return test_all_regex(number, tester);
	};

	const validate_company_type = (company_type) => {
		if (!company_types.includes(company_type)) return false;
		return true;
	};

	const handleUpdateComplianceDetails = (e) => {
		e.preventDefault();

		if (!loadingUpdateComplianceDetails) {
			if (!partnerUniqueId) {
				setErrorUpdateComplianceDetails(null);
				setSuccessUpdateComplianceDetails(null);
				setErrorUpdateComplianceDetails("Partner Unique ID is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyName) {
				setErrorUpdateComplianceDetails("Company name is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyName.length < 3) {
				setErrorUpdateComplianceDetails("Company name minimum characters - 3");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyName.length > 150) {
				setErrorUpdateComplianceDetails("Company name maximum characters - 150");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyEmail) {
				setErrorUpdateComplianceDetails("Company email is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validEmail.test(companyEmail)) {
				setErrorUpdateComplianceDetails("Invalid email");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyRcNumber) {
				setErrorUpdateComplianceDetails("RC Number is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validate_rc_number(companyRcNumber)) {
				setErrorUpdateComplianceDetails("Invalid RC Number");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyType) {
				setErrorUpdateComplianceDetails("Company Type is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validate_company_type(companyType)) {
				setErrorUpdateComplianceDetails("Invalid Company Type");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyAddress) {
				setErrorUpdateComplianceDetails("Company Address is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyAddress.length < 3) {
				setErrorUpdateComplianceDetails("Company address minimum characters - 3");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyAddress.length > 200) {
				setErrorUpdateComplianceDetails("Company address maximum characters - 200");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyWebsiteUrl && !validate_url(companyWebsiteUrl)) {
				setErrorUpdateComplianceDetails("Invalid Website Url");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else {
				setLoadingUpdateComplianceDetails(true);

				const updatePartnerComplianceDetailsRes = updatePartnerComplianceDetails(cookie, {
					partner_unique_id: partnerUniqueId,
					company_name: companyName,
					company_email: companyEmail,
					company_rc_number: companyRcNumber,
					company_type: companyType,
					company_address: companyAddress,
					website_url: companyWebsiteUrl === null ? undefined : companyWebsiteUrl,
				})

				updatePartnerComplianceDetailsRes.then(res => {
					setLoadingUpdateComplianceDetails(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateComplianceDetails(error);
							setTimeout(function () {
								setErrorUpdateComplianceDetails(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateComplianceDetails(error);
							setTimeout(function () {
								setErrorUpdateComplianceDetails(null);
							}, 2000)
						}
					} else {
						setErrorUpdateComplianceDetails(null);
						if (res.response_code === 200) {
							setSuccessUpdateComplianceDetails(`Compliance details verified successfully!`);
						} else {
							setSuccessUpdateComplianceDetails(`Compliance details edited successfully!`);
						}

						setTimeout(function () {
							setSuccessUpdateComplianceDetails(null);
							setPartnerUniqueId(null);
							setRemoveComplianceDetailsModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateComplianceDetails(false);
				})

			}
		}
	};

	return {
		cookie, companyName, companyEmail, companyRcNumber, companyAddress, companyWebsiteUrl, loadingUpdateComplianceDetails, setRemoveComplianceDetailsModal, 
		errorUpdateComplianceDetails, successUpdateComplianceDetails, setCompanyEmail, setCompanyRcNumber, handleCompanyAddress,
		handleUpdateComplianceDetails, handleCompanyName, handleCompanyEmail, handleCompanyRcNumber, setCompanyName, setCompanyWebsiteUrl,
		handleCompanyType, setCompanyType, companyType, handleCompanyWebsiteUrl, setCompanyAddress, setPartnerUniqueId, removeComplianceDetailsModal
	};
};

const useUploadPartnerComplianceDocument = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingComplianceDocument, setLoadingComplianceDocument] = useState(false);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [removeComplianceDocumentModal, setRemoveComplianceDocumentModal] = useState(null);
	const [selectedComplianceDocument, setSelectedComplianceDocument] = useState("");
	const [uploadingComplianceDocumentPercentage, setUploadingComplianceDocumentPercentage] = useState(0);

	const [errorComplianceDocument, setErrorComplianceDocument] = useState(null);
	const [successComplianceDocument, setSuccessComplianceDocument] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "application/pdf", "application/PDF"];
	const maximum_file_size = 5 * 1024 * 1024;

	const handleUploadComplianceDocument = (e) => {
		e.preventDefault();

		if (!loadingComplianceDocument) {
			if (!partnerUniqueId) {
				setErrorComplianceDocument(null);
				setSuccessComplianceDocument(null);
				setErrorComplianceDocument("Partner Unique ID is required");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedComplianceDocument.type)) {
				setErrorComplianceDocument("Invalid image format (.png, .jpg, .jpeg & .pdf)");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else if (selectedComplianceDocument.size > maximum_file_size) {
				setErrorComplianceDocument("File too large (max 5mb)");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else {
				setLoadingComplianceDocument(true);

				const complianceDocumentProofRes = getPartnerComplianceDocumentsProof({ partner_unique_id: partnerUniqueId })

				complianceDocumentProofRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingComplianceDocumentPercentage(0);
							setLoadingComplianceDocument(false);
							setErrorComplianceDocument(error);
							setTimeout(function () {
								setErrorComplianceDocument(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingComplianceDocumentPercentage(0);
							setLoadingComplianceDocument(false);
							setErrorComplianceDocument(error);
							setTimeout(function () {
								setErrorComplianceDocument(null);
							}, 2000)
						}
					} else {
						const partner_file_rename = res.data.data[1].registration_document;
						let lastDot = selectedComplianceDocument.name.lastIndexOf('.');
						let ext = selectedComplianceDocument.name.substring(lastDot + 1);

						const filePath = "/partners/" + partner_file_rename + "." + ext;

						const storageRef = ref(storage, filePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedComplianceDocument);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingComplianceDocumentPercentage(progress);
							},
							(error) => {
								setUploadingComplianceDocumentPercentage(0);
								setLoadingComplianceDocument(false);
								setErrorComplianceDocument("An error occured while uploading");
								setTimeout(function () {
									setErrorComplianceDocument(null);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const updatePartnerRegistrationDocumentRes = updatePartnerComplianceDocument(cookie, {
										partner_unique_id: partnerUniqueId,
										registration_document: downloadURL,
										registration_document_file_ext: filePath
									})

									updatePartnerRegistrationDocumentRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingComplianceDocumentPercentage(0);
												setLoadingComplianceDocument(false);
												setErrorComplianceDocument(error);
												setTimeout(function () {
													setErrorComplianceDocument(null);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingComplianceDocumentPercentage(0);
												setLoadingComplianceDocument(false);
												setErrorComplianceDocument(error);
												setTimeout(function () {
													setErrorComplianceDocument(null);
												}, 2000)
											}
										} else {
											setErrorComplianceDocument(null);
											setUploadingComplianceDocumentPercentage(0);
											setSuccessComplianceDocument(`Registration Document uploaded successfully!`);

											setTimeout(function () {
												setLoadingComplianceDocument(false);
												setSuccessComplianceDocument(null);
												setRemoveComplianceDocumentModal(true);
												setPartnerUniqueId(null);
											}, 3000)
										}
									}).catch(err => {
										setUploadingComplianceDocumentPercentage(0);
										setLoadingComplianceDocument(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingComplianceDocumentPercentage(0);
					setLoadingComplianceDocument(false);
				})
			}
		}
	};

	return {
		cookie, loadingComplianceDocument, errorComplianceDocument, successComplianceDocument, handleUploadComplianceDocument, partnerUniqueId, setSelectedComplianceDocument,
		setPartnerUniqueId, uploadingComplianceDocumentPercentage, selectedComplianceDocument, removeComplianceDocumentModal
	};
};

const useUploadPartnerComplianceCertificate = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingComplianceCertificate, setLoadingComplianceCertificate] = useState(false);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);
	const [removeComplianceCertificateModal, setRemoveComplianceCertificateModal] = useState(null);
	const [selectedComplianceCertificate, setSelectedComplianceCertificate] = useState("");
	const [uploadingComplianceCertificatePercentage, setUploadingComplianceCertificatePercentage] = useState(0);

	const [errorComplianceCertificate, setErrorComplianceCertificate] = useState(null);
	const [successComplianceCertificate, setSuccessComplianceCertificate] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "application/pdf", "application/PDF"];
	const maximum_file_size = 5 * 1024 * 1024;

	const handleUploadComplianceCertificate = (e) => {
		e.preventDefault();

		if (!loadingComplianceCertificate) {
			if (!partnerUniqueId) {
				setErrorComplianceCertificate(null);
				setSuccessComplianceCertificate(null);
				setErrorComplianceCertificate("Partner Unique ID is required");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedComplianceCertificate.type)) {
				setErrorComplianceCertificate("Invalid image format (.png, .jpg, .jpeg & .pdf)");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else if (selectedComplianceCertificate.size > maximum_file_size) {
				setErrorComplianceCertificate("File too large (max 5mb)");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else {
				setLoadingComplianceCertificate(true);

				const complianceCertificateProofRes = getPartnerComplianceDocumentsProof({ partner_unique_id: partnerUniqueId })

				complianceCertificateProofRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingComplianceCertificatePercentage(0);
							setLoadingComplianceCertificate(false);
							setErrorComplianceCertificate(error);
							setTimeout(function () {
								setErrorComplianceCertificate(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingComplianceCertificatePercentage(0);
							setLoadingComplianceCertificate(false);
							setErrorComplianceCertificate(error);
							setTimeout(function () {
								setErrorComplianceCertificate(null);
							}, 2000)
						}
					} else {
						const partner_file_rename = res.data.data[0].registration_certificate;
						let lastDot = selectedComplianceCertificate.name.lastIndexOf('.');
						let ext = selectedComplianceCertificate.name.substring(lastDot + 1);

						const filePath = "/partners/" + partner_file_rename + "." + ext;

						const storageRef = ref(storage, filePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedComplianceCertificate);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingComplianceCertificatePercentage(progress);
							},
							(error) => {
								setUploadingComplianceCertificatePercentage(0);
								setLoadingComplianceCertificate(false);
								setErrorComplianceCertificate("An error occured while uploading");
								setTimeout(function () {
									setErrorComplianceCertificate(null);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const updatePartnerRegistrationCertificateRes = updatePartnerComplianceCertificate(cookie, {
										partner_unique_id: partnerUniqueId,
										registration_certificate: downloadURL,
										registration_certificate_file_ext: filePath
									})

									updatePartnerRegistrationCertificateRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingComplianceCertificatePercentage(0);
												setLoadingComplianceCertificate(false);
												setErrorComplianceCertificate(error);
												setTimeout(function () {
													setErrorComplianceCertificate(null);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingComplianceCertificatePercentage(0);
												setLoadingComplianceCertificate(false);
												setErrorComplianceCertificate(error);
												setTimeout(function () {
													setErrorComplianceCertificate(null);
												}, 2000)
											}
										} else {
											setErrorComplianceCertificate(null);
											setUploadingComplianceCertificatePercentage(0);
											setSuccessComplianceCertificate(`Registration Certificate uploaded successfully!`);

											setTimeout(function () {
												setLoadingComplianceCertificate(false);
												setSuccessComplianceCertificate(null);
												setRemoveComplianceCertificateModal(true);
												setPartnerUniqueId(null);
											}, 3000)
										}
									}).catch(err => {
										setUploadingComplianceCertificatePercentage(0);
										setLoadingComplianceCertificate(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingComplianceCertificatePercentage(0);
					setLoadingComplianceCertificate(false);
				})
			}
		}
	};

	return {
		cookie, loadingComplianceCertificate, errorComplianceCertificate, successComplianceCertificate, handleUploadComplianceCertificate, partnerUniqueId, setSelectedComplianceCertificate,
		setPartnerUniqueId, uploadingComplianceCertificatePercentage, selectedComplianceCertificate, removeComplianceCertificateModal
	};
};

const useGrantPartnerAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingGrantPartnerAccess, setLoadingGrantPartnerAccess] = useState(false);
	const [removeGrantPartnerAccessModal, setRemoveGrantPartnerAccessModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);

	const [errorGrantPartnerAccess, setErrorGrantPartnerAccess] = useState(null);
	const [successGrantPartnerAccess, setSuccessGrantPartnerAccess] = useState(null);

	const handleGrantPartnerAccess = () => {

		if (!loadingGrantPartnerAccess) {
			if (!partnerUniqueId) {
				setErrorGrantPartnerAccess(null);
				setSuccessGrantPartnerAccess(null);
				setErrorGrantPartnerAccess("Partner Unique ID is required");
				setTimeout(function () {
					setErrorGrantPartnerAccess(null);
				}, 2500)
			} else {
				setLoadingGrantPartnerAccess(true);

				const grantPartnerAccessRes = grantPartnerAccess(cookie, {
					partner_unique_id: partnerUniqueId
				})

				grantPartnerAccessRes.then(res => {
					setLoadingGrantPartnerAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorGrantPartnerAccess(error);
							setTimeout(function () {
								setErrorGrantPartnerAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorGrantPartnerAccess(error);
							setTimeout(function () {
								setErrorGrantPartnerAccess(null);
							}, 2000)
						}
					} else {
						setErrorGrantPartnerAccess(null);
						setSuccessGrantPartnerAccess(`Partner access granted!`);

						setTimeout(function () {
							setSuccessGrantPartnerAccess(null);
							setRemoveGrantPartnerAccessModal(true);
							setPartnerUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingGrantPartnerAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingGrantPartnerAccess, removeGrantPartnerAccessModal, errorGrantPartnerAccess, successGrantPartnerAccess, handleGrantPartnerAccess,
		setRemoveGrantPartnerAccessModal, setPartnerUniqueId
	};
};

const useSuspendPartnerAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingSuspendPartnerAccess, setLoadingSuspendPartnerAccess] = useState(false);
	const [removeSuspendPartnerAccessModal, setRemoveSuspendPartnerAccessModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);

	const [errorSuspendPartnerAccess, setErrorSuspendPartnerAccess] = useState(null);
	const [successSuspendPartnerAccess, setSuccessSuspendPartnerAccess] = useState(null);

	const handleSuspendPartnerAccess = () => {

		if (!loadingSuspendPartnerAccess) {
			if (!partnerUniqueId) {
				setErrorSuspendPartnerAccess(null);
				setSuccessSuspendPartnerAccess(null);
				setErrorSuspendPartnerAccess("Partner Unique ID is required");
				setTimeout(function () {
					setErrorSuspendPartnerAccess(null);
				}, 2500)
			} else {
				setLoadingSuspendPartnerAccess(true);

				const suspendPartnerAccessRes = suspendPartnerAccess(cookie, {
					partner_unique_id: partnerUniqueId
				})

				suspendPartnerAccessRes.then(res => {
					setLoadingSuspendPartnerAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorSuspendPartnerAccess(error);
							setTimeout(function () {
								setErrorSuspendPartnerAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorSuspendPartnerAccess(error);
							setTimeout(function () {
								setErrorSuspendPartnerAccess(null);
							}, 2000)
						}
					} else {
						setErrorSuspendPartnerAccess(null);
						setSuccessSuspendPartnerAccess(`Partner access suspended!`);

						setTimeout(function () {
							setSuccessSuspendPartnerAccess(null);
							setRemoveSuspendPartnerAccessModal(true);
							setPartnerUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingSuspendPartnerAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingSuspendPartnerAccess, removeSuspendPartnerAccessModal, errorSuspendPartnerAccess, successSuspendPartnerAccess, handleSuspendPartnerAccess,
		setRemoveSuspendPartnerAccessModal, setPartnerUniqueId
	};
};

const useRevokePartnerAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRevokePartnerAccess, setLoadingRevokePartnerAccess] = useState(false);
	const [removeRevokePartnerAccessModal, setRemoveRevokePartnerAccessModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);

	const [errorRevokePartnerAccess, setErrorRevokePartnerAccess] = useState(null);
	const [successRevokePartnerAccess, setSuccessRevokePartnerAccess] = useState(null);

	const handleRevokePartnerAccess = () => {

		if (!loadingRevokePartnerAccess) {
			if (!partnerUniqueId) {
				setErrorRevokePartnerAccess(null);
				setSuccessRevokePartnerAccess(null);
				setErrorRevokePartnerAccess("Partner Unique ID is required");
				setTimeout(function () {
					setErrorRevokePartnerAccess(null);
				}, 2500)
			} else {
				setLoadingRevokePartnerAccess(true);

				const revokePartnerAccessRes = revokePartnerAccess(cookie, {
					partner_unique_id: partnerUniqueId
				})

				revokePartnerAccessRes.then(res => {
					setLoadingRevokePartnerAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRevokePartnerAccess(error);
							setTimeout(function () {
								setErrorRevokePartnerAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRevokePartnerAccess(error);
							setTimeout(function () {
								setErrorRevokePartnerAccess(null);
							}, 2000)
						}
					} else {
						setErrorRevokePartnerAccess(null);
						setSuccessRevokePartnerAccess(`Partner access revoked!`);

						setTimeout(function () {
							setSuccessRevokePartnerAccess(null);
							setRemoveRevokePartnerAccessModal(true);
							setPartnerUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRevokePartnerAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingRevokePartnerAccess, removeRevokePartnerAccessModal, errorRevokePartnerAccess, successRevokePartnerAccess, handleRevokePartnerAccess,
		setRemoveRevokePartnerAccessModal, setPartnerUniqueId
	};
};

const useVerifyPartner = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyPartner, setLoadingVerifyPartner] = useState(false);
	const [removeVerifyPartnerModal, setRemoveVerifyPartnerModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);

	const [errorVerifyPartner, setErrorVerifyPartner] = useState(null);
	const [successVerifyPartner, setSuccessVerifyPartner] = useState(null);

	const handleVerifyPartner = () => {

		if (!loadingVerifyPartner) {
			if (!partnerUniqueId) {
				setErrorVerifyPartner(null);
				setSuccessVerifyPartner(null);
				setErrorVerifyPartner("Partner Unique ID is required");
				setTimeout(function () {
					setErrorVerifyPartner(null);
				}, 2500)
			} else {
				setLoadingVerifyPartner(true);

				const verifyPartnerRes = verifyPartner(cookie, {
					partner_unique_id: partnerUniqueId
				})

				verifyPartnerRes.then(res => {
					setLoadingVerifyPartner(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyPartner(error);
							setTimeout(function () {
								setErrorVerifyPartner(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyPartner(error);
							setTimeout(function () {
								setErrorVerifyPartner(null);
							}, 2000)
						}
					} else {
						setErrorVerifyPartner(null);
						setSuccessVerifyPartner(`Partner verified successfully!`);

						setTimeout(function () {
							setSuccessVerifyPartner(null);
							setRemoveVerifyPartnerModal(true);
							setPartnerUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyPartner(false);
				})

			}
		}
	};

	return {
		cookie, loadingVerifyPartner, removeVerifyPartnerModal, errorVerifyPartner, successVerifyPartner, handleVerifyPartner,
		setRemoveVerifyPartnerModal, setPartnerUniqueId
	};
};

const useUnverifyPartner = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUnverifyPartner, setLoadingUnverifyPartner] = useState(false);
	const [removeUnverifyPartnerModal, setRemoveUnverifyPartnerModal] = useState(null);
	const [partnerUniqueId, setPartnerUniqueId] = useState(null);

	const [errorUnverifyPartner, setErrorUnverifyPartner] = useState(null);
	const [successUnverifyPartner, setSuccessUnverifyPartner] = useState(null);

	const handleUnverifyPartner = () => {

		if (!loadingUnverifyPartner) {
			if (!partnerUniqueId) {
				setErrorUnverifyPartner(null);
				setSuccessUnverifyPartner(null);
				setErrorUnverifyPartner("Partner Unique ID is required");
				setTimeout(function () {
					setErrorUnverifyPartner(null);
				}, 2500)
			} else {
				setLoadingUnverifyPartner(true);

				const unverifyPartnerRes = unverifyPartner(cookie, {
					partner_unique_id: partnerUniqueId
				})

				unverifyPartnerRes.then(res => {
					setLoadingUnverifyPartner(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUnverifyPartner(error);
							setTimeout(function () {
								setErrorUnverifyPartner(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUnverifyPartner(error);
							setTimeout(function () {
								setErrorUnverifyPartner(null);
							}, 2000)
						}
					} else {
						setErrorUnverifyPartner(null);
						setSuccessUnverifyPartner(`Partner unverified successfully!`);

						setTimeout(function () {
							setSuccessUnverifyPartner(null);
							setRemoveUnverifyPartnerModal(true);
							setPartnerUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUnverifyPartner(false);
				})

			}
		}
	};

	return {
		cookie, loadingUnverifyPartner, removeUnverifyPartnerModal, errorUnverifyPartner, successUnverifyPartner, handleUnverifyPartner,
		setRemoveUnverifyPartnerModal, setPartnerUniqueId
	};
};

export {
	useUpdateComplianceDetails, useUploadPartnerComplianceDocument, useUploadPartnerComplianceCertificate,
	useGrantPartnerAccess, useSuspendPartnerAccess, useRevokePartnerAccess, useVerifyPartner, useUnverifyPartner, 
	useUpdateWebsiteURL
};