import axios from 'axios';
import { config } from '../config';

const getTransactions = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transactions`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayTransactions = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/transactions`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransactionsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transactions/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransactionsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transactions/via/transaction/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getReferenceTransactions = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transactions/via/reference`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getDateTransactions = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/filter/transactions`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPurplepayReferenceTransaction = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/purplepay/transaction/via/reference`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerTransactions = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transactions`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerTransactionsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transactions/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerTransactionsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transactions/via/transaction/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerTransaction = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transaction`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completePartnerDeposit = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transaction/complete/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completePartnerWithdrawal = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/transaction/complete/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransactions = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transactions`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransactionsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transactions/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransactionsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transactions/via/transaction/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransaction = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transaction`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completeBusinessDeposit = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transaction/complete/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completeBusinessWithdrawal = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/business/transaction/complete/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserTransactions = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transactions`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserTransactionsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transactions/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserTransactionsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transactions/via/transaction/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserTransaction = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transaction`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completeUserDeposit = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transaction/complete/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const completeUserWithdrawal = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transaction/complete/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const reverseUserTransfer = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transaction/reverse/transfer`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const reverseUserBill = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/user/transaction/reverse/bill`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const deleteTransaction = async function (key, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/root/transaction`,
			{
				data: {
					key,
					...payload
				}
			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export {
	getTransactions, getTransactionsViaType, getTransactionsViaStatus, getPartnerTransactions, getPartnerTransactionsViaType, getDateTransactions, 
	getPartnerTransactionsViaStatus, getPartnerTransaction, completePartnerDeposit, completePartnerWithdrawal, getBusinessTransactions, reverseUserBill, 
	getBusinessTransactionsViaType, getBusinessTransactionsViaStatus, getBusinessTransaction, completeBusinessDeposit, completeBusinessWithdrawal,
	getUserTransactions, getUserTransactionsViaType, getUserTransactionsViaStatus, getUserTransaction, completeUserDeposit, deleteTransaction, 
	completeUserWithdrawal, getReferenceTransactions, reverseUserTransfer, getPurplepayTransactions, getPurplepayReferenceTransaction, 
};