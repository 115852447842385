import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import Search from "../icons/Search";
import useCookie from "../hooks/useCookie";
import Filter from "../icons/Filter";
import { getNins, getNinViaDeclinedStatus, getNinViaVerifiedStatus, getUserNIN, getUserNINLogsViaEmail, getUserNINLogsViaPhone } from "../api/nin";
import { useDeclineNIN, useVerifyNIN, useCreateVerificationLog } from "../hooks/useNIN";
import { config } from "../config";
import Close from "../icons/Close";
import Loading from "../icons/Loading";
import Copy from "../icons/Copy";
import Check from "../icons/Check";
import Star from "../icons/Star";
import Plus from "../icons/Plus";
import KeyAlt from "../icons/KeyAlt";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import EyeOpenAlt from "../icons/EyeOpenAlt";

export default function NIN(){
    const { cookie, forceLogout } = useCookie(config.key, "");
    const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

    const {
        errorVerifyNIN, handleVerifyNIN, loadingVerifyNIN, removeVerifyNINModal, setRemoveVerifyNINModal,
        setUniqueId: VerifyNinUniqueID, successVerifyNIN, setUserPID: VerifyNinPID, handleNote, note, setNote
    } = useVerifyNIN();

    const {
        errorDeclineNIN, handleDeclineNIN, loadingDeclineNIN, removeDeclineNINModal, setRemoveDeclineNINModal,
        setUniqueId: DeclineNinUniqueID, successDeclineNIN, handleMessage, message, setMessage, setUserPID: DeclineNinPID
    } = useDeclineNIN();

    const {
        errorCreateVerificationLog, handleCreateVerificationLog, loadingCreateVerificationLog, removeCreateVerificationLogModal,
        setRemoveCreateVerificationLogModal, setNIN: VerificationLogUserNIN, setVNIN: VerificationLogUserVNIN, successCreateVerificationLog
    } = useCreateVerificationLog();

    const [currentFunction, setCurrentFunction] = useState("getAllNin");

    const [allUserNinLogs, setAllUserNinLogs] = useState(null);
    const [errorAllUserNinLogs, setErrorAllUserNinLogs] = useState(null);
    const [loadingAllUserNinLogs, setLoadingAllUserNinLogs] = useState(false);

    const [allNin, setAllNin] = useState(null)
    const [errorAllNin, setErrorAllNin] = useState(null)
    const [loadingAllNin, setLoadingAllNin] = useState(false)

    const [ninVerifiedStatus, setNinVerifiedStatus] = useState(null)
    const [ninDeclinedStatus, setNinDeclinedStatus] = useState(null)

    const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

    const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
    const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
    const handleNinVerifiedStatus = (e) => { e.preventDefault(); setNinVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllNinByVerification"); getAllNinByVerification(e.target.value === 'verified' ? true : false, page, size); };
    const handleNinDeclinedStatus = (e) => { e.preventDefault(); setNinDeclinedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllNinByDeclination"); getAllNinByDeclination(e.target.value === 'declined' ? true : false, page, size); };

    async function recordsBySize(size) {
        switch (currentFunction) {
            case "getAllNin":
                getAllNin(page, size);
                break;
            case "getAllNinByVerification":
                getAllNinByVerification(ninVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllNinByDeclination":
                getAllNinByDeclination(ninDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            default:
                getAllNin(page, size);
        }
    };

    async function recordsByPage(page) {
        switch (currentFunction) {
            case "getAllNin":
                getAllNin(page, size);
                break;
            case "getAllNinByVerification":
                getAllNinByVerification(ninVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllNinByDeclination":
                getAllNinByDeclination(ninDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            default:
                getAllNin(page, size);
        }
    };

    async function callLastFunction() {
        switch (currentFunction) {
            case "getAllNin":
                getAllNin(page, size);
                break;
            case "getAllNinByVerification":
                getAllNinByVerification(ninVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllNinByDeclination":
                getAllNinByDeclination(ninDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            // default:
            // 	getAllNin(page, size);
        }
    };

    async function previousNins() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
            switch (currentFunction) {
                case "getAllNin":
                    getAllNin(page - 1, size);
                    break;
                case "getAllNinByVerification":
                    getAllNinByVerification(ninVerifiedStatus === 'verified' ? true : false, page - 1, size);
                    break;
                case "getAllNinByDeclination":
                    getAllNinByDeclination(ninDeclinedStatus === 'declined' ? true : false, page - 1, size);
                    break;
                default:
                    getAllNin(page - 1, size);
            }
        }
	};

	async function nextNins() {
		if (page < allNin.data.pages) setPage(page + 1);
		if (page < allNin.data.pages) {
            switch (currentFunction) {
                case "getAllNin":
                    getAllNin(page + 1, size);
                    break;
                case "getAllNinByVerification":
                    getAllNinByVerification(ninVerifiedStatus === 'verified' ? true : false, page + 1, size);
                    break;
                case "getAllNinByDeclination":
                    getAllNinByDeclination(ninDeclinedStatus === 'declined' ? true : false, page + 1, size);
                    break;
                default:
                    getAllNin(page + 1, size);
            }
        }
	};

    const [loadingViewUser, setLoadingViewUser] = useState(false);
    const [errorViewUser, setErrorViewUser] = useState(null);
    const [viewUserDetails, setViewUserDetails] = useState(null);

    async function getAllNin(_page, _size){
        setLoadingAllNin(true);
        const response = await getNins(cookie, (_page || page), (_size || size))
        setAllNin(response.data)
        if (response.error) setErrorAllNin(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllNin(false);
    }

    async function getAllNinByVerification(verified, _page, _size){
        setLoadingAllNin(true)
        const response = await getNinViaVerifiedStatus(cookie, (_page || page), (_size || size), {verified: verified})
        setAllNin(response.data)
        if (response.error) setErrorAllNin(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
        setLoadingAllNin(false)
    };

    async function getAllNinByDeclination(declined, _page, _size){
        setLoadingAllNin(true)
        const response = await getNinViaDeclinedStatus(cookie, (_page || page), (_size || size), {declined: declined})
        setAllNin(response.data)
        if (response.error) setErrorAllNin(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
        setLoadingAllNin(false)
    };

    async function getUserNINViaPID(pid) {
        const modalResponse = document.querySelector("#userSearchModal");
        modalResponse.setAttribute("display", false);
        setLoadingViewUser(true);
        const response = await getUserNIN(cookie, { pid });
        if (!response.err) { 
            setViewUserDetails(response.data); 
            setLoadingAllUserNinLogs(true);
            const response_logs = await getUserNINLogsViaEmail(cookie, { email: response.data.data.user_data.email });
            if (!response_logs.err) {
                setAllUserNinLogs(response_logs.data);
            } else {
                setAllUserNinLogs(null);
                setErrorAllUserNinLogs(response_logs.response_code !== 422 ? response_logs.error.response.data.message : response_logs.error.response.data.data[0].msg)
            }
            setLoadingAllUserNinLogs(false);
        }
        else { setViewUserDetails(null); setErrorViewUser(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
        setLoadingViewUser(false);
        setUserPIDSearch("");
    };


    const [showVerificationViaNIN, setShowVerificationViaNIN] = useState(false);
    const [showVerificationViaVNIN, setShowVerificationViaVNIN] = useState(false);
    const [userNIN, setUserNIN] = useState("");
    const handleUserNIN = (e) => { e.preventDefault(); setUserNIN(e.target.value); };
    const [userVNIN, setUserVNIN] = useState("");
    const handleUserVNIN = (e) => { e.preventDefault(); setUserVNIN(e.target.value.toUpperCase()); };

    const [userPIDSearch, setUserPIDSearch] = useState("");
    const handleUserPIDSearch = (e) => { e.preventDefault(); setUserPIDSearch(e.target.value.toUpperCase()); };

    useEffect(()=>{
        if(allNin === null){
            callLastFunction();
        }
    }, [allNin])

    const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

    if (removeVerifyNINModal) {
        const modalResponse = document.querySelector("#verifyNINModal");
        modalResponse.setAttribute("display", false);
        getUserNINViaPID(viewUserDetails.data.data.pid);
        setRemoveVerifyNINModal(null);
    }

    if (removeDeclineNINModal) {
        const modalResponse = document.querySelector("#declineNINModal");
        modalResponse.setAttribute("display", false);
        getUserNINViaPID(viewUserDetails.data.data.pid);
        setRemoveDeclineNINModal(null);
    }

    if (removeCreateVerificationLogModal) {
        const modalResponse = document.querySelector("#createVerificationLogModal");
        modalResponse.setAttribute("display", false);
        getUserNINViaPID(viewUserDetails.data.data.pid);
        setRemoveCreateVerificationLogModal(null);
    }

    const pageSelectArray = new Array(allNin ? allNin.data.pages : 0).fill(0);

    return(
        <>
            <Screen>
                <Content>
                    <Navbar placeholder="Search something..." makeHidden={true} />
                    <section className='xui-mt-2'>
                    <div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
                        <div className="xui-mb-1">
                            <h1 className='xui-font-sz-110 xui-font-w-normal'>All User NIN</h1>
                            <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allNin && allNin.success ? allNin.data.total : "..."}</p>
                        </div>
                        <div className="xui-mb-1">
                            <div className='xui-d-inline-flex'>
                                <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userSearchModal">
                                    <span className="xui-mr-half">Search</span>
                                    <Search width="15" height="15" />
                                </button>
                            </div>
                        </div>
                        <div className="xui-mb-1">
                            <div className='xui-d-inline-flex'>
                                <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
                                    <Filter width="16" height="16" />
                                    <select value={ninVerifiedStatus} onChange={handleNinVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
                                        {
										    ninVerifiedStatus === null ?
										    <option selected disabled>Filter By verification Status</option> :
										    <option value={null}>Select to Reset</option>
									    }
                                        <option value={"verified"}>Verified</option>
										<option value={"unverified"}>Unverified</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="xui-mb-1">
                            <div className='xui-d-inline-flex'>
                                <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
                                    <Filter width="16" height="16" />
                                    <select value={ninDeclinedStatus} onChange={handleNinDeclinedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
                                        {
										    ninDeclinedStatus === null ?
										    <option selected disabled>Filter By declination Status</option> :
										    <option value={null}>Select to Reset</option>
									    }
                                        <option value={"declined"}>Declined</option>
										<option value={"undeclined"}>Undeclined</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loadingAllNin
                        ?
                        <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
                        :
                        (
                            allNin && allNin.success
                            ?
                            <div className='xui-table-responsive'>
                                <table className='xui-table xui-font-sz-90'>
                                    <thead>
                                        <tr className='xui-text-left xui-opacity-6'>
                                            <th className='xui-w-30'>S/N</th>
                                            <th className='xui-min-w-100'>PID</th>
                                            <th className='xui-min-w-200'>NIN</th>
                                            <th className='xui-min-w-200'>Verification</th>
                                            <th className='xui-min-w-200'>Declined</th>
                                            <th className='xui-min-w-300'>Declined Timestamp</th>
                                            <th className='xui-min-w-200'>Status</th>
                                            <th className='xui-min-w-300'>Created At</th>
                                            <th className='xui-min-w-300'>Updated At</th>
                                            <th className='xui-min-w-100'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allNin.data.rows.map((data, i)=>(
                                                <tr className="" key={i}>
                                                    <td className='xui-opacity-5'>
														<span>{i + 1}</span>
													</td>
                                                    <td className='xui-opacity-5 xui-font-w-bold'>
                                                        <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                            <span>#{data.pid}</span>
                                                            <span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
                                                                {copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        {
                                                            data.vNIN ? 
                                                                <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Virtual NIN</span> : 
                                                                <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                                    <span>{data.nin}</span>
                                                                    <span title="Copy NIN" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.nin); setTextCopied(data.nin); }}>
                                                                        {copiedText && textCopied === data.nin ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                                    </span>
                                                                </div>
                                                        }
													</td>
                                                    <td className='xui-opacity-5'>
                                                        {
                                                            data.verified ?
                                                                <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
                                                                <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
                                                        }
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        {
                                                            data.declined === null ?
                                                                <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                                (
                                                                    data.declined ?
                                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
                                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
                                                                )

                                                        }
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        {
                                                            data.declined_timestamp === null ?
                                                                <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                                (
                                                                    data.declined_timestamp ?
                                                                        <span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
                                                                        "No timestamp"
                                                                )
                                                        }
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        {
                                                            data.status === 1 ?
                                                                <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
                                                                <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
                                                        }
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        <span>{data.createdAt.date} at {data.createdAt.time}</span>
                                                    </td>
                                                    <td className='xui-opacity-5'>
                                                        <span>{data.updatedAt.date} at {data.updatedAt.time}</span>
                                                    </td>
                                                    <td className=''>
                                                        <div className="xui-d-flex xui-grid-gap-1">
                                                            <button title="View User NIN Details" onClick={() => { getUserNINViaPID(data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserDetailsModal">
                                                                <EyeOpenAlt width="16" height="16" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                 <center className="xui-text-red">
                                        <Close width="100" height="100" />
                                        <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllNin}</h3>
                                    </center>
                                </div>
                            </div>
                        )
                    }
                    {
                        loadingAllNin
                        ?
                        <Loading width="12" height="12" /> :
                        (
                            allNin && allNin.success ?
                            <div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
                                                <span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
                                                    {
                                                        pageSelectArray.map((value, index) => {
                                                            return (
                                                                <option value={index + 1}>{index + 1}</option>
                                                            )
                                                        })
                                                    }
                                                </select></span> of {allNin ? allNin.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousNins}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextNins}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
                        )
                    }
                    </section>
                </Content>
            </Screen>
            <section className='xui-modal' xui-modal="viewUserDetailsModal" id="viewUserDetailsModal">
                <div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserDetailsModal">
                        <Close width="24" height="24" />
                    </div>
                    {
                        loadingViewUser ?
                            <center>
                                <Loading width="12" height="12" />
                            </center> : (
                                viewUserDetails && viewUserDetails.success ?
                                    <>
                                        <h1>{viewUserDetails.data.user_data.firstname + (viewUserDetails.data.user_data.middlename ? " " + viewUserDetails.data.user_data.middlename + " " : " ") + viewUserDetails.data.user_data.lastname}</h1>
                                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Details of this user below</p>
                                        <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
                                            <div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
                                                <img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserDetails.data.user_data.photo} alt={viewUserDetails.data.user_data.firstname + (viewUserDetails.data.user_data.middlename ? " " + viewUserDetails.data.user_data.middlename + " " : " ") + viewUserDetails.data.user_data.lastname + " Selfie Image"} />
                                                <center className="xui-mt-1">
                                                    <b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserDetails.data.data.pid}</b>
                                                    <center>
                                                        <div className="xui-d-inline-flex xui-flex-ai-center">
                                                            <span>
                                                                {
                                                                    viewUserDetails.data.user_data.star === 0 ?
                                                                        <div className='xui-m-half'>
                                                                            <p>No star</p>
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 1 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 2 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 3 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 4 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 5 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                            </span>
                                                        </div>
                                                    </center>
                                                </center>
                                            </div>
                                            <div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">First Name -</span> {viewUserDetails.data.user_data.firstname}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Middle Name -</span> {viewUserDetails.data.user_data.middlename ? viewUserDetails.data.user_data.middlename : "No middle name"}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Last Name -</span> {viewUserDetails.data.user_data.lastname}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Email -</span> {<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>{viewUserDetails.data.user_data.email}</span>}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Phone Number -</span> {<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>{viewUserDetails.data.user_data.phone_number}</span>}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Gender -</span> {viewUserDetails.data.user_data.gender} | <span className="xui-font-w-bold">Date of Birth -</span> {new Date(viewUserDetails.data.user_data.dob).toDateString()} / {viewUserDetails.data.user_data.dob}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">NIN -</span> {viewUserDetails.data.data.nin}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Virtual NIN -</span> {
                                                    viewUserDetails.data.data.vNIN ?
                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
                                                    viewUserDetails.data.data.verified ?
                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
                                                    viewUserDetails.data.data.declined === null ?
                                                        <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                        (
                                                            viewUserDetails.data.data.declined ?
                                                                <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
                                                                <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
                                                        )

                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
                                                    viewUserDetails.data.data.declined_timestamp === null ?
                                                        <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                        (
                                                            viewUserDetails.data.data.declined_timestamp ?
                                                                <span>{viewUserDetails.data.data.declined_timestamp.date} at {viewUserDetails.data.data.declined_timestamp.time}</span> :
                                                                "No timestamp"
                                                        )
                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
                                                    viewUserDetails.data.data.status === 1 ?
                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
                                                }</p>
                                            </div>
                                        </div>
                                        <center>
                                            <p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserDetails.data.data.createdAt.fulldate} | Last Updated - {viewUserDetails.data.data.updatedAt.fulldate}</p>
                                            <div className="xui-m-2">
                                                <button title="Verify User NIN" onClick={() => { VerifyNinUniqueID(viewUserDetails.data.data.unique_id); VerifyNinPID(viewUserDetails.data.data.pid); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyNINModal">
                                                    <Check width="16" height="16" />
                                                </button>
                                                <button title="Decline User NIN" onClick={() => { DeclineNinUniqueID(viewUserDetails.data.data.unique_id); DeclineNinPID(viewUserDetails.data.data.pid); }} className="xui-btn psc-btn-red xui-ml-3 xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineNINModal">
                                                    <Close width="16" height="16" />
                                                </button>
                                            </div>
                                        </center>

                                        <h1>NIN Verification Logs</h1>
                                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half xui-mb-2">NIN details of this user below</p>
                                        {
                                            loadingAllUserNinLogs
                                                ?
                                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
                                                :
                                                (
                                                    allUserNinLogs && allUserNinLogs.success
                                                        ?
                                                        <div className='xui-table-responsive'>
                                                            <table className='xui-table xui-font-sz-90'>
                                                                <thead>
                                                                    <tr className='xui-text-left xui-opacity-6'>
                                                                        <th className='xui-min-w-200'>First Name</th>
                                                                        <th className='xui-min-w-200'>Last Name</th>
                                                                        <th className='xui-min-w-200'>Email</th>
                                                                        <th className='xui-min-w-250'>Phone Number</th>
                                                                        <th className='xui-min-w-100'>Gender</th>
                                                                        <th className='xui-min-w-200'>Date Of Birth</th>
                                                                        <th className='xui-min-w-200'>Reference</th>
                                                                        <th className='xui-min-w-200'>Status</th>
                                                                        <th className='xui-min-w-200'>VNIN</th>
                                                                        <th className='xui-min-w-300'>Created At</th>
                                                                        <th className='xui-min-w-300'>Updated At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        allUserNinLogs.data.rows.map((data, i) => (
                                                                            <tr className="" key={i}>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.firstname}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.surname}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.email ? data.nin_data.email : <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>None</span>}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.telephoneno}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.gender}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.nin_data.birthdate}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.verification.reference}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{data.verification.status}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.verification.vnin ? data.verification.vnin : <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>None</span>}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.createdAt.date} at {data.createdAt.time}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.updatedAt.date} at {data.updatedAt.time}</span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                                            <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                                                <center className="xui-text-red">
                                                                    <Close width="100" height="100" />
                                                                    <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllUserNinLogs}</h3>
                                                                </center>
                                                                <center>
                                                                    {
                                                                        showVerificationViaNIN ?
                                                                            <form className="xui-form xui-mt-1" onSubmit={(e) => e.preventDefault()}>
                                                                                <div className="xui-w-300 xui-lg-w-300">
                                                                                    <label>Create Log Via NIN</label>
                                                                                    <div className="xui-d-flex xui-flex-ai-center">
                                                                                        <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-red xui-text-white xui-mr-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" onClick={() => { setShowVerificationViaNIN(false); setUserNIN("")}}>
                                                                                            <Close width="16" height="16" />
                                                                                        </div>
                                                                                        <input style={{ width: "calc(100% - 100px)" }} type={"text"} maxLength={11} value={userNIN} onChange={handleUserNIN} required />
                                                                                        <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle psc-btn-green xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" onClick={() => VerificationLogUserNIN(userNIN)} xui-modal-open="createVerificationLogModal">
                                                                                            <Check width="16" height="16" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form> :
                                                                            <button onClick={() => { setShowVerificationViaNIN(true); setShowVerificationViaVNIN(false); setUserVNIN("") }} className="xui-m-2 xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
                                                                                <span className="xui-mr-half">Create Verification Log (NIN)</span>
                                                                                <Plus width="16" height="16" />
                                                                            </button>
                                                                    }
                                                                    {
                                                                        showVerificationViaVNIN ?
                                                                            <form className="xui-form xui-mt-1" onSubmit={(e) => e.preventDefault()}>
                                                                                <div className="xui-w-300 xui-lg-w-300">
                                                                                    <label>Create Log Via Virtual NIN</label>
                                                                                    <div className="xui-d-flex xui-flex-ai-center">
                                                                                        <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-red xui-text-white xui-mr-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" onClick={() => { setShowVerificationViaVNIN(false); setUserVNIN("") }}>
                                                                                            <Close width="16" height="16" />
                                                                                        </div>
                                                                                        <input style={{ width: "calc(100% - 100px)" }} type={"text"} maxLength={16} value={userVNIN} onChange={handleUserVNIN} required />
                                                                                        <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle psc-btn-green xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer" onClick={() => VerificationLogUserVNIN(userVNIN)} xui-modal-open="createVerificationLogModal">
                                                                                            <Check width="16" height="16" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form> :
                                                                            <button onClick={() => { setShowVerificationViaVNIN(true); setShowVerificationViaNIN(false); setUserNIN(""); }} className="xui-m-2 xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
                                                                                <span className="xui-mr-half">Create Verification Log (VNIN)</span>
                                                                                <Plus width="16" height="16" />
                                                                            </button>
                                                                    }
                                                                </center>
                                                            </div>
                                                        </div>
                                                )
                                        }
                                    </> :
                                    <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                        <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                            <center className="xui-text-red">
                                                <Close width="100" height="100" />
                                                <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewUser}</h3>
                                            </center>
                                        </div>
                                    </div>
                            )
                    }
                </div>
            </section>
            <section className='xui-modal' xui-modal="createVerificationLogModal" id="createVerificationLogModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Create Verification Log</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCreateVerificationLog}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCreateVerificationLog}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCreateVerificationLog} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCreateVerificationLog ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCreateVerificationLog ? "" : "createVerificationLogModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="verifyNINModal" id="verifyNINModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Verify User NIN</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <form className="xui-form xui-mt-2">
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Note</label>
                            <textarea type={"text"} maxLength={500} placeholder={"Drop a note (optional)"} value={note} onChange={handleNote}></textarea>
                        </div>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyNIN}</span></p>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyNIN}</span></p>
                    </form>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleVerifyNIN} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingVerifyNIN ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyNIN ? "" : "verifyNINModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="declineNINModal" id="declineNINModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Decline User NIN</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <form className="xui-form xui-mt-2">
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Feedback</label>
                            <textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={message} onChange={handleMessage}></textarea>
                        </div>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineNIN}</span></p>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineNIN}</span></p>
                    </form>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleDeclineNIN} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingDeclineNIN ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineNIN ? "" : "declineNINModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="userSearchModal" id="userSearchModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userSearchModal">
                        <Close width="24" height="24" />
                    </div>
                    <h1>Search for User NIN</h1>
                    <form className="xui-form xui-mt-2" onSubmit={(e) => {
                        e.preventDefault();
                        getUserNINViaPID(userPIDSearch);
                    }}>
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>PID</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                                <input style={{ width: "calc(100% - 50px)" }} type={"text"} maxLength={6} placeholder={"Enter Passcoder ID"} value={userPIDSearch} onChange={handleUserPIDSearch} />
                                <button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open={`${userPIDSearch ? "viewUserDetailsModal" : ""}`}>
                                    <Search width="16" height="16" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
