import { useState } from "react";
import useCookie from "./useCookie";
import { sendGeneralNotification } from "../api/notifications";
import { config } from "../config";

function useSendGeneralNotification() {
	const { cookie } = useCookie(config.key, "");

	const [loadingSendGeneralNotification, setLoadingSendGeneralNotification] = useState(false);
	const [removeSendGeneralNotificationModal, setRemoveSendGeneralNotificationModal] = useState(null);
	const [title, setTitle] = useState(null);
	const [body, setBody] = useState(null);

	const [errorSendGeneralNotification, setErrorSendGeneralNotification] = useState(null);
	const [successSendGeneralNotification, setSuccessSendGeneralNotification] = useState(null);

	const handleTitle = (e) => { e.preventDefault(); setTitle(e.target.value); };
	const handleBody = (e) => { e.preventDefault(); setBody(e.target.value); };

	const handleSendGeneralNotification = (e) => {
		e.preventDefault();

		if (!loadingSendGeneralNotification) {
			if(!title) {
				setErrorSendGeneralNotification(null)
				setSuccessSendGeneralNotification(null)
				setErrorSendGeneralNotification("Title is required");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else if (title.length < 3) {
				setErrorSendGeneralNotification("Title minimum characters - 3");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else if (title.length > 200) {
				setErrorSendGeneralNotification("Title maximum characters - 200");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else if (!body) {
				setErrorSendGeneralNotification("Body is required");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else if (body.length < 3) {
				setErrorSendGeneralNotification("Body minimum characters - 3");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else if (body.length > 1000) {
				setErrorSendGeneralNotification("Body maximum characters - 1000");
				setTimeout(function () {
					setErrorSendGeneralNotification(null);
				}, 2500)
			} else {
				var continue_action = window.confirm("Are you sure you want to push this notification");

				if (continue_action) {
					setLoadingSendGeneralNotification(true)
	
					const sendGeneralNotificationRes = sendGeneralNotification(cookie, {
						title: title,
						body: body
					})
	
					sendGeneralNotificationRes.then((res) => {
	
						setLoadingSendGeneralNotification(false)
						if (res.error) {
							if (!res.error.response.data.success) {
								const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
								setErrorSendGeneralNotification(error)
								setTimeout(() => {
									setErrorSendGeneralNotification(null)
								}, 2000)
							} else {
								const error = `${res.error.code} - ${res.error.message}`;
								setErrorSendGeneralNotification(error)
								setTimeout(() => {
									setErrorSendGeneralNotification(null)
								}, 2000)
							}
						} else {
							setErrorSendGeneralNotification(null)
							setSuccessSendGeneralNotification("Notification sent out successfully");
	
							setTimeout(() => {
								setSuccessSendGeneralNotification(null);
								setRemoveSendGeneralNotificationModal(true);
								setTitle(null);
								setBody(null);
							}, 2500)
						}
					}).catch(err => {
						setLoadingSendGeneralNotification(false);
						setErrorSendGeneralNotification(err.message);
						setTimeout(() => {
							setErrorSendGeneralNotification(null)
						}, 2000)
					})
				} 
			}

		}
	}

	return {
		cookie, loadingSendGeneralNotification, successSendGeneralNotification, errorSendGeneralNotification, removeSendGeneralNotificationModal, setRemoveSendGeneralNotificationModal, handleSendGeneralNotification,
		title, body, setTitle, setBody, handleBody, handleTitle
	}
}
export { useSendGeneralNotification };