import axios from 'axios';
import { config } from '../config';

const getBillLogs = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBillLogsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs/via/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBillLogsViaCustomer = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs/via/customer`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBillLogsViaCode = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs/via/code`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBillLogsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBillLogsViaReference = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bill/logs/via/reference`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getBillLogs, getBillLogsViaStatus, getBillLogsViaCode, getBillLogsViaType, getBillLogsViaCustomer, getBillLogsViaReference };