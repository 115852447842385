import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import BundledEditor from '../BundledEditor';
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Edit from "../icons/Edit";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Minus from "../icons/Minus";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getMailingTemplates } from "../api/mailing";
import { useSendNewsletter } from "../hooks/useMailing";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";

export default function Newsletter() {
	const navigate = useNavigate();

	const { cookie, forceLogout } = useCookie(config.key, "");

	const editorRef = useRef(null);
	
	const {
		details, email, errorSendNewsletter, handleDetails, handleEmail, handleSubject, handleSubmitSendNewsletter, handleTemplate,
		loadingSendNewsletter, removeSendNewsletterModal, setRemoveSendNewsletterModal, subject, successSendNewsletter, template
	} = useSendNewsletter();

	const [showConfirmNewsletter, setShowConfirmNewsletter] = useState(false);

	const setDetailsContents = () => {
		if (editorRef.current) {
			handleDetails(editorRef.current.getContent());
		}
	};

	const [allMailingTemplate, setAllMailingTemplate] = useState(null);
	const [errorMailingTemplate, setErrorMailingTemplate] = useState(null);
	const [loadingAllMailingTemplate, setLoadingAllMailingTemplate] = useState(false);

	async function getAllMailingTemplates() {
		setLoadingAllMailingTemplate(true);
		const response = await getMailingTemplates(cookie, 1, 20);
		setAllMailingTemplate(response.data);
		if (response.error) setErrorMailingTemplate(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllMailingTemplate(false);
	};

	useEffect(() => {
		if (allMailingTemplate === null) {
			getAllMailingTemplates();
		}
	}, [allMailingTemplate]);

	if (removeSendNewsletterModal) {
		setRemoveSendNewsletterModal(null);
		setTimeout(function () {
			navigate(`/internal/mails`);
		}, 1500)
	}

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>New Email Newsletter</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half"></p>
							</div>
						</div>
						<form className="xui-form" layout="2" onSubmit={(e) => e.preventDefault()}>
							<div className="xui-form-box xui-mt-2">
								<label className="">Email</label>
								<input type="email" value={email} onChange={handleEmail} placeholder="Enter mailing list alias" required ></input>
							</div>
							<div className="xui-form-box xui-mt-2">
								<label>Template</label>
								<select value={template} onChange={handleTemplate} className="xui-bdr-black" required>
									<option selected value={""}>Select Template</option>
									{
										allMailingTemplate ? (
											allMailingTemplate.data.map((item, index) => {
												return (
													<option key={index} value={item.stripped}>{item.stripped}</option>
												)
											})
										) : ""
									}
								</select>
							</div>
							<div className="xui-form-box xui-mt-2">
								<label className="">Subject</label>
								<input type="text" value={subject} onChange={handleSubject} placeholder="Enter subject of mail" required ></input>
							</div>
							<div className="xui-form-box xui-mt-2">
								<label className="">Details</label>
								<BundledEditor
									onInit={(evt, editor) => editorRef.current = editor}
									initialValue={details}
									init={{
										height: 700,
										font_size_input_default_unit: "pt",
										menubar: false,
										plugins: [
											'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
											'searchreplace', 'table', 'wordcount'
										],
										toolbar: [
											'undo redo | styles | bold italic forecolor fontsizeinput | bullist numlist outdent indent | link image | alignleft aligncenter alignright alignjustify | removeformat',
										],
										toolbar_mode: 'floating',
										content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
									}}
								/>
							</div>
							{
								showConfirmNewsletter ?
									<div className="xui-m-3">
										<center>
											<h4>Confirm Newsletter Mail</h4>
											<p className="xui-opacity-5 xui-font-sz-90 xui-m-half">Are you sure you want to continue with this action?</p>
											<p className="xui-opacity-5 xui-font-sz-90">You can't edit or undo this, please check your contents again before sending ...</p>
										</center>
										<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSendNewsletter}</span></p>
										<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSendNewsletter}</span></p>
										<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
											<div className="xui-d-inline-flex xui-flex-ai-center">
												<button onClick={handleSubmitSendNewsletter} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">Yes</span>
													{
														loadingSendNewsletter ?
															<Loading width="12" height="12" />
															: <Check width="20" height="20" />
													}
												</button>
											</div>
											<div className="xui-d-inline-flex xui-flex-ai-center">
												<button onClick={() => setShowConfirmNewsletter(false)} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">No</span>
													<Close width="20" height="20" />
												</button>
											</div>
										</div>
									</div> :
									<div>
										<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSendNewsletter}</span></p>
										<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSendNewsletter}</span></p>
										<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
											<button disabled={subject.length < 3 || template.length < 1 || email.length < 5} onClick={() => { setDetailsContents(); setShowConfirmNewsletter(true); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
												<span className="xui-mr-half">Send Newsletter</span>
											</button>
										</div>
									</div>

							}
						</form>
					</section>
				</Content>
			</Screen>
		</>
	);

}