import axios from 'axios';
import { config } from '../config';

const getPartners = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partners`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartner = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerViaEmail = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/partner/via/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerComplianceDocumentsProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/partner/compliance/documents`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updatePartnerComplianceDetails = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/compliance/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updatePartnerComplianceCertificate = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/compliance/certificate`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updatePartnerComplianceDocument = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/compliance/document`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updatePartnerComplianceDocuments = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/compliance/documents`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updatePartnerWebsiteURL = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/website/url`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const grantPartnerAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/access/grant`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const suspendPartnerAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/access/suspend`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const revokePartnerAccess = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/access/revoke`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const verifyPartner = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const unverifyPartner = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/partner/unverify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export {
	getPartners, getPartner, getPartnerViaEmail, updatePartnerComplianceDetails, updatePartnerComplianceCertificate,
	updatePartnerComplianceDocument, updatePartnerComplianceDocuments, grantPartnerAccess, suspendPartnerAccess,
	revokePartnerAccess, verifyPartner, unverifyPartner, updatePartnerWebsiteURL, getPartnerComplianceDocumentsProof
};