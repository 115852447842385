import axios from 'axios'
import { config } from '../config'

async function getSschools(key, page, size){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/secondary/schools`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getSschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/secondary/school`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserSschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/secondary/school/verify`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserSschool(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/secondary/school/decline`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getSschoolViaPID(key, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/secondary/schools/via/user`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getSschoolsViaVerifiedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/secondary/schools/via/verification`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getSschoolsViaProofType(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/secondary/schools/via/type`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
    getSschools, getSschool, verifyUserSschool,declineUserSschool, 
	getSschoolViaPID, getSschoolsViaVerifiedStatus, getSschoolsViaProofType
}