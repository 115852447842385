import { Link } from 'react-router-dom';
import Screen from '../components/Screen';
import Content from '../components/Content';
import Navbar from '../components/Navbar';
import { useGetAnalytics } from "../hooks/useAnalytics";
import Loading from "../icons/Loading";
import { useSendGeneralNotification } from "../hooks/useNotifications";
import Close from "../icons/Close";
import Alarm from "../icons/Alarm";
import Mail from "../icons/Mail";
import Arrowright from "../icons/Arrowright";

export default function Dashboard() {
	const { analytics } = useGetAnalytics();

	const {
		body, errorSendGeneralNotification, handleBody, handleSendGeneralNotification, handleTitle, loadingSendGeneralNotification, removeSendGeneralNotificationModal,
		setBody, setRemoveSendGeneralNotificationModal, setTitle, successSendGeneralNotification, title
	} = useSendGeneralNotification();

	if (removeSendGeneralNotificationModal) {
		const modalResponse = document.querySelector("#sendGeneralNotificationModal");
		modalResponse.setAttribute("display", false);

		setRemoveSendGeneralNotificationModal(null);
	}

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					
					<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
						<div className="xui-mb-1">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70" xui-modal-open="sendGeneralNotificationModal">
								<Alarm width="16" height="16" />
								<span className="xui-ml-half">Push Notification</span>
							</button>
						</div>
						<div className="xui-mb-1">
							<Link to={`/internal/newsletter`} className="xui-text-dc-none xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Mail width="16" height="16" />
								<span className="xui-ml-half">Create Newsletter</span>
							</Link>
						</div>
					</div>

					<section className='xui-mb-3'>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_users.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Users</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_businesses.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Businesses</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_partners.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Partners</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.api_calls.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>API Calls</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.partner_completed_deposits.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Partner Completed Deposits</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.partner_cancelled_deposits.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Partner Cancelled Deposits</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_deposit_charges.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Deposit Charges</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_premium_subscription.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Subscriptions</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_payment_charges.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Payment Charges</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_payments.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Payments</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_completed_withdrawals.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Completed Withdrawals</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.business_cancelled_withdrawals.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Business Cancelled Withdrawals</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_completed_deposits.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Completed Deposits</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_completed_withdrawals.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Completed Withdrawals</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_payments.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Payments</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_cancelled_deposits.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Cancelled Deposits</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_cancelled_withdrawals.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Cancelled Withdrawals</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_deposit_charges.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Deposit Charges</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_paystack_deposit_charges.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Paystack Charges</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_purplepay_deposit_charges.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Purplepay Charges</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.bank_transfer_commissions.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Bank Transfer Commissions</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_to_user_payments.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User to User Payments</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.user_bank_transfers.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>User Bank Transfers</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? <span>&#x20A6; {analytics.data.transaction_analytics.reversed_transactions.toLocaleString()} </span> : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Reversed Transactions</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_transactions.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Transactions</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_tokens.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Tokens</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_requests.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Requests</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_app_defaults.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>App Defaults</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_api_keys.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>API Keys</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_api_criteria.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>API Criteria</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_offers.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Offers</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_apps.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Connected Apps</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_announcements.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Announcements</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_mailing_templates.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Mailing Templates</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_notifications.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Notifications</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_address.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Addresses</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_bvn.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>BVN</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_nin.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>NIN</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_next_of_kin.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Next Of Kin</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_medical_details.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Medical Details</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_medical_emergency_contacts.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Emergency Contacts</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_medical_history.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Medical Histories</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_driver_licence.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Driver's Licence</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_passport.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Passports</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_voter_card.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Voter Card</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_cac.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>CAC</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_polling.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Polling</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_tax_records.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Tax Records</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_primary_school.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Primary Schools</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_secondary_school.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Secondary Schools</span>
								</div>
							</div>
						</div>
						<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_tertiary_institution.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Tertiary Institutions</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_additional_qualification.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Additional Qualifications</span>
								</div>
							</div>
							<div className='xui-bg-pos-center xui-bg-sz-cover xui-bdr-rad-half xui-overflow-hidden' style={{ backgroundImage: "url('https://res.cloudinary.com/xnyder/image/upload/v1679054785/passcoder-for-business/17-athletics_y2m7nj.png')" }}>
								<div className='xui-py-1 xui-px-2 xui-overlay xui-h-fluid-100'>
									<h3 className='xui-font-sz-180 xui-font-w-normal'>{analytics ? analytics.data.total_work_history.toLocaleString() : <Loading width="12" height="12" />}</h3>
									<span className='xui-font-sz-90'>Work Histories</span>
								</div>
							</div>
						</div>
						{/* Add Transaction Analytics here later */}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="sendGeneralNotificationModal" id="sendGeneralNotificationModal">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="sendGeneralNotificationModal">
						<Close width="24" height="24" />
					</div>
					<h1>Send push notification to all platforms</h1>
					<form className="xui-form" onSubmit={handleSendGeneralNotification}>
						<div className="xui-form-box">
							<label>Title</label>
							<input className="xui-font-sz-90" type="text" value={title} onChange={handleTitle} required placeholder="Enter title of notification"></input>
							<p className="xui-font-sz-80 xui-my-1 xui-mt-1">{title ? title.length + "/200" : "0/200"}</p>
						</div>
						<div className="xui-form-box">
							<label>Body</label>
							<textarea type={"text"} required maxLength={1000} placeholder={"Enter body of notification"} value={body} onChange={handleBody}></textarea>
							<p className="xui-font-sz-80 xui-my-1 xui-mt-1">{body ? body.length + "/1000" : "0/1000"}</p>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">Warning: Please check your contents correctly before clicking on "Send", once that's done it can't be modified.</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSendGeneralNotification}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSendGeneralNotification}</span></p>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<span className="xui-ml-half">Send</span>
								{
									loadingSendGeneralNotification ?
										<Loading width="12" height="12" />
										: <Arrowright width="16" height="16" />
								}
							</button>
						</div>
					</form>
				</div>
			</section>
		</>
	);
}