import axios from 'axios'
import { config } from '../config'

async function getDriverLicences(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/driver/licences`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getDriverLicence(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/driver/licence/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserDriverLicence(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/driver/licence/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserDriverLicence(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/driver/licence/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getDriverLicenceViaPID(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/driver/licence/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getDriverLicencesViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/driver/licences/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getDriverLicences, getDriverLicence, verifyUserDriverLicence, declineUserDriverLicence, getDriverLicenceViaPID, getDriverLicencesViaVerifiedStatus,
}