import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserCAC, verifyUserCAC } from "../api/cac";
import { config } from "../config";

function useVerifyUserCAC() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserCAC, setLoadingVerifyUserCAC] = useState(false)
	const [removeVerifyUserCACModal, setRemoveVerifyUserCACModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [cacUniqueId, setCACUniqueId] = useState(null)

	const [errorVerifyUserCAC, setErrorVerifyUserCAC] = useState(null)
	const [successVerifyUserCAC, setSuccessVerifyUserCAC] = useState(null)

	const handleVerifyUserCAC = () => {
		if (!loadingVerifyUserCAC) {
			if (!userPID || !cacUniqueId) {
				setErrorVerifyUserCAC(null)
				setSuccessVerifyUserCAC(null)
				setErrorVerifyUserCAC(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserCAC(null)
				}, 2500)
			} else {
				setLoadingVerifyUserCAC(true)

				const verifyUserCACRes = verifyUserCAC(cookie, { pid: userPID, unique_id: cacUniqueId })

				verifyUserCACRes.then((res) => {

					setLoadingVerifyUserCAC(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserCAC(error)
							setTimeout(() => {
								setErrorVerifyUserCAC(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserCAC(error)
							setTimeout(() => {
								setErrorVerifyUserCAC(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserCAC(null)
						setSuccessVerifyUserCAC("User cac verified")

						setTimeout(() => {
							setSuccessVerifyUserCAC(null)
							setRemoveVerifyUserCACModal(true)
							setUserPID(null)
							setCACUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserCAC(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserCAC, successVerifyUserCAC, errorVerifyUserCAC, removeVerifyUserCACModal, setRemoveVerifyUserCACModal, handleVerifyUserCAC,
		userPID, setUserPID, cacUniqueId, setCACUniqueId
	}
}

function useDeclineUserCAC() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineCAC, setLoadingDeclineCAC] = useState(false)
	const [removeDeclineCACModal, setRemoveDeclineCACModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [cacUniqueId, setCACUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclineCAC, setErrorDeclineCAC] = useState(null)
	const [successDeclineCAC, setSuccessDeclineCAC] = useState(null)

	const handleDeclineCAC = () => {
		if (!loadingDeclineCAC) {
			if (!userPID || !cacUniqueId) {
				setErrorDeclineCAC(null)
				setSuccessDeclineCAC(null)
				setErrorDeclineCAC(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclineCAC(null)
				}, 2500)
			} else {
				setLoadingDeclineCAC(true)

				const declineUserCACRes = declineUserCAC(cookie, { pid: userPID, unique_id: cacUniqueId, message: declineMsg })

				declineUserCACRes.then((res) => {
					setLoadingDeclineCAC(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineCAC(error)
							setTimeout(() => {
								setErrorDeclineCAC(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineCAC(error)
							setTimeout(() => {
								setErrorDeclineCAC(null)
							}, 2500)
						}
					} else {
						setErrorDeclineCAC(null)
						setDeclineMsg("")
						setSuccessDeclineCAC("User cac declined")

						setTimeout(() => {
							setSuccessDeclineCAC(null)
							setRemoveDeclineCACModal(true)
							setUserPID(null)
							setCACUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclineCAC, successDeclineCAC, loadingDeclineCAC,
		userPID, setUserPID, cacUniqueId, setCACUniqueId, declineMsg, setDeclineMsg, handleDeclineCAC,
		removeDeclineCACModal, setRemoveDeclineCACModal
	}
}

export { useVerifyUserCAC, useDeclineUserCAC }