import { useEffect, useState } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';
import MarkdownPreview from "@uiw/react-markdown-preview";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getMailingTemplates, getMailingTemplate } from "../api/mailing";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";
import { useAddMailingTemplate, useDeleteMailingTemplate, useEditMailingTemplateDetails, useEditMailingTemplateName, useEditMailingTemplateSubject } from "../hooks/useMailingTemplates";
import Edit from "../icons/Edit";

export default function MailingTemplates() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorAddMailingTemplate, handleAddMailingTemplate, handleHtml, handleName, handleSubject, html, loadingAddMailingTemplate, name, removeAddMailingTemplateModal, 
		setHtml, setName, setRemoveAddMailingTemplateModal, setSubject, subject, successAddMailingTemplate
	} = useAddMailingTemplate();

	const {
		name: nameEdit, errorEditMailingTemplateName, handleEditMailingTemplateName, handleName: handleNameEdit, loadingEditMailingTemplateName, removeEditMailingTemplateNameModal, 
		setRemoveEditMailingTemplateNameModal, successEditMailingTemplateName, setName: setNameEdit, setUniqueId: EditUniqueIdName
	} = useEditMailingTemplateName();

	const {
		subject: subjectEdit, errorEditMailingTemplateSubject, handleEditMailingTemplateSubject, handleSubject: handleSubjectEdit, loadingEditMailingTemplateSubject, removeEditMailingTemplateSubjectModal, 
		setRemoveEditMailingTemplateSubjectModal, successEditMailingTemplateSubject, setSubject: setSubjectEdit, setUniqueId: EditUniqueIdSubject
	} = useEditMailingTemplateSubject();
	
	const {
		html: htmlEdit, errorEditMailingTemplateDetails, handleEditMailingTemplateDetails, handleHtml: handleHtmlEdit, loadingEditMailingTemplateDetails, removeEditMailingTemplateDetailsModal, 
		setRemoveEditMailingTemplateDetailsModal, successEditMailingTemplateDetails, setHtml: setHtmlEdit, setUniqueId: EditUniqueIdDetails
	} = useEditMailingTemplateDetails();

	const {
		errorDeleteMailingTemplate, handleDeleteMailingTemplate, loadingDeleteMailingTemplate, removeDeleteMailingTemplateModal, setUniqueId: DeleteUniqueId,
		setRemoveDeleteMailingTemplateModal, successDeleteMailingTemplate
	} = useDeleteMailingTemplate();

	const [allMailingTemplate, setAllMailingTemplate] = useState(null);
	const [errorMailingTemplate, setErrorMailingTemplate] = useState(null);
	const [loadingAllMailingTemplate, setLoadingAllMailingTemplate] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getMailingTemplate(page, e.target.value); };

	async function previousMailingTemplate() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getMailingTemplate(page - 1, size);
	};

	async function nextMailingTemplate() {
		if (page < allMailingTemplate.data.pages) setPage(page + 1);
		if (page < allMailingTemplate.data.pages) getMailingTemplate(page + 1, size);
	};

	async function getAllMailingTemplates(_page, _size) {
		setLoadingAllMailingTemplate(true);
		const response = await getMailingTemplates(cookie, (_page || page), (_size || size));
		setAllMailingTemplate(response.data);
		if (response.error) setErrorMailingTemplate(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllMailingTemplate(false);
	};

	useEffect(() => {
		if (allMailingTemplate === null) {
			getAllMailingTemplates();
		}
	}, [allMailingTemplate]);

	const [loadingViewMailingTemplate, setLoadingViewMailingTemplate] = useState(false)
	const [errorViewMailingTemplate, setErrorViewMailingTemplate] = useState(null)
	const [viewUserMailingTemplate, setViewUserMailingTemplate] = useState(null)

	async function getAMailingTemplate(stripped) {
		setLoadingViewMailingTemplate(true)
		const response = await getMailingTemplate(cookie, { stripped });
		if (!response.err) {
			setViewUserMailingTemplate(response.data);
			setHtmlEdit(response.data.data.html);
			setNameEdit(response.data.data.stripped);
			setSubjectEdit(response.data.data.subject);
		} else { setErrorViewMailingTemplate(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewMailingTemplate(false)
	};

	if (removeAddMailingTemplateModal) {
		const modalResponse = document.querySelector("#addMailingTemplateModal");
		modalResponse.setAttribute("display", false);
		getAllMailingTemplates();
		setRemoveAddMailingTemplateModal(null);
	}
	if (removeEditMailingTemplateNameModal) {
		const modalResponse = document.querySelector("#editMailingTemplateModal");
		modalResponse.setAttribute("display", false);
		getAllMailingTemplates();
		setRemoveEditMailingTemplateNameModal(null);
	}
	if (removeEditMailingTemplateSubjectModal) {
		const modalResponse = document.querySelector("#editMailingTemplateModal");
		modalResponse.setAttribute("display", false);
		getAllMailingTemplates();
		setRemoveEditMailingTemplateSubjectModal(null);
	}
	if (removeEditMailingTemplateDetailsModal) {
		const modalResponse = document.querySelector("#editMailingTemplateModal");
		modalResponse.setAttribute("display", false);
		getAllMailingTemplates();
		setRemoveEditMailingTemplateDetailsModal(null);
	}
	if (removeDeleteMailingTemplateModal) {
		const modalResponse = document.querySelector("#deleteMailingTemplateModal");
		modalResponse.setAttribute("display", false);
		getAllMailingTemplates();
		setRemoveDeleteMailingTemplateModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Mailing Templates</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all mailing templates</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="addMailingTemplateModal">
										<span className="xui-mr-half">Add Template</span>
										<Plus width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllMailingTemplate ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allMailingTemplate && allMailingTemplate.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Stripped</th>
														<th className='xui-min-w-150'>Subject</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-150'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allMailingTemplate.data.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.stripped}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.subject}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Edit Mailing Template" onClick={() => { EditUniqueIdDetails(data.stripped); EditUniqueIdName(data.stripped); EditUniqueIdSubject(data.stripped); getAMailingTemplate(data.stripped) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="editMailingTemplateModal">
																		<Edit width="20" height="20" />
																	</button>
																	<button title="Delete Mailing Template" onClick={() => { DeleteUniqueId(data.stripped); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteMailingTemplateModal">
																		<Delete width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorMailingTemplate}</h3>
												</center>
											</div>
										</div>
								)
						}
						{/* {
							loadingAllMailingTemplate ?
								<Loading width="12" height="12" /> :
								(
									allMailingTemplate && allMailingTemplate.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'>{page}</span> of {allMailingTemplate ? allMailingTemplate.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousMailingTemplate}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextMailingTemplate}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						} */}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="deleteMailingTemplateModal" id="deleteMailingTemplateModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete Mailing Template</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteMailingTemplate}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteMailingTemplate}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteMailingTemplate} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteMailingTemplate ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteMailingTemplate ? "" : "deleteMailingTemplateModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="addMailingTemplateModal" id="addMailingTemplateModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1200 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="addMailingTemplateModal">
						<Close width="24" height="24" />
					</div>
					<h1>Create new Mailing Template</h1>
					<form className="xui-form" layout="2" onSubmit={handleAddMailingTemplate}>
						<div className="xui-form-box xui-mt-2">
							<label>Name</label>
							<input className="xui-font-sz-90" type="text" value={name} onChange={handleName} required placeholder="Enter name of template"></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Subject</label>
							<input className="xui-font-sz-90" type={"text"} value={subject} onChange={handleSubject} required placeholder="Enter subject for this mail template" />
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>HTML</label>
							<section className="xui-d-grid xui-lg-grid-col-2 xui-grid-gap-2 xui-mt-1">
								<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
									<p className="xui-font-sz-100 xui-my-1 xui-text-center">Code</p>
									<div data-color-mode="light">
										<CodeEditor
											value={html}
											language="html"
											placeholder="Please enter HTML code."
											onChange={(evn) => setHtml(evn.target.value)}
											padding={15}
											style={{
												font: 12,
												backgroundColor: "#f5f5f5",
												fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
											}}
										/>
									</div>
								</div>
								<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
									<p className="xui-font-sz-100 xui-my-1 xui-text-center">Preview</p>
									<div data-color-mode="light">
										<MarkdownPreview source={html} />
									</div>
								</div>
							</section>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save Template</span>
								{
									loadingAddMailingTemplate ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddMailingTemplate}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddMailingTemplate}</span></p>
				</div>
			</section>
			<section className='xui-modal' xui-modal="editMailingTemplateModal" id="editMailingTemplateModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1200 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="editMailingTemplateModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewMailingTemplate ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewUserMailingTemplate && viewUserMailingTemplate.success ? 
									<>
										<h1>Edit Mailing Template</h1>
										<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
											<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
												<label>Name</label>
												<div className="xui-d-flex xui-flex-ai-center">
													<input style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={"Enter name of template"} value={nameEdit} onChange={handleNameEdit} required />
													<div onClick={handleEditMailingTemplateName} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
														{
															loadingEditMailingTemplateName ?
																<Loading width="16" height="16" />
																: <Check width="16" height="16" />
														}
													</div>
												</div>
												<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditMailingTemplateName}</span></p>
												<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditMailingTemplateName}</span></p>
											</div>
										</form>
										<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
											<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
												<label>Subject</label>
												<div className="xui-d-flex xui-flex-ai-center">
													<input style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={"Enter subject for this mail template"} value={subjectEdit} onChange={handleSubjectEdit} required />
													<div onClick={handleEditMailingTemplateSubject} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
														{
															loadingEditMailingTemplateSubject ?
																<Loading width="16" height="16" />
																: <Check width="16" height="16" />
														}
													</div>
												</div>
												<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditMailingTemplateSubject}</span></p>
												<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditMailingTemplateSubject}</span></p>
											</div>
										</form>
										<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
											<div className="xui-form-box xui-mt-2">
												<label>HTML</label>
												<section className="xui-d-grid xui-lg-grid-col-2 xui-grid-gap-2 xui-mt-1">
													<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
														<p className="xui-font-sz-100 xui-my-1 xui-text-center">Code</p>
														<div data-color-mode="light">
															<CodeEditor
																value={htmlEdit}
																language="html"
																placeholder="Please enter HTML code."
																onChange={(evn) => setHtmlEdit(evn.target.value)}
																padding={15}
																style={{
																	font: 12,
																	backgroundColor: "#f5f5f5",
																	fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
																}}
															/>
														</div>
													</div>
													<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
														<p className="xui-font-sz-100 xui-my-1 xui-text-center">Preview</p>
														<div data-color-mode="light">
															<MarkdownPreview source={htmlEdit} />
														</div>
													</div>
												</section>
											</div>
											<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
												<button onClick={handleEditMailingTemplateDetails} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">Save Changes</span>
													{
														loadingEditMailingTemplateDetails ?
															<Loading width="12" height="12" />
															: <Arrowright width="12" height="12" />
													}
												</button>
											</div>
											<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditMailingTemplateDetails}</span></p>
											<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditMailingTemplateDetails}</span></p>
										</form>
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewMailingTemplate}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
		</>
	);

}
