import axios from 'axios';
import { config } from '../config';

const getAllGallery = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/gallery/all?page=${page}&size=${size}`,
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getGallery = async function (key, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/gallery?unique_id=${payload.unique_id}`,
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const addGallery = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/gallery/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updateGalleryName = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/gallery/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const updateGalleryImage = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/gallery/image`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const deleteGallery = async function (key, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/root/gallery`,
			{
				data: {
					key,
					...payload
				}
			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getAllGallery, getGallery, addGallery, updateGalleryName, updateGalleryImage, deleteGallery };