import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserPassport, verifyUserPassport } from "../api/passports";
import { config } from "../config";

function useVerifyUserPassport() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserPassport, setLoadingVerifyUserPassport] = useState(false)
	const [removeVerifyUserPassportModal, setRemoveVerifyUserPassportModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [passportUniqueId, setPassportUniqueId] = useState(null)

	const [errorVerifyUserPassport, setErrorVerifyUserPassport] = useState(null)
	const [successVerifyUserPassport, setSuccessVerifyUserPassport] = useState(null)

	const handleVerifyUserPassport = () => {
		if (!loadingVerifyUserPassport) {
			if (!userPID || !passportUniqueId) {
				setErrorVerifyUserPassport(null)
				setSuccessVerifyUserPassport(null)
				setErrorVerifyUserPassport(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserPassport(null)
				}, 2500)
			} else {
				setLoadingVerifyUserPassport(true)

				const verifyUserPassportRes = verifyUserPassport(cookie, { pid: userPID, unique_id: passportUniqueId })

				verifyUserPassportRes.then((res) => {

					setLoadingVerifyUserPassport(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserPassport(error)
							setTimeout(() => {
								setErrorVerifyUserPassport(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserPassport(error)
							setTimeout(() => {
								setErrorVerifyUserPassport(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserPassport(null)
						setSuccessVerifyUserPassport("User passport verified")

						setTimeout(() => {
							setSuccessVerifyUserPassport(null)
							setRemoveVerifyUserPassportModal(true)
							setUserPID(null)
							setPassportUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserPassport(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserPassport, successVerifyUserPassport, errorVerifyUserPassport, removeVerifyUserPassportModal, setRemoveVerifyUserPassportModal, handleVerifyUserPassport,
		userPID, setUserPID, passportUniqueId, setPassportUniqueId
	}
}

function useDeclineUserPassport() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclinePassport, setLoadingDeclinePassport] = useState(false)
	const [removeDeclinePassportModal, setRemoveDeclinePassportModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [passportUniqueId, setPassportUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclinePassport, setErrorDeclinePassport] = useState(null)
	const [successDeclinePassport, setSuccessDeclinePassport] = useState(null)

	const handleDeclinePassport = () => {
		if (!loadingDeclinePassport) {
			if (!userPID || !passportUniqueId) {
				setErrorDeclinePassport(null)
				setSuccessDeclinePassport(null)
				setErrorDeclinePassport(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclinePassport(null)
				}, 2500)
			} else {
				setLoadingDeclinePassport(true)

				const declineUserPassportRes = declineUserPassport(cookie, { pid: userPID, unique_id: passportUniqueId, message: declineMsg })

				declineUserPassportRes.then((res) => {
					setLoadingDeclinePassport(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclinePassport(error)
							setTimeout(() => {
								setErrorDeclinePassport(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclinePassport(error)
							setTimeout(() => {
								setErrorDeclinePassport(null)
							}, 2500)
						}
					} else {
						setErrorDeclinePassport(null)
						setDeclineMsg("")
						setSuccessDeclinePassport("User passport declined")

						setTimeout(() => {
							setSuccessDeclinePassport(null)
							setRemoveDeclinePassportModal(true)
							setUserPID(null)
							setPassportUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclinePassport, successDeclinePassport, loadingDeclinePassport,
		userPID, setUserPID, passportUniqueId, setPassportUniqueId, declineMsg, setDeclineMsg, handleDeclinePassport,
		removeDeclinePassportModal, setRemoveDeclinePassportModal
	}
}

export { useVerifyUserPassport, useDeclineUserPassport }