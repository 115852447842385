import axios from 'axios'
import { config } from '../config'

async function getTaxRecords(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/tax/records`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTaxRecordsViaUser(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/tax/records/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTaxRecord(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/tax/record`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserTaxRecord(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/tax/record/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserTaxRecord(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/tax/record/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTaxRecordsViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/tax/records/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getTaxRecords, getTaxRecordsViaUser, verifyUserTaxRecord, declineUserTaxRecord, getTaxRecord, getTaxRecordsViaVerifiedStatus,
}