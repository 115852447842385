import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserWorkHistory, verifyUserWorkHistory } from "../api/workHistory";
import { config } from "../config";

function useVerifyUserWorkHistory() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserWorkHistory, setLoadingVerifyUserWorkHistory] = useState(false)
	const [removeVerifyUserWorkHistoryModal, setRemoveVerifyUserWorkHistoryModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [workHistoryUniqueId, setWorkHistoryUniqueId] = useState(null)

	const [errorVerifyUserWorkHistory, setErrorVerifyUserWorkHistory] = useState(null)
	const [successVerifyUserWorkHistory, setSuccessVerifyUserWorkHistory] = useState(null)

	const handleVerifyUserWorkHistory = () => {
		if (!loadingVerifyUserWorkHistory) {
			if (!userPID || !workHistoryUniqueId) {
				setErrorVerifyUserWorkHistory(null)
				setSuccessVerifyUserWorkHistory(null)
				setErrorVerifyUserWorkHistory(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserWorkHistory(null)
				}, 2500)
			} else {
				setLoadingVerifyUserWorkHistory(true)

				const verifyUserWorkHistoryRes = verifyUserWorkHistory(cookie, { pid: userPID, unique_id: workHistoryUniqueId })

				verifyUserWorkHistoryRes.then((res) => {

					setLoadingVerifyUserWorkHistory(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserWorkHistory(error)
							setTimeout(() => {
								setErrorVerifyUserWorkHistory(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserWorkHistory(error)
							setTimeout(() => {
								setErrorVerifyUserWorkHistory(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserWorkHistory(null)
						setSuccessVerifyUserWorkHistory("User work history verified")

						setTimeout(() => {
							setSuccessVerifyUserWorkHistory(null)
							setRemoveVerifyUserWorkHistoryModal(true)
							setUserPID(null)
							setWorkHistoryUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserWorkHistory(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserWorkHistory, successVerifyUserWorkHistory, errorVerifyUserWorkHistory, removeVerifyUserWorkHistoryModal, setRemoveVerifyUserWorkHistoryModal, handleVerifyUserWorkHistory,
		userPID, setUserPID, workHistoryUniqueId, setWorkHistoryUniqueId
	}
}

function useDeclineUserWorkHistory() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineWorkHistory, setLoadingDeclineWorkHistory] = useState(false)
	const [removeDeclineWorkHistoryModal, setRemoveDeclineWorkHistoryModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [workHistoryUniqueId, setWorkHistoryUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclineWorkHistory, setErrorDeclineWorkHistory] = useState(null)
	const [successDeclineWorkHistory, setSuccessDeclineWorkHistory] = useState(null)

	const handleDeclineWorkHistory = () => {
		if (!loadingDeclineWorkHistory) {
			if (!userPID || !workHistoryUniqueId) {
				setErrorDeclineWorkHistory(null)
				setSuccessDeclineWorkHistory(null)
				setErrorDeclineWorkHistory(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclineWorkHistory(null)
				}, 2500)
			} else {
				setLoadingDeclineWorkHistory(true)

				const declineUserWorkHistoryRes = declineUserWorkHistory(cookie, { pid: userPID, unique_id: workHistoryUniqueId, message: declineMsg })

				declineUserWorkHistoryRes.then((res) => {
					setLoadingDeclineWorkHistory(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineWorkHistory(error)
							setTimeout(() => {
								setErrorDeclineWorkHistory(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineWorkHistory(error)
							setTimeout(() => {
								setErrorDeclineWorkHistory(null)
							}, 2500)
						}
					} else {
						setErrorDeclineWorkHistory(null)
						setDeclineMsg("")
						setSuccessDeclineWorkHistory("User work history declined")

						setTimeout(() => {
							setSuccessDeclineWorkHistory(null)
							setRemoveDeclineWorkHistoryModal(true)
							setUserPID(null)
							setWorkHistoryUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclineWorkHistory, successDeclineWorkHistory, loadingDeclineWorkHistory,
		userPID, setUserPID, workHistoryUniqueId, setWorkHistoryUniqueId, declineMsg, setDeclineMsg, handleDeclineWorkHistory,
		removeDeclineWorkHistoryModal, setRemoveDeclineWorkHistoryModal
	}
}

export { useVerifyUserWorkHistory, useDeclineUserWorkHistory }