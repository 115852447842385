import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Logo from '../assets/images/logo-white.png';
import Category from '../icons/Category';
import Swap from '../icons/Swap';
import Team from '../icons/Team';
import Token from '../icons/Token';
import Alarm from '../icons/Alarm';
import Tag from '../icons/Tag';
import Users from '../icons/Users';
import Wallet from '../icons/Wallet';
import Setting from '../icons/Setting';
import Check from '../icons/Check';
import Close from '../icons/Close';
import Logout from '../icons/Logout';
import { config } from "../config";
import useCookie from "../hooks/useCookie";
import Loading from "../icons/Loading";
import '../assets/css/style.css';
import Key from "../icons/Key";
import { useSendGeneralNotification } from "../hooks/useNotifications";
import Arrowright from "../icons/Arrowright";
import Mail from "../icons/Mail";
import Document from "../icons/Document";
import Image from "../icons/Image";
import Folder from "../icons/Folder";
import Server from "../icons/Server";

export default function Layout() {
    const loc = useLocation();

    const { removeCookie } = useCookie(config.token, "");

    const navigate = useNavigate();

    const [loadingLogout, setLoadingLogout] = useState(false);

    const handleLogout = () => {
        setLoadingLogout(true);
        removeCookie();
        setTimeout(function () {
            navigate(`/signin`);
            window.location.reload(true);
        }, 1500)
    };

    const {
        body, errorSendGeneralNotification, handleBody, handleSendGeneralNotification, handleTitle, loadingSendGeneralNotification, removeSendGeneralNotificationModal, 
        setBody, setRemoveSendGeneralNotificationModal, setTitle, successSendGeneralNotification, title
    } = useSendGeneralNotification();

    if (removeSendGeneralNotificationModal) {
        const modalResponse = document.querySelector("#sendGeneralNotificationModal");
        modalResponse.setAttribute("display", false);

        setRemoveSendGeneralNotificationModal(null);
    }

    return (
        <>
            <section className="xui-dashboard">
                <div className="navigator xui-text-white xui-px-2 disable-scrollbars">
                    <div className="brand xui-pt-2">
                        <div className="maxified xui-d-flex xui-flex-ai-center">
                            <Link className='xui-text-inherit xui-text-dc-none' to={`/internal/dashboard`}>
                                <div className='xui-d-inline-flex'>
                                    <img className='xui-img-30' src={Logo} alt='logo' />
                                    <div className='xui-pl-1'>
                                        <p className='xui-font-w-bold'>Admin</p>
                                        <span className='xui-font-sz-70 xui-opacity-7'>for passcoder</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="links xui-pt-2">
                        <div className='xui-d-flex psc-dashboard-profile'>
                            <div className='xui-pl-half'>
                                <h3 className='xui-font-sz-90 xui-font-w-normal'>Administration</h3>
                            </div>
                        </div>
                        <Link to={`/internal/dashboard`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/dashboard` ? 'active' : '')}>
                            <div className="icon">
                                <Category width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Dashboard</span>
                            </div>
                        </Link>
                        <Link to={`/internal/api/keys`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/api/keys` ? 'active' : '')}>
                            <div className="icon">
                                <Key width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>API Keys</span>
                            </div>
                        </Link>
                        <Link to={`/internal/api/criteria`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/api/criteria` ? 'active' : '')}>
                            <div className="icon">
                                <Tag width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>API Criteria</span>
                            </div>
                        </Link>
                        <Link to={`/internal/app/defaults`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/app/defaults` ? 'active' : '')}>
                            <div className="icon">
                                <Setting width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>App Defaults</span>
                            </div>
                        </Link>
                        <Link to={`/internal/transactions`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/transactions` ? 'active' : '')}>
                            <div className="icon">
                                <Wallet width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Transactions</span>
                            </div>
                        </Link>
                        <Link to={`/internal/purplepay-transactions`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/purplepay-transactions` ? 'active' : '')}>
                            <div className="icon">
                                <Wallet width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Purplepay</span>
                                <p>Transactions</p>
                            </div>
                        </Link>
                        <Link to={`/internal/purplepay-logs`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/purplepay-logs` ? 'active' : '')}>
                            <div className="icon">
                                <Server width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Purplepay Logs</span>
                            </div>
                        </Link>
                        <Link to={`/internal/transfer-logs`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/transfer-logs` ? 'active' : '')}>
                            <div className="icon">
                                <Server width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Transfer Logs</span>
                            </div>
                        </Link>
                        <Link to={`/internal/bill-logs`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/bill-logs` ? 'active' : '')}>
                            <div className="icon">
                                <Server width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Bill Logs</span>
                            </div>
                        </Link>
                        <Link to={`/internal/nin`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/nin` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>NIN</span>
                            </div>
                        </Link>
                        <Link to={`/internal/bvn`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/bvn` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>BVN</span>
                            </div>
                        </Link>
                        <Link to={`/internal/address`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/address` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Address</span>
                            </div>
                        </Link>
                        <Link to={`/internal/users`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/users` ? 'active' : '')}>
                            <div className="icon">
                                <Users width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Users</span>
                            </div>
                        </Link>
                        <Link to={`/internal/partners`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/partners` ? 'active' : '')}>
                            <div className="icon">
                                <Users width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Partners</span>
                            </div>
                        </Link>
                        <Link to={`/internal/businesses`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/businesses` ? 'active' : '')}>
                            <div className="icon">
                                <Users width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Businesses</span>
                            </div>
                        </Link>
                        <Link to={`/internal/additional-qualifications`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/additional-qualifications` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Additional</span>
                                <p>Qualifications</p>
                            </div>
                        </Link>
                        <Link to={`/internal/primary-school`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/primary-school` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Primary School</span>
                            </div>
                        </Link>
                        <Link to={`/internal/secondary-school`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/secondary-school` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Secondary School</span>
                            </div>
                        </Link>
                        <Link to={`/internal/tertiary-institution`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/tertiary-institution` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Tertiary Institution</span>
                            </div>
                        </Link>
                        <Link to={`/internal/work-history`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/work-history` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Work History</span>
                            </div>
                        </Link>
                        <Link to={`/internal/passport`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/passport` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Passport</span>
                            </div>
                        </Link>
                        <Link to={`/internal/driver-licence`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/driver-licence` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Drivers Licence</span>
                            </div>
                        </Link>
                        <Link to={`/internal/voter-card`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/voter-card` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Voter Card</span>
                            </div>
                        </Link>
                        <Link to={`/internal/tax-record`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/tax-record` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Tax Record</span>
                            </div>
                        </Link>
                        <Link to={`/internal/cac`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/cac` ? 'active' : '')}>
                            <div className="icon">
                                <Document width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>CAC</span>
                            </div>
                        </Link>
                        <Link to={`/internal/mails`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/mails` ? 'active' : '')}>
                            <div className="icon">
                                <Mail width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Mails</span>
                            </div>
                        </Link>
                        <Link to={`/internal/mailing/templates`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/mailing/templates` ? 'active' : '')}>
                            <div className="icon">
                                <Mail width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Mailing Templates</span>
                            </div>
                        </Link>
                        <Link to={`/internal/newsletter`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/newsletter` ? 'active' : '')}>
                            <div className="icon">
                                <Mail width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Newsletter</span>
                            </div>
                        </Link>
                        <Link to={`/internal/resources`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/resources` ? 'active' : '')}>
                            <div className="icon">
                                <Folder width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Resources</span>
                            </div>
                        </Link>
                        <Link to={`/internal/gallery`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6 " + (loc.pathname === `/internal/gallery` ? 'active' : '')}>
                            <div className="icon">
                                <Image width="20" height="20" />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Gallery</span>
                            </div>
                        </Link>
                        <Link to={`/internal/settings`} className={"xui-text-inherit link-box xui-font-sz-90 xui-opacity-6" + (loc.pathname === `/internal/settings` ? 'active' : '')}>
                            <div className="icon">
                                <Setting />
                            </div>
                            <div className="name xui-ml-half">
                                <span>Settings</span>
                            </div>
                        </Link>
                        <div xui-modal-open="sendGeneralNotificationModal" className="bottom-fixed xui-mt--5 xui-cursor-pointer">
                            <div xui-modal-open="sendGeneralNotificationModal" className="xui-text-inherit link-box xui-font-sz-90 xui-opacity-6">
                                <div xui-modal-open="sendGeneralNotificationModal" className="icon">
                                    <Alarm width="20" height="20" />
                                </div>
                                <div xui-modal-open="sendGeneralNotificationModal" className="name xui-ml-half">
                                    <span>Push Notification</span>
                                </div>
                            </div>
                        </div>
                        <div xui-modal-open="logoutModal" className="bottom-fixed xui-mt--5 xui-mb--5 xui-cursor-pointer">
                            <div xui-modal-open="logoutModal" className="xui-text-inherit link-box xui-font-sz-90 xui-opacity-6">
                                <div xui-modal-open="logoutModal" className="icon">
                                    <Logout width="20" height="20" />
                                </div>
                                <div xui-modal-open="logoutModal" className="name xui-ml-half">
                                    <span>Logout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='xui-modal' xui-modal="logoutModal" id="logoutModal">
                    <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                        <center>
                            <h1>Logout confirmation</h1>
                            <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to cotinue with this action?</p>
                        </center>
                        <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <button onClick={handleLogout} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                    <span className="xui-mr-half">Yes</span>
                                    {
                                        loadingLogout ?
                                            <Loading width="12" height="12" />
                                            : <Check width="20" height="20" />
                                    }
                                </button>
                            </div>
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close="logoutModal">
                                    <span className="xui-mr-half">No</span>
                                    <Close width="20" height="20" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='xui-modal' xui-modal="sendGeneralNotificationModal" id="sendGeneralNotificationModal">
                    <div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
                        <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="sendGeneralNotificationModal">
                            <Close width="24" height="24" />
                        </div>
                        <h1>Send push notification to all platforms</h1>
                        <form className="xui-form" onSubmit={handleSendGeneralNotification}>
                            <div className="xui-form-box">
                                <label>Title</label>
                                <input className="xui-font-sz-90" type="text" value={title} onChange={handleTitle} required placeholder="Enter title of notification"></input>
                                <p className="xui-font-sz-80 xui-my-1 xui-mt-1">{title ? title.length + "/200" : "0/200"}</p>
                            </div>
                            <div className="xui-form-box">
                                <label>Body</label>
                                <textarea type={"text"} required maxLength={1000} placeholder={"Enter body of notification"} value={body} onChange={handleBody}></textarea>
                                <p className="xui-font-sz-80 xui-my-1 xui-mt-1">{body ? body.length + "/1000" : "0/1000"}</p>
                            </div>
                            <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">Warning: Please check your contents correctly before clicking on "Send", once that's done it can't be modified.</span></p>
                            <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSendGeneralNotification}</span></p>
                            <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSendGeneralNotification}</span></p>
                            <div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
                                <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
                                    <span className="xui-mr-half">Send</span>
                                    {
                                        loadingSendGeneralNotification ?
                                            <Loading width="12" height="12" />
                                            : <Arrowright width="16" height="16" />
                                    }
                                </button>
                                {/* Bill payments available now 🚀 */}
                                {/* Subscribe your internet, buy airtime, top up your betting accounts, pay your utility bills and more, now with ease. */}
                            </div>
                        </form>
                    </div>
                </section>
                <Outlet />
            </section>
        </>
    );
}