import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Edit from "../icons/Edit";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Minus from "../icons/Minus";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getMails } from "../api/mailing";
import { useRetryEmail } from "../hooks/useMailing";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";
import Reset from "../icons/Reset";

export default function Mails() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorRetryEmail, handleRetryEmail, loadingRetryEmail, removeRetryEmailModal, setUniqueId: RetryEmailUniqueId,
		setRemoveRetryEmailModal, successRetryEmail
	} = useRetryEmail();

	const [allMails, setAllMails] = useState(null);
	const [errorMails, setErrorMails] = useState(null);
	const [loadingAllMails, setLoadingAllMails] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getAllMails(page, e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); getAllMails(parseInt(e.target.value), size); };

	async function previousMails() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getAllMails(page - 1, size);
	};

	async function nextMails() {
		if (page < allMails.data.pages) setPage(page + 1);
		if (page < allMails.data.pages) getAllMails(page + 1, size);
	};

	async function getAllMails(_page, _size) {
		setLoadingAllMails(true);
		const response = await getMails(cookie, (_page || page), (_size || size));
		setAllMails(response.data);
		if (response.error) setErrorMails(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllMails(false);
	};

	useEffect(() => {
		if (allMails === null) {
			getAllMails();
		}
	}, [allMails]);

	if (removeRetryEmailModal) {
		const modalResponse = document.querySelector("#retryEmailModal");
		modalResponse.setAttribute("display", false);
		getAllMails();
		setRemoveRetryEmailModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allMails ? allMails.data.pages : 0).fill(0);

	const getObjectValues = (obj) => {
		let values = [];
		Object.keys(obj).some((key) => {
			values.push(<span id={obj[key]}><b>{key}</b> : {obj[key].length > 300 ? obj[key].slice(0, 200) + " ..." : obj[key]}<br></br></span>)
		});
		return values;
	};

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Mails</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View all mails sent</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<Link to={`/internal/newsletter`} className="xui-text-dc-none xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80">
										<span className="xui-mr-half">Create newsletter</span>
										<Plus width="15" height="15" />
									</Link>
								</div>
							</div>
						</div>
						{
							loadingAllMails ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allMails && allMails.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-200'>Unique ID</th>
														<th className='xui-min-w-200'>Recipient</th>
														<th className='xui-min-w-200'>Template</th>
														<th className='xui-min-w-100'>Data</th>
														<th className='xui-min-w-300'>Message ID</th>
														<th className='xui-min-w-300'>Response</th>
														<th className='xui-min-w-100'>State</th>
														<th className='xui-min-w-100'>Attempts</th>
														<th className='xui-min-w-100'>Error</th>
														<th className='xui-min-w-300'>Start Time</th>
														<th className='xui-min-w-300'>End Time</th>
														<th className='xui-min-w-100'>Action</th>
													</tr>
												</thead>
												<tbody>
													{allMails.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>#{data.unique_id}</span>
																	<span title="Copy Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.to}</span>
																	<span title="Copy Recipient" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.to); setTextCopied(data.to); }}>
																		{copiedText && textCopied === data.to ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.template.name}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		getObjectValues(data.template.data).map(val => {
																			return val;
																		})
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.delivery.error ? 
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span>{data.delivery.info.messageId}</span>
																				<span title="Copy Recipient" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.delivery.info.messageId); setTextCopied(data.delivery.info.messageId); }}>
																					{copiedText && textCopied === data.delivery.info.messageId ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																				</span>
																			</div>
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.delivery.error ? 
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																			data.delivery.info.response
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.delivery.error ? 
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																			(
																				data.delivery.state === "SUCCESS" ? 
																					<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.delivery.state}</span> : 
																					(
																						data.delivery.state === "RETRY" ? 
																							<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>{data.delivery.state}</span> : 
																							(
																								data.delivery.state === "ERROR" ? 
																									<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.delivery.state}</span> : 
																									<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>{data.delivery.state}</span> 
																							)
																					)
																			)
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.delivery.attempts}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.delivery.error ?
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.delivery.error}</span> :
																			<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>None</span> 
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.delivery.startTime.date} at {data.delivery.startTime.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.delivery.endTime.date} at {data.delivery.endTime.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Retry Email" onClick={() => { RetryEmailUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="retryEmailModal">
																		<Reset width="20" height="20" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorMails}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllMails ?
								<Loading width="12" height="12" /> :
								(
									allMails && allMails.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allMails ? allMails.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousMails}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextMails}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="retryEmailModal" id="retryEmailModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Retry Email</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRetryEmail}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRetryEmail}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleRetryEmail} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingRetryEmail ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingRetryEmail ? "" : "retryEmailModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);

}
