import axios from 'axios'
import { config } from '../config'

async function getBvns(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/bvn/all`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getBvnViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/bvn/all/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

async function getBvnViaDeclinedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/bvn/all/via/declination`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserBVN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/bvn/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserBVNLogs = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bvn/verification/logs`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserBVNLogsViaEmail = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bvn/verification/logs/via/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserBVNLogsViaPhoneOne = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bvn/verification/logs/via/phone/one`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserBVNLogsViaPhoneTwo = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bvn/verification/logs/via/phone/two`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const verifyUserBVN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/bvn/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const createVirtualAccount = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/create/virtual/account`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const createVerificationLog = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/bvn/create/verification/log`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const declineUserBVN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/bvn/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export {
	getBvns, getBvnViaVerifiedStatus, getUserBVN, verifyUserBVN, declineUserBVN, createVirtualAccount, createVerificationLog, 
	getBvnViaDeclinedStatus, getUserBVNLogs, getUserBVNLogsViaEmail, getUserBVNLogsViaPhoneOne, getUserBVNLogsViaPhoneTwo
}