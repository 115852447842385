import axios from 'axios'
import { config } from '../config'

async function getVoterCards(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/voter/cards`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getVoterCard(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/voter/card/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserVoterCard(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/voter/card/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserVoterCard(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/voter/card/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getVoterCardViaPID(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/voter/card/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getVoterCardsViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/voter/cards/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getVoterCards, getVoterCard, verifyUserVoterCard, declineUserVoterCard, getVoterCardViaPID, getVoterCardsViaVerifiedStatus,
}