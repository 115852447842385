import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Filter from "../icons/Filter";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import {
	getPartnerTransactions, getPartnerTransaction, getPartnerTransactionsViaStatus, getPartnerTransactionsViaType, 
	getBusinessTransactions, getBusinessTransaction, getBusinessTransactionsViaStatus, getBusinessTransactionsViaType, 
	getUserTransactions, getUserTransaction, getUserTransactionsViaStatus, getUserTransactionsViaType, getPurplepayTransactions, 
	getTransactions, getTransactionsViaStatus, getTransactionsViaType, getReferenceTransactions, getPurplepayReferenceTransaction, 
	getDateTransactions
} from "../api/transactions";
import Loading from "../icons/Loading";
import { 
	useCompleteBusinessDeposit, useCompletePartnerDeposit, useCompleteBusinessWithdrawal, useCompletePartnerWithdrawal, 
	useCompleteUserDeposit, useCompleteUserWithdrawal, useReverseUserTransfer, useDeleteTransaction, useReverseUserBill
} from "../hooks/useTransactions";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import Delete from "../icons/Delete";

export default function Transactions() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorCompletePartnerDeposit, handleCompletePartnerDeposit, loadingCompletePartnerDeposit, partnerDepositUniqueId, 
		removeCompletePartnerDepositModal, setPartnerDepositUniqueId, setPartnerUniqueId: PartnerDepositUniqueID, setRemoveCompletePartnerDepositModal, 
		successCompletePartnerDeposit
	} = useCompletePartnerDeposit();

	const {
		errorCompletePartnerWithdrawal, handleCompletePartnerWithdrawal, loadingCompletePartnerWithdrawal, partnerWithdrawalUniqueId,
		removeCompletePartnerWithdrawalModal, setPartnerWithdrawalUniqueId, setPartnerUniqueId: PartnerWithdrawalUniqueID, setRemoveCompletePartnerWithdrawalModal,
		successCompletePartnerWithdrawal
	} = useCompletePartnerWithdrawal();

	const {
		errorCompleteBusinessDeposit, handleCompleteBusinessDeposit, loadingCompleteBusinessDeposit, businessDepositUniqueId,
		removeCompleteBusinessDepositModal, setBusinessDepositUniqueId, setBusinessUniqueId: BusinessDepositUniqueID, setRemoveCompleteBusinessDepositModal,
		successCompleteBusinessDeposit
	} = useCompleteBusinessDeposit();

	const {
		errorCompleteBusinessWithdrawal, handleCompleteBusinessWithdrawal, loadingCompleteBusinessWithdrawal, businessWithdrawalUniqueId,
		removeCompleteBusinessWithdrawalModal, setBusinessWithdrawalUniqueId, setBusinessUniqueId: BusinessWithdrawalUniqueID, setRemoveCompleteBusinessWithdrawalModal,
		successCompleteBusinessWithdrawal
	} = useCompleteBusinessWithdrawal();

	const {
		errorCompleteUserDeposit, handleCompleteUserDeposit, loadingCompleteUserDeposit, userDepositUniqueId,
		removeCompleteUserDepositModal, setUserDepositUniqueId, setUserPID: UserDepositPID, setRemoveCompleteUserDepositModal,
		successCompleteUserDeposit
	} = useCompleteUserDeposit();

	const {
		errorCompleteUserWithdrawal, handleCompleteUserWithdrawal, loadingCompleteUserWithdrawal, userWithdrawalUniqueId,
		removeCompleteUserWithdrawalModal, setUserWithdrawalUniqueId, setUserPID: UserWithdrawalPID, setRemoveCompleteUserWithdrawalModal,
		successCompleteUserWithdrawal
	} = useCompleteUserWithdrawal();

	const {
		errorReverseUserTransfer, handleReverseUserTransfer, loadingReverseUserTransfer, userTransferUniqueId,
		removeReverseUserTransferModal, setUserTransferUniqueId, setUserPID: UserTransferPID, setRemoveReverseUserTransferModal,
		successReverseUserTransfer
	} = useReverseUserTransfer();

	const {
		errorReverseUserBill, handleReverseUserBill, loadingReverseUserBill, userBillUniqueId,
		removeReverseUserBillModal, setUserBillUniqueId, setUserPID: UserBillPID, setRemoveReverseUserBillModal,
		successReverseUserBill
	} = useReverseUserBill();
	
	const {
		errorDeleteTransaction, handleDeleteTransaction, loadingDeleteTransaction, deleteTransactionUniqueId,
		removeDeleteTransactionModal, setDeleteTransactionUniqueId,  setRemoveDeleteTransactionModal,
		successDeleteTransaction
	} = useDeleteTransaction();

	const [currentFunction, setCurrentFunction] = useState("getAllTransactions");

	const [filterByPartnerFilterType, setFilterByPartnerFilterType] = useState(null);
	const [filterByPartnerTransactionStatus, setFilterByPartnerTransactionStatus] = useState(null);
	const [filterByPartnerUniqueID, setFilterByPartnerUniqueID] = useState("");
	const [removePartnerFilterModal, setRemovePartnerFilterModal] = useState(null);

	const [filterByBusinessFilterType, setFilterByBusinessFilterType] = useState(null);
	const [filterByBusinessTransactionStatus, setFilterByBusinessTransactionStatus] = useState(null);
	const [filterByBusinessUniqueID, setFilterByBusinessUniqueID] = useState("");
	const [removeBusinessFilterModal, setRemoveBusinessFilterModal] = useState(null);

	const [filterByUserFilterType, setFilterByUserFilterType] = useState(null);
	const [filterByUserTransactionStatus, setFilterByUserTransactionStatus] = useState(null);
	const [filterByUserPID, setFilterByUserPID] = useState("");
	const [removeUserFilterModal, setRemoveUserFilterModal] = useState(null);

	const [filterByReference, setFilterByReference] = useState("");
	const [removeReferenceFilterModal, setRemoveReferenceFilterModal] = useState(null);

	const [filterType, setFilterType] = useState(null);
	const [transactionStatus, setTransactionStatus] = useState(null);

	const [filterStartDate, setFilterStartDate] = useState(null);
	const [filterEndDate, setFilterEndDate] = useState(null);

	const [removeDateFilterModal, setRemoveDateFilterModal] = useState(null);

	const handleFilterStartDate = (e) => { e.preventDefault(); setFilterStartDate(e.target.value); };
	const handleFilterEndDate = (e) => { e.preventDefault(); setFilterEndDate(e.target.value); };

	const [transactionFullDetails, setTransactionFullDetails] = useState(null);

	const [purplepayTransactionDetails, setPurplepayTransactionDetails] = useState(null);
	const [errorPurplepayTransactionDetails, setErrorPurplepayTransactionDetails] = useState(null);
	const [loadingPurplepayTransactionDetails, setLoadingPurplepayTransactionDetails] = useState(false);

	const [userBankDetails, setUserBankDetails] = useState(null);
	const [businessBankDetails, setBusinessBankDetails] = useState(null);
	const [partnerBankDetails, setPartnerBankDetails] = useState(null);

	const [allTransactions, setAllTransactions] = useState(null);
	const [errorTransactions, setErrorTransactions] = useState(null);
	const [loadingAllTransactions, setLoadingAllTransactions] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); transactionsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); transactionsByPage(parseInt(e.target.value), size); };
	const handleFilterType = (e) => { e.preventDefault(); setFilterType(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllTypeTransactions"); getAllTypeTransactions(e.target.value, page, size);  };
	const handleTransactionStatus = (e) => { e.preventDefault(); setTransactionStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllStatusTransactions"); getAllStatusTransactions(e.target.value, page, size);  };

	const handleFilterByPartnerUniqueID = (e) => { e.preventDefault(); setFilterByPartnerUniqueID(e.target.value); };
	const handleFilterByPartnerFilterType = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByPartnerFilterType(e.target.value); else setFilterByPartnerFilterType(null); setFilterByPartnerTransactionStatus(null); };
	const handleFilterByPartnerTransactionStatus = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByPartnerTransactionStatus(e.target.value); else setFilterByPartnerTransactionStatus(null); setFilterByPartnerFilterType(null); };

	const handleFilterByBusinessUniqueID = (e) => { e.preventDefault(); setFilterByBusinessUniqueID(e.target.value); };
	const handleFilterByBusinessFilterType = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByBusinessFilterType(e.target.value); else setFilterByBusinessFilterType(null); setFilterByBusinessTransactionStatus(null); };
	const handleFilterByBusinessTransactionStatus = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByBusinessTransactionStatus(e.target.value); else setFilterByBusinessTransactionStatus(null); setFilterByBusinessFilterType(null); };

	const handleFilterByUserPID = (e) => { e.preventDefault(); setFilterByUserPID(e.target.value.toLocaleUpperCase()); };
	const handleFilterByUserFilterType = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByUserFilterType(e.target.value); else setFilterByUserFilterType(null); setFilterByUserTransactionStatus(null); };
	const handleFilterByUserTransactionStatus = (e) => { e.preventDefault(); if (e.target.value.length > 0) setFilterByUserTransactionStatus(e.target.value); else setFilterByUserTransactionStatus(null); setFilterByUserFilterType(null); };

	const handleFilterByReference = (e) => { e.preventDefault(); setFilterByReference(e.target.value); };

	const resetPartnerFilterParameters = () => {
		setFilterByPartnerUniqueID("");
		setFilterByPartnerFilterType(null);
		setFilterByPartnerTransactionStatus(null);
		setCurrentFunction("getAllTransactions");
	};

	const resetBusinessFilterParameters = () => {
		setFilterByBusinessUniqueID("");
		setFilterByBusinessFilterType(null);
		setFilterByBusinessTransactionStatus(null);
		setCurrentFunction("getAllTransactions");
	};

	const resetUserFilterParameters = () => {
		setFilterByUserPID("");
		setFilterByUserFilterType(null);
		setFilterByUserTransactionStatus(null);
		setCurrentFunction("getAllTransactions");
	};

	const resetReferenceFilterParameters = () => {
		setFilterByReference("");
		setCurrentFunction("getAllTransactions");
	};

	const resetDateFilterParameters = () => {
		setFilterStartDate(null);
		setFilterEndDate(null);
		setCurrentFunction("getAllTransactions");
	};

	const continuePartnerFilterByPartner = (e) => {
		e.preventDefault();

		if (filterByPartnerFilterType) {
			setPage(1); 
			setCurrentFunction("getAllPartnerTypeTransactions");
			getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page, size);
			setRemovePartnerFilterModal(true);
		} else if (filterByPartnerTransactionStatus) {
			setPage(1); 
			setCurrentFunction("getAllPartnerStatusTransactions");
			getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page, size);
			setRemovePartnerFilterModal(true);
		} else {
			setPage(1); 
			setCurrentFunction("getAllPartnerTransactions");
			getAllPartnerTransactions(filterByPartnerUniqueID, page, size);
			setRemovePartnerFilterModal(true);
		}
	};

	const continueBusinessFilterByBusiness = (e) => {
		e.preventDefault();

		if (filterByBusinessFilterType) {
			setPage(1);
			setCurrentFunction("getAllBusinessTypeTransactions");
			getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page, size);
			setRemoveBusinessFilterModal(true);
		} else if (filterByBusinessTransactionStatus) {
			setPage(1);
			setCurrentFunction("getAllBusinessStatusTransactions");
			getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page, size);
			setRemoveBusinessFilterModal(true);
		} else {
			setPage(1);
			setCurrentFunction("getAllBusinessTransactions");
			getAllBusinessTransactions(filterByBusinessUniqueID, page, size);
			setRemoveBusinessFilterModal(true);
		}
	};

	const continueUserFilterByUser = (e) => {
		e.preventDefault();

		if (filterByUserFilterType) {
			setPage(1);
			setCurrentFunction("getAllUserTypeTransactions");
			getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page, size);
			setRemoveUserFilterModal(true);
		} else if (filterByUserTransactionStatus) {
			setPage(1);
			setCurrentFunction("getAllUserStatusTransactions");
			getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page, size);
			setRemoveUserFilterModal(true);
		} else {
			setPage(1);
			setCurrentFunction("getAllUserTransactions");
			getAllUserTransactions(filterByUserPID, page, size);
			setRemoveUserFilterModal(true);
		}
	};
	
	const continueFilterByReference = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllReferenceTransactions");
		getAllReferenceTransactions(filterByReference, page, size);
		setRemoveReferenceFilterModal(true);
	};

	const continueFilterByDate = (e) => {
		e.preventDefault();

		if (!filterStartDate || !filterEndDate) {
			alert("Start and End dates are required");
		} else {
			setPage(1);
			setCurrentFunction("getAllDateTransactions");
			getAllDateTransactions(filterStartDate, filterEndDate, page, size);
			setRemoveDateFilterModal(true);
			// setFilterEndDate(null); setFilterStartDate(null);
		}
	};

	async function callLastTransactionFunction () {
		switch (currentFunction) {
			case "getAllTransactions":
				getAllTransactions(page, size);
				break;
			case "getAllTypeTransactions":
				getAllTypeTransactions(filterType, page, size);
				break;
			case "getAllStatusTransactions":
				getAllStatusTransactions(transactionStatus, page, size);
				break;
			case "getAllPartnerTypeTransactions":
				getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page, size);
				break;
			case "getAllPartnerStatusTransactions":
				getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page, size);
				break;
			case "getAllPartnerTransactions":
				getAllPartnerTransactions(filterByPartnerUniqueID, page, size);
				break;
			case "getAllBusinessTypeTransactions":
				getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page, size);
				break;
			case "getAllBusinessStatusTransactions":
				getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page, size);
				break;
			case "getAllBusinessTransactions":
				getAllBusinessTransactions(filterByBusinessUniqueID, page, size);
				break;
			case "getAllUserTypeTransactions":
				getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page, size);
				break;
			case "getAllUserStatusTransactions":
				getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page, size);
				break;
			case "getAllUserTransactions":
				getAllUserTransactions(filterByUserPID, page, size);
				break;
			case "getAllReferenceTransactions":
				getAllReferenceTransactions(filterByReference, page, size);
				break;
			case "getAllDateTransactions":
				getAllDateTransactions(filterStartDate, filterEndDate, page, size);
				break;
			// default:
			// 	getAllTransactions(page, size);
		}
	};

	async function transactionsBySize(size) {
		switch (currentFunction) {
			case "getAllTransactions":
				getAllTransactions(page, size);
				break;
			case "getAllTypeTransactions":
				getAllTypeTransactions(filterType, page, size);
				break;
			case "getAllStatusTransactions":
				getAllStatusTransactions(transactionStatus, page, size);
				break;
			case "getAllPartnerTypeTransactions":
				getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page, size);
				break;
			case "getAllPartnerStatusTransactions":
				getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page, size);
				break;
			case "getAllPartnerTransactions":
				getAllPartnerTransactions(filterByPartnerUniqueID, page, size);
				break;
			case "getAllBusinessTypeTransactions":
				getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page, size);
				break;
			case "getAllBusinessStatusTransactions":
				getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page, size);
				break;
			case "getAllBusinessTransactions":
				getAllBusinessTransactions(filterByBusinessUniqueID, page, size);
				break;
			case "getAllUserTypeTransactions":
				getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page, size);
				break;
			case "getAllUserStatusTransactions":
				getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page, size);
				break;
			case "getAllUserTransactions":
				getAllUserTransactions(filterByUserPID, page, size);
				break;
			case "getAllReferenceTransactions":
				getAllReferenceTransactions(filterByReference, page, size);
				break;
			case "getAllDateTransactions":
				getAllDateTransactions(filterStartDate, filterEndDate, page, size);
				break;
			default:
				getAllTransactions(page, size);
		}
	};

	async function transactionsByPage(page) {
		switch (currentFunction) {
			case "getAllTransactions":
				getAllTransactions(page, size);
				break;
			case "getAllTypeTransactions":
				getAllTypeTransactions(filterType, page, size);
				break;
			case "getAllStatusTransactions":
				getAllStatusTransactions(transactionStatus, page, size);
				break;
			case "getAllPartnerTypeTransactions":
				getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page, size);
				break;
			case "getAllPartnerStatusTransactions":
				getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page, size);
				break;
			case "getAllPartnerTransactions":
				getAllPartnerTransactions(filterByPartnerUniqueID, page, size);
				break;
			case "getAllBusinessTypeTransactions":
				getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page, size);
				break;
			case "getAllBusinessStatusTransactions":
				getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page, size);
				break;
			case "getAllBusinessTransactions":
				getAllBusinessTransactions(filterByBusinessUniqueID, page, size);
				break;
			case "getAllUserTypeTransactions":
				getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page, size);
				break;
			case "getAllUserStatusTransactions":
				getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page, size);
				break;
			case "getAllUserTransactions":
				getAllUserTransactions(filterByUserPID, page, size);
				break;
			case "getAllReferenceTransactions":
				getAllReferenceTransactions(filterByReference, page, size);
				break;
			case "getAllDateTransactions":
				getAllDateTransactions(filterStartDate, filterEndDate, page, size);
				break;
			default:
				getAllTransactions(page, size);
		}
	};

	async function previousTransactions() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) { 
			switch (currentFunction) {
				case "getAllTransactions":
					getAllTransactions(page - 1, size);
					break;
				case "getAllTypeTransactions":
					getAllTypeTransactions(filterType, page - 1, size);
					break;
				case "getAllStatusTransactions":
					getAllStatusTransactions(transactionStatus, page - 1, size);
					break;
				case "getAllPartnerTypeTransactions":
					getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page - 1, size);
					break;
				case "getAllPartnerStatusTransactions":
					getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page - 1, size);
					break;
				case "getAllPartnerTransactions":
					getAllPartnerTransactions(filterByPartnerUniqueID, page - 1, size);
					break;
				case "getAllBusinessTypeTransactions":
					getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page - 1, size);
					break;
				case "getAllBusinessStatusTransactions":
					getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page - 1, size);
					break;
				case "getAllBusinessTransactions":
					getAllBusinessTransactions(filterByBusinessUniqueID, page - 1, size);
					break;
				case "getAllUserTypeTransactions":
					getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page - 1, size);
					break;
				case "getAllUserStatusTransactions":
					getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page - 1, size);
					break;
				case "getAllUserTransactions":
					getAllUserTransactions(filterByUserPID, page - 1, size);
					break;
				case "getAllReferenceTransactions":
					getAllReferenceTransactions(filterByReference, page - 1, size);
					break;
				case "getAllDateTransactions":
					getAllDateTransactions(filterStartDate, filterEndDate, page - 1, size);
					break;
				default: 
					getAllTransactions(page - 1, size);
			}
		};
	};

	async function nextTransactions() {
		if (page < allTransactions.data.pages) setPage(page + 1);
		if (page < allTransactions.data.pages) { 
			switch (currentFunction) {
				case "getAllTransactions":
					getAllTransactions(page + 1, size);
					break;
				case "getAllTypeTransactions":
					getAllTypeTransactions(filterType, page + 1, size);
					break;
				case "getAllStatusTransactions":
					getAllStatusTransactions(transactionStatus, page + 1, size);
					break;
				case "getAllPartnerTypeTransactions":
					getAllPartnerTypeTransactions(filterByPartnerUniqueID, filterByPartnerFilterType, page + 1, size);
					break;
				case "getAllPartnerStatusTransactions":
					getAllPartnerStatusTransactions(filterByPartnerUniqueID, filterByPartnerTransactionStatus, page + 1, size);
					break;
				case "getAllPartnerTransactions":
					getAllPartnerTransactions(filterByPartnerUniqueID, page + 1, size);
					break;
				case "getAllBusinessTypeTransactions":
					getAllBusinessTypeTransactions(filterByBusinessUniqueID, filterByBusinessFilterType, page + 1, size);
					break;
				case "getAllBusinessStatusTransactions":
					getAllBusinessStatusTransactions(filterByBusinessUniqueID, filterByBusinessTransactionStatus, page + 1, size);
					break;
				case "getAllBusinessTransactions":
					getAllBusinessTransactions(filterByBusinessUniqueID, page + 1, size);
					break;
				case "getAllUserTypeTransactions":
					getAllUserTypeTransactions(filterByUserPID, filterByUserFilterType, page + 1, size);
					break;
				case "getAllUserStatusTransactions":
					getAllUserStatusTransactions(filterByUserPID, filterByUserTransactionStatus, page + 1, size);
					break;
				case "getAllUserTransactions":
					getAllUserTransactions(filterByUserPID, page + 1, size);
					break;
				case "getAllReferenceTransactions":
					getAllReferenceTransactions(filterByReference, page + 1, size);
					break;
				case "getAllDateTransactions":
					getAllDateTransactions(filterStartDate, filterEndDate, page + 1, size);
					break;
				default:
					getAllTransactions(page + 1, size);
			}
		};
	};

	async function getAllTransactions(_page, _size) {
		setLoadingAllTransactions(true);
		const response = await getTransactions(cookie, (_page || page), (_size || size));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllTypeTransactions(type, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getTransactionsViaType(cookie, (_page || page), (_size || size), ({ type: type }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllStatusTransactions(transaction_status, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getTransactionsViaStatus(cookie, (_page || page), (_size || size), ({ transaction_status: transaction_status }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getAllPartnerTransactions(partner_unique_id, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getPartnerTransactions(cookie, (_page || page), (_size || size), ({ partner_unique_id: partner_unique_id }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllPartnerTypeTransactions(partner_unique_id, type, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getPartnerTransactionsViaType(cookie, (_page || page), (_size || size), ({ partner_unique_id: partner_unique_id, type: type }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllPartnerStatusTransactions(partner_unique_id, transaction_status, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getPartnerTransactionsViaStatus(cookie, (_page || page), (_size || size), ({ partner_unique_id: partner_unique_id, transaction_status: transaction_status }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getAllBusinessTransactions(business_unique_id, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getBusinessTransactions(cookie, (_page || page), (_size || size), ({ business_unique_id: business_unique_id }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllBusinessTypeTransactions(business_unique_id, type, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getBusinessTransactionsViaType(cookie, (_page || page), (_size || size), ({ business_unique_id: business_unique_id, type: type }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllBusinessStatusTransactions(business_unique_id, transaction_status, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getBusinessTransactionsViaStatus(cookie, (_page || page), (_size || size), ({ business_unique_id: business_unique_id, transaction_status: transaction_status }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getAllUserTransactions(pid, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getUserTransactions(cookie, (_page || page), (_size || size), ({ pid: pid }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllUserTypeTransactions(pid, type, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getUserTransactionsViaType(cookie, (_page || page), (_size || size), ({ pid: pid, type: type }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};
	async function getAllUserStatusTransactions(pid, transaction_status, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getUserTransactionsViaStatus(cookie, (_page || page), (_size || size), ({ pid: pid, transaction_status: transaction_status }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getAllReferenceTransactions(reference, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getReferenceTransactions(cookie, (_page || page), (_size || size), ({ reference: reference }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getAllDateTransactions(start_date, end_date, _page, _size) {
		setLoadingAllTransactions(true);
		const response = await getDateTransactions(cookie, (_page || page), (_size || size), ({ start_date: start_date, end_date: end_date }));
		setAllTransactions(response.data);
		if (response.error) setErrorTransactions(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTransactions(false);
	};

	async function getReferenceTransactionFromPurplepay(reference) {
		setLoadingPurplepayTransactionDetails(true);
		const response = await getPurplepayReferenceTransaction(cookie, ({ reference: reference }));
		setPurplepayTransactionDetails(response.data);
		if (response.error) setErrorPurplepayTransactionDetails(response.error.response.data.message);
		setLoadingPurplepayTransactionDetails(false);
	};

	useEffect(() => {
		if (allTransactions === null) {
			callLastTransactionFunction();
		}
	}, [allTransactions]);

	if (removeCompletePartnerDepositModal) {
		const modalResponse = document.querySelector("#partnerDepositConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompletePartnerDepositModal(null);
	}
	if (removeCompletePartnerWithdrawalModal) {
		const modalResponse = document.querySelector("#partnerWithdrawalConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompletePartnerWithdrawalModal(null);
	}
	if (removeCompleteBusinessDepositModal) {
		const modalResponse = document.querySelector("#businessDepositConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompleteBusinessDepositModal(null);
	}
	if (removeCompleteBusinessWithdrawalModal) {
		const modalResponse = document.querySelector("#businessWithdrawalConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompleteBusinessWithdrawalModal(null);
	}
	if (removeCompleteUserDepositModal) {
		const modalResponse = document.querySelector("#userDepositConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompleteUserDepositModal(null);
	}
	if (removeCompleteUserWithdrawalModal) {
		const modalResponse = document.querySelector("#userWithdrawalConfirmationDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveCompleteUserWithdrawalModal(null);
	}
	if (removeReverseUserTransferModal) {
		const modalResponse = document.querySelector("#userTransferReverseDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveReverseUserTransferModal(null);
	}
	if (removeReverseUserBillModal) {
		const modalResponse = document.querySelector("#userBillReverseDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveReverseUserBillModal(null);
	}
	if (removeDeleteTransactionModal) {
		const modalResponse = document.querySelector("#deleteTransactionDialog");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveDeleteTransactionModal(null);
	}

	if (removePartnerFilterModal) {
		const modalResponse = document.querySelector("#filterByPartner");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemovePartnerFilterModal(null);
	}

	if (removeBusinessFilterModal) {
		const modalResponse = document.querySelector("#filterByBusiness");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveBusinessFilterModal(null);
	}

	if (removeUserFilterModal) {
		const modalResponse = document.querySelector("#filterByUser");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveUserFilterModal(null);
	}

	if (removeReferenceFilterModal) {
		const modalResponse = document.querySelector("#filterByReference");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveReferenceFilterModal(null);
	}

	if (removeDateFilterModal) {
		const modalResponse = document.querySelector("#filterByDate");
		modalResponse.setAttribute("display", false);
		callLastTransactionFunction();
		setRemoveDateFilterModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allTransactions ? allTransactions.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className='xui-mt-2'>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Transactions</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allTransactions && allTransactions.success ? allTransactions.data.total : "..."}</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={filterType} onChange={handleFilterType} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!filterType ?
													<option selected disabled>Filter By Type</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"Deposit"}>Deposit</option>
											<option value={"Airtime"}>Airtime</option>
											<option value={"Betting"}>Betting</option>
											<option value={"Cable"}>Cable</option>
											<option value={"Data"}>Data</option>
											<option value={"Internet"}>Internet</option>
											<option value={"Utility"}>Utility</option>
											<option value={"Reversal"}>Reversal</option>
											<option value={"Ticket"}>Ticket</option>
											<option value={"Credit"}>Credit</option>
											<option value={"Debit"}>Debit</option>
											<option value={"Transfer"}>Transfer</option>
											<option value={"Charges"}>Charges</option>
											<option value={"CBN STAMP DUTY"}>CBN STAMP DUTY</option>
											<option value={"Stamp"}>Stamp</option>
											<option value={"VAT"}>VAT</option>
											<option value={"Withdrawal"}>Withdrawal</option>
											<option value={"API Call"}>API Call</option>
											<option value={"Payment"}>Payment</option>
											<option value={"Subscription"}>Subscription</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={transactionStatus} onChange={handleTransactionStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!transactionStatus ?
													<option selected disabled>Filter By Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"Processing"}>Processing</option>
											<option value={"Reversed"}>Reversed</option>
											<option value={"Cancelled"}>Cancelled</option>
											<option value={"Completed"}>Completed</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByPartner">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Partner</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByBusiness">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Business</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByUser">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By User</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByReference">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Reference</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByDate">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Date</span>
									</div>
								</div>
							</div>
						</div>
						{
							loadingAllTransactions ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allTransactions && allTransactions.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-min-w-20'>S/N</th>
														<th className='xui-min-w-150'>Reference</th>
														<th className='xui-min-w-400'>Card / Transfer Reference</th>
														<th className='xui-min-w-400'>Business / Partner / User</th>
														<th className='xui-min-w-200'>Type</th>
														<th className='xui-min-w-200'>Payment Method</th>
														<th className='xui-min-w-150'>Package</th>
														<th className='xui-min-w-150'>Months</th>
														<th className='xui-min-w-150'>Amount</th>
														<th className='xui-min-w-150'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-250'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allTransactions.data.rows.sort((a, b) => new Date(a.createdAt.date + " " + a.createdAt.time).getTime() < new Date(b.createdAt.date + " " + b.createdAt.time).getTime() ? 1 : -1).map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	{i + 1}
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>#{data.unique_id}</span>
																	<span title="Copy ID Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.reference}</span>
																	<span title="Copy Paystack Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.reference); setTextCopied(data.reference); }}>
																		{copiedText && textCopied === data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.partner_data || allTransactions.data.partner ? 
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span>{(data.partner_data ? data.partner_data.name : allTransactions.data.partner.name) + " (Partner)"}</span>
																				<span title="Copy Partner Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.partner_data ? data.partner_data.unique_id : allTransactions.data.partner.unique_id); setTextCopied(data.partner_data ? data.partner_data.unique_id : allTransactions.data.partner.unique_id); }}>
																					{copiedText && textCopied === (data.partner_data ? data.partner_data.unique_id : allTransactions.data.partner.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																				</span>
																			</div> : 
																			(
																				data.business_data || allTransactions.data.business ? 
																					<div className='xui-d-inline-flex xui-flex-ai-center'>
																						<span>{(data.business_data ? data.business_data.name + ", " + data.business_data.city : allTransactions.data.business.name + ", " + allTransactions.data.business.city) + " (Business)"}</span>
																						<span title="Copy Business Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.business_data ? data.business_data.unique_id : allTransactions.data.business.unique_id); setTextCopied(data.business_data ? data.business_data.unique_id : allTransactions.data.business.unique_id); }}>
																							{copiedText && textCopied === (data.business_data ? data.business_data.unique_id : allTransactions.data.business.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																						</span>
																					</div> : 
																					(
																						data.user_data || allTransactions.data.user ?
																							<div className='xui-d-inline-flex xui-flex-ai-center'>
																								<span>{(data.user_data ? data.user_data.firstname + (data.user_data.middlename ? " " + data.user_data.middlename + " " : " ") + data.user_data.lastname : allTransactions.data.user.firstname + (allTransactions.data.user.middlename ? " " + allTransactions.data.user.middlename + " " : " ") + allTransactions.data.user.lastname) + " (User)"}</span>
																								<span title="Copy User PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); setTextCopied(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); }}>
																									{copiedText && textCopied === (data.user_data ? data.user_data.pid : allTransactions.data.user.pid) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																								</span>
																							</div> :
																							"No result found"
																					)
																			)
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.type}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.payment_method ? data.payment_method : "Not found"}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.package ? data.package : "Not found"}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.months ? data.months + " Months" : "Not found"}</span>
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																<span>{data.amount === 0 ? "Free" : "NGN " + data.amount.toLocaleString()}</span>
															</td>
															<td className=''>
																{
																	data.transaction_status === "Completed" ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
																}
																{
																	data.transaction_status === "Processing" ?
																		<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
																}
																{
																	data.transaction_status === "Reversed" ?
																		<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
																}
																{
																	data.transaction_status === "Cancelled" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	{
																		data.transaction_status === "Processing" && data.type === "Deposit" && (data.partner_data || allTransactions.data.partner) ?
																			<button title="Complete Partner Deposit Transaction" onClick={() => { setPartnerDepositUniqueId(data.unique_id); PartnerDepositUniqueID(data.partner_data ? data.partner_data.unique_id : allTransactions.data.partner.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="partnerDepositConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.partner_data || allTransactions.data.partner) ?
																			<button title="Complete Partner Withdrawal Transaction" onClick={() => { setPartnerWithdrawalUniqueId(data.unique_id); PartnerWithdrawalUniqueID(data.partner_data ? data.partner_data.unique_id : allTransactions.data.partner.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="partnerWithdrawalConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.partner_data || allTransactions.data.partner) ?
																			<button title="View Partner Bank Details"
																				onClick={() => {
																					setPartnerBankDetails(
																						data.partner_data ? {
																							name: data.partner_data.name,
																							account_name: data.partner_data.account_name,
																							account_number: data.partner_data.account_number,
																							bank: data.partner_data.bank
																						} : {
																							name: allTransactions.data.partner.name,
																							account_name: allTransactions.data.partner.account_name,
																							account_number: allTransactions.data.partner.account_number,
																							bank: allTransactions.data.partner.bank
																						});
																				}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="partnerBankDetailsModal">
																				<EyeOpenAlt width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Deposit" && (data.business_data || allTransactions.data.business) ?
																			<button title="Complete Business Deposit Transaction" onClick={() => { setBusinessDepositUniqueId(data.unique_id); BusinessDepositUniqueID(data.business_data ? data.business_data.unique_id : allTransactions.data.business.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="businessDepositConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.business_data || allTransactions.data.business) ?
																			<button title="Complete Business Withdrawal Transaction" onClick={() => { setBusinessWithdrawalUniqueId(data.unique_id); BusinessWithdrawalUniqueID(data.business_data ? data.business_data.unique_id : allTransactions.data.business.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="businessWithdrawalConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.business_data || allTransactions.data.business) ?
																			<button title="View Business Bank Details"
																				onClick={() => {
																					setBusinessBankDetails(
																						data.business_data ? {
																							name: data.business_data.name + ", " + data.business_data.city + " (Business)",
																							account_name: data.business_data.account_name,
																							account_number: data.business_data.account_number,
																							bank: data.business_data.bank
																						} : {
																							name: allTransactions.data.business.name + ", " + allTransactions.data.business.city + " (Business)",
																							account_name: allTransactions.data.business.account_name,
																							account_number: allTransactions.data.business.account_number,
																							bank: allTransactions.data.business.bank
																						});
																				}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="businessBankDetailsModal">
																				<EyeOpenAlt width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Deposit" && (data.user_data || allTransactions.data.user) ?
																			<button title="Complete User Deposit Transaction" onClick={() => { setUserDepositUniqueId(data.unique_id); UserDepositPID(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="userDepositConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.user_data || allTransactions.data.user) ?
																			<button title="Complete User Withdrawal Transaction" onClick={() => { setUserWithdrawalUniqueId(data.unique_id); UserWithdrawalPID(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="userWithdrawalConfirmationDialog">
																				<Check width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		(data.transaction_status === "Processing" || data.transaction_status === "Completed") && data.type === "Transfer" && (data.user_data || allTransactions.data.user) ?
																			<button title="Reverse User Transfer Transaction" onClick={() => { setUserTransferUniqueId(data.unique_id); UserTransferPID(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); getReferenceTransactionFromPurplepay(data.reference); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="userTransferReverseDialog">
																				<Reset width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		(data.transaction_status === "Processing" || data.transaction_status === "Completed") && (data.type === "Airtime" || data.type === "Data" || data.type === "Cable" || data.type === "Betting" || data.type === "Internet" || data.type === "Utility") && (data.user_data || allTransactions.data.user) ?
																			<button title="Reverse User Bill Transaction" onClick={() => { setUserBillUniqueId(data.unique_id); UserBillPID(data.user_data ? data.user_data.pid : allTransactions.data.user.pid); getReferenceTransactionFromPurplepay(data.reference); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="userBillReverseDialog">
																				<Reset width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		(data.type === "Transfer" || data.type === "Reversal" || data.type === "Deposit" || data.type === "Airtime" || data.type === "Data" || data.type === "Cable" || data.type === "Betting" || data.type === "Internet" || data.type === "Utility") && (data.user_data || allTransactions.data.user) ?
																			<button title="View Purplepay Transaction Details"
																				onClick={() => {
																					getReferenceTransactionFromPurplepay(data.reference);
																				}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="purplepayTransactionDetailsModal">
																				<EyeOpenAlt width="20" height="20" />
																			</button> :
																			""
																	}
																	{
																		data.transaction_status === "Processing" && data.type === "Withdrawal" && (data.user_data || allTransactions.data.user) ?
																			<button title="View User Bank Details" 
																				onClick={() => { 
																					setUserBankDetails(
																						data.user_data ? { 
																							name: data.user_data.firstname + (data.user_data.middlename ? " " + data.user_data.middlename + " " : " ") + data.user_data.lastname,
																							account_name: data.user_data.account_name, 
																							account_number: data.user_data.account_number, 
																							bank: data.user_data.bank 
																						} : { 
																							name: allTransactions.data.user.firstname + (allTransactions.data.user.middlename ? " " + allTransactions.data.user.middlename + " " : " ") + allTransactions.data.user.lastname,
																							account_name: allTransactions.data.user.account_name, 
																							account_number: allTransactions.data.user.account_number, 
																							bank: allTransactions.data.user.bank 
																						}); 
																				}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="userBankDetailsModal">
																				<EyeOpenAlt width="20" height="20" />
																			</button> :
																			""
																	}
																	<button title="View Transaction Full Details"
																		onClick={() => {
																			setTransactionFullDetails(data);
																		}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="transactionFullDetailsModal">
																		<EyeOpenAlt width="20" height="20" />
																	</button>
																	<button title="Delete Transaction"
																		onClick={() => {
																			setDeleteTransactionUniqueId(data.unique_id);
																		}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteTransactionDialog">
																		<Delete width="20" height="20" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorTransactions}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllTransactions ?
								<Loading width="12" height="12" /> :
								(
									allTransactions && allTransactions.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allTransactions ? allTransactions.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousTransactions}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextTransactions}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="filterByPartner" id="filterByPartner">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetPartnerFilterParameters()} xui-modal-close="filterByPartner">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Transactions By Partner</h1>
					<form className="xui-form" onSubmit={continuePartnerFilterByPartner}>
						<div className="xui-form-box">
							<label>Partner Unique ID</label>
							<input className="xui-font-sz-90" type="text" value={filterByPartnerUniqueID} onChange={handleFilterByPartnerUniqueID} required placeholder="Enter/Paste Partner Unique ID"></input>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
							<div className="xui-form-box">
								<label>Type</label>
								<select value={filterByPartnerFilterType} onChange={handleFilterByPartnerFilterType} className='xui-font-w-normal'>
									{
										!filterByPartnerFilterType ?
											<option selected disabled>Filter By Type</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Deposit"}>Deposit</option>
									<option value={"Reversal"}>Reversal</option>
									<option value={"Ticket"}>Ticket</option>
									<option value={"Credit"}>Credit</option>
									<option value={"Debit"}>Debit</option>
									<option value={"Transfer"}>Transfer</option>
									<option value={"Charges"}>Charges</option>
									<option value={"CBN STAMP DUTY"}>CBN STAMP DUTY</option>
									<option value={"Stamp"}>Stamp</option>
									<option value={"VAT"}>VAT</option>
									<option value={"Withdrawal"}>Withdrawal</option>
									<option value={"API Call"}>API Call</option>
									<option value={"Payment"}>Payment</option>
									<option value={"Subscription"}>Subscription</option>
								</select>
								{
									filterByPartnerFilterType ? 
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
							<div className="xui-form-box">
								<label>Transaction Status</label>
								<select value={filterByPartnerTransactionStatus} onChange={handleFilterByPartnerTransactionStatus} className='xui-font-w-normal'>
									{
										!filterByPartnerTransactionStatus ?
											<option selected disabled>Filter By Status</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Processing"}>Processing</option>
									<option value={"Reversed"}>Reversed</option>
									<option value={"Cancelled"}>Cancelled</option>
									<option value={"Completed"}>Completed</option>
								</select>
								{
									filterByPartnerTransactionStatus ? 
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByBusiness" id="filterByBusiness">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetBusinessFilterParameters()} xui-modal-close="filterByBusiness">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Transactions By Business</h1>
					<form className="xui-form" onSubmit={continueBusinessFilterByBusiness}>
						<div className="xui-form-box">
							<label>Business Unique ID</label>
							<input className="xui-font-sz-90" type="text" value={filterByBusinessUniqueID} onChange={handleFilterByBusinessUniqueID} required placeholder="Enter/Paste Business Unique ID"></input>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
							<div className="xui-form-box">
								<label>Type</label>
								<select value={filterByBusinessFilterType} onChange={handleFilterByBusinessFilterType} className='xui-font-w-normal'>
									{
										!filterByBusinessFilterType ?
											<option selected disabled>Filter By Type</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Deposit"}>Deposit</option>
									<option value={"Reversal"}>Reversal</option>
									<option value={"Ticket"}>Ticket</option>
									<option value={"Credit"}>Credit</option>
									<option value={"Debit"}>Debit</option>
									<option value={"Transfer"}>Transfer</option>
									<option value={"Charges"}>Charges</option>
									<option value={"CBN STAMP DUTY"}>CBN STAMP DUTY</option>
									<option value={"Stamp"}>Stamp</option>
									<option value={"VAT"}>VAT</option>
									<option value={"Withdrawal"}>Withdrawal</option>
									<option value={"API Call"}>API Call</option>
									<option value={"Payment"}>Payment</option>
									<option value={"Subscription"}>Subscription</option>
								</select>
								{
									filterByBusinessFilterType ?
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
							<div className="xui-form-box">
								<label>Transaction Status</label>
								<select value={filterByBusinessTransactionStatus} onChange={handleFilterByBusinessTransactionStatus} className='xui-font-w-normal'>
									{
										!filterByBusinessTransactionStatus ?
											<option selected disabled>Filter By Status</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Processing"}>Processing</option>
									<option value={"Reversed"}>Reversed</option>
									<option value={"Cancelled"}>Cancelled</option>
									<option value={"Completed"}>Completed</option>
								</select>
								{
									filterByBusinessTransactionStatus ?
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByUser" id="filterByUser">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetUserFilterParameters()} xui-modal-close="filterByUser">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Transactions By User</h1>
					<form className="xui-form" onSubmit={continueUserFilterByUser}>
						<div className="xui-form-box">
							<label>User PID</label>
							<input className="xui-font-sz-90" type="text" minLength={6} maxLength={6} value={filterByUserPID} onChange={handleFilterByUserPID} required placeholder="Enter/Paste User PID"></input>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
							<div className="xui-form-box">
								<label>Type</label>
								<select value={filterByUserFilterType} onChange={handleFilterByUserFilterType} className='xui-font-w-normal'>
									{
										!filterByUserFilterType ?
											<option selected disabled>Filter By Type</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Deposit"}>Deposit</option>
									<option value={"Airtime"}>Airtime</option>
									<option value={"Betting"}>Betting</option>
									<option value={"Cable"}>Cable</option>
									<option value={"Data"}>Data</option>
									<option value={"Internet"}>Internet</option>
									<option value={"Utility"}>Utility</option>
									<option value={"Reversal"}>Reversal</option>
									<option value={"Ticket"}>Ticket</option>
									<option value={"Credit"}>Credit</option>
									<option value={"Debit"}>Debit</option>
									<option value={"Transfer"}>Transfer</option>
									<option value={"Charges"}>Charges</option>
									<option value={"CBN STAMP DUTY"}>CBN STAMP DUTY</option>
									<option value={"Stamp"}>Stamp</option>
									<option value={"VAT"}>VAT</option>
									<option value={"Withdrawal"}>Withdrawal</option>
									<option value={"API Call"}>API Call</option>
									<option value={"Payment"}>Payment</option>
									<option value={"Subscription"}>Subscription</option>
								</select>
								{
									filterByUserFilterType ?
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
							<div className="xui-form-box">
								<label>Transaction Status</label>
								<select value={filterByUserTransactionStatus} onChange={handleFilterByUserTransactionStatus} className='xui-font-w-normal'>
									{
										!filterByUserTransactionStatus ?
											<option selected disabled>Filter By Status</option> :
											<option value={""}>Select to Reset</option>
									}
									<option value={"Processing"}>Processing</option>
									<option value={"Reversed"}>Reversed</option>
									<option value={"Cancelled"}>Cancelled</option>
									<option value={"Completed"}>Completed</option>
								</select>
								{
									filterByUserTransactionStatus ?
										<div className="psc-broken-line-text xui-opacity-4">
											<span className="xui-font-sz-80 xui-font-w-700"><Check width="16" height="16" /></span>
										</div> : ""
								}
							</div>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByReference" id="filterByReference">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetReferenceFilterParameters()} xui-modal-close="filterByReference">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Transactions By Reference</h1>
					<form className="xui-form" onSubmit={continueFilterByReference}>
						<div className="xui-form-box">
							<label>Reference</label>
							<input className="xui-font-sz-90" type="text" value={filterByReference} onChange={handleFilterByReference} required placeholder="Enter/Paste Reference"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByDate" id="filterByDate">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetDateFilterParameters()} xui-modal-close="filterByDate">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Transactions By Date</h1>
					<form className="xui-form" onSubmit={continueFilterByDate}>
						<div className="xui-form-box">
							<label>Start Date</label>
							<input className="xui-font-sz-90" type="date" name="startDate" onChange={handleFilterStartDate} required></input>
						</div>
						<div className="xui-form-box">
							<label>End Date</label>
							<input className="xui-font-sz-90" type="date" name="endDate" onChange={handleFilterEndDate} required></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="partnerDepositConfirmationDialog" id="partnerDepositConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete Partner Deposit Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompletePartnerDeposit}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompletePartnerDeposit}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompletePartnerDeposit} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompletePartnerDeposit ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompletePartnerDeposit ? "" : "partnerDepositConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="partnerWithdrawalConfirmationDialog" id="partnerWithdrawalConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete Partner Withdrawal Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompletePartnerWithdrawal}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompletePartnerWithdrawal}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompletePartnerWithdrawal} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompletePartnerWithdrawal ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompletePartnerWithdrawal ? "" : "partnerWithdrawalConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="businessDepositConfirmationDialog" id="businessDepositConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete Business Deposit Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompleteBusinessDeposit}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompleteBusinessDeposit}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompleteBusinessDeposit} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompleteBusinessDeposit ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompleteBusinessDeposit ? "" : "businessDepositConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="businessWithdrawalConfirmationDialog" id="businessWithdrawalConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete Business Withdrawal Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompleteBusinessWithdrawal}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompleteBusinessWithdrawal}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompleteBusinessWithdrawal} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompleteBusinessWithdrawal ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompleteBusinessWithdrawal ? "" : "businessWithdrawalConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="userDepositConfirmationDialog" id="userDepositConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete User Deposit Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompleteUserDeposit}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompleteUserDeposit}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompleteUserDeposit} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompleteUserDeposit ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompleteUserDeposit ? "" : "userDepositConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="userTransferReverseDialog" id="userTransferReverseDialog">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setPurplepayTransactionDetails(null)} xui-modal-close="userTransferReverseDialog">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>Reverse User Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					{
						loadingPurplepayTransactionDetails ?
							<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
							(
								!purplepayTransactionDetails ?
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<center>
											<h4>Purplepay Transaction Details</h4>
										</center>
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPurplepayTransactionDetails}</h3>
											</center>
										</div>
									</div> :
									<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-1 xui-px-1 xui-mt-2">
										<center>
											<h4>Purplepay Transaction Details</h4>
										</center>
										<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
											<div className="">
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Reference</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Type</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Amount</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Sum</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Status</p>
											</div>
											<div className="">
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span>{purplepayTransactionDetails.data.reference}</span>
														<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(purplepayTransactionDetails.data.reference); setTextCopied(purplepayTransactionDetails.data.reference); }}>
															{copiedText && textCopied === purplepayTransactionDetails.data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_type}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_amount}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_sum}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.status}</p>
											</div>
										</div>
									</div>
							)
					}
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorReverseUserTransfer}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successReverseUserTransfer}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleReverseUserTransfer} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingReverseUserTransfer ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingReverseUserTransfer ? "" : "userTransferReverseDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="userBillReverseDialog" id="userBillReverseDialog">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setPurplepayTransactionDetails(null)} xui-modal-close="userBillReverseDialog">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>Reverse User Bill Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					{
						loadingPurplepayTransactionDetails ?
							<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
							(
								!purplepayTransactionDetails ?
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<center>
											<h4>Purplepay Transaction Details</h4>
										</center>
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPurplepayTransactionDetails}</h3>
											</center>
										</div>
									</div> :
									<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-1 xui-px-1 xui-mt-2">
										<center>
											<h4>Purplepay Transaction Details</h4>
										</center>
										<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
											<div className="">
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Reference</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Type</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Amount</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Sum</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Status</p>
											</div>
											<div className="">
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span>{purplepayTransactionDetails.data.reference}</span>
														<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(purplepayTransactionDetails.data.reference); setTextCopied(purplepayTransactionDetails.data.reference); }}>
															{copiedText && textCopied === purplepayTransactionDetails.data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_type}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_amount}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_sum}</p>
												<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.status}</p>
											</div>
										</div>
									</div>
							)
					}
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorReverseUserBill}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successReverseUserBill}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleReverseUserBill} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingReverseUserBill ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingReverseUserBill ? "" : "userBillReverseDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="deleteTransactionDialog" id="deleteTransactionDialog">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="deleteTransactionDialog">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>Delete Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteTransaction}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteTransaction}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteTransaction} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteTransaction ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteTransaction ? "" : "deleteTransactionDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="purplepayTransactionDetailsModal" id="purplepayTransactionDetailsModal">
				<div className='xui-modal-content xui-max-h-500 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setPurplepayTransactionDetails(null)} xui-modal-close="purplepayTransactionDetailsModal">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>Purplepay Transaction Details</h1>
					</center>
					{
						loadingPurplepayTransactionDetails ?
							<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> : 
							(
								!purplepayTransactionDetails ? 
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPurplepayTransactionDetails}</h3>
											</center>
										</div>
									</div> : 
									<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
										<div className="">
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Reference</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Type</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Amount</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Sum</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Status</p>
										</div>
										<div className="">
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">
												<div className='xui-d-inline-flex xui-flex-ai-center'>
													<span>{purplepayTransactionDetails.data.reference}</span>
													<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(purplepayTransactionDetails.data.reference); setTextCopied(purplepayTransactionDetails.data.reference); }}>
														{copiedText && textCopied === purplepayTransactionDetails.data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
													</span>
												</div>
											</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_type}</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_amount}</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.transaction_sum}</p>
											<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{purplepayTransactionDetails.data.status}</p>
										</div>
									</div>
							)
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="transactionFullDetailsModal" id="transactionFullDetailsModal">
				<div className='xui-modal-content xui-max-h-500 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setTransactionFullDetails(null)} xui-modal-close="transactionFullDetailsModal">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>Transaction Full Details</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">
							<div className='xui-d-inline-flex xui-flex-ai-center'>
								<span>#{transactionFullDetails?.unique_id}</span>
								<span title="Copy ID Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(transactionFullDetails?.unique_id); setTextCopied(transactionFullDetails?.unique_id); }}>
									{copiedText && textCopied === transactionFullDetails?.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
								</span>
							</div>
						</p>
					</center>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Amount - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Contact - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Months - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Other - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Package - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Reference - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Type - </p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Status - </p>
						</div>
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half"><span>{transactionFullDetails?.amount === 0 ? "Free" : "NGN " + transactionFullDetails?.amount.toLocaleString()}</span></p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{transactionFullDetails?.contact ? transactionFullDetails?.contact : "None"}</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{transactionFullDetails?.months ? transactionFullDetails?.months : "None"}</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{
								transactionFullDetails?.other ? 
									<span>{transactionFullDetails?.other.name} | {transactionFullDetails?.other.account} | {transactionFullDetails?.other.bank}</span> : 
									<span>None</span>
							}</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{transactionFullDetails?.package ? transactionFullDetails?.package : "None"}</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">
								<div className='xui-d-inline-flex xui-flex-ai-center'>
									<span>{transactionFullDetails?.reference}</span>
									<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(transactionFullDetails?.reference); setTextCopied(transactionFullDetails?.reference); }}>
										{copiedText && textCopied === transactionFullDetails?.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
									</span>
								</div>
							</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{transactionFullDetails?.type}</p>
							<hr></hr>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">
								{
									transactionFullDetails?.transaction_status === "Completed" ?
										<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{transactionFullDetails?.transaction_status}</span> : ""
								}
								{
									transactionFullDetails?.transaction_status === "Processing" ?
										<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>{transactionFullDetails?.transaction_status}</span> : ""
								}
								{
									transactionFullDetails?.transaction_status === "Reversed" ?
										<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{transactionFullDetails?.transaction_status}</span> : ""
								}
								{
									transactionFullDetails?.transaction_status === "Cancelled" ?
										<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{transactionFullDetails?.transaction_status}</span> : ""
								}
							</p>
						</div>
					</div>
					<center>
						<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {transactionFullDetails?.createdAt.fulldate} | Last Updated - {transactionFullDetails?.updatedAt.fulldate}</p>
					</center>
				</div>
			</section>
			<section className='xui-modal' xui-modal="userBankDetailsModal" id="userBankDetailsModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setUserBankDetails(null)} xui-modal-close="userBankDetailsModal">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>{userBankDetails?.name}</h1>
						<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank Details</p>
					</center>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Name</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Number</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank</p>
						</div>
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{userBankDetails?.account_name}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{userBankDetails?.account_number}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{userBankDetails?.bank}</p>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="businessBankDetailsModal" id="businessBankDetailsModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setBusinessBankDetails(null)} xui-modal-close="businessBankDetailsModal">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>{businessBankDetails?.name}</h1>
						<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank Details</p>
					</center>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Name</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Number</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank</p>
						</div>
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{businessBankDetails?.account_name}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{businessBankDetails?.account_number}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{businessBankDetails?.bank}</p>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="partnerBankDetailsModal" id="partnerBankDetailsModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setPartnerBankDetails(null)} xui-modal-close="partnerBankDetailsModal">
						<Close width="24" height="24" />
					</div>
					<center>
						<h1>{partnerBankDetails?.name}</h1>
						<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank Details</p>
					</center>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Name</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Account Number</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Bank</p>
						</div>
						<div className="">
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{partnerBankDetails?.account_name}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{partnerBankDetails?.account_number}</p>
							<p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{partnerBankDetails?.bank}</p>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="userWithdrawalConfirmationDialog" id="userWithdrawalConfirmationDialog">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Complete User Withdrawal Transaction</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCompleteUserWithdrawal}</span></p>
					<p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCompleteUserWithdrawal}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleCompleteUserWithdrawal} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingCompleteUserWithdrawal ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCompleteUserWithdrawal ? "" : "userWithdrawalConfirmationDialog"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}