import { useState } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { declineUserBVN, verifyUserBVN, createVirtualAccount, createVerificationLog } from "../api/bvn";

const useVerifyBVN = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyBVN, setLoadingVerifyBVN] = useState(false);
	const [removeVerifyBVNModal, setRemoveVerifyBVNModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [note, setNote] = useState(null);

	const [errorVerifyBVN, setErrorVerifyBVN] = useState(null);
	const [successVerifyBVN, setSuccessVerifyBVN] = useState(null);

	const handleNote = (e) => { e.preventDefault(); setNote(e.target.value); };

	const handleVerifyBVN = () => {

		if (!loadingVerifyBVN) {
			if (!uniqueId) {
				setErrorVerifyBVN(null);
				setSuccessVerifyBVN(null);
				setErrorVerifyBVN("Unique ID is required");
				setTimeout(function () {
					setErrorVerifyBVN(null);
				}, 2500)
			} else if (!userPID) {
				setErrorVerifyBVN("PID is required");
				setTimeout(function () {
					setErrorVerifyBVN(null);
				}, 2500)
			} else {
				setLoadingVerifyBVN(true);

				const verifyUserBVNRes = verifyUserBVN(cookie, {
					unique_id: uniqueId,
					pid: userPID,
					note: note === null ? undefined : note,
				})

				verifyUserBVNRes.then(res => {
					setLoadingVerifyBVN(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyBVN(error);
							setTimeout(function () {
								setErrorVerifyBVN(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyBVN(error);
							setTimeout(function () {
								setErrorVerifyBVN(null);
							}, 2000)
						}
					} else {
						setErrorVerifyBVN(null);
						setSuccessVerifyBVN(`User BVN Verified!`);

						setTimeout(function () {
							setSuccessVerifyBVN(null);
							setRemoveVerifyBVNModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyBVN(false);
				})

			}
		}
	};

	return {
		cookie, loadingVerifyBVN, removeVerifyBVNModal, errorVerifyBVN, successVerifyBVN, handleVerifyBVN,
		setRemoveVerifyBVNModal, setUniqueId, setUserPID, note, setNote, handleNote
	};
};

const useDeclineBVN = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineBVN, setLoadingDeclineBVN] = useState(false);
	const [removeDeclineBVNModal, setRemoveDeclineBVNModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [message, setMessage] = useState(null);

	const [errorDeclineBVN, setErrorDeclineBVN] = useState(null);
	const [successDeclineBVN, setSuccessDeclineBVN] = useState(null);

	const handleMessage = (e) => { e.preventDefault(); setMessage(e.target.value); };

	const handleDeclineBVN = () => {

		if (!loadingDeclineBVN) {
			if (!uniqueId) {
				setErrorDeclineBVN(null);
				setSuccessDeclineBVN(null);
				setErrorDeclineBVN("Unique ID is required");
				setTimeout(function () {
					setErrorDeclineBVN(null);
				}, 2500)
			} else if (!userPID) {
				setErrorDeclineBVN("PID is required");
				setTimeout(function () {
					setErrorDeclineBVN(null);
				}, 2500)
			} else if (!message) {
				setErrorDeclineBVN("Message is required");
				setTimeout(function () {
					setErrorDeclineBVN(null);
				}, 2500)
			} else if (message.length < 3) {
				setErrorDeclineBVN("Message minimum characters - 3");
				setTimeout(function () {
					setErrorDeclineBVN(null);
				}, 2500)
			} else if (message.length > 500) {
				setErrorDeclineBVN("Message maximum characters - 500");
				setTimeout(function () {
					setErrorDeclineBVN(null);
				}, 2500)
			} else {
				setLoadingDeclineBVN(true);

				const declineUserBVNRes = declineUserBVN(cookie, {
					unique_id: uniqueId,
					pid: userPID,
					message: message
				})

				declineUserBVNRes.then(res => {
					setLoadingDeclineBVN(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineBVN(error);
							setTimeout(function () {
								setErrorDeclineBVN(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineBVN(error);
							setTimeout(function () {
								setErrorDeclineBVN(null);
							}, 2000)
						}
					} else {
						setErrorDeclineBVN(null);
						setSuccessDeclineBVN(`User BVN Declined!`);

						setTimeout(function () {
							setSuccessDeclineBVN(null);
							setRemoveDeclineBVNModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeclineBVN(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeclineBVN, removeDeclineBVNModal, errorDeclineBVN, successDeclineBVN, handleDeclineBVN,
		setRemoveDeclineBVNModal, setUniqueId, message, setMessage, handleMessage, setUserPID
	};
};

const useCreateVirtualAccount = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCreateVirtualAccount, setLoadingCreateVirtualAccount] = useState(false);
	const [removeCreateVirtualAccountModal, setRemoveCreateVirtualAccountModal] = useState(null);
	const [userPID, setUserPID] = useState(null);

	const [errorCreateVirtualAccount, setErrorCreateVirtualAccount] = useState(null);
	const [successCreateVirtualAccount, setSuccessCreateVirtualAccount] = useState(null);

	const handleCreateVirtualAccount = () => {

		if (!loadingCreateVirtualAccount) {
			if (!userPID) {
				setErrorCreateVirtualAccount(null);
				setSuccessCreateVirtualAccount(null);
				setErrorCreateVirtualAccount("PID is required");
				setTimeout(function () {
					setErrorCreateVirtualAccount(null);
				}, 2500)
			} else {
				setLoadingCreateVirtualAccount(true);

				const createVirtualAccountRes = createVirtualAccount(cookie, {
					pid: userPID
				})

				createVirtualAccountRes.then(res => {
					setLoadingCreateVirtualAccount(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCreateVirtualAccount(error);
							setTimeout(function () {
								setErrorCreateVirtualAccount(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCreateVirtualAccount(error);
							setTimeout(function () {
								setErrorCreateVirtualAccount(null);
							}, 2000)
						}
					} else {
						setErrorCreateVirtualAccount(null);
						setSuccessCreateVirtualAccount(`User virtual account created!`);

						setTimeout(function () {
							setSuccessCreateVirtualAccount(null);
							setRemoveCreateVirtualAccountModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCreateVirtualAccount(false);
				})

			}
		}
	};

	return {
		cookie, loadingCreateVirtualAccount, removeCreateVirtualAccountModal, errorCreateVirtualAccount, successCreateVirtualAccount, handleCreateVirtualAccount,
		setRemoveCreateVirtualAccountModal, setUserPID
	};
};

const useCreateVerificationLog = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCreateVerificationLog, setLoadingCreateVerificationLog] = useState(false);
	const [removeCreateVerificationLogModal, setRemoveCreateVerificationLogModal] = useState(null);
	const [bvn, setBVN] = useState(null);

	const [errorCreateVerificationLog, setErrorCreateVerificationLog] = useState(null);
	const [successCreateVerificationLog, setSuccessCreateVerificationLog] = useState(null);

	const handleCreateVerificationLog = () => {

		if (!loadingCreateVerificationLog) {
			if (!bvn) {
				setErrorCreateVerificationLog(null);
				setSuccessCreateVerificationLog(null);
				setErrorCreateVerificationLog("BVN is required");
				setTimeout(function () {
					setErrorCreateVerificationLog(null);
				}, 2500)
			} else {
				setLoadingCreateVerificationLog(true);

				const createVerificationLogRes = createVerificationLog(cookie, {
					bvn: bvn
				})

				createVerificationLogRes.then(res => {
					setLoadingCreateVerificationLog(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCreateVerificationLog(error);
							setTimeout(function () {
								setErrorCreateVerificationLog(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCreateVerificationLog(error);
							setTimeout(function () {
								setErrorCreateVerificationLog(null);
							}, 2000)
						}
					} else {
						setErrorCreateVerificationLog(null);
						setSuccessCreateVerificationLog(`User verification log created!`);

						setTimeout(function () {
							setSuccessCreateVerificationLog(null);
							setRemoveCreateVerificationLogModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCreateVerificationLog(false);
				})

			}
		}
	};

	return {
		cookie, loadingCreateVerificationLog, removeCreateVerificationLogModal, errorCreateVerificationLog, successCreateVerificationLog, handleCreateVerificationLog,
		setRemoveCreateVerificationLogModal, setBVN
	};
};

export { useVerifyBVN, useDeclineBVN, useCreateVirtualAccount, useCreateVerificationLog };