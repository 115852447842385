import axios from 'axios'
import { config } from '../config'

async function getAddresses(key, page, size){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/addresses`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAddress(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/address`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserAddress(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/address/verify`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserAddress(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/address/decline`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAddressViaPID(key, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/addresses/via/user`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAddressesViaVerifiedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/addresses/via/verification`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAddressesViaProofType(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/addresses/via/type`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
    getAddresses, getAddress, verifyUserAddress,declineUserAddress, 
	getAddressViaPID, getAddressesViaVerifiedStatus, getAddressesViaProofType
}