import axios from 'axios';
import { config } from '../config';

const getMails = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/mails`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getMailingTemplates = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/mailing/templates`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getMailingTemplate = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/mailing/template`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const sendNewsletter = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/send/newsletter`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const retryEmail = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/mail/retry`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const addMailingTemplate = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/mailing/template/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const editMailingTemplateName = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/mailing/template/edit/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const editMailingTemplateSubject = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/mailing/template/edit/subject`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const editMailingTemplateDetails = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/mailing/template/edit/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const deleteMailingTemplate = async function (key, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/root/mailing/template`,
			{
				data: {
					key,
					...payload
				}
			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getMails, getMailingTemplates, getMailingTemplate, addMailingTemplate, editMailingTemplateName, editMailingTemplateSubject, editMailingTemplateDetails, deleteMailingTemplate, sendNewsletter, retryEmail };