import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import useCookie from "../hooks/useCookie";
import Search from "../icons/Search";
import { getTaxRecord, getTaxRecordsViaUser, getTaxRecords, getTaxRecordsViaVerifiedStatus } from "../api/taxRecord";
import { config } from "../config";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Check from "../icons/Check";
import Copy from "../icons/Copy";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import Star from "../icons/Star";
import { useVerifyUserTaxRecord, useDeclineUserTaxRecord } from "../hooks/useTaxRecords";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import Filter from "../icons/Filter";

export default function TaxRecords() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const { handleVerifyUserTaxRecord, setUserPID: verifyUserTaxRecordPID,
		setTaxRecordUniqueId: verifyUserTaxRecordUniqueId, errorVerifyUserTaxRecord, successVerifyUserTaxRecord,
		loadingVerifyUserTaxRecord, removeVerifyUserTaxRecordModal, setRemoveVerifyUserTaxRecordModal
	} = useVerifyUserTaxRecord();

	const { errorDeclineTaxRecord, successDeclineTaxRecord, handleDeclineTaxRecord, setUserPID: declineUserTaxRecordPID,
		setTaxRecordUniqueId: declineUserTaxRecordUniqueId, declineMsg, setDeclineMsg,
		loadingDeclineTaxRecord, removeDeclineTaxRecordModal, setRemoveDeclineTaxRecordModal } = useDeclineUserTaxRecord()

	const [currentFunction, setCurrentFunction] = useState("getAllTaxRecords");

	const [allTaxRecords, setAllTaxRecords] = useState(null);
	const [errorAllTaxRecords, setErrorAllTaxRecords] = useState(null);
	const [loadingAllTaxRecords, setLoadingAllTaxRecords] = useState(false);

	const [taxRecordVerifiedStatus, setTaxRecordVerifiedStatus] = useState(null)

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleTaxRecordVerifiedStatus = (e) => { e.preventDefault(); setTaxRecordVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllTaxRecordsByVerification"); getAllTaxRecordsByVerification(e.target.value === 'verified' ? true : false, page, size); };

	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllTaxRecords":
				getAllTaxRecords(page, size);
				break;
			case "getAllTaxRecordsByVerification":
				getAllTaxRecordsByVerification(taxRecordVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllTaxRecords(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllTaxRecords":
				getAllTaxRecords(page, size);
				break;
			case "getAllTaxRecordsByVerification":
				getAllTaxRecordsByVerification(taxRecordVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllTaxRecords(page, size);
		}
	};

	async function callLastFunction() {
		switch (currentFunction) {
			case "getAllTaxRecords":
				getAllTaxRecords(page, size);
				break;
			case "getAllTaxRecordsByVerification":
				getAllTaxRecordsByVerification(taxRecordVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			// default:
			// 	getAllTaxRecords(page, size);
		}
	};

	async function previousTaxRecords() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllTaxRecords":
					getAllTaxRecords(page - 1, size);
					break;
				case "getAllTaxRecordsByVerification":
					getAllTaxRecordsByVerification(taxRecordVerifiedStatus === 'verified' ? true : false, page - 1, size);
					break;
				default:
					getAllTaxRecords(page - 1, size);
			}
		}
	};

	async function nextTaxRecords() {
		if (page < allTaxRecords.data.pages) setPage(page + 1);
		if (page < allTaxRecords.data.pages) {
			switch (currentFunction) {
				case "getAllTaxRecords":
					getAllTaxRecords(page + 1, size);
					break;
				case "getAllTaxRecordsByVerification":
					getAllTaxRecordsByVerification(taxRecordVerifiedStatus === 'verified' ? true : false, page + 1, size);
					break;
				default:
					getAllTaxRecords(page + 1, size);
			}
		}
	};

	async function getAllTaxRecords(_page, _size) {
		setLoadingAllTaxRecords(true);
		const response = await getTaxRecords(cookie, (_page | page), (_size || size));
		setAllTaxRecords(response.data);
		if (response.error) setErrorAllTaxRecords(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllTaxRecords(false);
	}

	async function getAllTaxRecordsByVerification(verified, _page, _size) {
		setLoadingAllTaxRecords(true)
		const response = await getTaxRecordsViaVerifiedStatus(cookie, (_page || page), (_size || size), { verified: verified })
		setAllTaxRecords(response.data)
		if (response.error) setErrorAllTaxRecords(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllTaxRecords(false);
	}

	useEffect(() => {
		if (allTaxRecords === null) {
			callLastFunction();
		}
	}, [allTaxRecords])

	const [loadingViewTaxRecord, setLoadingViewTaxRecord] = useState(false)
	const [errorViewTaxRecord, setErrorViewTaxRecord] = useState(null)
	const [viewUserTaxRecord, setViewUserTaxRecord] = useState(null)

	async function getUserTaxRecord(uniqueId, pid) {
		setLoadingViewTaxRecord(true)
		const response = await getTaxRecord(cookie, { unique_id: uniqueId, pid: pid })
		if (!response.err) {
			setViewUserTaxRecord(response.data)
		} else { setErrorViewTaxRecord(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewTaxRecord(false)
	}

	async function getUserTaxRecords(pid) {
		// const modalResponse = document.querySelector("#userTaxRecordSearchModal");
		// modalResponse.setAttribute("display", false);
		// setLoadingViewTaxRecord(true);
		// const response = await getTaxRecordsViaUser(cookie, { pid: pid })
		// if (!response.error) {
		// 	setViewUserTaxRecord(response.data)
		// } else {
		// 	setViewUserTaxRecord(null); setErrorViewTaxRecord(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message)
		// }
		// setLoadingViewTaxRecord(false)
		// setTaxRecordPIDSearch("")
		setLoadingAllTaxRecords(true);
		const modalResponse = document.querySelector("#userTaxRecordSearchModal");
		modalResponse.setAttribute("display", false);
		const response = await getTaxRecordsViaUser(cookie, { pid: pid, page, size })
		setAllTaxRecords(response.data)
		if (response.error) setErrorAllTaxRecords(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllTaxRecords(false);
	}

	const [taxRecordPIDSearch, setTaxRecordPIDSearch] = useState("")
	const handleTaxRecordPIDSearch = (e) => { e.preventDefault(); setTaxRecordPIDSearch(e.target.value.toUpperCase()); }

	if (removeVerifyUserTaxRecordModal) {
		const modalResponse = document.querySelector("#verifyUserTaxRecordModal");
		modalResponse.setAttribute('display', false);
		getUserTaxRecord(viewUserTaxRecord.data.data.unique_id, viewUserTaxRecord.data.data.pid)
		setRemoveVerifyUserTaxRecordModal(null)
	}
	if (removeDeclineTaxRecordModal) {
		const modalResponse = document.querySelector("#declineUserTaxRecordModal")
		modalResponse.setAttribute('display', false)
		getUserTaxRecord(viewUserTaxRecord.data.data.unique_id, viewUserTaxRecord.data.data.pid)
		setRemoveDeclineTaxRecordModal(null)
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allTaxRecords ? allTaxRecords.data.pages : 0).fill(0);
	
	return (
		<>
			<Screen>
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className='xui-mt-2'>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All User Tax Records</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allTaxRecords && allTaxRecords.success ? allTaxRecords.data.total : "..."}</p>
							</div>

							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userTaxRecordSearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={taxRecordVerifiedStatus} onChange={handleTaxRecordVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												taxRecordVerifiedStatus === null ?
													<option selected disabled>Filter By verification Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"verified"}>Verified</option>
											<option value={"unverified"}>Unverified</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						{
							loadingAllTaxRecords
								?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
								:
								(
									allTaxRecords && allTaxRecords.success
										?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>PID</th>
														<th className='xui-min-w-200'>Document Reference</th>
														<th className='xui-min-w-200'>TIN</th>
														<th className='xui-min-w-150'>Issued</th>
														<th className='xui-min-w-200'>Verification</th>
														<th className='xui-min-w-200'>Declined</th>
														<th className='xui-min-w-300'>Declined Timestamp</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{
														allTaxRecords.data.rows ? 
															allTaxRecords.data.rows.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.doc_ref}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.tin}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.issued_date}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserTaxRecord(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserTaxRecordModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															)) : 
															allTaxRecords.data.data.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.doc_ref}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.tin}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.issued_date}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserTaxRecord(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserTaxRecordModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															))
													}
												</tbody>
											</table>
										</div>
										:
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllTaxRecords}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllTaxRecords ?
								<Loading width="12" height="12" /> :
								(
									allTaxRecords && allTaxRecords.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allTaxRecords ? allTaxRecords.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousTaxRecords}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextTaxRecords}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewUserTaxRecordModal" id="viewUserTaxRecordModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserTaxRecordModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewTaxRecord
							?
							<center>
								<Loading width="12" height="12" />
							</center>
							:
							(
								viewUserTaxRecord && viewUserTaxRecord.success
									?
									<>
										<h1>{viewUserTaxRecord.data.user_data.firstname + (viewUserTaxRecord.data.user_data.middlename ? " " + viewUserTaxRecord.data.user_data.middlename + " " : " ") + viewUserTaxRecord.data.user_data.lastname}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Tax Record details for this user below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserTaxRecord.data.user_data.photo} alt={viewUserTaxRecord.data.user_data.firstname + (viewUserTaxRecord.data.user_data.middlename ? " " + viewUserTaxRecord.data.user_data.middlename + " " : " ") + viewUserTaxRecord.data.user_data.lastname + " Selfie Image"} />
												<center className="xui-mt-1">
													<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserTaxRecord.data.data.pid}</b>
													<center>
														<div className="xui-d-inline-flex xui-flex-ai-center">
															<span>
																{
																	viewUserTaxRecord.data.user_data.star === 0 ?
																		<div className='xui-m-half'>
																			<p>No star</p>
																		</div>
																		: ""
																}
																{
																	viewUserTaxRecord.data.user_data.star === 1 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserTaxRecord.data.user_data.star === 2 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserTaxRecord.data.user_data.star === 3 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserTaxRecord.data.user_data.star === 4 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserTaxRecord.data.user_data.star === 5 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
															</span>
														</div>
													</center>
													<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">No: <b>{viewUserTaxRecord.data.user_data.phone_number}</b></p>
												</center>
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Document Reference -</span> {viewUserTaxRecord.data.data.doc_ref}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">TIN -</span> {viewUserTaxRecord.data.data.tin}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Issued -</span> {viewUserTaxRecord.data.data.issued_date}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
													viewUserTaxRecord.data.data.status === 1
														?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span>
														:
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
													viewUserTaxRecord.data.data.verified ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
													viewUserTaxRecord.data.data.declined === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserTaxRecord.data.data.declined ?
																<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
														)

												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
													viewUserTaxRecord.data.data.declined_timestamp === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserTaxRecord.data.data.declined_timestamp ?
																<span>{viewUserTaxRecord.data.data.declined_timestamp.date} at {viewUserTaxRecord.data.data.declined_timestamp.time}</span> :
																"No timestamp"
														)
												}</p>
												<p className="xui-opacity-10 xui-font-sz-100 xui-m-half"><span className="xui-opacity-4 xui-font-w-bold">Proof - </span> {
													viewUserTaxRecord.data.data.proof
														?
														viewUserTaxRecord.data.data.proof.split(".")[3] === 'pdf'
															?
															<a href={viewUserTaxRecord.data.data.proof} rel="noreferrer" target="_blank" > {viewUserTaxRecord.data.user_data.firstname + " " + viewUserTaxRecord.data.user_data.lastname + " pdf_proof"} </a>
															:
															<img className="xui-img-300 xui-max-h-300 xui-mt-2" src={viewUserTaxRecord.data.data.proof} alt={viewUserTaxRecord.data.user_data.firstname + (viewUserTaxRecord.data.user_data.middlename ? " " + viewUserTaxRecord.data.user_data.middlename + " " : " ") + viewUserTaxRecord.data.user_data.lastname + " Proof"} />
														:
														"Null"
												}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserTaxRecord.data.data.createdAt?.fulldate} | Last Updated - {viewUserTaxRecord.data.data.updatedAt?.fulldate}</p>
											<div className="xui-m-2">
												<button title="Verify User Tax Record" onClick={() => { verifyUserTaxRecordPID(viewUserTaxRecord.data.data.pid); verifyUserTaxRecordUniqueId(viewUserTaxRecord.data.data.unique_id) }} className="xui-btn xui-mr-3 psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyUserTaxRecordModal">
													<Check width="16" height="16" />
												</button>

												<button title="Decline User Tax Record" onClick={() => { declineUserTaxRecordPID(viewUserTaxRecord.data.data.pid); declineUserTaxRecordUniqueId(viewUserTaxRecord.data.data.unique_id) }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineUserTaxRecordModal">
													<Close width="16" height="16" />
												</button>
											</div>
										</center>
									</>
									:
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewTaxRecord}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>

			<section className='xui-modal' xui-modal="verifyUserTaxRecordModal" id="verifyUserTaxRecordModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify User Tax Record</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyUserTaxRecord}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyUserTaxRecord}</span></p>
						<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button onClick={handleVerifyUserTaxRecord} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Yes</span>
									{
										loadingVerifyUserTaxRecord ?
											<Loading width="12" height="12" />
											: <Check width="20" height="20" />
									}
								</button>
							</div>

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyUserTaxRecord ? "" : "verifyUserTaxRecordModal"}>
									<span className="xui-mr-half">No</span>
									<Close width="20" height="20" />
								</button>
							</div>
						</div>
					</center>
				</div>
			</section>

			<section className='xui-modal' xui-modal="declineUserTaxRecordModal" id="declineUserTaxRecordModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Decline User Tax Record</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<form className="xui-form xui-mt-2">
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Feedback</label>
							<textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={declineMsg} onChange={(e) => setDeclineMsg(e.target.value)}></textarea>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineTaxRecord}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineTaxRecord}</span></p>
					</form>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeclineTaxRecord} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeclineTaxRecord ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineTaxRecord ? "" : "declineUserTaxRecordModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>

			<section className='xui-modal' xui-modal="userTaxRecordSearchModal" id="userTaxRecordSearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userTaxRecordSearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for User</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getUserTaxRecords(taxRecordPIDSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>PID</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"text"} maxLength={6} placeholder={"Enter Passcoder ID"} value={taxRecordPIDSearch} onChange={handleTaxRecordPIDSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}