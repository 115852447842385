import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getAllGallery, getGallery as getAGalleryImage } from "../api/gallery";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";
import { useAddGallery, useDeleteGallery, useUpdateGalleryName, useUploadGalleryImage } from "../hooks/useGallery";
import Edit from "../icons/Edit";
import EyeOpen from "../icons/EyeOpen";

export default function Gallery() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorAddGallery, handleAddGallery, handleName, loadingAddGallery, name, removeAddGalleryModal, selectedAddGallery, setName,
		setRemoveAddGalleryModal, setSelectedAddGallery, successAddGallery, uploadingAddGalleryPercentage
	} = useAddGallery();

	const {
		errorUpdateGalleryName, handleName: handleNameEdit, handleUpdateGalleryName, loadingUpdateGalleryName, name: nameEdit, removeUpdateGalleryNameModal,
		setName: setNameEdit, setRemoveUpdateGalleryNameModal, setUniqueId: EditUniqueIdName, successUpdateGalleryName
	} = useUpdateGalleryName();

	const {
		errorGalleryImage, handleUploadGalleryImage, loadingGalleryImage, removeGalleryImageModal, selectedGalleryImage, setRemoveGalleryImageModal,
		setSelectedGalleryImage, setUniqueId: UploadGalleryImageUniqueId, successGalleryImage, uploadingGalleryImagePercentage
	} = useUploadGalleryImage();

	const {
		errorDeleteGallery, handleDeleteGallery, loadingDeleteGallery, removeDeleteGalleryModal, setUniqueId: DeleteUniqueId,
		setRemoveDeleteGalleryModal, successDeleteGallery
	} = useDeleteGallery();

	const handleSelectAddGallery = (e) => {
		const el = e.target.files[0];
		setSelectedAddGallery("");
		setSelectedAddGallery(el);
	};

	const handleSelectGalleryImage = (e) => {
		const el = e.target.files[0];
		setSelectedGalleryImage("");
		setSelectedGalleryImage(el);
	};

	const getFileExtension = (filename) => {
		let lastDot = filename.lastIndexOf('.');
		let ext = filename.substring(lastDot + 1);
		return ext;
	};

	const getFileNameAlone = (filename) => {
		let _filename = filename.split("/");
		return _filename[_filename.length - 1];
	};

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const [allGallery, setAllGallery] = useState(null);
	const [errorGallery, setErrorGallery] = useState(null);
	const [loadingAllGallery, setLoadingAllGallery] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getGallery(page, e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); getGallery(parseInt(e.target.value), size); };

	async function previousGallery() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getGallery(page - 1, size);
	};

	async function nextGallery() {
		if (page < allGallery.data.pages) setPage(page + 1);
		if (page < allGallery.data.pages) getGallery(page + 1, size);
	};

	async function getGallery(_page, _size) {
		setLoadingAllGallery(true);
		const response = await getAllGallery(cookie, (_page || page), (_size || size));
		setAllGallery(response.data);
		if (response.error) setErrorGallery(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllGallery(false);
	};

	useEffect(() => {
		if (allGallery === null) {
			getGallery();
		}
	}, [allGallery]);

	const [loadingViewGalleryImage, setLoadingViewGalleryImage] = useState(false)
	const [errorViewGalleryImage, setErrorViewGalleryImage] = useState(null)
	const [viewGalleryImage, setViewGalleryImage] = useState(null)

	async function getGalleryImage(unique_id) {
		setLoadingViewGalleryImage(true)
		const response = await getAGalleryImage(cookie, { unique_id });
		if (!response.err) {
			setViewGalleryImage(response.data);
			setNameEdit(response.data.data.name);
		} else { setErrorViewGalleryImage(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewGalleryImage(false)
	};

	if (removeAddGalleryModal) {
		const modalResponse = document.querySelector("#addGalleryModal");
		modalResponse.setAttribute("display", false);
		getGallery();
		setRemoveAddGalleryModal(null);
	}
	if (removeUpdateGalleryNameModal) {
		const modalResponse = document.querySelector("#editGalleryModal");
		modalResponse.setAttribute("display", false);
		getGallery();
		setRemoveUpdateGalleryNameModal(null);
	}
	if (removeGalleryImageModal) {
		const modalResponse = document.querySelector("#editGalleryModal");
		modalResponse.setAttribute("display", false);
		getGallery();
		setRemoveGalleryImageModal(null);
	}
	if (removeDeleteGalleryModal) {
		const modalResponse = document.querySelector("#deleteGalleryModal");
		modalResponse.setAttribute("display", false);
		getGallery();
		setRemoveDeleteGalleryModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allGallery ? allGallery.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Gallery</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all gallery</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="addGalleryModal">
										<span className="xui-mr-half">Add Gallery</span>
										<Plus width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllGallery ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allGallery && allGallery.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Name</th>
														<th className='xui-min-w-150'>Image</th>
														<th className='xui-min-w-150'>Size</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-150'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allGallery.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.name}</span>
															</td>
															{/* <td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span><a href={data.image} target="_blank">{data.image}</a></span>
																	<span title="Copy Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.image); setTextCopied(data.image); }}>
																		{copiedText && textCopied === data.image ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td> */}
															<td className=''>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<img className="xui-img-50" src={data.image} alt="Gallery Image" />
																	<span title="Copy Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.image); setTextCopied(data.image); }}>
																		{copiedText && textCopied === data.image ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																	<span title="View File" className="xui-cursor-pointer xui-mx-1" onClick={() => { showPreview(data.image); }}>
																		<EyeOpen width="16" height="16" />
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.image_size}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Edit Gallery" onClick={() => { UploadGalleryImageUniqueId(data.unique_id); EditUniqueIdName(data.unique_id); getGalleryImage(data.unique_id) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="editGalleryModal">
																		<Edit width="20" height="20" />
																	</button>
																	<button title="Delete Gallery" onClick={() => { DeleteUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteGalleryModal">
																		<Delete width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorGallery}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllGallery ?
								<Loading width="12" height="12" /> :
								(
									allGallery && allGallery.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allGallery ? allGallery.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousGallery}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextGallery}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="deleteGalleryModal" id="deleteGalleryModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete Gallery</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteGallery}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteGallery}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteGallery} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteGallery ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteGallery ? "" : "deleteGalleryModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="addGalleryModal" id="addGalleryModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1200 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="addGalleryModal">
						<Close width="24" height="24" />
					</div>
					<h1>Create new Gallery Image</h1>
					<form className="xui-form" layout="2" onSubmit={handleAddGallery}>
						<div className="xui-form-box xui-mt-2">
							<label>Name</label>
							<input className="xui-font-sz-90" type="text" value={name} onChange={handleName} required placeholder="Enter name of resource"></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Image</label>
							<input onChange={handleSelectAddGallery} type={"file"} accept={"image/*"} id="image" required />
						</div>
						{
							uploadingAddGalleryPercentage > 0 ?
								<>
									<label htmlFor="uploader">Uploading</label>
									<progress className="xui-h-30" value={uploadingAddGalleryPercentage} id="uploader" max="100">{uploadingAddGalleryPercentage + "%"}</progress><br /><br></br>
								</> :
								""
						}
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button disabled={loadingAddGallery} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save Gallery</span>
								{
									loadingAddGallery ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddGallery}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddGallery}</span></p>
				</div>
			</section>
			<section className='xui-modal' xui-modal="editGalleryModal" id="editGalleryModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1200 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="editGalleryModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewGalleryImage ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewGalleryImage && viewGalleryImage.success ?
									<>
										<h1>Edit Gallery Image</h1>
										<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
											<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
												<label>Name</label>
												<div className="xui-d-flex xui-flex-ai-center">
													<input style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={"Enter name of resource"} value={nameEdit} onChange={handleNameEdit} required />
													<div onClick={handleUpdateGalleryName} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
														{
															loadingUpdateGalleryName ?
																<Loading width="16" height="16" />
																: <Check width="16" height="16" />
														}
													</div>
												</div>
												<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateGalleryName}</span></p>
												<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateGalleryName}</span></p>
											</div>
										</form>
										<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
											<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
												<label>Image</label>
												<div className="xui-d-flex xui-flex-ai-center">
													<img className="xui-img-200" src={viewGalleryImage.data.image} alt="Gallery Image" />
													<input onChange={handleSelectGalleryImage} className='xui-my-2' type={"file"} accept={"image/*"} id="image" required />
												</div>
												{
													uploadingGalleryImagePercentage > 0 ?
														<>
															<label htmlFor="uploader">Uploading</label>
															<progress className="xui-h-30" value={uploadingGalleryImagePercentage} id="uploader" max="100">{uploadingGalleryImagePercentage + "%"}</progress><br /><br></br>
														</> :
														""
												}
												<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorGalleryImage}</span></p>
												<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successGalleryImage}</span></p>
											</div>
											<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
												<button disabled={loadingGalleryImage} onClick={handleUploadGalleryImage} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">Save Changes</span>
													{
														loadingGalleryImage ?
															<Loading width="12" height="12" />
															: <Arrowright width="12" height="12" />
													}
												</button>
											</div>
										</form>
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewGalleryImage}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
		</>
	);

}
