import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserTaxRecord, verifyUserTaxRecord } from "../api/taxRecord";
import { config } from "../config";

function useVerifyUserTaxRecord() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserTaxRecord, setLoadingVerifyUserTaxRecord] = useState(false)
	const [removeVerifyUserTaxRecordModal, setRemoveVerifyUserTaxRecordModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [taxRecordUniqueId, setTaxRecordUniqueId] = useState(null)

	const [errorVerifyUserTaxRecord, setErrorVerifyUserTaxRecord] = useState(null)
	const [successVerifyUserTaxRecord, setSuccessVerifyUserTaxRecord] = useState(null)

	const handleVerifyUserTaxRecord = () => {
		if (!loadingVerifyUserTaxRecord) {
			if (!userPID || !taxRecordUniqueId) {
				setErrorVerifyUserTaxRecord(null)
				setSuccessVerifyUserTaxRecord(null)
				setErrorVerifyUserTaxRecord(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserTaxRecord(null)
				}, 2500)
			} else {
				setLoadingVerifyUserTaxRecord(true)

				const verifyUserTaxRecordRes = verifyUserTaxRecord(cookie, { pid: userPID, unique_id: taxRecordUniqueId })

				verifyUserTaxRecordRes.then((res) => {

					setLoadingVerifyUserTaxRecord(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserTaxRecord(error)
							setTimeout(() => {
								setErrorVerifyUserTaxRecord(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserTaxRecord(error)
							setTimeout(() => {
								setErrorVerifyUserTaxRecord(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserTaxRecord(null)
						setSuccessVerifyUserTaxRecord("User tax record verified")

						setTimeout(() => {
							setSuccessVerifyUserTaxRecord(null)
							setRemoveVerifyUserTaxRecordModal(true)
							setUserPID(null)
							setTaxRecordUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserTaxRecord(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserTaxRecord, successVerifyUserTaxRecord, errorVerifyUserTaxRecord, removeVerifyUserTaxRecordModal, setRemoveVerifyUserTaxRecordModal, handleVerifyUserTaxRecord,
		userPID, setUserPID, taxRecordUniqueId, setTaxRecordUniqueId
	}
}

function useDeclineUserTaxRecord() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineTaxRecord, setLoadingDeclineTaxRecord] = useState(false)
	const [removeDeclineTaxRecordModal, setRemoveDeclineTaxRecordModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [taxRecordUniqueId, setTaxRecordUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclineTaxRecord, setErrorDeclineTaxRecord] = useState(null)
	const [successDeclineTaxRecord, setSuccessDeclineTaxRecord] = useState(null)

	const handleDeclineTaxRecord = () => {
		if (!loadingDeclineTaxRecord) {
			if (!userPID || !taxRecordUniqueId) {
				setErrorDeclineTaxRecord(null)
				setSuccessDeclineTaxRecord(null)
				setErrorDeclineTaxRecord(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclineTaxRecord(null)
				}, 2500)
			} else {
				setLoadingDeclineTaxRecord(true)

				const declineUserTaxRecordRes = declineUserTaxRecord(cookie, { pid: userPID, unique_id: taxRecordUniqueId, message: declineMsg })

				declineUserTaxRecordRes.then((res) => {
					setLoadingDeclineTaxRecord(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineTaxRecord(error)
							setTimeout(() => {
								setErrorDeclineTaxRecord(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineTaxRecord(error)
							setTimeout(() => {
								setErrorDeclineTaxRecord(null)
							}, 2500)
						}
					} else {
						setErrorDeclineTaxRecord(null)
						setDeclineMsg("")
						setSuccessDeclineTaxRecord("User tax record declined")

						setTimeout(() => {
							setSuccessDeclineTaxRecord(null)
							setRemoveDeclineTaxRecordModal(true)
							setUserPID(null)
							setTaxRecordUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclineTaxRecord, successDeclineTaxRecord, loadingDeclineTaxRecord,
		userPID, setUserPID, taxRecordUniqueId, setTaxRecordUniqueId, declineMsg, setDeclineMsg, handleDeclineTaxRecord,
		removeDeclineTaxRecordModal, setRemoveDeclineTaxRecordModal
	}
}

export { useVerifyUserTaxRecord, useDeclineUserTaxRecord }