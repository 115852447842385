import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserAdditionalQualification, verifyUserAdditionalQualification } from "../api/additionalQualifications";
import { config } from "../config";

function useVerifyUserAdditionalQualification() {
    const { cookie } = useCookie(config.key, "");

    const [loadingVerifyUserAdditionalQualification, setLoadingVerifyUserAdditionalQualification] = useState(false)
    const [removeVerifyUserAdditionalQualificationModal, setRemoveVerifyUserAdditionalQualificationModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [additionalQualificationUniqueId, setAdditionalQualificationUniqueId] = useState(null)

    const [errorVerifyUserAdditionalQualification, setErrorVerifyUserAdditionalQualification] = useState(null)
    const [successVerifyUserAdditionalQualification, setSuccessVerifyUserAdditionalQualification] = useState(null)

    const handleVerifyUserAdditionalQualification = () => {
        if (!loadingVerifyUserAdditionalQualification) {
            if (!userPID || !additionalQualificationUniqueId) {
                setErrorVerifyUserAdditionalQualification(null)
                setSuccessVerifyUserAdditionalQualification(null)
                setErrorVerifyUserAdditionalQualification(userPID ? "Unique id is required" : "User PID is required")
                setTimeout(function () {
                    setErrorVerifyUserAdditionalQualification(null)
                }, 2500)
            } else {
                setLoadingVerifyUserAdditionalQualification(true)

                const verifyUserAdditionalQualificationRes = verifyUserAdditionalQualification(cookie, { pid: userPID, unique_id: additionalQualificationUniqueId })

                verifyUserAdditionalQualificationRes.then((res) => {

                    setLoadingVerifyUserAdditionalQualification(false)
                    if (res.error) {
                        if (!res.error.response.data.success) {
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorVerifyUserAdditionalQualification(error)
                            setTimeout(() => {
                                setErrorVerifyUserAdditionalQualification(null)
                            }, 2000)
                        } else {
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorVerifyUserAdditionalQualification(error)
                            setTimeout(() => {
                                setErrorVerifyUserAdditionalQualification(null)
                            }, 2000)
                        }
                    } else {
                        setErrorVerifyUserAdditionalQualification(null)
                        setSuccessVerifyUserAdditionalQualification("User additional qualification verified")

                        setTimeout(() => {
                            setSuccessVerifyUserAdditionalQualification(null)
                            setRemoveVerifyUserAdditionalQualificationModal(true)
                            setUserPID(null)
                            setAdditionalQualificationUniqueId(null)
                        }, 2500)
                    }
                }).catch(err => {
                    setLoadingVerifyUserAdditionalQualification(false)
                })
            }

        }
    }

    return {
        cookie, loadingVerifyUserAdditionalQualification, successVerifyUserAdditionalQualification, errorVerifyUserAdditionalQualification, removeVerifyUserAdditionalQualificationModal, setRemoveVerifyUserAdditionalQualificationModal, handleVerifyUserAdditionalQualification,
        userPID, setUserPID, additionalQualificationUniqueId, setAdditionalQualificationUniqueId
    }
}

function useDeclineUserAdditionalQualification() {
    const { cookie } = useCookie(config.key, "");

    const [loadingDeclineAdditionalQualification, setLoadingDeclineAdditionalQualification] = useState(false)
    const [removeDeclineAdditionalQualificationModal, setRemoveDeclineAdditionalQualificationModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [additionalQualificationUniqueId, setAdditionalQualificationUniqueId] = useState(null)
    const [declineMsg, setDeclineMsg] = useState(null)

    const [errorDeclineAdditionalQualification, setErrorDeclineAdditionalQualification] = useState(null)
    const [successDeclineAdditionalQualification, setSuccessDeclineAdditionalQualification] = useState(null)

    const handleDeclineAdditionalQualification = () => {
        if (!loadingDeclineAdditionalQualification) {
            if (!userPID || !additionalQualificationUniqueId) {
                setErrorDeclineAdditionalQualification(null)
                setSuccessDeclineAdditionalQualification(null)
                setErrorDeclineAdditionalQualification(userPID ? "Unique ID is required" : "User PID is required")
                setTimeout(() => {
                    setErrorDeclineAdditionalQualification(null)
                }, 2500)
            } else {
                setLoadingDeclineAdditionalQualification(true)

                const declineUserAdditionalQualificationRes = declineUserAdditionalQualification(cookie, { pid: userPID, unique_id: additionalQualificationUniqueId, message: declineMsg })

                declineUserAdditionalQualificationRes.then((res) => {
                    setLoadingDeclineAdditionalQualification(false)

                    if (res.error) {
                        if (!res.error.response.data.success) {
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorDeclineAdditionalQualification(error)
                            setTimeout(() => {
                                setErrorDeclineAdditionalQualification(null)
                            }, 2500)
                        } else {
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorDeclineAdditionalQualification(error)
                            setTimeout(() => {
                                setErrorDeclineAdditionalQualification(null)
                            }, 2500)
                        }
                    } else {
                        setErrorDeclineAdditionalQualification(null)
                        setDeclineMsg("")
                        setSuccessDeclineAdditionalQualification("User additional qualification declined")

                        setTimeout(() => {
                            setSuccessDeclineAdditionalQualification(null)
                            setRemoveDeclineAdditionalQualificationModal(true)
                            setUserPID(null)
                            setAdditionalQualificationUniqueId(null)
                        }, 2500)
                    }
                })
            }
        }
    }

    return {
        errorDeclineAdditionalQualification, successDeclineAdditionalQualification, loadingDeclineAdditionalQualification,
        userPID, setUserPID, additionalQualificationUniqueId, setAdditionalQualificationUniqueId, declineMsg, setDeclineMsg, handleDeclineAdditionalQualification,
        removeDeclineAdditionalQualificationModal, setRemoveDeclineAdditionalQualificationModal
    }
}

export { useVerifyUserAdditionalQualification, useDeclineUserAdditionalQualification }