import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addMailingTemplate, deleteMailingTemplate, editMailingTemplateDetails, editMailingTemplateName, editMailingTemplateSubject } from "../api/mailing";

const useAddMailingTemplate = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingAddMailingTemplate, setLoadingAddMailingTemplate] = useState(false);
	const [removeAddMailingTemplateModal, setRemoveAddMailingTemplateModal] = useState(null);
	const [name, setName] = useState(null);
	const [subject, setSubject] = useState(null);
	const [html, setHtml] = useState("");

	const [errorAddMailingTemplate, setErrorAddMailingTemplate] = useState(null);
	const [successAddMailingTemplate, setSuccessAddMailingTemplate] = useState(null);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };
	const handleSubject = (e) => { e.preventDefault(); setSubject(e.target.value); };
	const handleHtml = (e) => { e.preventDefault(); setHtml(e.target.value); };

	const handleAddMailingTemplate = (e) => {
		e.preventDefault();

		if (!loadingAddMailingTemplate) {
			if (!name) {
				setErrorAddMailingTemplate(null);
				setSuccessAddMailingTemplate(null);
				setErrorAddMailingTemplate("Name is required");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else if (name.length < 1 || name.length > 50) {
				setErrorAddMailingTemplate("Name character range 1 - 50");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else if (!subject) {
				setErrorAddMailingTemplate("Subject is required");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else if (subject < 1 || subject.length > 50) {
				setErrorAddMailingTemplate("Subject character range 1 - 50");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else if (!html) {
				setErrorAddMailingTemplate("Html is required");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else if (html.length < 1) {
				setErrorAddMailingTemplate("Html is required");
				setTimeout(function () {
					setErrorAddMailingTemplate(null);
				}, 2500)
			} else {
				setLoadingAddMailingTemplate(true);

				const addMailingTemplateRes = addMailingTemplate(cookie, {
					name: name,
					subject: subject,
					html: html
				})

				addMailingTemplateRes.then(res => {
					setLoadingAddMailingTemplate(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddMailingTemplate(error);
							setTimeout(function () {
								setErrorAddMailingTemplate(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddMailingTemplate(error);
							setTimeout(function () {
								setErrorAddMailingTemplate(null);
							}, 2000)
						}
					} else {
						setErrorAddMailingTemplate(null);
						setSuccessAddMailingTemplate(`Mailing template added successfully!`);

						setTimeout(function () {
							setSuccessAddMailingTemplate(null);
							setRemoveAddMailingTemplateModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingAddMailingTemplate(false);
				})

			}
		}
	};

	return {
		cookie, name, subject, html, loadingAddMailingTemplate, setRemoveAddMailingTemplateModal, errorAddMailingTemplate, successAddMailingTemplate,
		setSubject, setHtml, handleAddMailingTemplate, handleName, handleSubject, handleHtml, setName, removeAddMailingTemplateModal
	};
};

const useEditMailingTemplateName = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingEditMailingTemplateName, setLoadingEditMailingTemplateName] = useState(false);
	const [removeEditMailingTemplateNameModal, setRemoveEditMailingTemplateNameModal] = useState(null);
	const [name, setName] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorEditMailingTemplateName, setErrorEditMailingTemplateName] = useState(null);
	const [successEditMailingTemplateName, setSuccessEditMailingTemplateName] = useState(null);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };

	const handleEditMailingTemplateName = (e) => {
		e.preventDefault();

		if (!loadingEditMailingTemplateName) {
			if (!uniqueId) {
				setErrorEditMailingTemplateName(null);
				setSuccessEditMailingTemplateName(null);
				setErrorEditMailingTemplateName("Unique ID is required");
				setTimeout(function () {
					setErrorEditMailingTemplateName(null);
				}, 2500)
			} else if (!name) {
				setErrorEditMailingTemplateName("Name is required");
				setTimeout(function () {
					setErrorEditMailingTemplateName(null);
				}, 2500)
			} else if (name.length < 1 || name.length > 50) {
				setErrorEditMailingTemplateName("Name character range 1 - 50");
				setTimeout(function () {
					setErrorEditMailingTemplateName(null);
				}, 2500)
			} else {
				setLoadingEditMailingTemplateName(true);

				const editMailingTemplateNameRes = editMailingTemplateName(cookie, {
					stripped: uniqueId,
					name: name
				})

				editMailingTemplateNameRes.then(res => {
					setLoadingEditMailingTemplateName(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditMailingTemplateName(error);
							setTimeout(function () {
								setErrorEditMailingTemplateName(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditMailingTemplateName(error);
							setTimeout(function () {
								setErrorEditMailingTemplateName(null);
							}, 2000)
						}
					} else {
						setErrorEditMailingTemplateName(null);
						setSuccessEditMailingTemplateName(`Mailing template name edited successfully!`);

						setTimeout(function () {
							setSuccessEditMailingTemplateName(null);
							setRemoveEditMailingTemplateNameModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditMailingTemplateName(false);
				})

			}
		}
	};

	return {
		cookie, name, loadingEditMailingTemplateName, setRemoveEditMailingTemplateNameModal, errorEditMailingTemplateName, successEditMailingTemplateName,
		handleEditMailingTemplateName, handleName, setName, removeEditMailingTemplateNameModal, setUniqueId
	};
};

const useEditMailingTemplateSubject = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingEditMailingTemplateSubject, setLoadingEditMailingTemplateSubject] = useState(false);
	const [removeEditMailingTemplateSubjectModal, setRemoveEditMailingTemplateSubjectModal] = useState(null);
	const [subject, setSubject] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorEditMailingTemplateSubject, setErrorEditMailingTemplateSubject] = useState(null);
	const [successEditMailingTemplateSubject, setSuccessEditMailingTemplateSubject] = useState(null);

	const handleSubject = (e) => { e.preventDefault(); setSubject(e.target.value); };

	const handleEditMailingTemplateSubject = (e) => {
		e.preventDefault();

		if (!loadingEditMailingTemplateSubject) {
			if (!uniqueId) {
				setErrorEditMailingTemplateSubject(null);
				setSuccessEditMailingTemplateSubject(null);
				setErrorEditMailingTemplateSubject("Unique ID is required");
				setTimeout(function () {
					setErrorEditMailingTemplateSubject(null);
				}, 2500)
			} else if (!subject) {
				setErrorEditMailingTemplateSubject("Subject is required");
				setTimeout(function () {
					setErrorEditMailingTemplateSubject(null);
				}, 2500)
			} else if (subject < 1 || subject.length > 50) {
				setErrorEditMailingTemplateSubject("Subject character range 1 - 50");
				setTimeout(function () {
					setErrorEditMailingTemplateSubject(null);
				}, 2500)
			} else {
				setLoadingEditMailingTemplateSubject(true);

				const editMailingTemplateSubjectRes = editMailingTemplateSubject(cookie, {
					stripped: uniqueId,
					subject: subject
				})

				editMailingTemplateSubjectRes.then(res => {
					setLoadingEditMailingTemplateSubject(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditMailingTemplateSubject(error);
							setTimeout(function () {
								setErrorEditMailingTemplateSubject(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditMailingTemplateSubject(error);
							setTimeout(function () {
								setErrorEditMailingTemplateSubject(null);
							}, 2000)
						}
					} else {
						setErrorEditMailingTemplateSubject(null);
						setSuccessEditMailingTemplateSubject(`Mailing template subject edited successfully!`);

						setTimeout(function () {
							setSuccessEditMailingTemplateSubject(null);
							setRemoveEditMailingTemplateSubjectModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditMailingTemplateSubject(false);
				})

			}
		}
	};

	return {
		cookie, subject, loadingEditMailingTemplateSubject, setRemoveEditMailingTemplateSubjectModal, errorEditMailingTemplateSubject, successEditMailingTemplateSubject,
		setSubject, handleEditMailingTemplateSubject, handleSubject, removeEditMailingTemplateSubjectModal, setUniqueId
	};
};

const useEditMailingTemplateDetails = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingEditMailingTemplateDetails, setLoadingEditMailingTemplateDetails] = useState(false);
	const [removeEditMailingTemplateDetailsModal, setRemoveEditMailingTemplateDetailsModal] = useState(null);
	const [html, setHtml] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorEditMailingTemplateDetails, setErrorEditMailingTemplateDetails] = useState(null);
	const [successEditMailingTemplateDetails, setSuccessEditMailingTemplateDetails] = useState(null);

	const handleHtml = (e) => { e.preventDefault(); setHtml(e.target.value); };

	const handleEditMailingTemplateDetails = (e) => {
		e.preventDefault();

		if (!loadingEditMailingTemplateDetails) {
			if (!uniqueId) {
				setErrorEditMailingTemplateDetails(null);
				setSuccessEditMailingTemplateDetails(null);
				setErrorEditMailingTemplateDetails("Unique ID is required");
				setTimeout(function () {
					setErrorEditMailingTemplateDetails(null);
				}, 2500)
			} else if (!html) {
				setErrorEditMailingTemplateDetails("Html is required");
				setTimeout(function () {
					setErrorEditMailingTemplateDetails(null);
				}, 2500)
			} else if (html.length < 1) {
				setErrorEditMailingTemplateDetails("Html is required");
				setTimeout(function () {
					setErrorEditMailingTemplateDetails(null);
				}, 2500)
			} else {
				setLoadingEditMailingTemplateDetails(true);

				const editMailingTemplateDetailsRes = editMailingTemplateDetails(cookie, {
					stripped: uniqueId,
					html: html
				})

				editMailingTemplateDetailsRes.then(res => {
					setLoadingEditMailingTemplateDetails(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditMailingTemplateDetails(error);
							setTimeout(function () {
								setErrorEditMailingTemplateDetails(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditMailingTemplateDetails(error);
							setTimeout(function () {
								setErrorEditMailingTemplateDetails(null);
							}, 2000)
						}
					} else {
						setErrorEditMailingTemplateDetails(null);
						setSuccessEditMailingTemplateDetails(`Mailing template details edited successfully!`);

						setTimeout(function () {
							setSuccessEditMailingTemplateDetails(null);
							setRemoveEditMailingTemplateDetailsModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditMailingTemplateDetails(false);
				})

			}
		}
	};

	return {
		cookie, html, loadingEditMailingTemplateDetails, setRemoveEditMailingTemplateDetailsModal, errorEditMailingTemplateDetails, successEditMailingTemplateDetails,
		setHtml, handleEditMailingTemplateDetails, handleHtml, removeEditMailingTemplateDetailsModal, setUniqueId
	};
};

const useDeleteMailingTemplate = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteMailingTemplate, setLoadingDeleteMailingTemplate] = useState(false);
	const [removeDeleteMailingTemplateModal, setRemoveDeleteMailingTemplateModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDeleteMailingTemplate, setErrorDeleteMailingTemplate] = useState(null);
	const [successDeleteMailingTemplate, setSuccessDeleteMailingTemplate] = useState(null);

	const handleDeleteMailingTemplate = () => {

		if (!loadingDeleteMailingTemplate) {
			if (!uniqueId) {
				setErrorDeleteMailingTemplate(null);
				setSuccessDeleteMailingTemplate(null);
				setErrorDeleteMailingTemplate("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteMailingTemplate(null);
				}, 2500)
			} else {
				setLoadingDeleteMailingTemplate(true);

				const deleteMailingTemplateRes = deleteMailingTemplate(cookie, {
					stripped: uniqueId
				})

				deleteMailingTemplateRes.then(res => {
					setLoadingDeleteMailingTemplate(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteMailingTemplate(error);
							setTimeout(function () {
								setErrorDeleteMailingTemplate(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteMailingTemplate(error);
							setTimeout(function () {
								setErrorDeleteMailingTemplate(null);
							}, 2000)
						}
					} else {
						setErrorDeleteMailingTemplate(null);
						setSuccessDeleteMailingTemplate(`Mailing template deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteMailingTemplate(null);
							setRemoveDeleteMailingTemplateModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteMailingTemplate(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteMailingTemplate, removeDeleteMailingTemplateModal, errorDeleteMailingTemplate, successDeleteMailingTemplate, handleDeleteMailingTemplate,
		setRemoveDeleteMailingTemplateModal, setUniqueId
	};
};

export { useAddMailingTemplate, useEditMailingTemplateName, useEditMailingTemplateSubject, useEditMailingTemplateDetails, useDeleteMailingTemplate };