import axios from 'axios'
import { config } from '../config'

async function getTinstitutions(key, page, size){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institutions`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTinstitution(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institution`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserTinstitution(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/tertiary/institution/verify`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserTinstitution(key, payload){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/tertiary/institution/decline`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTinstitutionViaPID(key, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institutions/via/user`,
			{
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTinstitutionsViaVerifiedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institutions/via/verification`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTinstitutionsViaType(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institutions/via/type`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getTinstitutionsViaDegree(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/tertiary/institutions/via/degree`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getTinstitutions, getTinstitution, verifyUserTinstitution, declineUserTinstitution, getTinstitutionsViaDegree, 
	getTinstitutionViaPID, getTinstitutionsViaVerifiedStatus, getTinstitutionsViaType
}