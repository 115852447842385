import axios from 'axios'
import { config } from '../config'

async function getCACs(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/cac/all`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getCACsViaUser(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/cac/all/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getCAC(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/cac`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserCAC(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/cac/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserCAC(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/government/cac/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getCACsViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/government/cac/all/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getCACs, getCACsViaUser, verifyUserCAC, declineUserCAC, getCAC, getCACsViaVerifiedStatus,
}