import axios from 'axios'
import { config } from '../config'

async function getNins(key, page, size){
    try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/nin/all`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getNinViaVerifiedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/nin/all/via/verification`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

async function getNinViaDeclinedStatus(key, page, size, payload){
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/nin/all/via/declination`,
			{
				page,
				size,
                ...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserNIN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/bio/nin/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserNINLogs = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/nin/verification/logs`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserNINLogsViaEmail = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/nin/verification/logs/via/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserNINLogsViaPhone = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/nin/verification/logs/via/phone`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserNINLogsViaVNIN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/nin/verification/logs/via/vnin`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const createVerificationLog = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/nin/create/verification/log`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const verifyUserNIN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/nin/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const declineUserNIN = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/bio/nin/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export {
	getNins, getNinViaVerifiedStatus, getUserNIN, verifyUserNIN, declineUserNIN, getNinViaDeclinedStatus, getUserNINLogs, 
	getUserNINLogsViaEmail, getUserNINLogsViaPhone, getUserNINLogsViaVNIN, createVerificationLog
}