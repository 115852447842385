import axios from 'axios'
import { config } from '../config'

async function getAdditionalQualifications(key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/additional/qualifications`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAdditionalQualificationsViaUser(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/additional/qualifications/via/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAdditionalQualification(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/additional/qualification`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function verifyUserAdditionalQualification(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/additional/qualification/verify`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function declineUserAdditionalQualification(key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/credentials/additional/qualification/decline`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

async function getAdditionalQualificationsViaVerifiedStatus(key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/credentials/additional/qualifications/via/verification`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
}

export {
	getAdditionalQualifications, getAdditionalQualificationsViaUser, verifyUserAdditionalQualification, declineUserAdditionalQualification, getAdditionalQualification, getAdditionalQualificationsViaVerifiedStatus,
}