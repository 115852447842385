import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import Search from "../icons/Search";
import Close from "../icons/Close";
import Loading from "../icons/Loading";
import { getBvnViaVerifiedStatus, getBvns, getUserBVN, getBvnViaDeclinedStatus, getUserBVNLogs } from "../api/bvn";
import { useDeclineBVN, useVerifyBVN, useCreateVerificationLog, useCreateVirtualAccount } from "../hooks/useBVN";
import { config } from "../config";
import useCookie from "../hooks/useCookie";
import Copy from "../icons/Copy";
import Check from "../icons/Check";
import Star from "../icons/Star";
import Plus from "../icons/Plus";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import KeyAlt from "../icons/KeyAlt";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import Filter from "../icons/Filter";

export default function BVN() {
    const { cookie, forceLogout } = useCookie(config.key, "");
    const [copiedText, setCopiedText] = useState(false);
    const [textCopied, setTextCopied] = useState(null);

    const {
        errorVerifyBVN, handleVerifyBVN, loadingVerifyBVN, removeVerifyBVNModal, setRemoveVerifyBVNModal,
        setUniqueId: VerifyBvnUniqueID, successVerifyBVN, setUserPID: VerifyBvnPID, handleNote, note, setNote
    } = useVerifyBVN();

    const {
        errorDeclineBVN, handleDeclineBVN, loadingDeclineBVN, removeDeclineBVNModal, setRemoveDeclineBVNModal,
        setUniqueId: DeclineBvnUniqueID, successDeclineBVN, handleMessage, message, setMessage, setUserPID: DeclineBvnPID
    } = useDeclineBVN();

    const {
        errorCreateVirtualAccount, handleCreateVirtualAccount, loadingCreateVirtualAccount, removeCreateVirtualAccountModal, 
        setRemoveCreateVirtualAccountModal, setUserPID: VirtualAccountUserPID, successCreateVirtualAccount
    } = useCreateVirtualAccount();
    
    const {
        errorCreateVerificationLog, handleCreateVerificationLog, loadingCreateVerificationLog, removeCreateVerificationLogModal, 
        setRemoveCreateVerificationLogModal, setBVN: VerificationLogUserBVN, successCreateVerificationLog
    } = useCreateVerificationLog();

    const [currentFunction, setCurrentFunction] = useState("getAllBvns");

    const [allUserBvnLogs, setAllUserBvnLogs] = useState(null);
    const [errorAllUserBvnLogs, setErrorAllUserBvnLogs] = useState(null);
    const [loadingAllUserBvnLogs, setLoadingAllUserBvnLogs] = useState(false);

    const [allBvns, setAllBvns] = useState(null);
    const [errorAllBvns, setErrorAllBvns] = useState(null);
    const [loadingAllBvns, setLoadingAllBvns] = useState(false);

    const [bvnVerifiedStatus, setBvnVerifiedStatus] = useState(null);
    const [bvnDeclinedStatus, setBvnDeclinedStatus] = useState(null);

    const [size, setSize] = useState(20);
    const [page, setPage] = useState(1);

    const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
    const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
    const handleBvnVerifiedStatus = (e) => { e.preventDefault(); setBvnVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllBvnsByVerification"); getAllBvnsByVerification(e.target.value === 'verified' ? true : false, page, size); };
    const handleBvnDeclinedStatus = (e) => { e.preventDefault(); setBvnDeclinedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllBvnByDeclination"); getAllBvnByDeclination(e.target.value === 'declined' ? true : false, page, size); };
    
    async function recordsBySize(size) {
        switch (currentFunction) {
            case "getAllBvns":
                getAllBvns(page, size);
                break;
            case "getAllBvnsByVerification":
                getAllBvnsByVerification(bvnVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllBvnByDeclination":
                getAllBvnByDeclination(bvnDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            default:
                getAllBvns(page, size);
        }
    };

    async function recordsByPage(page) {
        switch (currentFunction) {
            case "getAllBvns":
                getAllBvns(page, size);
                break;
            case "getAllBvnsByVerification":
                getAllBvnsByVerification(bvnVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllBvnByDeclination":
                getAllBvnByDeclination(bvnDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            default:
                getAllBvns(page, size);
        }
    };

    async function callLastFunction() {
        switch (currentFunction) {
            case "getAllBvns":
                getAllBvns(page, size);
                break;
            case "getAllBvnsByVerification":
                getAllBvnsByVerification(bvnVerifiedStatus === 'verified' ? true : false, page, size);
                break;
            case "getAllBvnByDeclination":
                getAllBvnByDeclination(bvnDeclinedStatus === 'declined' ? true : false, page, size);
                break;
            // default:
            // 	getAllBvns(page, size);
        }
    };

    async function previousBvns() {
        if (page !== 1) setPage(page - 1);
        if (page !== 1) {
            switch (currentFunction) {
                case "getAllBvns":
                    getAllBvns(page - 1, size);
                    break;
                case "getAllBvnsByVerification":
                    getAllBvnsByVerification(bvnVerifiedStatus === 'verified' ? true : false, page - 1, size);
                    break;
                case "getAllBvnByDeclination":
                    getAllBvnByDeclination(bvnDeclinedStatus === 'declined' ? true : false, page - 1, size);
                    break;
                default:
                    getAllBvns(page - 1, size);
            }
        }
    };

    async function nextBvns() {
        if (page < allBvns.data.pages) setPage(page + 1);
        if (page < allBvns.data.pages) {
            switch (currentFunction) {
                case "getAllBvns":
                    getAllBvns(page + 1, size);
                    break;
                case "getAllBvnsByVerification":
                    getAllBvnsByVerification(bvnVerifiedStatus === 'verified' ? true : false, page + 1, size);
                    break;
                case "getAllBvnByDeclination":
                    getAllBvnByDeclination(bvnDeclinedStatus === 'declined' ? true : false, page + 1, size);
                    break;
                default:
                    getAllBvns(page + 1, size);
            }
        }
    };

    const [loadingViewUser, setLoadingViewUser] = useState(false);
    const [errorViewUser, setErrorViewUser] = useState(null);
    const [viewUserDetails, setViewUserDetails] = useState(null);

    async function getAllBvns(_page, _size) {
        setLoadingAllBvns(true);
        const response = await getBvns(cookie, (_page || page), (_size || size))
        setAllBvns(response.data)
        if (response.error) setErrorAllBvns(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
        setLoadingAllBvns(false);
    }

    async function getAllBvnsByVerification(verified, _page, _size) {
        setLoadingAllBvns(true)
        const response = await getBvnViaVerifiedStatus(cookie, (_page || page), (_size || size), { verified: verified })
        setAllBvns(response.data)
        if (response.error) setErrorAllBvns(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
        setLoadingAllBvns(false)
    }

    async function getAllBvnByDeclination(declined, _page, _size) {
        setLoadingAllBvns(true)
        const response = await getBvnViaDeclinedStatus(cookie, (_page || page), (_size || size), { declined: declined })
        setAllBvns(response.data)
        if (response.error) setErrorAllBvns(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
        setLoadingAllBvns(false)
    };

    async function getUserBVNViaPID(pid) {
        const modalResponse = document.querySelector("#userSearchModal");
        modalResponse.setAttribute("display", false);
        setLoadingViewUser(true);
        const response = await getUserBVN(cookie, { pid });
        if (!response.err) { 
            setViewUserDetails(response.data); 
            setLoadingAllUserBvnLogs(true);
            const response_logs = await getUserBVNLogs(cookie, { bvn: response.data.data.data.bvn });
            if (!response_logs.err) {
                setAllUserBvnLogs(response_logs.data);
            } else {
                setAllUserBvnLogs(null); 
                setErrorAllUserBvnLogs(response_logs.response_code !== 422 ? response_logs.error.response.data.message : response_logs.error.response.data.data[0].msg)
            }
            setLoadingAllUserBvnLogs(false);
        }
        else { setViewUserDetails(null); setErrorViewUser(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
        setLoadingViewUser(false);
        setUserPIDSearch("");
    };

    const [userPIDSearch, setUserPIDSearch] = useState("");
    const handleUserPIDSearch = (e) => { e.preventDefault(); setUserPIDSearch(e.target.value.toUpperCase()); };


    // Implement later
    // const [recordSearch, setRecordSearch] = useState("");
    // const handleRecordSearch = (e) => { 
    //     e.preventDefault(); 
    //     setAllBvns({
    //         ...allBvns,
    //         rows: allBvns.data.rows.filter(item => {
    //             if (!e.target.value) return true
    //             if (item.bvn.includes(e.target.value) || item.pid.includes(e.target.value)) {
    //                 return true
    //             }
    //         })
    //     });
    //     setRecordSearch(e.target.value); 
    // };

    useEffect(() => {
        if (allBvns === null) {
            callLastFunction();
        }
    }, [allBvns])

    const copySomeText = (text) => {
        navigator.clipboard.writeText(text);
    };

    const copyText = (text) => {
        copySomeText(text);
        setCopiedText(true);
        setTimeout(function () {
            setCopiedText(false);
        }, 2000)
    };

    if (removeVerifyBVNModal) {
        const modalResponse = document.querySelector("#verifyBVNModal");
        modalResponse.setAttribute("display", false);
        getUserBVNViaPID(viewUserDetails.data.data.pid);
        setRemoveVerifyBVNModal(null);
    }

    if (removeDeclineBVNModal) {
        const modalResponse = document.querySelector("#declineBVNModal");
        modalResponse.setAttribute("display", false);
        getUserBVNViaPID(viewUserDetails.data.data.pid);
        setRemoveDeclineBVNModal(null);
    }
    
    if (removeCreateVirtualAccountModal) {
        const modalResponse = document.querySelector("#createVirtualAccountModal");
        modalResponse.setAttribute("display", false);
        getUserBVNViaPID(viewUserDetails.data.data.pid);
        setRemoveCreateVirtualAccountModal(null);
    }
    
    if (removeCreateVerificationLogModal) {
        const modalResponse = document.querySelector("#createVerificationLogModal");
        modalResponse.setAttribute("display", false);
        getUserBVNViaPID(viewUserDetails.data.data.pid);
        setRemoveCreateVerificationLogModal(null);
    }

    const pageSelectArray = new Array(allBvns ? allBvns.data.pages : 0).fill(0);

    return (
        <>
            <Screen>
                <Content>
                    <Navbar placeholder="Search something..." makeHidden={true} />
                    <section className='xui-mt-2'>
                        <div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
                            <div className="xui-mb-1">
                                <h1 className='xui-font-sz-110 xui-font-w-normal'>All User BVN</h1>
                                <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allBvns && allBvns.success ? allBvns.data.total : "..."}</p>
                            </div>
                            <div className="xui-mb-1">
                                <div className='xui-d-inline-flex'>
                                    <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userSearchModal">
                                        <span className="xui-mr-half">Search</span>
                                        <Search width="15" height="15" />
                                    </button>
                                </div>
                            </div>
                            <div className="xui-mb-1">
                                <div className='xui-d-inline-flex'>
                                    <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
                                        <Filter width="16" height="16" />
                                        <select value={bvnVerifiedStatus} onChange={handleBvnVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
                                            {
                                                bvnVerifiedStatus === null ?
                                                    <option selected disabled>Filter By verification Status</option> :
                                                    <option value={null}>Select to Reset</option>
                                            }
                                            <option value={"verified"}>Verified</option>
                                            <option value={"unverified"}>Unverified</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="xui-mb-1">
                                <div className='xui-d-inline-flex'>
                                    <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
                                        <Filter width="16" height="16" />
                                        <select value={bvnDeclinedStatus} onChange={handleBvnDeclinedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
                                            {
                                                bvnDeclinedStatus === null ?
                                                    <option selected disabled>Filter By declination Status</option> :
                                                    <option value={null}>Select to Reset</option>
                                            }
                                            <option value={"declined"}>Declined</option>
                                            <option value={"undeclined"}>Undeclined</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Implement later */}
                        {/* <div className="xui-form xui-mt-2 xui-mb-2">
                            <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                                <label>Filter records</label>
                                <input type={"text"} placeholder={"Enter value"} value={recordSearch} onChange={handleRecordSearch} />
                            </div>
                        </div> */}
                        {
                            loadingAllBvns
                                ?
                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
                                :
                                (
                                    allBvns && allBvns.success
                                        ?
                                        <div className='xui-table-responsive'>
                                            <table className='xui-table xui-font-sz-90'>
                                                <thead>
                                                    <tr className='xui-text-left xui-opacity-6'>
                                                        <th className='xui-w-30'>S/N</th>
                                                        <th className='xui-min-w-100'>PID</th>
                                                        <th className='xui-min-w-200'>BVN</th>
                                                        <th className='xui-min-w-200'>Verification</th>
                                                        <th className='xui-min-w-200'>Declined</th>
                                                        <th className='xui-min-w-300'>Declined Timestamp</th>
                                                        <th className='xui-min-w-200'>Status</th>
                                                        <th className='xui-min-w-300'>Created At</th>
                                                        <th className='xui-min-w-300'>Updated At</th>
                                                        <th className='xui-min-w-100'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allBvns.data.rows.map((data, i) => (
                                                            <tr className="" key={i}>
                                                                <td className='xui-opacity-5'>
                                                                    <span>{i + 1}</span>
                                                                </td>
                                                                <td className='xui-opacity-5 xui-font-w-bold'>
                                                                    <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                                        <span>#{data.pid}</span>
                                                                        <span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
                                                                            {copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                                        <span>{data.bvn}</span>
                                                                        <span title="Copy BVN" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.bvn); setTextCopied(data.bvn); }}>
                                                                            {copiedText && textCopied === data.bvn ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    {
                                                                        data.verified ?
                                                                            <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
                                                                            <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
                                                                    }
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    {
                                                                        data.declined === null ? 
                                                                            <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> : 
                                                                            (
                                                                                data.declined ?
                                                                                    <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
                                                                                    <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
                                                                            )

                                                                    }
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    {
                                                                        data.declined_timestamp === null ?
                                                                            <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                                            (
                                                                                data.declined_timestamp ?
                                                                                    <span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
                                                                                    "No timestamp"
                                                                            )
                                                                    }
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    {
                                                                        data.status === 1 ?
                                                                            <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
                                                                            <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
                                                                    }
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    <span>{data.createdAt.date} at {data.createdAt.time}</span>
                                                                </td>
                                                                <td className='xui-opacity-5'>
                                                                    <span>{data.updatedAt.date} at {data.updatedAt.time}</span>
                                                                </td>
                                                                <td className=''>
                                                                    <div className="xui-d-flex xui-grid-gap-1">
                                                                        <button title="View User BVN Details" onClick={() => { getUserBVNViaPID(data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserDetailsModal">
                                                                            <EyeOpenAlt width="16" height="16" />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                            <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                                <center className="xui-text-red">
                                                    <Close width="100" height="100" />
                                                    <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllBvns}</h3>
                                                </center>
                                            </div>
                                        </div>
                                )
                        }
                        {
                            loadingAllBvns ?
                                <Loading width="12" height="12" /> :
                                (
                                    allBvns && allBvns.success ?
                                        <div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
                                            <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                <span>Rows per page:</span>
                                                <select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={500}>500</option>
                                                    <option value={1000}>1000</option>
                                                </select>
                                            </div>
                                            <div className='xui-mx-1 xui-lg-mx-2'>
                                                <span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
                                                    {
                                                        pageSelectArray.map((value, index) => {
                                                            return (
                                                                <option value={index + 1}>{index + 1}</option>
                                                            )
                                                        })
                                                    }
                                                </select></span> of {allBvns ? allBvns.data.pages : "..."}</span>
                                            </div>
                                            <div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
                                                <div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousBvns}>
                                                    <Arrowleft width="18" height="18" />
                                                </div>
                                                <div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextBvns}>
                                                    <Arrowright width="18" height="18" />
                                                </div>
                                            </div>
                                        </div> :
                                        ""
                                )
                        }
                    </section>
                </Content>
            </Screen>
            <section className='xui-modal' xui-modal="viewUserDetailsModal" id="viewUserDetailsModal">
                <div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserDetailsModal">
                        <Close width="24" height="24" />
                    </div>
                    {
                        loadingViewUser ?
                            <center>
                                <Loading width="12" height="12" />
                            </center> : (
                                viewUserDetails && viewUserDetails.success ?
                                    <>
                                        <h1>{viewUserDetails.data.user_data.firstname + (viewUserDetails.data.user_data.middlename ? " " + viewUserDetails.data.user_data.middlename + " " : " ") + viewUserDetails.data.user_data.lastname}</h1>
                                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Details of this user below</p>
                                        <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
                                            <div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
                                                <img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserDetails.data.user_data.photo} alt={viewUserDetails.data.user_data.firstname + (viewUserDetails.data.user_data.middlename ? " " + viewUserDetails.data.user_data.middlename + " " : " ") + viewUserDetails.data.user_data.lastname + " Selfie Image"} />
                                                <center className="xui-mt-1">
                                                    <b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserDetails.data.data.pid}</b>
                                                    <center>
                                                        <div className="xui-d-inline-flex xui-flex-ai-center">
                                                            <span>
                                                                {
                                                                    viewUserDetails.data.user_data.star === 0 ?
                                                                        <div className='xui-m-half'>
                                                                            <p>No star</p>
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 1 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 2 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 3 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 4 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    viewUserDetails.data.user_data.star === 5 ?
                                                                        <div className='xui-m-half'>
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                            <Star width="18" height="18" />
                                                                        </div>
                                                                        : ""
                                                                }
                                                            </span>
                                                        </div>
                                                    </center>
                                                </center>
                                            </div>
                                            <div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">First Name -</span> {viewUserDetails.data.user_data.firstname}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Middle Name -</span> {viewUserDetails.data.user_data.middlename ? viewUserDetails.data.user_data.middlename : "No middle name"}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Last Name -</span> {viewUserDetails.data.user_data.lastname}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Email -</span> {<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{viewUserDetails.data.user_data.email}</span>}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Phone Number -</span> {<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{viewUserDetails.data.user_data.phone_number}</span>}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Gender -</span> {viewUserDetails.data.user_data.gender} | <span className="xui-font-w-bold">Date of Birth -</span> {new Date(viewUserDetails.data.user_data.dob).toDateString()} / {viewUserDetails.data.user_data.dob}</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">BVN - </span>
                                                    <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                        <span>{viewUserDetails.data.data.bvn}</span>
                                                        <span title="Copy BVN" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewUserDetails.data.data.bvn); setTextCopied(viewUserDetails.data.data.bvn); }}>
                                                            {copiedText && textCopied === viewUserDetails.data.data.bvn ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                        </span>
                                                    </div>
                                                </p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
                                                    viewUserDetails.data.data.verified ?
                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
                                                    viewUserDetails.data.data.declined === null ?
                                                        <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                        (
                                                            viewUserDetails.data.data.declined ?
                                                                <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
                                                                <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
                                                        )

                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
                                                    viewUserDetails.data.data.declined_timestamp === null ?
                                                        <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
                                                        (
                                                            viewUserDetails.data.data.declined_timestamp ?
                                                                <span>{viewUserDetails.data.data.declined_timestamp.date} at {viewUserDetails.data.data.declined_timestamp.time}</span> :
                                                                "No timestamp"
                                                        )
                                                }</p>
                                                <p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
                                                    viewUserDetails.data.data.status === 1 ?
                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
                                                }</p>
                                            </div>
                                        </div>
                                        <center>
                                            <p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserDetails.data.data.createdAt.fulldate} | Last Updated - {viewUserDetails.data.data.updatedAt.fulldate}</p>
                                            <div className="xui-m-2">
                                                <button title="Verify User BVN" onClick={() => { VerifyBvnUniqueID(viewUserDetails.data.data.unique_id); VerifyBvnPID(viewUserDetails.data.data.pid); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyBVNModal">
                                                    <Check width="16" height="16" />
                                                </button>
                                                <button title="Decline User BVN" onClick={() => { DeclineBvnUniqueID(viewUserDetails.data.data.unique_id); DeclineBvnPID(viewUserDetails.data.data.pid); }} className="xui-btn psc-btn-red xui-ml-3 xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineBVNModal">
                                                    <Close width="16" height="16" />
                                                </button>
                                            </div>
                                        </center>

                                        <h1>BVN Verification Logs</h1>
                                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half xui-mb-2">BVN details of this user below</p>
                                        {
                                            loadingAllUserBvnLogs
                                                ?
                                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
                                                :
                                                (
                                                    allUserBvnLogs && allUserBvnLogs.success
                                                        ?
                                                        <div className='xui-table-responsive'>
                                                            <table className='xui-table xui-font-sz-90'>
                                                                <thead>
                                                                    <tr className='xui-text-left xui-opacity-6'>
                                                                        <th className='xui-min-w-200'>First Name</th>
                                                                        <th className='xui-min-w-200'>Middle Name</th>
                                                                        <th className='xui-min-w-200'>Last Name</th>
                                                                        <th className='xui-min-w-200'>Email</th>
                                                                        <th className='xui-min-w-250'>Phone Number</th>
                                                                        <th className='xui-min-w-100'>Gender</th>
                                                                        <th className='xui-min-w-200'>Date Of Birth</th>
                                                                        <th className='xui-min-w-200'>Reference</th>
                                                                        <th className='xui-min-w-200'>Status</th>
                                                                        <th className='xui-min-w-200'>Endpoint</th>
                                                                        <th className='xui-min-w-300'>Created At</th>
                                                                        <th className='xui-min-w-300'>Updated At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        allUserBvnLogs.data.rows.map((data, i) => (
                                                                            <tr className="" key={i}>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.firstName}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.middleName ? data.bvn_data.middleName : <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>None</span>}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.lastName}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.email ? data.bvn_data.email : <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>None</span>}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.phoneNumber1} | {data.bvn_data.phoneNumber2}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.gender}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.bvn_data.dateOfBirth}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.verification.reference}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>{data.verification.status}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.verification.endpoint}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.createdAt.date} at {data.createdAt.time}</span>
                                                                                </td>
                                                                                <td className='xui-opacity-5'>
                                                                                    <span>{data.updatedAt.date} at {data.updatedAt.time}</span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                                            <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                                                <center className="xui-text-red">
                                                                    <Close width="100" height="100" />
                                                                    <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllUserBvnLogs}</h3>
                                                                </center>
                                                                <center>
                                                                    <button onClick={() => VerificationLogUserBVN(viewUserDetails.data.data.bvn)} className="xui-m-2 xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70" xui-modal-open="createVerificationLogModal">
                                                                        <span className="xui-mr-half">Create Verification Log</span>
                                                                        <Plus width="16" height="16" />
                                                                    </button>
                                                                </center>
                                                            </div>
                                                        </div>
                                                )
                                        }
                                    </> :
                                    <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                        <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                            <center className="xui-text-red">
                                                <Close width="100" height="100" />
                                                <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewUser}</h3>
                                            </center>
                                        </div>
                                    </div>
                            )
                    }
                </div>
            </section>
            <section className='xui-modal' xui-modal="verifyBVNModal" id="verifyBVNModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Verify User BVN</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <form className="xui-form xui-mt-2">
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Note</label>
                            <textarea type={"text"} maxLength={500} placeholder={"Drop a note (optional)"} value={note} onChange={handleNote}></textarea>
                        </div>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyBVN}</span></p>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyBVN}</span></p>
                    </form>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleVerifyBVN} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingVerifyBVN ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyBVN ? "" : "verifyBVNModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="declineBVNModal" id="declineBVNModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Decline User BVN</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <form className="xui-form xui-mt-2">
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Feedback</label>
                            <textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={message} onChange={handleMessage}></textarea>
                        </div>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineBVN}</span></p>
                        <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineBVN}</span></p>
                    </form>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleDeclineBVN} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingDeclineBVN ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineBVN ? "" : "declineBVNModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="createVerificationLogModal" id="createVerificationLogModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Create Verification Log</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCreateVerificationLog}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCreateVerificationLog}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCreateVerificationLog} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCreateVerificationLog ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCreateVerificationLog ? "" : "createVerificationLogModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="createVirtualAccountModal" id="createVirtualAccountModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Create Virtual Account</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCreateVirtualAccount}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCreateVirtualAccount}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCreateVirtualAccount} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCreateVirtualAccount ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCreateVirtualAccount ? "" : "createVirtualAccountModal"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="userSearchModal" id="userSearchModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userSearchModal">
                        <Close width="24" height="24" />
                    </div>
                    <h1>Search for User BVN</h1>
                    <form className="xui-form xui-mt-2" onSubmit={(e) => {
                        e.preventDefault();
                        getUserBVNViaPID(userPIDSearch);
                    }}>
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>PID</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                                <input style={{ width: "calc(100% - 50px)" }} type={"text"} maxLength={6} placeholder={"Enter Passcoder ID"} value={userPIDSearch} onChange={handleUserPIDSearch} />
                                <button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open={`${userPIDSearch ? "viewUserDetailsModal" : ""}`}>
                                    <Search width="16" height="16" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}