import { useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../firebase";
import useCookie from "./useCookie";
import { config } from "../config";
import {
	updateBusinessComplianceDetails, updateBusinessComplianceCertificate, updateBusinessComplianceDocument, updateBusinessComplianceDocuments, 
	grantBusinessAccess, suspendBusinessAccess, revokeBusinessAccess, verifyBusiness, unverifyBusiness, upgradeBusinessPremium, 
	getBusinessComplianceDocumentsProof
} from "../api/businesses";

const usePremiumUpgrade = () => {

	const { cookie } = useCookie(config.key, "");

	const [premiumUpgradeReference, setPremiumUpgradeReference] = useState(null);
	const [loadingPremiumPackage, setLoadingPremiumPackage] = useState(false);
	const [removePremiumPackageModal, setRemovePremiumPackageModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [premiumPackage, setPremiumPackage] = useState("");
	const [months, setMonths] = useState(1);
	const [paymentMethod, setPaymentMethod] = useState("Transfer");
	const [premiumAmount, setPremiumAmount] = useState(null);
	const [premiumPrice, setPremiumPrice] = useState(null);

	const [selectedPackage, setSelectedPackage] = useState(null);

	const [errorPremiumPackage, setErrorPremiumPackage] = useState(null);
	const [successPremiumPackage, setSuccessPremiumPackage] = useState(null);

	const handleMonths = (e) => {
		e.preventDefault();
		setMonths(e.target.value);
		setPremiumAmount(premiumPrice * parseInt(e.target.value));
	};
	const handleSelectedPackage = (premium_package, index) => {
		setSelectedPackage(index);
		setPremiumPackage(premium_package.name);
		setPremiumAmount(parseInt(premium_package.price) * months);
		setPremiumPrice(premium_package.price);
	};

	const handlePremiumPackage = () => {

		if (!loadingPremiumPackage) {
			if (!businessUniqueId) {
				setErrorPremiumPackage(null);
				setSuccessPremiumPackage(null);
				setErrorPremiumPackage("Business Unique ID is required");
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else if (!premiumPackage) {
				setErrorPremiumPackage("Package is required");
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else if (months < 1) {
				setErrorPremiumPackage(`Minimum of 1 month`);
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else if (months > 12) {
				setErrorPremiumPackage(`Minimum of 12 months`);
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else if (!paymentMethod) {
				setErrorPremiumPackage("Payment Method is Required");
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else if (!premiumAmount) {
				setErrorPremiumPackage("Unable to calculate total");
				setTimeout(function () {
					setErrorPremiumPackage(null);
				}, 2500)
			} else {
				setLoadingPremiumPackage(true);

				const upgradeBusinessPremiumRes = upgradeBusinessPremium(cookie, {
					business_unique_id: businessUniqueId,
					package: premiumPackage,
					months: parseInt(months),
					payment_method: paymentMethod
				})

				upgradeBusinessPremiumRes.then(res => {
					setLoadingPremiumPackage(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorPremiumPackage(error);
							setTimeout(function () {
								setErrorPremiumPackage(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorPremiumPackage(error);
							setTimeout(function () {
								setErrorPremiumPackage(null);
							}, 2000)
						}
					} else {
						setErrorPremiumPackage(null);
						setSuccessPremiumPackage(`Business upgraded to premium successfully!`);

						setTimeout(function () {
							setSuccessPremiumPackage(null);
							setRemovePremiumPackageModal(true);
							setBusinessUniqueId(null);
							setMonths(1);
							setSelectedPackage(null);
							setPremiumPackage("");
							setPremiumAmount(null);
							setPremiumPrice(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingPremiumPackage(false);
				})

			}
		}
	};

	return {
		cookie, loadingPremiumPackage, removePremiumPackageModal, errorPremiumPackage, successPremiumPackage, handlePremiumPackage,
		setRemovePremiumPackageModal, setBusinessUniqueId, premiumPackage, months, paymentMethod, handleMonths, setPremiumPrice, 
		selectedPackage, handleSelectedPackage, premiumAmount, premiumPrice
	};
};

const useUpdateComplianceDetails = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateComplianceDetails, setLoadingUpdateComplianceDetails] = useState(false);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [removeComplianceDetailsModal, setRemoveComplianceDetailsModal] = useState(null);
	const [companyName, setCompanyName] = useState(null);
	const [companyEmail, setCompanyEmail] = useState(null);
	const [companyRcNumber, setCompanyRcNumber] = useState(null);
	const [companyType, setCompanyType] = useState(null);
	const [companyAddress, setCompanyAddress] = useState(null);
	const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState(null);

	const [errorUpdateComplianceDetails, setErrorUpdateComplianceDetails] = useState(null);
	const [successUpdateComplianceDetails, setSuccessUpdateComplianceDetails] = useState(null);

	// validating values that need precision
	const validEmail = new RegExp(config.EMAIL_REGEX);
	const company_types = ["BN", "RC", "IT", "LL", "LLP"];

	const handleCompanyName = (e) => { e.preventDefault(); setCompanyName(e.target.value); };
	const handleCompanyEmail = (e) => { e.preventDefault(); setCompanyEmail(e.target.value); };
	const handleCompanyRcNumber = (e) => { e.preventDefault(); setCompanyRcNumber(e.target.value); };
	const handleCompanyType = (e) => { e.preventDefault(); setCompanyType(e.target.value); };
	const handleCompanyAddress = (e) => { e.preventDefault(); setCompanyAddress(e.target.value); };
	const handleCompanyWebsiteUrl = (e) => { e.preventDefault(); setCompanyWebsiteUrl(e.target.value); };

	const test_all_regex = (data, regex) => {
		if (!data) {
			return false;
		}

		const valid = regex.test(data);
		if (!valid) {
			return false;
		}

		return true;
	};

	const validate_url = (url) => {
		const tester = /^((http|https):\/\/)(www.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}((\.[a-z]{2,6})|([a-z0-9:]){2,10})\b([-a-zA-Z0-9@:%._\+~#?&//=]*)$/;
		return test_all_regex(url, tester);
	};

	const validate_rc_number = (number) => {
		const tester = /^([0-9]{6,14})$/;
		return test_all_regex(number, tester);
	};

	const validate_company_type = (company_type) => {
		if (!company_types.includes(company_type)) return false;
		return true;
	};

	const handleUpdateComplianceDetails = (e) => {
		e.preventDefault();

		if (!loadingUpdateComplianceDetails) {
			if (!businessUniqueId) {
				setErrorUpdateComplianceDetails(null);
				setSuccessUpdateComplianceDetails(null);
				setErrorUpdateComplianceDetails("Business Unique ID is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyName) {
				setErrorUpdateComplianceDetails("Company name is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyName.length < 3) {
				setErrorUpdateComplianceDetails("Company name minimum characters - 3");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyName.length > 150) {
				setErrorUpdateComplianceDetails("Company name maximum characters - 150");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyEmail) {
				setErrorUpdateComplianceDetails("Company email is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validEmail.test(companyEmail)) {
				setErrorUpdateComplianceDetails("Invalid email");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyRcNumber) {
				setErrorUpdateComplianceDetails("RC Number is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validate_rc_number(companyRcNumber)) {
				setErrorUpdateComplianceDetails("Invalid RC Number");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyType) {
				setErrorUpdateComplianceDetails("Company Type is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!validate_company_type(companyType)) {
				setErrorUpdateComplianceDetails("Invalid Company Type");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (!companyAddress) {
				setErrorUpdateComplianceDetails("Company Address is required");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyAddress.length < 3) {
				setErrorUpdateComplianceDetails("Company address minimum characters - 3");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyAddress.length > 200) {
				setErrorUpdateComplianceDetails("Company address maximum characters - 200");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else if (companyWebsiteUrl && !validate_url(companyWebsiteUrl)) {
				setErrorUpdateComplianceDetails("Invalid Website Url");
				setTimeout(function () {
					setErrorUpdateComplianceDetails(null);
				}, 2500)
			} else {
				setLoadingUpdateComplianceDetails(true);

				const updateBusinessComplianceDetailsRes = updateBusinessComplianceDetails(cookie, {
					business_unique_id: businessUniqueId,
					company_name: companyName,
					company_email: companyEmail,
					company_rc_number: companyRcNumber,
					company_type: companyType,
					company_address: companyAddress,
					website_url: companyWebsiteUrl === null ? undefined : companyWebsiteUrl,
				})

				updateBusinessComplianceDetailsRes.then(res => {
					setLoadingUpdateComplianceDetails(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateComplianceDetails(error);
							setTimeout(function () {
								setErrorUpdateComplianceDetails(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateComplianceDetails(error);
							setTimeout(function () {
								setErrorUpdateComplianceDetails(null);
							}, 2000)
						}
					} else {
						setErrorUpdateComplianceDetails(null);
						if (res.response_code === 200) {
							setSuccessUpdateComplianceDetails(`Compliance details verified successfully!`);
						} else {
							setSuccessUpdateComplianceDetails(`Compliance details edited successfully!`);
						}

						setTimeout(function () {
							setSuccessUpdateComplianceDetails(null);
							setBusinessUniqueId(null);
							setRemoveComplianceDetailsModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateComplianceDetails(false);
				})

			}
		}
	};

	return {
		cookie, companyName, companyEmail, companyRcNumber, companyAddress, companyWebsiteUrl, loadingUpdateComplianceDetails, setRemoveComplianceDetailsModal, 
		errorUpdateComplianceDetails, successUpdateComplianceDetails, setCompanyEmail, setCompanyRcNumber, handleCompanyAddress,
		handleUpdateComplianceDetails, handleCompanyName, handleCompanyEmail, handleCompanyRcNumber, setCompanyName, setCompanyWebsiteUrl,
		handleCompanyType, setCompanyType, companyType, handleCompanyWebsiteUrl, setCompanyAddress, setBusinessUniqueId, removeComplianceDetailsModal
	};
};

const useUploadBusinessComplianceDocument = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingComplianceDocument, setLoadingComplianceDocument] = useState(false);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [removeComplianceDocumentModal, setRemoveComplianceDocumentModal] = useState(null);
	const [selectedComplianceDocument, setSelectedComplianceDocument] = useState("");
	const [uploadingComplianceDocumentPercentage, setUploadingComplianceDocumentPercentage] = useState(0);

	const [errorComplianceDocument, setErrorComplianceDocument] = useState(null);
	const [successComplianceDocument, setSuccessComplianceDocument] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "application/pdf", "application/PDF"];
	const maximum_file_size = 5 * 1024 * 1024;

	const handleUploadComplianceDocument = (e) => {
		e.preventDefault();

		if (!loadingComplianceDocument) {
			if (!businessUniqueId) {
				setErrorComplianceDocument(null);
				setSuccessComplianceDocument(null);
				setErrorComplianceDocument("Business Unique ID is required");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedComplianceDocument.type)) {
				setErrorComplianceDocument("Invalid image format (.png, .jpg, .jpeg & .pdf)");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else if (selectedComplianceDocument.size > maximum_file_size) {
				setErrorComplianceDocument("File too large (max 5mb)");
				setTimeout(function () {
					setErrorComplianceDocument(null);
				}, 2000)
			} else {
				setLoadingComplianceDocument(true);

				const complianceDocumentProofRes = getBusinessComplianceDocumentsProof({ business_unique_id: businessUniqueId })

				complianceDocumentProofRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingComplianceDocumentPercentage(0);
							setLoadingComplianceDocument(false);
							setErrorComplianceDocument(error);
							setTimeout(function () {
								setErrorComplianceDocument(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingComplianceDocumentPercentage(0);
							setLoadingComplianceDocument(false);
							setErrorComplianceDocument(error);
							setTimeout(function () {
								setErrorComplianceDocument(null);
							}, 2000)
						}
					} else {
						const business_file_rename = res.data.data[1].registration_document;
						let lastDot = selectedComplianceDocument.name.lastIndexOf('.');
						let ext = selectedComplianceDocument.name.substring(lastDot + 1);

						const filePath = "/businesses/" + business_file_rename + "." + ext;

						const storageRef = ref(storage, filePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedComplianceDocument);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingComplianceDocumentPercentage(progress);
							},
							(error) => {
								setUploadingComplianceDocumentPercentage(0);
								setLoadingComplianceDocument(false);
								setErrorComplianceDocument("An error occured while uploading");
								setTimeout(function () {
									setErrorComplianceDocument(null);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const updateBusinessRegistrationDocumentRes = updateBusinessComplianceDocument(cookie, {
										business_unique_id: businessUniqueId,
										registration_document: downloadURL,
										registration_document_file_ext: filePath
									})

									updateBusinessRegistrationDocumentRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingComplianceDocumentPercentage(0);
												setLoadingComplianceDocument(false);
												setErrorComplianceDocument(error);
												setTimeout(function () {
													setErrorComplianceDocument(null);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingComplianceDocumentPercentage(0);
												setLoadingComplianceDocument(false);
												setErrorComplianceDocument(error);
												setTimeout(function () {
													setErrorComplianceDocument(null);
												}, 2000)
											}
										} else {
											setErrorComplianceDocument(null);
											setUploadingComplianceDocumentPercentage(0);
											setSuccessComplianceDocument(`Registration Document uploaded successfully!`);

											setTimeout(function () {
												setLoadingComplianceDocument(false);
												setSuccessComplianceDocument(null);
												setRemoveComplianceDocumentModal(true);
												setBusinessUniqueId(null);
											}, 3000)
										}
									}).catch(err => {
										setUploadingComplianceDocumentPercentage(0);
										setLoadingComplianceDocument(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingComplianceDocumentPercentage(0);
					setLoadingComplianceDocument(false);
				})
			}
		}
	};

	return {
		cookie, loadingComplianceDocument, errorComplianceDocument, successComplianceDocument, handleUploadComplianceDocument, businessUniqueId, setSelectedComplianceDocument,
		setBusinessUniqueId, uploadingComplianceDocumentPercentage, selectedComplianceDocument, removeComplianceDocumentModal
	};
};

const useUploadBusinessComplianceCertificate = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.key, "");

	const [loadingComplianceCertificate, setLoadingComplianceCertificate] = useState(false);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);
	const [removeComplianceCertificateModal, setRemoveComplianceCertificateModal] = useState(null);
	const [selectedComplianceCertificate, setSelectedComplianceCertificate] = useState("");
	const [uploadingComplianceCertificatePercentage, setUploadingComplianceCertificatePercentage] = useState(0);

	const [errorComplianceCertificate, setErrorComplianceCertificate] = useState(null);
	const [successComplianceCertificate, setSuccessComplianceCertificate] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "application/pdf", "application/PDF"];
	const maximum_file_size = 5 * 1024 * 1024;

	const handleUploadComplianceCertificate = (e) => {
		e.preventDefault();

		if (!loadingComplianceCertificate) {
			if (!businessUniqueId) {
				setErrorComplianceCertificate(null);
				setSuccessComplianceCertificate(null);
				setErrorComplianceCertificate("Business Unique ID is required");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else if (!allowed_extensions.includes(selectedComplianceCertificate.type)) {
				setErrorComplianceCertificate("Invalid image format (.png, .jpg, .jpeg & .pdf)");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else if (selectedComplianceCertificate.size > maximum_file_size) {
				setErrorComplianceCertificate("File too large (max 5mb)");
				setTimeout(function () {
					setErrorComplianceCertificate(null);
				}, 2000)
			} else {
				setLoadingComplianceCertificate(true);

				const complianceCertificateProofRes = getBusinessComplianceDocumentsProof({ business_unique_id: businessUniqueId })

				complianceCertificateProofRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingComplianceCertificatePercentage(0);
							setLoadingComplianceCertificate(false);
							setErrorComplianceCertificate(error);
							setTimeout(function () {
								setErrorComplianceCertificate(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingComplianceCertificatePercentage(0);
							setLoadingComplianceCertificate(false);
							setErrorComplianceCertificate(error);
							setTimeout(function () {
								setErrorComplianceCertificate(null);
							}, 2000)
						}
					} else {
						const business_file_rename = res.data.data[0].registration_certificate;
						let lastDot = selectedComplianceCertificate.name.lastIndexOf('.');
						let ext = selectedComplianceCertificate.name.substring(lastDot + 1);

						const filePath = "/businesses/" + business_file_rename + "." + ext;

						const storageRef = ref(storage, filePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedComplianceCertificate);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingComplianceCertificatePercentage(progress);
							},
							(error) => {
								setUploadingComplianceCertificatePercentage(0);
								setLoadingComplianceCertificate(false);
								setErrorComplianceCertificate("An error occured while uploading");
								setTimeout(function () {
									setErrorComplianceCertificate(null);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const updateBusinessRegistrationCertificateRes = updateBusinessComplianceCertificate(cookie, {
										business_unique_id: businessUniqueId,
										registration_certificate: downloadURL,
										registration_certificate_file_ext: filePath
									})

									updateBusinessRegistrationCertificateRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingComplianceCertificatePercentage(0);
												setLoadingComplianceCertificate(false);
												setErrorComplianceCertificate(error);
												setTimeout(function () {
													setErrorComplianceCertificate(null);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingComplianceCertificatePercentage(0);
												setLoadingComplianceCertificate(false);
												setErrorComplianceCertificate(error);
												setTimeout(function () {
													setErrorComplianceCertificate(null);
												}, 2000)
											}
										} else {
											setErrorComplianceCertificate(null);
											setUploadingComplianceCertificatePercentage(0);
											setSuccessComplianceCertificate(`Registration Certificate uploaded successfully!`);

											setTimeout(function () {
												setLoadingComplianceCertificate(false);
												setSuccessComplianceCertificate(null);
												setRemoveComplianceCertificateModal(true);
												setBusinessUniqueId(null);
											}, 3000)
										}
									}).catch(err => {
										setUploadingComplianceCertificatePercentage(0);
										setLoadingComplianceCertificate(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingComplianceCertificatePercentage(0);
					setLoadingComplianceCertificate(false);
				})
			}
		}
	};

	return {
		cookie, loadingComplianceCertificate, errorComplianceCertificate, successComplianceCertificate, handleUploadComplianceCertificate, businessUniqueId, setSelectedComplianceCertificate,
		setBusinessUniqueId, uploadingComplianceCertificatePercentage, selectedComplianceCertificate, removeComplianceCertificateModal
	};
};

const useGrantBusinessAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingGrantBusinessAccess, setLoadingGrantBusinessAccess] = useState(false);
	const [removeGrantBusinessAccessModal, setRemoveGrantBusinessAccessModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);

	const [errorGrantBusinessAccess, setErrorGrantBusinessAccess] = useState(null);
	const [successGrantBusinessAccess, setSuccessGrantBusinessAccess] = useState(null);

	const handleGrantBusinessAccess = () => {

		if (!loadingGrantBusinessAccess) {
			if (!businessUniqueId) {
				setErrorGrantBusinessAccess(null);
				setSuccessGrantBusinessAccess(null);
				setErrorGrantBusinessAccess("Business Unique ID is required");
				setTimeout(function () {
					setErrorGrantBusinessAccess(null);
				}, 2500)
			} else {
				setLoadingGrantBusinessAccess(true);

				const grantBusinessAccessRes = grantBusinessAccess(cookie, {
					business_unique_id: businessUniqueId
				})

				grantBusinessAccessRes.then(res => {
					setLoadingGrantBusinessAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorGrantBusinessAccess(error);
							setTimeout(function () {
								setErrorGrantBusinessAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorGrantBusinessAccess(error);
							setTimeout(function () {
								setErrorGrantBusinessAccess(null);
							}, 2000)
						}
					} else {
						setErrorGrantBusinessAccess(null);
						setSuccessGrantBusinessAccess(`Business access granted!`);

						setTimeout(function () {
							setSuccessGrantBusinessAccess(null);
							setRemoveGrantBusinessAccessModal(true);
							setBusinessUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingGrantBusinessAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingGrantBusinessAccess, removeGrantBusinessAccessModal, errorGrantBusinessAccess, successGrantBusinessAccess, handleGrantBusinessAccess,
		setRemoveGrantBusinessAccessModal, setBusinessUniqueId
	};
};

const useSuspendBusinessAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingSuspendBusinessAccess, setLoadingSuspendBusinessAccess] = useState(false);
	const [removeSuspendBusinessAccessModal, setRemoveSuspendBusinessAccessModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);

	const [errorSuspendBusinessAccess, setErrorSuspendBusinessAccess] = useState(null);
	const [successSuspendBusinessAccess, setSuccessSuspendBusinessAccess] = useState(null);

	const handleSuspendBusinessAccess = () => {

		if (!loadingSuspendBusinessAccess) {
			if (!businessUniqueId) {
				setErrorSuspendBusinessAccess(null);
				setSuccessSuspendBusinessAccess(null);
				setErrorSuspendBusinessAccess("Business Unique ID is required");
				setTimeout(function () {
					setErrorSuspendBusinessAccess(null);
				}, 2500)
			} else {
				setLoadingSuspendBusinessAccess(true);

				const suspendBusinessAccessRes = suspendBusinessAccess(cookie, {
					business_unique_id: businessUniqueId
				})

				suspendBusinessAccessRes.then(res => {
					setLoadingSuspendBusinessAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorSuspendBusinessAccess(error);
							setTimeout(function () {
								setErrorSuspendBusinessAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorSuspendBusinessAccess(error);
							setTimeout(function () {
								setErrorSuspendBusinessAccess(null);
							}, 2000)
						}
					} else {
						setErrorSuspendBusinessAccess(null);
						setSuccessSuspendBusinessAccess(`Business access suspended!`);

						setTimeout(function () {
							setSuccessSuspendBusinessAccess(null);
							setRemoveSuspendBusinessAccessModal(true);
							setBusinessUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingSuspendBusinessAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingSuspendBusinessAccess, removeSuspendBusinessAccessModal, errorSuspendBusinessAccess, successSuspendBusinessAccess, handleSuspendBusinessAccess,
		setRemoveSuspendBusinessAccessModal, setBusinessUniqueId
	};
};

const useRevokeBusinessAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRevokeBusinessAccess, setLoadingRevokeBusinessAccess] = useState(false);
	const [removeRevokeBusinessAccessModal, setRemoveRevokeBusinessAccessModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);

	const [errorRevokeBusinessAccess, setErrorRevokeBusinessAccess] = useState(null);
	const [successRevokeBusinessAccess, setSuccessRevokeBusinessAccess] = useState(null);

	const handleRevokeBusinessAccess = () => {

		if (!loadingRevokeBusinessAccess) {
			if (!businessUniqueId) {
				setErrorRevokeBusinessAccess(null);
				setSuccessRevokeBusinessAccess(null);
				setErrorRevokeBusinessAccess("Business Unique ID is required");
				setTimeout(function () {
					setErrorRevokeBusinessAccess(null);
				}, 2500)
			} else {
				setLoadingRevokeBusinessAccess(true);

				const revokeBusinessAccessRes = revokeBusinessAccess(cookie, {
					business_unique_id: businessUniqueId
				})

				revokeBusinessAccessRes.then(res => {
					setLoadingRevokeBusinessAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRevokeBusinessAccess(error);
							setTimeout(function () {
								setErrorRevokeBusinessAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRevokeBusinessAccess(error);
							setTimeout(function () {
								setErrorRevokeBusinessAccess(null);
							}, 2000)
						}
					} else {
						setErrorRevokeBusinessAccess(null);
						setSuccessRevokeBusinessAccess(`Business access revoked!`);

						setTimeout(function () {
							setSuccessRevokeBusinessAccess(null);
							setRemoveRevokeBusinessAccessModal(true);
							setBusinessUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRevokeBusinessAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingRevokeBusinessAccess, removeRevokeBusinessAccessModal, errorRevokeBusinessAccess, successRevokeBusinessAccess, handleRevokeBusinessAccess,
		setRemoveRevokeBusinessAccessModal, setBusinessUniqueId
	};
};

const useVerifyBusiness = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyBusiness, setLoadingVerifyBusiness] = useState(false);
	const [removeVerifyBusinessModal, setRemoveVerifyBusinessModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);

	const [errorVerifyBusiness, setErrorVerifyBusiness] = useState(null);
	const [successVerifyBusiness, setSuccessVerifyBusiness] = useState(null);

	const handleVerifyBusiness = () => {

		if (!loadingVerifyBusiness) {
			if (!businessUniqueId) {
				setErrorVerifyBusiness(null);
				setSuccessVerifyBusiness(null);
				setErrorVerifyBusiness("Business Unique ID is required");
				setTimeout(function () {
					setErrorVerifyBusiness(null);
				}, 2500)
			} else {
				setLoadingVerifyBusiness(true);

				const verifyBusinessRes = verifyBusiness(cookie, {
					business_unique_id: businessUniqueId
				})

				verifyBusinessRes.then(res => {
					setLoadingVerifyBusiness(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyBusiness(error);
							setTimeout(function () {
								setErrorVerifyBusiness(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyBusiness(error);
							setTimeout(function () {
								setErrorVerifyBusiness(null);
							}, 2000)
						}
					} else {
						setErrorVerifyBusiness(null);
						setSuccessVerifyBusiness(`Business verified successfully!`);

						setTimeout(function () {
							setSuccessVerifyBusiness(null);
							setRemoveVerifyBusinessModal(true);
							setBusinessUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyBusiness(false);
				})

			}
		}
	};

	return {
		cookie, loadingVerifyBusiness, removeVerifyBusinessModal, errorVerifyBusiness, successVerifyBusiness, handleVerifyBusiness,
		setRemoveVerifyBusinessModal, setBusinessUniqueId
	};
};

const useUnverifyBusiness = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUnverifyBusiness, setLoadingUnverifyBusiness] = useState(false);
	const [removeUnverifyBusinessModal, setRemoveUnverifyBusinessModal] = useState(null);
	const [businessUniqueId, setBusinessUniqueId] = useState(null);

	const [errorUnverifyBusiness, setErrorUnverifyBusiness] = useState(null);
	const [successUnverifyBusiness, setSuccessUnverifyBusiness] = useState(null);

	const handleUnverifyBusiness = () => {

		if (!loadingUnverifyBusiness) {
			if (!businessUniqueId) {
				setErrorUnverifyBusiness(null);
				setSuccessUnverifyBusiness(null);
				setErrorUnverifyBusiness("Business Unique ID is required");
				setTimeout(function () {
					setErrorUnverifyBusiness(null);
				}, 2500)
			} else {
				setLoadingUnverifyBusiness(true);

				const unverifyBusinessRes = unverifyBusiness(cookie, {
					business_unique_id: businessUniqueId
				})

				unverifyBusinessRes.then(res => {
					setLoadingUnverifyBusiness(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUnverifyBusiness(error);
							setTimeout(function () {
								setErrorUnverifyBusiness(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUnverifyBusiness(error);
							setTimeout(function () {
								setErrorUnverifyBusiness(null);
							}, 2000)
						}
					} else {
						setErrorUnverifyBusiness(null);
						setSuccessUnverifyBusiness(`Business unverified successfully!`);

						setTimeout(function () {
							setSuccessUnverifyBusiness(null);
							setRemoveUnverifyBusinessModal(true);
							setBusinessUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUnverifyBusiness(false);
				})

			}
		}
	};

	return {
		cookie, loadingUnverifyBusiness, removeUnverifyBusinessModal, errorUnverifyBusiness, successUnverifyBusiness, handleUnverifyBusiness,
		setRemoveUnverifyBusinessModal, setBusinessUniqueId
	};
};

export {
	usePremiumUpgrade, useUpdateComplianceDetails, useUploadBusinessComplianceDocument, useUploadBusinessComplianceCertificate, 
	useGrantBusinessAccess, useSuspendBusinessAccess, useRevokeBusinessAccess, useVerifyBusiness, useUnverifyBusiness 
};