import axios from 'axios';
import { config } from '../config';

const sendGeneralNotification = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/notification/send`,
			{
				...payload,
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { sendGeneralNotification };
