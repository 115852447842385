import { useLocation } from "react-router-dom";

export default function Headertext() {
    const loc = useLocation();

    if (loc.pathname === `/internal/dashboard`) {
        return "Dashboard";
    } else if (loc.pathname === `/internal/users`) {
        return "Users";
    } else if (loc.pathname === `/internal/api/keys`) {
        return "API Keys";
    } else if (loc.pathname === `/internal/api/criteria`) {
        return "API Criteria";
    } else if (loc.pathname === `/internal/app/defaults`) {
        return "App Defaults";
    } else if (loc.pathname === `/internal/nin`) {
        return "NIN"
    } else if (loc.pathname === `/internal/bvn`) {
        return "BVN"
    } else if (loc.pathname === `/internal/address`) {
        return "Address"
    } else if (loc.pathname === `/internal/additional-qualifications`) {
        return "Additonal Qualifications"
    } else if (loc.pathname === `/internal/primary-school`) {
        return "Primary school"
    } else if (loc.pathname === `/internal/secondary-school`) {
        return "Secondary school"
    } else if (loc.pathname === `/internal/tertiary-institution`) {
        return "Tertiary institution"
    } else if (loc.pathname === `/internal/work-history`) {
        return "Work History"
    } else if (loc.pathname === `/internal/passport`) {
        return "Passport"
    } else if (loc.pathname === `/internal/driver-licence`) {
        return "Drivers Licence"
    } else if (loc.pathname === `/internal/voter-card`) {
        return "Voters Card"
    } else if (loc.pathname === `/internal/cac`) {
        return "CAC"
    } else if (loc.pathname === `/internal/tax-record`) {
        return "Tax Records"
    } else if (loc.pathname === `/internal/businesses`) {
        return "Businesses";
    } else if (loc.pathname === `/internal/partners`) {
        return "Partners";
    } else if (loc.pathname === `/internal/transactions`) {
        return "Transactions";
    } else if (loc.pathname === `/internal/purplepay-transactions`) {
        return "Purplepay Transactions";
    } else if (loc.pathname === `/internal/purplepay-logs`) {
        return "Purplepay Logs";
    } else if (loc.pathname === `/internal/transfer-logs`) {
        return "Transfer Logs";
    } else if (loc.pathname === `/internal/bill-logs`) {
        return "Bill Logs";
    } else if (loc.pathname === `/internal/mails`) {
        return "Mails";
    } else if (loc.pathname === `/internal/mailing/templates`) {
        return "Mailing Templates";
    } else if (loc.pathname === `/internal/resources`) {
        return "Resources";
    } else if (loc.pathname === `/internal/gallery`) {
        return "Gallery";
    } else if (loc.pathname === `/internal/newsletter`) {
        return "Newsletter";
    } else if (loc.pathname === `/internal/settings`) {
        return "Settings";
    } else {
        return "Page not found";
    }
}