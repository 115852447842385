import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserAddress, verifyUserAddress } from "../api/address";
import { config } from "../config";

function useVerifyUserAddress(){
    const { cookie } = useCookie(config.key, "");

    const [loadingVerifyUserAddress, setLoadingVerifyUserAddress] = useState(false)
    const [removeVerifyUserAddressModal, setRemoveVerifyUserAddressModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [addressUniqueId, setAddressUniqueId] = useState(null)

    const [errorVerifyUserAddress, setErrorVerifyUserAddress] = useState(null)
    const [successVerifyUserAddress, setSuccessVerifyUserAddress] = useState(null)

    const handleVerifyUserAddress = ()=>{
        if(!loadingVerifyUserAddress){
            if(!userPID || !addressUniqueId){
                setErrorVerifyUserAddress(null)
                setSuccessVerifyUserAddress(null)
                setErrorVerifyUserAddress(userPID ? "Unique id is required" : "User PID is required")
                setTimeout(function(){
                    setErrorVerifyUserAddress(null)
                }, 2500)
            }else{
                setLoadingVerifyUserAddress(true)

                const verifyUserAddressRes = verifyUserAddress(cookie, {pid: userPID, unique_id: addressUniqueId})

                verifyUserAddressRes.then((res)=>{
                
                    setLoadingVerifyUserAddress(false)
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorVerifyUserAddress(error)
                            setTimeout(()=>{
                                setErrorVerifyUserAddress(null)
                            }, 2000)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorVerifyUserAddress(error)
                            setTimeout(()=>{
                                setErrorVerifyUserAddress(null)
                            }, 2000)
                        }
                    } else {
                        setErrorVerifyUserAddress(null)
                        setSuccessVerifyUserAddress("User address verified")

                        setTimeout(()=>{
                            setSuccessVerifyUserAddress(null)
                            setRemoveVerifyUserAddressModal(true)
                            setUserPID(null)
                            setAddressUniqueId(null)
                        }, 2500)
                    }
                }).catch(err=>{
                    setLoadingVerifyUserAddress(false)
                })
            }

        }
    }

    return{
        cookie, loadingVerifyUserAddress, successVerifyUserAddress, errorVerifyUserAddress, removeVerifyUserAddressModal, setRemoveVerifyUserAddressModal, handleVerifyUserAddress,
        userPID, setUserPID, addressUniqueId, setAddressUniqueId
    }
}

function useDeclineUserAddress(){
    const{cookie}= useCookie(config.key, "");

    const [loadingDeclineAddress, setLoadingDeclineAddress] = useState(false)
    const [removeDeclineAddressModal, setRemoveDeclineAddressModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [addressUniqueId, setAddressUniqueId] = useState(null)
    const [declineMsg, setDeclineMsg] = useState(null)

    const [errorDeclineAddress, setErrorDeclineAddress] = useState(null)
    const [successDeclineAddress, setSuccessDeclineAddress] = useState(null)

    const handleDeclineAddress = ()=>{
        if(!loadingDeclineAddress){
            if(!userPID || !addressUniqueId){
                setErrorDeclineAddress(null)
                setSuccessDeclineAddress(null)
                setErrorDeclineAddress(userPID ? "Address uniqueId is required": "User PID is required")
                setTimeout(()=>{
                    setErrorDeclineAddress(null)
                }, 2500)
            }else{
                setLoadingDeclineAddress(true)

                const declineUserAddressRes = declineUserAddress(cookie, {pid: userPID, unique_id: addressUniqueId, message: declineMsg})

                declineUserAddressRes.then((res)=>{
                    setLoadingDeclineAddress(false)
                
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorDeclineAddress(error)
                            setTimeout(()=>{
                                setErrorDeclineAddress(null)
                            }, 2500)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorDeclineAddress(error)
                            setTimeout(()=>{
                                setErrorDeclineAddress(null)
                            }, 2500)
                        }
                    }else{
                        setErrorDeclineAddress(null)
                        setDeclineMsg("")
                        setSuccessDeclineAddress("User address declined")

                        setTimeout(()=>{
                            setSuccessDeclineAddress(null)
                            setRemoveDeclineAddressModal(true)
                            setUserPID(null)
                            setAddressUniqueId(null)
                        }, 2500)
                    }
                })
            }
        }
    }

    return {
        errorDeclineAddress, successDeclineAddress, loadingDeclineAddress, 
        userPID, setUserPID, addressUniqueId, setAddressUniqueId, declineMsg, setDeclineMsg, handleDeclineAddress,
        removeDeclineAddressModal, setRemoveDeclineAddressModal
    }
}

export {useVerifyUserAddress, useDeclineUserAddress}