import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { declineUserNIN, verifyUserNIN, createVerificationLog } from "../api/nin";

const useVerifyNIN = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyNIN, setLoadingVerifyNIN] = useState(false);
	const [removeVerifyNINModal, setRemoveVerifyNINModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [note, setNote] = useState(null);

	const [errorVerifyNIN, setErrorVerifyNIN] = useState(null);
	const [successVerifyNIN, setSuccessVerifyNIN] = useState(null);

	const handleNote = (e) => { e.preventDefault(); setNote(e.target.value); };

	const handleVerifyNIN = () => {

		if (!loadingVerifyNIN) {
			if (!uniqueId) {
				setErrorVerifyNIN(null);
				setSuccessVerifyNIN(null);
				setErrorVerifyNIN("Unique ID is required");
				setTimeout(function () {
					setErrorVerifyNIN(null);
				}, 2500)
			} else if (!userPID) {
				setErrorVerifyNIN("PID is required");
				setTimeout(function () {
					setErrorVerifyNIN(null);
				}, 2500)
			} else {
				setLoadingVerifyNIN(true);

				const verifyUserNINRes = verifyUserNIN(cookie, {
					unique_id: uniqueId,
					pid: userPID,
					note: note === null ? undefined : note,
				})

				verifyUserNINRes.then(res => {
					setLoadingVerifyNIN(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyNIN(error);
							setTimeout(function () {
								setErrorVerifyNIN(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyNIN(error);
							setTimeout(function () {
								setErrorVerifyNIN(null);
							}, 2000)
						}
					} else {
						setErrorVerifyNIN(null);
						setSuccessVerifyNIN(`User NIN Verified!`);

						setTimeout(function () {
							setSuccessVerifyNIN(null);
							setRemoveVerifyNINModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyNIN(false);
				})

			}
		}
	};

	return {
		cookie, loadingVerifyNIN, removeVerifyNINModal, errorVerifyNIN, successVerifyNIN, handleVerifyNIN,
		setRemoveVerifyNINModal, setUniqueId, setUserPID, note, setNote, handleNote
	};
};

const useDeclineNIN = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineNIN, setLoadingDeclineNIN] = useState(false);
	const [removeDeclineNINModal, setRemoveDeclineNINModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [message, setMessage] = useState(null);

	const [errorDeclineNIN, setErrorDeclineNIN] = useState(null);
	const [successDeclineNIN, setSuccessDeclineNIN] = useState(null);

	const handleMessage = (e) => { e.preventDefault(); setMessage(e.target.value); };

	const handleDeclineNIN = () => {

		if (!loadingDeclineNIN) {
			if (!uniqueId) {
				setErrorDeclineNIN(null);
				setSuccessDeclineNIN(null);
				setErrorDeclineNIN("Unique ID is required");
				setTimeout(function () {
					setErrorDeclineNIN(null);
				}, 2500)
			} else if (!userPID) {
				setErrorDeclineNIN("PID is required");
				setTimeout(function () {
					setErrorDeclineNIN(null);
				}, 2500)
			} else if (!message) {
				setErrorDeclineNIN("Message is required");
				setTimeout(function () {
					setErrorDeclineNIN(null);
				}, 2500)
			} else if (message.length < 3) {
				setErrorDeclineNIN("Message minimum characters - 3");
				setTimeout(function () {
					setErrorDeclineNIN(null);
				}, 2500)
			} else if (message.length > 500) {
				setErrorDeclineNIN("Message maximum characters - 500");
				setTimeout(function () {
					setErrorDeclineNIN(null);
				}, 2500)
			} else {
				setLoadingDeclineNIN(true);

				const declineUserNINRes = declineUserNIN(cookie, {
					unique_id: uniqueId,
					pid: userPID,
					message: message
				})

				declineUserNINRes.then(res => {
					setLoadingDeclineNIN(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineNIN(error);
							setTimeout(function () {
								setErrorDeclineNIN(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineNIN(error);
							setTimeout(function () {
								setErrorDeclineNIN(null);
							}, 2000)
						}
					} else {
						setErrorDeclineNIN(null);
						setSuccessDeclineNIN(`User NIN Declined!`);

						setTimeout(function () {
							setSuccessDeclineNIN(null);
							setRemoveDeclineNINModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeclineNIN(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeclineNIN, removeDeclineNINModal, errorDeclineNIN, successDeclineNIN, handleDeclineNIN,
		setRemoveDeclineNINModal, setUniqueId, message, setMessage, handleMessage, setUserPID
	};
};

const useCreateVerificationLog = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingCreateVerificationLog, setLoadingCreateVerificationLog] = useState(false);
	const [removeCreateVerificationLogModal, setRemoveCreateVerificationLogModal] = useState(null);
	const [nin, setNIN] = useState(null);
	const [vNIN, setVNIN] = useState(null);

	const [errorCreateVerificationLog, setErrorCreateVerificationLog] = useState(null);
	const [successCreateVerificationLog, setSuccessCreateVerificationLog] = useState(null);

	const handleCreateVerificationLog = () => {

		if (!loadingCreateVerificationLog) {
			if (!nin && vNIN) {
				setErrorCreateVerificationLog(null);
				setSuccessCreateVerificationLog(null);
				setErrorCreateVerificationLog("NIN or Virtual NIN is required");
				setTimeout(function () {
					setErrorCreateVerificationLog(null);
				}, 2500)
			} else {
				setLoadingCreateVerificationLog(true);

				const payload = nin ? {
					nin: parseInt(nin)
				} : {
					vNIN: vNIN
				};

				const createVerificationLogRes = createVerificationLog(cookie, {
					...payload
				})

				createVerificationLogRes.then(res => {
					setLoadingCreateVerificationLog(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCreateVerificationLog(error);
							setTimeout(function () {
								setErrorCreateVerificationLog(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCreateVerificationLog(error);
							setTimeout(function () {
								setErrorCreateVerificationLog(null);
							}, 2000)
						}
					} else {
						setErrorCreateVerificationLog(null);
						setSuccessCreateVerificationLog(`User verification log created!`);

						setTimeout(function () {
							setSuccessCreateVerificationLog(null);
							setRemoveCreateVerificationLogModal(true);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCreateVerificationLog(false);
				})

			}
		}
	};

	return {
		cookie, loadingCreateVerificationLog, removeCreateVerificationLogModal, errorCreateVerificationLog, successCreateVerificationLog, handleCreateVerificationLog,
		setRemoveCreateVerificationLogModal, setNIN, setVNIN
	};
};

export { useVerifyNIN, useDeclineNIN, useCreateVerificationLog };
