import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserVoterCard, verifyUserVoterCard } from "../api/voterCard";
import { config } from "../config";

function useVerifyUserVoterCard() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserVoterCard, setLoadingVerifyUserVoterCard] = useState(false)
	const [removeVerifyUserVoterCardModal, setRemoveVerifyUserVoterCardModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [voterCardUniqueId, setVoterCardUniqueId] = useState(null)

	const [errorVerifyUserVoterCard, setErrorVerifyUserVoterCard] = useState(null)
	const [successVerifyUserVoterCard, setSuccessVerifyUserVoterCard] = useState(null)

	const handleVerifyUserVoterCard = () => {
		if (!loadingVerifyUserVoterCard) {
			if (!userPID || !voterCardUniqueId) {
				setErrorVerifyUserVoterCard(null)
				setSuccessVerifyUserVoterCard(null)
				setErrorVerifyUserVoterCard(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserVoterCard(null)
				}, 2500)
			} else {
				setLoadingVerifyUserVoterCard(true)

				const verifyUserVoterCardRes = verifyUserVoterCard(cookie, { pid: userPID, unique_id: voterCardUniqueId })

				verifyUserVoterCardRes.then((res) => {

					setLoadingVerifyUserVoterCard(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserVoterCard(error)
							setTimeout(() => {
								setErrorVerifyUserVoterCard(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserVoterCard(error)
							setTimeout(() => {
								setErrorVerifyUserVoterCard(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserVoterCard(null)
						setSuccessVerifyUserVoterCard("User voter card verified")

						setTimeout(() => {
							setSuccessVerifyUserVoterCard(null)
							setRemoveVerifyUserVoterCardModal(true)
							setUserPID(null)
							setVoterCardUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserVoterCard(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserVoterCard, successVerifyUserVoterCard, errorVerifyUserVoterCard, removeVerifyUserVoterCardModal, setRemoveVerifyUserVoterCardModal, handleVerifyUserVoterCard,
		userPID, setUserPID, voterCardUniqueId, setVoterCardUniqueId
	}
}

function useDeclineUserVoterCard() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineVoterCard, setLoadingDeclineVoterCard] = useState(false)
	const [removeDeclineVoterCardModal, setRemoveDeclineVoterCardModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [voterCardUniqueId, setVoterCardUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclineVoterCard, setErrorDeclineVoterCard] = useState(null)
	const [successDeclineVoterCard, setSuccessDeclineVoterCard] = useState(null)

	const handleDeclineVoterCard = () => {
		if (!loadingDeclineVoterCard) {
			if (!userPID || !voterCardUniqueId) {
				setErrorDeclineVoterCard(null)
				setSuccessDeclineVoterCard(null)
				setErrorDeclineVoterCard(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclineVoterCard(null)
				}, 2500)
			} else {
				setLoadingDeclineVoterCard(true)

				const declineUserVoterCardRes = declineUserVoterCard(cookie, { pid: userPID, unique_id: voterCardUniqueId, message: declineMsg })

				declineUserVoterCardRes.then((res) => {
					setLoadingDeclineVoterCard(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineVoterCard(error)
							setTimeout(() => {
								setErrorDeclineVoterCard(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineVoterCard(error)
							setTimeout(() => {
								setErrorDeclineVoterCard(null)
							}, 2500)
						}
					} else {
						setErrorDeclineVoterCard(null)
						setDeclineMsg("")
						setSuccessDeclineVoterCard("User voter card declined")

						setTimeout(() => {
							setSuccessDeclineVoterCard(null)
							setRemoveDeclineVoterCardModal(true)
							setUserPID(null)
							setVoterCardUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclineVoterCard, successDeclineVoterCard, loadingDeclineVoterCard,
		userPID, setUserPID, voterCardUniqueId, setVoterCardUniqueId, declineMsg, setDeclineMsg, handleDeclineVoterCard,
		removeDeclineVoterCardModal, setRemoveDeclineVoterCardModal
	}
}

export { useVerifyUserVoterCard, useDeclineUserVoterCard }