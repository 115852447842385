import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import useCookie from "../hooks/useCookie";
import Search from "../icons/Search";
import { getCAC, getCACsViaUser, getCACs, getCACsViaVerifiedStatus } from "../api/cac";
import { config } from "../config";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Check from "../icons/Check";
import Copy from "../icons/Copy";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import Star from "../icons/Star";
import { useVerifyUserCAC, useDeclineUserCAC } from "../hooks/useCACs";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import Filter from "../icons/Filter";

export default function CACs() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const { handleVerifyUserCAC, setUserPID: verifyUserCACPID,
		setCACUniqueId: verifyUserCACUniqueId, errorVerifyUserCAC, successVerifyUserCAC,
		loadingVerifyUserCAC, removeVerifyUserCACModal, setRemoveVerifyUserCACModal
	} = useVerifyUserCAC();

	const { 
		errorDeclineCAC, successDeclineCAC, handleDeclineCAC, setUserPID: declineUserCACPID,
		setCACUniqueId: declineUserCACUniqueId, declineMsg, setDeclineMsg,
		loadingDeclineCAC, removeDeclineCACModal, setRemoveDeclineCACModal
	} = useDeclineUserCAC();

	const [currentFunction, setCurrentFunction] = useState("getAllCACs");

	const [allCACs, setAllCACs] = useState(null);
	const [errorAllCACs, setErrorAllCACs] = useState(null);
	const [loadingAllCACs, setLoadingAllCACs] = useState(false);

	const [cacVerifiedStatus, setCACVerifiedStatus] = useState(null)

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleCACVerifiedStatus = (e) => { e.preventDefault(); setCACVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllCACsByVerification"); getAllCACsByVerification(e.target.value === 'verified' ? true : false, page, size); };
	
	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllCACs":
				getAllCACs(page, size);
				break;
			case "getAllCACsByVerification":
				getAllCACsByVerification(cacVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllCACs(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllCACs":
				getAllCACs(page, size);
				break;
			case "getAllCACsByVerification":
				getAllCACsByVerification(cacVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			default:
				getAllCACs(page, size);
		}
	};

	async function callLastFunction() {
		switch (currentFunction) {
			case "getAllCACs":
				getAllCACs(page, size);
				break;
			case "getAllCACsByVerification":
				getAllCACsByVerification(cacVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			// default:
			// 	getAllCACs(page, size);
		}
	};

	async function previousCACs() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllCACs":
					getAllCACs(page - 1, size);
					break;
				case "getAllCACsByVerification":
					getAllCACsByVerification(cacVerifiedStatus === 'verified' ? true : false, page - 1, size);
					break;
				default:
					getAllCACs(page - 1, size);
			}
		}
	};

	async function nextCACs() {
		if (page < allCACs.data.pages) setPage(page + 1);
		if (page < allCACs.data.pages) {
			switch (currentFunction) {
				case "getAllCACs":
					getAllCACs(page + 1, size);
					break;
				case "getAllCACsByVerification":
					getAllCACsByVerification(cacVerifiedStatus === 'verified' ? true : false, page + 1, size);
					break;
				default:
					getAllCACs(page + 1, size);
			}
		}
	};

	async function getAllCACs(_page, _size) {
		setLoadingAllCACs(true);
		const response = await getCACs(cookie, (_page | page), (_size || size));
		setAllCACs(response.data);
		if (response.error) setErrorAllCACs(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllCACs(false);
	}

	async function getAllCACsByVerification(verified, _page, _size) {
		setLoadingAllCACs(true)
		const response = await getCACsViaVerifiedStatus(cookie, (_page || page), (_size || size), { verified: verified })
		setAllCACs(response.data)
		if (response.error) setErrorAllCACs(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllCACs(false);
	}

	useEffect(() => {
		if (allCACs === null) {
			callLastFunction();
		}
	}, [allCACs])

	const [loadingViewCAC, setLoadingViewCAC] = useState(false)
	const [errorViewCAC, setErrorViewCAC] = useState(null)
	const [viewUserCAC, setViewUserCAC] = useState(null)

	async function getUserCAC(uniqueId, pid) {
		setLoadingViewCAC(true)
		const response = await getCAC(cookie, { unique_id: uniqueId, pid: pid })
		if (!response.err) {
			setViewUserCAC(response.data)
		} else { setErrorViewCAC(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewCAC(false)
	}

	async function getUserCACs(pid) {
		// const modalResponse = document.querySelector("#userCACSearchModal");
		// modalResponse.setAttribute("display", false);
		// setLoadingViewCAC(true);
		// const response = await getCACsViaUser(cookie, { pid: pid })
		// if (!response.error) {
		// 	setViewUserCAC(response.data)
		// } else {
		// 	setViewUserCAC(null); setErrorViewCAC(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message)
		// }
		// setLoadingViewCAC(false)
		// setCACPIDSearch("")
		setLoadingAllCACs(true);
		const modalResponse = document.querySelector("#userCACSearchModal");
		modalResponse.setAttribute("display", false);
		const response = await getCACsViaUser(cookie, { pid: pid, page, size })
		setAllCACs(response.data)
		if (response.error) setErrorAllCACs(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllCACs(false);
	}

	const [cacPIDSearch, setCACPIDSearch] = useState("")
	const handleCACPIDSearch = (e) => { e.preventDefault(); setCACPIDSearch(e.target.value.toUpperCase()); }

	if (removeVerifyUserCACModal) {
		const modalResponse = document.querySelector("#verifyUserCACModal");
		modalResponse.setAttribute('display', false);
		getUserCAC(viewUserCAC.data.data.unique_id, viewUserCAC.data.data.pid)
		setRemoveVerifyUserCACModal(null)
	}
	if (removeDeclineCACModal) {
		const modalResponse = document.querySelector("#declineUserCACModal")
		modalResponse.setAttribute('display', false)
		getUserCAC(viewUserCAC.data.data.unique_id, viewUserCAC.data.data.pid)
		setRemoveDeclineCACModal(null)
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allCACs ? allCACs.data.pages : 0).fill(0);

	return (
		<>
			<Screen>
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className='xui-mt-2'>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All User CACs</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allCACs && allCACs.success ? allCACs.data.total : "..."}</p>
							</div>

							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userCACSearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={cacVerifiedStatus} onChange={handleCACVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												cacVerifiedStatus === null ?
													<option selected disabled>Filter By verification Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"verified"}>Verified</option>
											<option value={"unverified"}>Unverified</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						{
							loadingAllCACs
								?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
								:
								(
									allCACs && allCACs.success
										?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>PID</th>
														<th className='xui-min-w-200'>Company Name</th>
														<th className='xui-min-w-200'>Email</th>
														<th className='xui-min-w-150'>RC Number</th>
														<th className='xui-min-w-100'>Type</th>
														<th className='xui-min-w-300'>Address</th>
														<th className='xui-min-w-200'>Website</th>
														<th className='xui-min-w-200'>Registration Date</th>
														<th className='xui-min-w-200'>Verification</th>
														<th className='xui-min-w-200'>Declined</th>
														<th className='xui-min-w-300'>Declined Timestamp</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{
														allCACs.data.rows ?
															allCACs.data.rows.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_name}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_email}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_rc_number}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_type}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_address}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.website_url ? data.website_url : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Not provided</span>}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.registration_date}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserCAC(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserCACModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															)) :
															allCACs.data.data.map((data, i) => (
																<tr className='' key={i}>
																	<td className='xui-opacity-5'>
																		<span>{i + 1}</span>
																	</td>
																	<td className='xui-opacity-5 xui-font-w-bold'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>#{data.pid}</span>
																			<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																				{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_name}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_email}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_rc_number}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_type}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.company_address}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.website_url ? data.website_url : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Not provided</span>}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.registration_date}</span>
																		</div>
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.verified ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined ?
																						<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																				)

																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.declined_timestamp === null ?
																				<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																				(
																					data.declined_timestamp ?
																						<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																						"No timestamp"
																				)
																		}
																	</td>
																	<td className='xui-opacity-5'>
																		{
																			data.status === 1 ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																		}
																	</td>
																	<td className=''>
																		<div className="xui-d-flex xui-grid-gap-1">
																			<button title="View User Details" onClick={() => { getUserCAC(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserCACModal">
																				<EyeOpenAlt width="16" height="16" />
																			</button>
																		</div>
																	</td>
																</tr>
															))
													}
												</tbody>
											</table>
										</div>
										:
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllCACs}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllCACs ?
								<Loading width="12" height="12" /> :
								(
									allCACs && allCACs.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allCACs ? allCACs.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousCACs}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextCACs}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewUserCACModal" id="viewUserCACModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserCACModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewCAC
							?
							<center>
								<Loading width="12" height="12" />
							</center>
							:
							(
								viewUserCAC && viewUserCAC.success
									?
									<>
										<h1>{viewUserCAC.data.user_data.firstname + (viewUserCAC.data.user_data.middlename ? " " + viewUserCAC.data.user_data.middlename + " " : " ") + viewUserCAC.data.user_data.lastname}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">CAC details for this user below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserCAC.data.user_data.photo} alt={viewUserCAC.data.user_data.firstname + (viewUserCAC.data.user_data.middlename ? " " + viewUserCAC.data.user_data.middlename + " " : " ") + viewUserCAC.data.user_data.lastname + " Selfie Image"} />
												<center className="xui-mt-1">
													<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserCAC.data.data.pid}</b>
													<center>
														<div className="xui-d-inline-flex xui-flex-ai-center">
															<span>
																{
																	viewUserCAC.data.user_data.star === 0 ?
																		<div className='xui-m-half'>
																			<p>No star</p>
																		</div>
																		: ""
																}
																{
																	viewUserCAC.data.user_data.star === 1 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserCAC.data.user_data.star === 2 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserCAC.data.user_data.star === 3 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserCAC.data.user_data.star === 4 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserCAC.data.user_data.star === 5 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
															</span>
														</div>
													</center>
													<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">No: <b>{viewUserCAC.data.user_data.phone_number}</b></p>
												</center>
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Name -</span> {viewUserCAC.data.data.company_name}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Email -</span> {viewUserCAC.data.data.company_email}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">RC Number -</span> {viewUserCAC.data.data.company_rc_number}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Type -</span> {viewUserCAC.data.data.company_type}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Address -</span> {viewUserCAC.data.data.company_address}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Website -</span> {viewUserCAC.data.data.website ? viewUserCAC.data.data.website : <span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Not provided</span>}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Registration Date -</span> {viewUserCAC.data.data.registration_date}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
													viewUserCAC.data.data.status === 1
														?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span>
														:
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
													viewUserCAC.data.data.verified ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
													viewUserCAC.data.data.declined === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserCAC.data.data.declined ?
																<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
														)

												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
													viewUserCAC.data.data.declined_timestamp === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserCAC.data.data.declined_timestamp ?
																<span>{viewUserCAC.data.data.declined_timestamp.date} at {viewUserCAC.data.data.declined_timestamp.time}</span> :
																"No timestamp"
														)
												}</p>
												<p className="xui-opacity-10 xui-font-sz-100 xui-m-half"><span className="xui-opacity-4 xui-font-w-bold">Registration Certificate - </span> {
													viewUserCAC.data.data.registration_certificate
														?
														viewUserCAC.data.data.registration_certificate.split(".")[3] === 'pdf'
															?
															<a href={viewUserCAC.data.data.registration_certificate} rel="noreferrer" target="_blank" > {viewUserCAC.data.user_data.firstname + " " + viewUserCAC.data.user_data.lastname + " pdf_registration_certificate"} </a>
															:
															<img className="xui-img-300 xui-max-h-300 xui-mt-2" src={viewUserCAC.data.data.registration_certificate} alt={viewUserCAC.data.user_data.firstname + (viewUserCAC.data.user_data.middlename ? " " + viewUserCAC.data.user_data.middlename + " " : " ") + viewUserCAC.data.user_data.lastname + " Registration Certificate"} />
														:
														"Null"
												}</p>
												<p className="xui-opacity-10 xui-font-sz-100 xui-m-half"><span className="xui-opacity-4 xui-font-w-bold">Registration Document - </span> {
													viewUserCAC.data.data.registration_document
														?
														viewUserCAC.data.data.registration_document.split(".")[3] === 'pdf'
															?
															<a href={viewUserCAC.data.data.registration_document} rel="noreferrer" target="_blank" > {viewUserCAC.data.user_data.firstname + " " + viewUserCAC.data.user_data.lastname + " pdf_registration_document"} </a>
															:
															<img className="xui-img-300 xui-max-h-300 xui-mt-2" src={viewUserCAC.data.data.registration_document} alt={viewUserCAC.data.user_data.firstname + (viewUserCAC.data.user_data.middlename ? " " + viewUserCAC.data.user_data.middlename + " " : " ") + viewUserCAC.data.user_data.lastname + " Registration Document"} />
														:
														"Null"
												}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserCAC.data.data.createdAt?.fulldate} | Last Updated - {viewUserCAC.data.data.updatedAt?.fulldate}</p>
											<div className="xui-m-2">
												<button title="Verify User CAC" onClick={() => { verifyUserCACPID(viewUserCAC.data.data.pid); verifyUserCACUniqueId(viewUserCAC.data.data.unique_id) }} className="xui-btn xui-mr-3 psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyUserCACModal">
													<Check width="16" height="16" />
												</button>

												<button title="Decline User CAC" onClick={() => { declineUserCACPID(viewUserCAC.data.data.pid); declineUserCACUniqueId(viewUserCAC.data.data.unique_id) }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineUserCACModal">
													<Close width="16" height="16" />
												</button>
											</div>
										</center>
									</>
									:
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewCAC}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>

			<section className='xui-modal' xui-modal="verifyUserCACModal" id="verifyUserCACModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify User CAC</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyUserCAC}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyUserCAC}</span></p>
						<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button onClick={handleVerifyUserCAC} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Yes</span>
									{
										loadingVerifyUserCAC ?
											<Loading width="12" height="12" />
											: <Check width="20" height="20" />
									}
								</button>
							</div>

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyUserCAC ? "" : "verifyUserCACModal"}>
									<span className="xui-mr-half">No</span>
									<Close width="20" height="20" />
								</button>
							</div>
						</div>
					</center>
				</div>
			</section>

			<section className='xui-modal' xui-modal="declineUserCACModal" id="declineUserCACModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Decline User CAC</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<form className="xui-form xui-mt-2">
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Feedback</label>
							<textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={declineMsg} onChange={(e) => setDeclineMsg(e.target.value)}></textarea>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineCAC}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineCAC}</span></p>
					</form>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeclineCAC} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeclineCAC ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineCAC ? "" : "declineUserCACModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>

			<section className='xui-modal' xui-modal="userCACSearchModal" id="userCACSearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userCACSearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for User</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getUserCACs(cacPIDSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>PID</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"text"} maxLength={6} placeholder={"Enter Passcoder ID"} value={cacPIDSearch} onChange={handleCACPIDSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}