import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Filter from "../icons/Filter";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getBillLogs, getBillLogsViaCustomer, getBillLogsViaReference, getBillLogsViaCode, getBillLogsViaType, getBillLogsViaStatus } from "../api/billLogs";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";

export default function BillLogs() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const [allBillLogs, setAllBillLogs] = useState(null);
	const [errorBillLogs, setErrorBillLogs] = useState(null);
	const [loadingAllBillLogs, setLoadingAllBillLogs] = useState(false);

	const [currentFunction, setCurrentFunction] = useState("getAllBillLogs");

	const [filterByReference, setFilterByReference] = useState("");
	const [removeReferenceFilterModal, setRemoveReferenceFilterModal] = useState(null);

	const [filterByCustomer, setFilterByCustomer] = useState("");
	const [removeCustomerFilterModal, setRemoveCustomerFilterModal] = useState(null);

	const [filterByCode, setFilterByCode] = useState("");
	const [removeCodeFilterModal, setRemoveCodeFilterModal] = useState(null);

	const [transactionStatus, setTransactionStatus] = useState(null);
	const [transactionType, setTransactionType] = useState(null);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleTransactionStatus = (e) => { e.preventDefault(); setTransactionStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset"); continueFilterByStatus(e.target.value); };
	const handleTransactionType = (e) => { e.preventDefault(); setTransactionType(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset"); continueFilterByType(e.target.value); };
	const handleFilterByReference = (e) => { e.preventDefault(); setFilterByReference(e.target.value); };
	const handleFilterByCustomer = (e) => { e.preventDefault(); setFilterByCustomer(e.target.value.toString()); };
	const handleFilterByCode = (e) => { e.preventDefault(); setFilterByCode(e.target.value); };

	const resetReferenceFilterParameters = () => {
		setFilterByReference("");
		setCurrentFunction("getAllBillLogs");
	};

	const resetCustomerFilterParameters = () => {
		setFilterByCustomer("");
		setCurrentFunction("getAllBillLogs");
	};

	const resetCodeFilterParameters = () => {
		setFilterByCode("");
		setCurrentFunction("getAllBillLogs");
	};

	const continueFilterByReference = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllBillLogsViaReference");
		getAllBillLogsViaReference(filterByReference, page, size);
		setRemoveReferenceFilterModal(true);
	};

	const continueFilterByCustomer = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllBillLogsViaCustomer");
		getAllBillLogsViaCustomer(filterByCustomer, page, size);
		setRemoveCustomerFilterModal(true);
	};

	const continueFilterByCode = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllBillLogsViaCode");
		getAllBillLogsViaCode(filterByCode, page, size);
		setRemoveCodeFilterModal(true);
	};

	const continueFilterByStatus = (status) => {

		setPage(1);
		setCurrentFunction("getAllBillLogsViaStatus");
		getAllBillLogsViaStatus((status === "TRUE" || status === "FALSE" ? (status === "TRUE" ? true : false) : parseInt(status)), page, size);
	};

	const continueFilterByType = (type) => {

		setPage(1);
		setCurrentFunction("getAllBillLogsViaType");
		getAllBillLogsViaType(type, page, size);
	};

	async function callLastLogFunction() {
		switch (currentFunction) {
			case "getAllBillLogs":
				getAllBillLogs(page, size);
				break;
			case "getAllBillLogsViaReference":
				getAllBillLogsViaReference(filterByReference, page, size);
				break;
			case "getAllBillLogsViaCustomer":
				getAllBillLogsViaCustomer(filterByCustomer, page, size);
				break;
			case "getAllBillLogsViaCode":
				getAllBillLogsViaCode(filterByCode, page, size);
				break;
			case "getAllBillLogsViaType":
				getAllBillLogsViaType(transactionType, page, size);
				break;
			case "getAllBillLogsViaStatus":
				getAllBillLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			// default:
			// 	getAllBillLogs(page, size);
		}
	};

	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllBillLogs":
				getAllBillLogs(page, size);
				break;
			case "getAllBillLogsViaReference":
				getAllBillLogsViaReference(filterByReference, page, size);
				break;
			case "getAllBillLogsViaCustomer":
				getAllBillLogsViaCustomer(filterByCustomer, page, size);
				break;
			case "getAllBillLogsViaCode":
				getAllBillLogsViaCode(filterByCode, page, size);
				break;
			case "getAllBillLogsViaType":
				getAllBillLogsViaType(transactionType, page, size);
				break;
			case "getAllBillLogsViaStatus":
				getAllBillLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			default:
				getAllBillLogs(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllBillLogs":
				getAllBillLogs(page, size);
				break;
			case "getAllBillLogsViaReference":
				getAllBillLogsViaReference(filterByReference, page, size);
				break;
			case "getAllBillLogsViaCustomer":
				getAllBillLogsViaCustomer(filterByCustomer, page, size);
				break;
			case "getAllBillLogsViaCode":
				getAllBillLogsViaCode(filterByCode, page, size);
				break;
			case "getAllBillLogsViaType":
				getAllBillLogsViaType(transactionType, page, size);
				break;
			case "getAllBillLogsViaStatus":
				getAllBillLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : parseInt(transactionStatus)), page, size);
				break;
			default:
				getAllBillLogs(page, size);
		}
	};

	async function previousBillLogs() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllBillLogs":
					getAllBillLogs(page - 1, size);
					break;
				case "getAllBillLogsViaReference":
					getAllBillLogsViaReference(filterByReference, page - 1, size);
					break;
				case "getAllBillLogsViaCustomer":
					getAllBillLogsViaCustomer(filterByCustomer, page - 1, size);
					break;
				case "getAllBillLogsViaCode":
					getAllBillLogsViaCode(filterByCode, page - 1, size);
					break;
				case "getAllBillLogsViaType":
					getAllBillLogsViaType(transactionType, page - 1, size);
					break;
				case "getAllBillLogsViaStatus":
					getAllBillLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : transactionStatus), parseInt(page) - 1, size);
					break;
				default:
					getAllBillLogs(page - 1, size);
			}
		};
	};

	async function nextBillLogs() {
		if (page < allBillLogs.data.pages) setPage(page + 1);
		if (page < allBillLogs.data.pages) {
			switch (currentFunction) {
				case "getAllBillLogs":
					getAllBillLogs(page + 1, size);
					break;
				case "getAllBillLogsViaReference":
					getAllBillLogsViaReference(filterByReference, page + 1, size);
					break;
				case "getAllBillLogsViaCustomer":
					getAllBillLogsViaCustomer(filterByCustomer, page + 1, size);
					break;
				case "getAllBillLogsViaCode":
					getAllBillLogsViaCode(filterByCode, page + 1, size);
					break;
				case "getAllBillLogsViaType":
					getAllBillLogsViaType(transactionType, page + 1, size);
					break;
				case "getAllBillLogsViaStatus":
					getAllBillLogsViaStatus((transactionStatus === "TRUE" || transactionStatus === "FALSE" ? (transactionStatus === "TRUE" ? true : false) : transactionStatus), parseInt(page) + 1, size);
					break;
				default:
					getAllBillLogs(page + 1, size);
			}
		}
	};

	async function getAllBillLogs(_page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogs(cookie, (_page || page), (_size || size));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	async function getAllBillLogsViaStatus(status, _page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogsViaStatus(cookie, (_page || page), (_size || size), ({ status: status }));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	async function getAllBillLogsViaReference(reference, _page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogsViaReference(cookie, (_page || page), (_size || size), ({ reference: reference }));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	async function getAllBillLogsViaCustomer(customer, _page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogsViaCustomer(cookie, (_page || page), (_size || size), ({ customer: customer }));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	async function getAllBillLogsViaCode(code, _page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogsViaCode(cookie, (_page || page), (_size || size), ({ code: code }));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	async function getAllBillLogsViaType(type, _page, _size) {
		setLoadingAllBillLogs(true);
		const response = await getBillLogsViaType(cookie, (_page || page), (_size || size), ({ type: type }));
		setAllBillLogs(response.data);
		if (response.error) setErrorBillLogs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllBillLogs(false);
	};

	useEffect(() => {
		if (allBillLogs === null) {
			callLastLogFunction();
		}
	}, [allBillLogs]);

	if (removeReferenceFilterModal) {
		const modalResponse = document.querySelector("#filterByReference");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveReferenceFilterModal(null);
	}

	if (removeCustomerFilterModal) {
		const modalResponse = document.querySelector("#filterByCustomer");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveCustomerFilterModal(null);
	}

	if (removeCodeFilterModal) {
		const modalResponse = document.querySelector("#filterByCode");
		modalResponse.setAttribute("display", false);
		callLastLogFunction();
		setRemoveCodeFilterModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allBillLogs ? allBillLogs.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Logs for Bills</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allBillLogs && allBillLogs.success ? allBillLogs.data.total : "..."}</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={transactionStatus} onChange={handleTransactionStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!transactionStatus ?
													<option selected disabled>Filter By Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"TRUE"}>True</option>
											<option value={"FALSE"}>False</option>
											<option value={409}>409</option>
											<option value={500}>500</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={transactionType} onChange={handleTransactionType} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!transactionType ?
													<option selected disabled>Filter By Type</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"Pay"}>Pay</option>
											<option value={"Validate"}>Validate</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByReference">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Reference</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByCustomer">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Customer</span>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half xui-cursor-pointer' xui-modal-open="filterByCode">
										<Filter width="16" height="16" />
										<span className="xui-font-w-bold xui-font-sz-80 xui-ml-half">Filter By Code</span>
									</div>
								</div>
							</div>
						</div>
						{
							loadingAllBillLogs ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allBillLogs && allBillLogs.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>Unique ID</th>
														<th className='xui-min-w-100'>Type</th>
														<th className='xui-min-w-400'>Reference</th>
														<th className='xui-min-w-300'>Customer</th>
														<th className='xui-min-w-200'>Code</th>
														<th className='xui-min-w-200'>Amount</th>
														<th className='xui-min-w-200'>Response Code</th>
														<th className='xui-min-w-300'>Response Message</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
													</tr>
												</thead>
												<tbody>
													{allBillLogs.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.unique_id}</span>
																	<span title="Copy Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.type}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> :
																		(
																			data.reference ?
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span>{data.reference}</span>
																					<span title="Copy Reference" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.reference); setTextCopied(data.reference); }}>
																						{copiedText && textCopied === data.reference ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No reference</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> :
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.customer}</span>
																			<span title="Copy Customer" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.customer); setTextCopied(data.payload.customer); }}>
																				{copiedText && textCopied === data.payload.customer ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> :
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<span>{data.payload.code}</span>
																			<span title="Copy Code" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.payload.code); setTextCopied(data.payload.code); }}>
																				{copiedText && textCopied === data.payload.code ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Error occured</span> : 
																		(
																			data.type === "Validate" ? 
																				<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>No amount</span> : 
																				<span className="xui-font-w-bold">NGN {parseFloat(data.payload.amount).toLocaleString()}</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.code}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		(
																			data.status >= 400 && data.status < 500 ?
																				<span>{data.data.detail}</span> :
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Copy details</span>
																					<span title="Copy Details" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.data.detail); setTextCopied(data.data.detail); }}>
																						{copiedText && textCopied === data.data.detail ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div>
																		) : 
																		(
																			data.message === "Success" ? 
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.message}</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.message}</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	typeof data.status === "number" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> :
																		(
																			data.status ?
																				<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Success</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Failed</span>
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorBillLogs}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllBillLogs ?
								<Loading width="12" height="12" /> :
								(
									allBillLogs && allBillLogs.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allBillLogs ? allBillLogs.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousBillLogs}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextBillLogs}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="filterByReference" id="filterByReference">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetReferenceFilterParameters()} xui-modal-close="filterByReference">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Reference</h1>
					<form className="xui-form" onSubmit={continueFilterByReference}>
						<div className="xui-form-box">
							<label>Reference</label>
							<input className="xui-font-sz-90" type="text" value={filterByReference} onChange={handleFilterByReference} required placeholder="Enter/Paste Reference"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByCustomer" id="filterByCustomer">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetCustomerFilterParameters()} xui-modal-close="filterByCustomer">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Customer</h1>
					<form className="xui-form" onSubmit={continueFilterByCustomer}>
						<div className="xui-form-box">
							<label>Customer</label>
							<input className="xui-font-sz-90" type="text" value={filterByCustomer} onChange={handleFilterByCustomer} required placeholder="Enter/Paste Customer (i.e. phone number / bet account id / utility bill account etc.)"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="filterByCode" id="filterByCode">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetCodeFilterParameters()} xui-modal-close="filterByCode">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Logs By Code</h1>
					<form className="xui-form" onSubmit={continueFilterByCode}>
						<div className="xui-form-box">
							<label>Code</label>
							<input className="xui-font-sz-90" type="text" value={filterByCode} onChange={handleFilterByCode} required placeholder="Enter/Paste Code"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
		</>
	);

}
