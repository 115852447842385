import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserDriverLicence, verifyUserDriverLicence } from "../api/driverLicence";
import { config } from "../config";

function useVerifyUserDriverLicence() {
	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyUserDriverLicence, setLoadingVerifyUserDriverLicence] = useState(false)
	const [removeVerifyUserDriverLicenceModal, setRemoveVerifyUserDriverLicenceModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [driverLicenceUniqueId, setDriverLicenceUniqueId] = useState(null)

	const [errorVerifyUserDriverLicence, setErrorVerifyUserDriverLicence] = useState(null)
	const [successVerifyUserDriverLicence, setSuccessVerifyUserDriverLicence] = useState(null)

	const handleVerifyUserDriverLicence = () => {
		if (!loadingVerifyUserDriverLicence) {
			if (!userPID || !driverLicenceUniqueId) {
				setErrorVerifyUserDriverLicence(null)
				setSuccessVerifyUserDriverLicence(null)
				setErrorVerifyUserDriverLicence(userPID ? "Unique id is required" : "User PID is required")
				setTimeout(function () {
					setErrorVerifyUserDriverLicence(null)
				}, 2500)
			} else {
				setLoadingVerifyUserDriverLicence(true)

				const verifyUserDriverLicenceRes = verifyUserDriverLicence(cookie, { pid: userPID, unique_id: driverLicenceUniqueId })

				verifyUserDriverLicenceRes.then((res) => {

					setLoadingVerifyUserDriverLicence(false)
					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyUserDriverLicence(error)
							setTimeout(() => {
								setErrorVerifyUserDriverLicence(null)
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyUserDriverLicence(error)
							setTimeout(() => {
								setErrorVerifyUserDriverLicence(null)
							}, 2000)
						}
					} else {
						setErrorVerifyUserDriverLicence(null)
						setSuccessVerifyUserDriverLicence("User driver licence verified")

						setTimeout(() => {
							setSuccessVerifyUserDriverLicence(null)
							setRemoveVerifyUserDriverLicenceModal(true)
							setUserPID(null)
							setDriverLicenceUniqueId(null)
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyUserDriverLicence(false)
				})
			}

		}
	}

	return {
		cookie, loadingVerifyUserDriverLicence, successVerifyUserDriverLicence, errorVerifyUserDriverLicence, removeVerifyUserDriverLicenceModal, setRemoveVerifyUserDriverLicenceModal, handleVerifyUserDriverLicence,
		userPID, setUserPID, driverLicenceUniqueId, setDriverLicenceUniqueId
	}
}

function useDeclineUserDriverLicence() {
	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineDriverLicence, setLoadingDeclineDriverLicence] = useState(false)
	const [removeDeclineDriverLicenceModal, setRemoveDeclineDriverLicenceModal] = useState(null)
	const [userPID, setUserPID] = useState(null)
	const [driverLicenceUniqueId, setDriverLicenceUniqueId] = useState(null)
	const [declineMsg, setDeclineMsg] = useState(null)

	const [errorDeclineDriverLicence, setErrorDeclineDriverLicence] = useState(null)
	const [successDeclineDriverLicence, setSuccessDeclineDriverLicence] = useState(null)

	const handleDeclineDriverLicence = () => {
		if (!loadingDeclineDriverLicence) {
			if (!userPID || !driverLicenceUniqueId) {
				setErrorDeclineDriverLicence(null)
				setSuccessDeclineDriverLicence(null)
				setErrorDeclineDriverLicence(userPID ? "Unique ID is required" : "User PID is required")
				setTimeout(() => {
					setErrorDeclineDriverLicence(null)
				}, 2500)
			} else {
				setLoadingDeclineDriverLicence(true)

				const declineUserDriverLicenceRes = declineUserDriverLicence(cookie, { pid: userPID, unique_id: driverLicenceUniqueId, message: declineMsg })

				declineUserDriverLicenceRes.then((res) => {
					setLoadingDeclineDriverLicence(false)

					if (res.error) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineDriverLicence(error)
							setTimeout(() => {
								setErrorDeclineDriverLicence(null)
							}, 2500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineDriverLicence(error)
							setTimeout(() => {
								setErrorDeclineDriverLicence(null)
							}, 2500)
						}
					} else {
						setErrorDeclineDriverLicence(null)
						setDeclineMsg("")
						setSuccessDeclineDriverLicence("User driver licence declined")

						setTimeout(() => {
							setSuccessDeclineDriverLicence(null)
							setRemoveDeclineDriverLicenceModal(true)
							setUserPID(null)
							setDriverLicenceUniqueId(null)
						}, 2500)
					}
				})
			}
		}
	}

	return {
		errorDeclineDriverLicence, successDeclineDriverLicence, loadingDeclineDriverLicence,
		userPID, setUserPID, driverLicenceUniqueId, setDriverLicenceUniqueId, declineMsg, setDeclineMsg, handleDeclineDriverLicence,
		removeDeclineDriverLicenceModal, setRemoveDeclineDriverLicenceModal
	}
}

export { useVerifyUserDriverLicence, useDeclineUserDriverLicence }