import { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Screen from '../components/Screen';
import Content from '../components/Content';
import GalleryAdd from "../assets/images/gallery-add.png";
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import { getBusiness, getBusinessViaEmail, getBusinesses, getPremiumPackages } from "../api/businesses";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import {
	useGrantBusinessAccess, usePremiumUpgrade, useRevokeBusinessAccess, useSuspendBusinessAccess, useUnverifyBusiness, useUpdateComplianceDetails, 
	useUploadBusinessComplianceCertificate, useUploadBusinessComplianceDocument, useVerifyBusiness
} from "../hooks/useBusinesses";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import KeyAlt from "../icons/KeyAlt";
import Check from "../icons/Check";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import EyeOpen from "../icons/EyeOpen";
import Search from "../icons/Search";
import Copy from "../icons/Copy";
import Key from "../icons/Key";
import CancelAlt from "../icons/CancelAlt";

export default function Businesses() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorGrantBusinessAccess, handleGrantBusinessAccess, loadingGrantBusinessAccess, removeGrantBusinessAccessModal, setRemoveGrantBusinessAccessModal, 
		setBusinessUniqueId: GrantAccessBusinessUniqueId, successGrantBusinessAccess
	} = useGrantBusinessAccess();

	const {
		errorSuspendBusinessAccess, handleSuspendBusinessAccess, loadingSuspendBusinessAccess, removeSuspendBusinessAccessModal, setRemoveSuspendBusinessAccessModal,
		setBusinessUniqueId: SuspendAccessBusinessUniqueId, successSuspendBusinessAccess
	} = useSuspendBusinessAccess();

	const {
		errorRevokeBusinessAccess, handleRevokeBusinessAccess, loadingRevokeBusinessAccess, removeRevokeBusinessAccessModal, setRemoveRevokeBusinessAccessModal,
		setBusinessUniqueId: RevokeAccessBusinessUniqueId, successRevokeBusinessAccess
	} = useRevokeBusinessAccess();

	const {
		errorPremiumPackage, handleMonths, handlePremiumPackage, handleSelectedPackage, loadingPremiumPackage, months, paymentMethod, premiumAmount, successPremiumPackage, 
		premiumPackage, premiumPrice, removePremiumPackageModal, selectedPackage, setBusinessUniqueId: PremiumPackageBusinessUniqueId, setPremiumPrice, setRemovePremiumPackageModal, 
	} = usePremiumUpgrade();

	const {
		errorVerifyBusiness, handleVerifyBusiness, loadingVerifyBusiness, removeVerifyBusinessModal, setRemoveVerifyBusinessModal,
		setBusinessUniqueId: VerifyBusinessUniqueId, successVerifyBusiness
	} = useVerifyBusiness();

	const {
		errorUnverifyBusiness, handleUnverifyBusiness, loadingUnverifyBusiness, removeUnverifyBusinessModal, setRemoveUnverifyBusinessModal,
		setBusinessUniqueId: UnverifyBusinessUniqueId, successUnverifyBusiness
	} = useUnverifyBusiness();

	const {
		companyAddress, companyEmail, companyName, companyRcNumber, companyType, companyWebsiteUrl, errorUpdateComplianceDetails,
		handleCompanyAddress, handleCompanyEmail, handleCompanyName, handleCompanyRcNumber, handleCompanyType, handleCompanyWebsiteUrl,
		handleUpdateComplianceDetails, loadingUpdateComplianceDetails, successUpdateComplianceDetails, removeComplianceDetailsModal, 
		setCompanyAddress, setCompanyEmail, setCompanyName, setCompanyRcNumber, setCompanyWebsiteUrl, setCompanyType, 
		setBusinessUniqueId: ComplianceDetailsBusinessUniqueId, setRemoveComplianceDetailsModal
	} = useUpdateComplianceDetails();

	const {
		errorComplianceDocument, handleUploadComplianceDocument, loadingComplianceDocument, setBusinessUniqueId: DocumentSetBusinessUniqueId, setSelectedComplianceDocument, successComplianceDocument,
		uploadingComplianceDocumentPercentage, selectedComplianceDocument
	} = useUploadBusinessComplianceDocument();

	const {
		errorComplianceCertificate, handleUploadComplianceCertificate, loadingComplianceCertificate, setBusinessUniqueId: CertificateSetBusinessUniqueId, setSelectedComplianceCertificate, successComplianceCertificate,
		uploadingComplianceCertificatePercentage, selectedComplianceCertificate
	} = useUploadBusinessComplianceCertificate();

	const handleSelectComplianceDocument = (e) => {
		const el = e.target.files[0];
		setSelectedComplianceDocument("");
		setSelectedComplianceDocument(el);
	}

	const handleSelectComplianceCertificate = (e) => {
		const el = e.target.files[0];
		setSelectedComplianceCertificate("");
		setSelectedComplianceCertificate(el);
	}

	const getFileExtension = (filename) => {
		let lastDot = filename.lastIndexOf('.');
		let ext = filename.substring(lastDot + 1);
		return ext;
	}

	const getFileNameAlone = (filename) => {
		let _filename = filename.split("/");
		return _filename[_filename.length - 1];
	}

	const [allBusinesses, setAllBusinesses] = useState(null);
	const [errorAllBusinesses, setErrorAllBusinesses] = useState(null);
	const [loadingAllBusinesses, setLoadingAllBusinesses] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getAllBusinesses(page, e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); getAllBusinesses(parseInt(e.target.value), size); };

	async function previousBusinesses() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getAllBusinesses(page - 1, size);
	};

	async function nextBusinesses() {
		if (page < allBusinesses.data.pages) setPage(page + 1);
		if (page < allBusinesses.data.pages) getAllBusinesses(page + 1, size);
	};

	async function getAllBusinesses(_page, _size) {
		setLoadingAllBusinesses(true);
		const response = await getBusinesses(cookie, (_page || page), (_size || size));
		setAllBusinesses(response.data);
		if (response.error) setErrorAllBusinesses(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingAllBusinesses(false);
	};

	useEffect(() => {
		if (allBusinesses === null) {
			getAllBusinesses();
		}
	}, [allBusinesses]);

	const [loadingViewBusiness, setLoadingViewBusiness] = useState(false);
	const [errorViewBusiness, setErrorViewBusiness] = useState(null);
	const [viewBusinessDetails, setViewBusinessDetails] = useState(null);

	async function getBusinessDetails(business_unique_id) {
		setLoadingViewBusiness(true);
		const response = await getBusiness(cookie, { business_unique_id });
		if (!response.err) { setViewBusinessDetails(response.data); PremiumPackageBusinessUniqueId(response.data.data.business_unique_id); }
		else { setErrorViewBusiness(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
		setLoadingViewBusiness(false);
	};

	async function getBusinessDetailsViaEmail(email) {
		const modalResponse = document.querySelector("#businessSearchModal");
		modalResponse.setAttribute("display", false);
		setLoadingViewBusiness(true);
		const response = await getBusinessViaEmail(cookie, { email });
		if (!response.err) { setViewBusinessDetails(response.data); PremiumPackageBusinessUniqueId(response.data.data.business_unique_id); }
		else { setViewBusinessDetails(null); setErrorViewBusiness(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg) };
		setLoadingViewBusiness(false);
		setBusinessEmailSearch("");
	};
	
	const [businessEmailSearch, setBusinessEmailSearch] = useState("");
	const handleBusinessEmailSearch = (e) => { e.preventDefault(); setBusinessEmailSearch(e.target.value); };
	
	const [loadingPremiumPackages, setLoadingPremiumPackages] = useState(false);
	const [premiumPackages, setPremiumPackages] = useState(null);
	const [errorPremiumPackages, setErrorPremiumPackages] = useState(null);

	async function _getPremiumPackages() {
		setLoadingPremiumPackages(true);
		const response = await getPremiumPackages(cookie);
		setPremiumPackages(response.data);
		if (response.error) setErrorPremiumPackages(response.error.response.status === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message);
		setLoadingPremiumPackages(false);
	};

	if (removeGrantBusinessAccessModal) {
		const modalResponse = document.querySelector("#grantBusinessAccessModal");
		modalResponse.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveGrantBusinessAccessModal(null);
	}
	if (removeSuspendBusinessAccessModal) {
		const modalResponse = document.querySelector("#suspendBusinessAccessModal");
		modalResponse.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveSuspendBusinessAccessModal(null);
	}
	if (removeRevokeBusinessAccessModal) {
		const modalResponse = document.querySelector("#revokeBusinessAccessModal");
		modalResponse.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveRevokeBusinessAccessModal(null);
	}
	if (removePremiumPackageModal) {
		const modalResponse = document.querySelector("#upgradePremiumPackageModal");
		modalResponse.setAttribute("display", false);
		const modalResponse2 = document.querySelector("#upgradePlan");
		modalResponse2.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemovePremiumPackageModal(null);
		const modalResponse3 = document.querySelector("#viewBusinessDetailsModal");
		modalResponse3.setAttribute("display", true);
	}
	if (removeVerifyBusinessModal) {
		const modalResponse = document.querySelector("#verifyBusinessModal");
		modalResponse.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveVerifyBusinessModal(null);
	}
	if (removeUnverifyBusinessModal) {
		const modalResponse = document.querySelector("#unverifyBusinessModal");
		modalResponse.setAttribute("display", false);
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveUnverifyBusinessModal(null);
	}
	if (removeComplianceDetailsModal) {
		getBusinessDetails(viewBusinessDetails.data.unique_id);
		setRemoveComplianceDetailsModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const validateExpiration = (expiration) => {
		const d = new Date(expiration);
		const today = new Date();
		today.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		if (d === "Invalid Date") return false;
		if (today.getTime() > d.getTime()) return false;
		return true;
	};

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const pageSelectArray = new Array(allBusinesses ? allBusinesses.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Businesses</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all businesses details</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="businessSearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllBusinesses ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allBusinesses && allBusinesses.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-400'>Name</th>
														<th className='xui-min-w-300'>Email</th>
														<th className='xui-min-w-100'>PID</th>
														<th className='xui-min-w-250'>State</th>
														<th className='xui-min-w-100'>Country</th>
														<th className='xui-min-w-100'>Balance</th>
														<th className='xui-min-w-150'>Point Threshold</th>
														<th className='xui-min-w-300'>Premium</th>
														<th className='xui-min-w-100'>Verified</th>
														<th className='xui-min-w-100'>Access</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allBusinesses.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{data.name + ", " + data.city}</span>
																	<span title="Copy Business Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === (data.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																<span>
																	{
																		data.email_verified ?
																			<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>
																				<div className='xui-d-inline-flex xui-flex-ai-center'>
																					<span>{data.email}</span>
																					<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.email); setTextCopied(data.email); }}>
																						{copiedText && textCopied === data.email ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																					</span>
																				</div>
																			</span> :
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.email}</span>
																	}
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>{data.pid}</span>
																		<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																			{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																		</span>
																	</div>
																</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.state}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.country}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.balance.toLocaleString()}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.point_threshold ? data.point_threshold.toLocaleString() : "Not set"}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{
																	validateExpiration(data.premium.expiring.date + " " + data.premium.expiring.time) ?
																		<>
																			<span>{data.premium.type}</span>
																			<span className="xui-d-inline-block xui-font-sz-90 xui-mt-half">{"Expiring - " + data.premium.expiring.fulldate}</span>
																		</> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-90 xui-bdr-rad-half'>Plan Expired</span>
																}</span>
															</td>
															<td className='xui-opacity-5'>
																{
																	data.verified ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Unverified</span>
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	data.access === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Granted</span> : (
																			data.access === 2 ?
																				<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Suspended</span> : (
																					data.access === 3 ?
																						<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Revoked</span> :
																						<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>No Idea</span>
																				)
																		)
																}
															</td>
															<td className='xui-opacity-5'>
																{
																	data.status === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																}
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="View Business Details" onClick={() => { getBusinessDetails(data.unique_id) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewBusinessDetailsModal">
																		<EyeOpenAlt width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllBusinesses}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllBusinesses ?
								<Loading width="12" height="12" /> :
								(
									allBusinesses && allBusinesses.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allBusinesses ? allBusinesses.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousBusinesses}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextBusinesses}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewBusinessDetailsModal" id="viewBusinessDetailsModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewBusinessDetailsModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewBusiness ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewBusinessDetails && viewBusinessDetails.success ?
									<>
										<h1>{viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Details of this business below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className={`xui-w-200 xui-h-200 xui-m-1-half ${viewBusinessDetails.data.cover ? "xui-mb-10" : ""}`}>
												<img className={`xui-img-200 xui-max-h-200 xui-bdr-rad-circle ${viewBusinessDetails.data.cover ? "xui-mb-2" : ""}`} src={viewBusinessDetails.data.photo} alt={viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city + " Business Profile Image"} />
												{
													viewBusinessDetails.data.cover ? 
														<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-1" src={viewBusinessDetails.data.cover} alt={viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city + " Business Cover Image"} /> : 
														""
												}
											</div>
											<div className={`xui-m-1-half xui-lg-ml--10 xui-md-ml--7 ${viewBusinessDetails.data.cover ? "xui-mt-4" : ""}`}>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Access URL - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span><a href={viewBusinessDetails.data.access_url} target="_blank">Click to go to link</a></span>
														<span title="Copy Access URL" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewBusinessDetails.data.access_url); setTextCopied(viewBusinessDetails.data.access_url); }}>
															{copiedText && textCopied === (viewBusinessDetails.data.access_url) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Business Unique ID - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span>{viewBusinessDetails.data.unique_id}</span>
														<span title="Copy Business Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewBusinessDetails.data.unique_id); setTextCopied(viewBusinessDetails.data.unique_id); }}>
															{copiedText && textCopied === (viewBusinessDetails.data.unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Name -</span> {viewBusinessDetails.data.name}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">City -</span> {viewBusinessDetails.data.city}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">State, Country -</span> {viewBusinessDetails.data.state + ", " + viewBusinessDetails.data.country}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Email - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														{
															viewBusinessDetails.data.email_verified ?
																<span className='xui-badge xui-badge-success xui-font-sz-100 xui-bdr-rad-half'>{viewBusinessDetails.data.email}</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-100 xui-bdr-rad-half'>{viewBusinessDetails.data.email}</span>
														}
														<span title="Copy Email" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewBusinessDetails.data.email); setTextCopied(viewBusinessDetails.data.email); }}>
															{copiedText && textCopied === (viewBusinessDetails.data.email) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">PID - </span>
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														<span className='xui-badge xui-badge-blue xui-font-sz-100 xui-bdr-rad-half'>{viewBusinessDetails.data.pid}</span>
														<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewBusinessDetails.data.pid); setTextCopied(viewBusinessDetails.data.pid); }}>
															{copiedText && textCopied === (viewBusinessDetails.data.pid) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
														</span>
													</div>
												</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Balance -</span> {viewBusinessDetails.data.balance.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Point Threshold -</span> {viewBusinessDetails.data.point_threshold ? viewBusinessDetails.data.point_threshold.toLocaleString() : "Not set"}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Name -</span> {viewBusinessDetails.data.company_name ? viewBusinessDetails.data.company_name : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Email -</span> {viewBusinessDetails.data.company_email ? viewBusinessDetails.data.company_email : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company RC Number -</span> {viewBusinessDetails.data.company_rc_number ? viewBusinessDetails.data.company_rc_number : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Type -</span> {viewBusinessDetails.data.company_type ? viewBusinessDetails.data.company_type : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Company Address -</span> {viewBusinessDetails.data.company_address ? viewBusinessDetails.data.company_address : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Website URL -</span> {viewBusinessDetails.data.website_url ? viewBusinessDetails.data.website_url : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Access -</span> {
													viewBusinessDetails.data.access === 1 ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Granted</span> : (
															viewBusinessDetails.data.access === 2 ?
																<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Suspended</span> : (
																	viewBusinessDetails.data.access === 3 ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Revoked</span> :
																		<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>No Idea</span>
																)
														)
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">Verification - </span> 
													<div className='xui-d-inline-flex xui-flex-ai-center'>
														{
															viewBusinessDetails.data.verified ?
																<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>Verified</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
														}
														{
															viewBusinessDetails.data.verified ? 
																<span title="Unverify Platform" className="xui-cursor-pointer xui-ml-1" onClick={() => { UnverifyBusinessUniqueId(viewBusinessDetails.data.unique_id); }} xui-modal-open="unverifyBusinessModal">
																	<CancelAlt width="20" height="20" />
																</span> : 
																<span title="Verify Platform" className="xui-cursor-pointer xui-ml-1" onClick={() => { VerifyBusinessUniqueId(viewBusinessDetails.data.unique_id); }} xui-modal-open="verifyBusinessModal">
																	<Check width="20" height="20" />
																</span>
														}
													</div>
												</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Account Name -</span> {viewBusinessDetails.data.account_name ? viewBusinessDetails.data.account_name : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Account Number -</span> {viewBusinessDetails.data.account_number ? viewBusinessDetails.data.account_number : "None"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Bank -</span> {viewBusinessDetails.data.bank ? viewBusinessDetails.data.bank : "None"}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Premium -</span> {viewBusinessDetails.data.premium.type}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Expiring -</span> {
													validateExpiration(viewBusinessDetails.data.premium.expiring.date + " " + viewBusinessDetails.data.premium.expiring.time) ?
														<span className="xui-d-inline-block xui-font-sz-90 xui-mt-half">{viewBusinessDetails.data.premium.expiring.fulldate}</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-90 xui-bdr-rad-half'>Plan Expired</span>
												}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewBusinessDetails.data.createdAt.fulldate} | Last Updated - {viewBusinessDetails.data.updatedAt.fulldate}</p>
											<div className="xui-m-2">
												{
													viewBusinessDetails.data.verified ?
														<button title="Unverify Platform" onClick={() => { UnverifyBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-70" xui-modal-open="unverifyBusinessModal">
															<span className="xui-mr-half">Unverify Business</span>
															<Close width="16" height="16" />
														</button> :
														<button title="Verify Platform" onClick={() => { VerifyBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-70" xui-modal-open="verifyBusinessModal">
															<span className="xui-mr-half">Verify Business</span>
															<Check width="16" height="16" />
														</button>
												}
											</div>
											<div className="xui-m-2">
												<button title="Grant Business Access" onClick={() => { GrantAccessBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="grantBusinessAccessModal">
													<Check width="16" height="16" />
												</button>
												<button title="Suspend Business Access" onClick={() => { SuspendAccessBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-ml-3 xui-mr-3 xui-btn xui-bg-warning xui-text-white xui-bdr-rad-half xui-font-sz-50" xui-modal-open="suspendBusinessAccessModal">
													<KeyAlt width="16" height="16" />
												</button>
												<button title="Revoke Business Access" onClick={() => { RevokeAccessBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="revokeBusinessAccessModal">
													<Close width="16" height="16" />
												</button>
											</div>
											<button onClick={() => { PremiumPackageBusinessUniqueId(viewBusinessDetails.data.unique_id); _getPremiumPackages(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70" xui-modal-close="viewBusinessDetailsModal" xui-modal-open="upgradePlan">
												<span className="xui-mr-half">Upgrade Package</span>
												<Key width="16" height="16" />
											</button>
											<div className="xui-m-2">
												<button onClick={() => { ComplianceDetailsBusinessUniqueId(viewBusinessDetails.data.unique_id); DocumentSetBusinessUniqueId(viewBusinessDetails.data.unique_id); CertificateSetBusinessUniqueId(viewBusinessDetails.data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70" xui-modal-close="viewBusinessDetailsModal" xui-modal-open="complianceDetailsModal">
													<span className="xui-mr-half">Compliance Details</span>
													<EyeOpenAlt width="16" height="16" />
												</button>
											</div>
										</center>
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewBusiness}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="upgradePlan" id="upgradePlan">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="upgradePlan" xui-modal-open="viewBusinessDetailsModal">
						<Close width="24" height="24" />
					</div>
					<h1>Premium Upgrade for {viewBusinessDetails && viewBusinessDetails.success ? (viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city) : ""}</h1>
					<span className="xui-font-sz-90 xui-opacity-5">Select your preferred package, determine the duration (months) and upgrade</span>
					<section className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
						{
							loadingPremiumPackages ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									premiumPackages && premiumPackages.success ?
										<section className="xui-d-grid xui-lg-grid-col-3 xui-grid-gap-2 xui-mt-1">
											{
												premiumPackages.data.map((data, i) => (
													(
														data.name === "Free" ? "" :
															<div onClick={() => handleSelectedPackage(data, i)} className={`xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1 xui-cursor-pointer ${selectedPackage === i ? "xui-bg-blue xui-text-white" : ""}`}>
																<h3 className="xui-font-sz-100 xui-font-w-bold xui-mt-half">{data.name}</h3>
																<hr className="xui-opacity-4 xui-my-1" />
																<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Offers - {data.offers === true ? "Unlimited" : data.offers.toLocaleString()}</h2>
																<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Announcements - {data.announcements === true ? "Unlimited" : data.announcements.toLocaleString()}</h2>
																<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Issued Points - {data.customer_issued_points === true ? "Unlimited" : data.customer_issued_points.toLocaleString()}</h2>
																{/* <h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Export to Excel - 
                                                                {
                                                                    data.export_to_excel ?
                                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half xui-mx-1'>{"Yes"}</span> :
                                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half xui-mx-1'>{"No"}</span>
                                                                }
                                                            </h2> */}
																<h2 className="xui-font-w-bold xui-text-center xui-font-sz-100 xui-mx-auto xui-w-fluid-100 xui-mt-2">NGN {data.price.toLocaleString()}</h2>
															</div>
													)
												))
											}
										</section> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPremiumPackages}</h3>
												</center>
											</div>
										</div>
								)
						}
					</section>
					<form className="xui-form" onSubmit={(e) => { e.preventDefault(); }}>
						<div className="xui-form-box">
							<label>Enter Duration <span className="xui-font-w-bold">(Number of Months)</span></label>
							<input required onChange={handleMonths} min={1} max={12} value={months} type={"number"} />
						</div>
						<span className="xui-font-sz-90 xui-opacity-5 xui-font-w-bold xui-mt-1">{premiumPackage ? premiumPackage + " | " : ""}Total: NGN {premiumAmount ? premiumAmount.toLocaleString() : 0}</span>
						<p className="xui-font-sz-90 xui-opacity-5 xui-font-w-bold xui-mt-1 xui-text-red">Info: Make sure the "Total" here matches with the receipt from the provided transaction evidence by the user</p>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPremiumPackage}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPremiumPackage}</span></p>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end" xui-modal-open={premiumPrice ? "upgradePremiumPackageModal" : ""}>
							<button disabled={loadingPremiumPackage} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								{
									loadingPremiumPackage ?
										<>
											<span className="xui-mr-half">Processing</span>
											<Loading width="12" height="12" />
										</> :
										<>
											<span className="xui-mr-half">Upgrade</span>
											<Arrowright width="12" height="12" />
										</>
								}
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="complianceDetailsModal" id="complianceDetailsModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="complianceDetailsModal" xui-modal-open="viewBusinessDetailsModal">
						<Close width="24" height="24" />
					</div>
					<h1>Compliance Details for {viewBusinessDetails && viewBusinessDetails.success ? (viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city) : ""}</h1>
					<span className="xui-font-sz-90 xui-opacity-5">View / Edit business compliance details</span>
					<form className="xui-form xui-mt-4" onSubmit={handleUpdateComplianceDetails}>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1">
							<div className="xui-w-fluid-100">
								<label>Company Name</label>
								<input type={"text"} disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} placeholder={viewBusinessDetails ? viewBusinessDetails.data.company_name : ""} readOnly={viewBusinessDetails ? false : true} value={companyName} onClick={() => { if (viewBusinessDetails) setCompanyName(viewBusinessDetails.data.company_name) }} onChange={handleCompanyName} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Company Email</label>
								<input type={"email"} disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} placeholder={viewBusinessDetails ? viewBusinessDetails.data.company_email : ""} readOnly={viewBusinessDetails ? false : true} value={companyEmail} onClick={() => { if (viewBusinessDetails) setCompanyEmail(viewBusinessDetails.data.company_email) }} onChange={handleCompanyEmail} required />
							</div>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1 xui-mt-2">
							<div className="xui-w-fluid-100">
								<label>Company RC Number</label>
								<input type={"text"} disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} placeholder={viewBusinessDetails ? viewBusinessDetails.data.company_rc_number : ""} readOnly={viewBusinessDetails ? false : true} value={companyRcNumber} onClick={() => { if (viewBusinessDetails) setCompanyRcNumber(viewBusinessDetails.data.company_rc_number) }} onChange={handleCompanyRcNumber} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Company Type</label>
								<select disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} onChange={handleCompanyType} value={companyType} required>
									<option selected disabled>Select Company Type</option>
									<option selected={viewBusinessDetails ? (viewBusinessDetails.data.company_type === "BN" ? true : false) : false} value={"BN"}>BN</option>
									<option selected={viewBusinessDetails ? (viewBusinessDetails.data.company_type === "RC" ? true : false) : false} value={"RC"}>RC</option>
									<option selected={viewBusinessDetails ? (viewBusinessDetails.data.company_type === "IT" ? true : false) : false} value={"IT"}>IT</option>
									<option selected={viewBusinessDetails ? (viewBusinessDetails.data.company_type === "LL" ? true : false) : false} value={"LL"}>LL</option>
									<option selected={viewBusinessDetails ? (viewBusinessDetails.data.company_type === "LLP" ? true : false) : false} value={"LLP"}>LLP</option>
								</select>
							</div>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1 xui-mt-2 xui-mb-2">
							<div className="xui-w-fluid-100">
								<label>Company Address</label>
								<input type={"text"} disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} placeholder={viewBusinessDetails ? viewBusinessDetails.data.company_address : ""} readOnly={viewBusinessDetails ? false : true} value={companyAddress} onClick={() => { if (viewBusinessDetails) setCompanyAddress(viewBusinessDetails.data.company_address) }} onChange={handleCompanyAddress} required />
							</div>
							<div className="xui-w-fluid-100">
								<label>Website URL</label>
								<input type={"text"} disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} placeholder={viewBusinessDetails ? viewBusinessDetails.data.website_url : ""} readOnly={viewBusinessDetails ? false : true} value={companyWebsiteUrl} onClick={() => { if (viewBusinessDetails) setCompanyWebsiteUrl(viewBusinessDetails.data.website_url) }} onChange={handleCompanyWebsiteUrl} />
							</div>
						</div>
						<div className="xui-d-flex">
							{
								viewBusinessDetails ?
									(
										viewBusinessDetails.data.registration_document === null || viewBusinessDetails.data.registration_certificate === null ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												Upload Documents
											</button> : (
												viewBusinessDetails.data.verified ?
													<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
														Verified
													</button> :
													<button className="xui-btn psc-btn-blue xui-font-sz-80">
														{
															loadingUpdateComplianceDetails ?
																<Loading width="16" height="16" />
																: "Save Changes"
														}
													</button>
											)
									) :
									<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
										Loading ...
									</button>
							}
						</div>
						<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateComplianceDetails}</span></p>
						<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateComplianceDetails}</span></p>
					</form>
					<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-2 xui-mt-2 xui-mb-2">
						<form className="xui-form" onSubmit={handleUploadComplianceDocument}>
							<div className="xui-form-box xui-w-fluid-100 xui-mt-3">
								<label>Registration Document</label>
								<label htmlFor="registrationDocument">
									<div className="xui-w-fluid-100 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
										{
											selectedComplianceDocument ?
												<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{selectedComplianceDocument.name}</span> :
												<>
													{
														viewBusinessDetails ?
															(
																viewBusinessDetails.data.registration_document === null ?
																	<img className="xui-img-50" src={GalleryAdd} alt="" /> :
																	(
																		getFileExtension(viewBusinessDetails.data.registration_document) === "pdf" || getFileExtension(viewBusinessDetails.data.registration_document) === "PDF" ?
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{getFileNameAlone(viewBusinessDetails.data.registration_document)}</span>
																				<span title="View File" className="xui-cursor-pointer xui-mr-1" onClick={() => { showPreview(viewBusinessDetails.data.registration_document); }}>
																					<EyeOpen width="20" height="20" />
																				</span>
																			</div> :
																			<img className="xui-img-200" src={viewBusinessDetails.data.registration_document} alt="Registration Document" />
																	)
															) :
															<img className="xui-img-50" src={GalleryAdd} alt="" />
													}
													<span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
												</>
										}
									</div>
								</label>
								<input disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} onChange={handleSelectComplianceDocument} type={"file"} id="registrationDocument" style={{ display: "none" }} required />
								<div className="xui-mt-1">
									{
										uploadingComplianceDocumentPercentage > 0 ?
											<>
												<label htmlFor="uploader">Uploading</label>
												<progress className="xui-h-30" value={uploadingComplianceDocumentPercentage} id="uploader" max="100">{uploadingComplianceDocumentPercentage + "%"}</progress><br /><br></br>
											</> :
											""
									}
									{
										loadingComplianceDocument ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												<Loading width="16" height="16" />
											</button> :
											viewBusinessDetails ?
												(
													<>
														{
															viewBusinessDetails.data.verified ?
																<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
																	Verified
																</button> :
																<button type="submit" className="xui-btn psc-btn-blue xui-font-sz-80">
																	Upload
																</button>
														}
													</>
												) :
												<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
													Loading ...
												</button>
									}
								</div>
								<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorComplianceDocument}</span></p>
								<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successComplianceDocument}</span></p>
							</div>
						</form>
						<form className="xui-form" onSubmit={handleUploadComplianceCertificate}>
							<div className="xui-form-box xui-w-fluid-100 xui-mt-3">
								<label>Registration Certificate</label>
								<label htmlFor="registrationCertificate">
									<div className="xui-w-fluid-100 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
										{
											selectedComplianceCertificate ?
												<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{selectedComplianceCertificate.name}</span> :
												<>
													{
														viewBusinessDetails ?
															(
																viewBusinessDetails.data.registration_certificate === null ?
																	<img className="xui-img-50" src={GalleryAdd} alt="" /> :
																	(
																		getFileExtension(viewBusinessDetails.data.registration_certificate) === "pdf" || getFileExtension(viewBusinessDetails.data.registration_certificate) === "PDF" ?
																			<div className='xui-d-inline-flex xui-flex-ai-center'>
																				<span className="xui-font-sz-120 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80" style={{ wordBreak: "break-word" }}>{getFileNameAlone(viewBusinessDetails.data.registration_certificate)}</span>
																				<span title="View File" className="xui-cursor-pointer xui-mr-1" onClick={() => { showPreview(viewBusinessDetails.data.registration_certificate); }}>
																					<EyeOpen width="20" height="20" />
																				</span>
																			</div> :
																			<img className="xui-img-200" src={viewBusinessDetails.data.registration_certificate} alt="Registration Certificate" />
																	)
															) :
															<img className="xui-img-50" src={GalleryAdd} alt="" />
													}
													<span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
												</>
										}
									</div>
								</label>
								<input disabled={viewBusinessDetails ? viewBusinessDetails.data.verified : false} onChange={handleSelectComplianceCertificate} type={"file"} id="registrationCertificate" style={{ display: "none" }} required />
								<div className="xui-mt-1">
									{
										uploadingComplianceCertificatePercentage > 0 ?
											<>
												<label htmlFor="uploader">Uploading</label>
												<progress className="xui-h-30" value={uploadingComplianceCertificatePercentage} id="uploader" max="100">{uploadingComplianceCertificatePercentage + "%"}</progress><br /><br></br>
											</> :
											""
									}
									{
										loadingComplianceCertificate ?
											<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
												<Loading width="16" height="16" />
											</button> :
											viewBusinessDetails ?
												(
													<>
														{
															viewBusinessDetails.data.verified ?
																<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
																	Verified
																</button> :
																<button type="submit" className="xui-btn psc-btn-blue xui-font-sz-80">
																	Upload
																</button>
														}
													</>
												) :
												<button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
													Loading ...
												</button>
									}
								</div>
								<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorComplianceCertificate}</span></p>
								<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successComplianceCertificate}</span></p>
							</div>
						</form>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="grantBusinessAccessModal" id="grantBusinessAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Grant Business Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorGrantBusinessAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successGrantBusinessAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleGrantBusinessAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingGrantBusinessAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingGrantBusinessAccess ? "" : "grantBusinessAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="suspendBusinessAccessModal" id="suspendBusinessAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Suspend Business Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorSuspendBusinessAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successSuspendBusinessAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleSuspendBusinessAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingSuspendBusinessAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingSuspendBusinessAccess ? "" : "suspendBusinessAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="revokeBusinessAccessModal" id="revokeBusinessAccessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Revoke Business Access</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRevokeBusinessAccess}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRevokeBusinessAccess}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleRevokeBusinessAccess} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingRevokeBusinessAccess ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingRevokeBusinessAccess ? "" : "revokeBusinessAccessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="upgradePremiumPackageModal" id="upgradePremiumPackageModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Upgrade {viewBusinessDetails && viewBusinessDetails.success ? (viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city) : ""} to {premiumPackage} Premium Package</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPremiumPackage}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPremiumPackage}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handlePremiumPackage} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingPremiumPackage ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingPremiumPackage ? "" : "upgradePremiumPackageModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="verifyBusinessModal" id="verifyBusinessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify {viewBusinessDetails && viewBusinessDetails.success ? (viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city) : ""} Business</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyBusiness}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyBusiness}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleVerifyBusiness} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingVerifyBusiness ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyBusiness ? "" : "verifyBusinessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="unverifyBusinessModal" id="unverifyBusinessModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Unverify {viewBusinessDetails && viewBusinessDetails.success ? (viewBusinessDetails.data.name + ", " + viewBusinessDetails.data.city) : ""} Business</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUnverifyBusiness}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUnverifyBusiness}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleUnverifyBusiness} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingUnverifyBusiness ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUnverifyBusiness ? "" : "unverifyBusinessModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="businessSearchModal" id="businessSearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="businessSearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for Business</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getBusinessDetailsViaEmail(businessEmailSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Email</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"email"} placeholder={"Enter email address"} value={businessEmailSearch} onChange={handleBusinessEmailSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open={`${businessEmailSearch ? "viewBusinessDetailsModal" : ""}`}>
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	);
}