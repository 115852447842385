import { useEffect, useState } from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Screen from "../components/Screen";
import useCookie from "../hooks/useCookie";
import Search from "../icons/Search";
import { getSschool, getSschoolViaPID, getSschools, getSschoolsViaProofType, getSschoolsViaVerifiedStatus } from "../api/secondarySchool";
import { config } from "../config";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Check from "../icons/Check";
import Copy from "../icons/Copy";
import EyeOpenAlt from "../icons/EyeOpenAlt";
import Star from "../icons/Star";
import { useVerifyUserSschool, useDeclineUserSschool } from "../hooks/useSecondarySchool";
import Arrowleft from "../icons/Arrowleft";
import Arrowright from "../icons/Arrowright";
import Filter from "../icons/Filter";

export default function PrimarySchool() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const { handleVerifyUserSschool, setUserPID: verifyUserSschoolPID,
		setSschoolUniqueId: verifyUserSschoolSschoolId, errorVerifyUserSschool, successVerifyUserSschool,
		loadingVerifyUserSschool, removeVeriyUserSschoolModal, setRemoveVerifyUserSschoolModal
	} = useVerifyUserSschool();

	const { errorDeclineSschool, successDeclineSschool, handleDeclineSschool, setUserPID: declineUserSschoolPID,
		setSschoolUniqueId: declineUserSschoolSschoolId, declineMsg, setDeclineMsg,
		loadingDeclineSschool, removeDeclineSschoolModal, setRemoveDeclineSschoolModal } = useDeclineUserSschool()

	const [currentFunction, setCurrentFunction] = useState("getAllSschools");

	const [allSecondarySchools, setAllSecondarySchools] = useState(null);
	const [errorAllSschools, setErrorAllSschools] = useState(null);
	const [loadingAllSschools, setLoadingAllSschools] = useState(false);

	const [sschoolVerifiedStatus, setSschoolVerifiedStatus] = useState(null)
	const [sschoolProofType, setSschoolProofType] = useState(null)

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); recordsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); recordsByPage(parseInt(e.target.value), size); };
	const handleSschoolVerifiedStatus = (e) => { e.preventDefault(); setSschoolVerifiedStatus(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllSschoolsByVerification"); getAllSschoolsByVerification(e.target.value === 'verified' ? true : false, page, size); };
	const handleSschoolProofType = (e) => { e.preventDefault(); setSschoolProofType(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllSschoolsByProofType"); getAllSschoolsByProofType(e.target.value, page, size); };

	async function recordsBySize(size) {
		switch (currentFunction) {
			case "getAllSschools":
				getAllSschools(page, size);
				break;
			case "getAllSschoolsByVerification":
				getAllSschoolsByVerification(sschoolVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			case "getAllSschoolsByProofType":
				getAllSschoolsByProofType(sschoolProofType, page, size);
				break;
			default:
				getAllSschools(page, size);
		}
	};

	async function recordsByPage(page) {
		switch (currentFunction) {
			case "getAllSschools":
				getAllSschools(page, size);
				break;
			case "getAllSschoolsByVerification":
				getAllSschoolsByVerification(sschoolVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			case "getAllSschoolsByProofType":
				getAllSschoolsByProofType(sschoolProofType, page, size);
				break;
			default:
				getAllSschools(page, size);
		}
	};

	async function callLastFunction() {
		switch (currentFunction) {
			case "getAllSschools":
				getAllSschools(page, size);
				break;
			case "getAllSschoolsByVerification":
				getAllSschoolsByVerification(sschoolVerifiedStatus === 'verified' ? true : false, page, size);
				break;
			case "getAllSschoolsByProofType":
				getAllSschoolsByProofType(sschoolProofType, page, size);
				break;
			// default:
			// 	getAllSschools(page, size);
		}
	};

	async function previousSschools() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllSschools":
					getAllSschools(page - 1, size);
					break;
				case "getAllSschoolsByVerification":
					getAllSschoolsByVerification(sschoolVerifiedStatus === 'verified' ? true : false, page - 1, size);
					break;
				case "getAllSschoolsByProofType":
					getAllSschoolsByProofType(sschoolProofType, page - 1, size);
					break;
				default:
					getAllSschools(page - 1, size);
			}
		}
	};

	async function nextSschools() {
		if (page < allSecondarySchools.data.pages) setPage(page + 1);
		if (page < allSecondarySchools.data.pages) {
			switch (currentFunction) {
				case "getAllSschools":
					getAllSschools(page + 1, size);
					break;
				case "getAllSschoolsByVerification":
					getAllSschoolsByVerification(sschoolVerifiedStatus === 'verified' ? true : false, page + 1, size);
					break;
				case "getAllSschoolsByProofType":
					getAllSschoolsByProofType(sschoolProofType, page + 1, size);
					break;
				default:
					getAllSschools(page + 1, size);
			}
		}
	};

	async function getAllSschools(_page, _size) {
		setLoadingAllSschools(true);
		const response = await getSschools(cookie, (_page || page), (_size || size));
		setAllSecondarySchools(response.data);
		if (response.error) setErrorAllSschools(response.error.response.data.message);
		setLoadingAllSschools(false);
	}

	async function getAllSschoolsByVerification(verified, _page, _size) {
		setLoadingAllSschools(true)
		const response = await getSschoolsViaVerifiedStatus(cookie, (_page || page), (_size || size), { verified: verified })
		setAllSecondarySchools(response.data)
		if (response.error) setErrorAllSschools(response.error.response.data.message);
		setLoadingAllSschools(false);
	}

	async function getAllSschoolsByProofType(certificate_type, _page, _size) {
		setLoadingAllSschools(true)
		const response = await getSschoolsViaProofType(cookie, (_page || page), (_size || size), { certificate_type })
		setAllSecondarySchools(response.data)
		if (response.error) setErrorAllSschools(response.error.response.data.message);
		setLoadingAllSschools(false);
	}

	useEffect(() => {
		if (allSecondarySchools === null) {
			callLastFunction();
		}
	}, [allSecondarySchools])

	const [loadingViewSschool, setLoadingViewSschool] = useState(false)
	const [errorViewSschool, setErrorViewSschool] = useState(null)
	const [viewUserSschool, setViewUserSschool] = useState(null)

	async function getUserSschool(uniqueId, pid) {
		setLoadingViewSschool(true)
		const response = await getSschool(cookie, { unique_id: uniqueId, pid: pid })
		if (!response.err) {
			setViewUserSschool(response.data)
		} else { setErrorViewSschool(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewSschool(false)
	}

	async function getUserSschoolViaPID(pid) {
		const modalResponse = document.querySelector("#userSschoolSearchModal");
		modalResponse.setAttribute("display", false);
		setLoadingViewSschool(true);
		const response = await getSschoolViaPID(cookie, { pid: pid })
		if (!response.error) {
			setViewUserSschool(response.data)
		} else {
			setViewUserSschool(null); setErrorViewSschool(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message)
		}
		setLoadingViewSschool(false)
		setSschoolPIDSearch("")
	}

	const [sschoolPIDSearch, setSschoolPIDSearch] = useState("")
	const handleSschoolPIDSearch = (e) => { e.preventDefault(); setSschoolPIDSearch(e.target.value); }

	if (removeVeriyUserSschoolModal) {
		const modalResponse = document.querySelector("#verifyUserSschoolModal");
		modalResponse.setAttribute('display', false);
		getUserSschool(viewUserSschool.data.data.unique_id, viewUserSschool.data.data.pid)
		setRemoveVerifyUserSschoolModal(null)
	}
	if (removeDeclineSschoolModal) {
		const modalResponse = document.querySelector("#declineUserSschoolModal")
		modalResponse.setAttribute('display', false)
		getUserSschool(viewUserSschool.data.data.unique_id, viewUserSschool.data.data.pid)
		setRemoveDeclineSschoolModal(null)
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allSecondarySchools ? allSecondarySchools.data.pages : 0).fill(0);

	return (
		<>
			<Screen>
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className='xui-mt-2'>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All User Secondary Schools</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Total - {allSecondarySchools && allSecondarySchools.success ? allSecondarySchools.data.total : "..."}</p>
							</div>

							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="userSschoolSearchModal">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={sschoolVerifiedStatus} onChange={handleSschoolVerifiedStatus} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												sschoolVerifiedStatus === null ?
													<option selected disabled>Filter By verification Status</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"verified"}>Verified</option>
											<option value={"unverified"}>Unverified</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={sschoolProofType} onChange={handleSschoolProofType} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!sschoolProofType ?
													<option selected disabled>Filter By Certificate Type</option> :
													<option value={null}>Select to Reset</option>
											}
											<option value={"NECO"}>NECO</option>
											<option value={"GCE"}>GCE</option>
											<option value={"SSCE"}>SSCE</option>
											<option value={"JAMB"}>JAMB</option>
										</select>
									</div>
								</div>
							</div>

						</div>

						{
							loadingAllSschools
								?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center>
								:
								(
									allSecondarySchools && allSecondarySchools.success
										?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-100'>PID</th>
														<th className='xui-min-w-300'>Name</th>
														<th className='xui-min-w-50'>From</th>
														<th className='xui-min-w-50'>To</th>
														<th className='xui-min-w-100'>Certificate</th>
														<th className='xui-min-w-200'>Verification</th>
														<th className='xui-min-w-200'>Declined</th>
														<th className='xui-min-w-300'>Declined Timestamp</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-50'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{
														allSecondarySchools.data.rows.map((data, i) => (
															<tr className='' key={i}>
																<td className='xui-opacity-5'>
																	<span>{i + 1}</span>
																</td>
																<td className='xui-opacity-5 xui-font-w-bold'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>#{data.pid}</span>
																		<span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.pid); setTextCopied(data.pid); }}>
																			{copiedText && textCopied === data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																		</span>
																	</div>
																</td>
																<td className='xui-opacity-5'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>{data.name}</span>
																	</div>
																</td>
																<td className='xui-opacity-5'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>{data.from_year}</span>
																	</div>
																</td>
																<td className='xui-opacity-5'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>{data.to_year}</span>
																	</div>
																</td>
																<td className='xui-opacity-5'>
																	<div className='xui-d-inline-flex xui-flex-ai-center'>
																		<span>{data.certificate_type}</span>
																	</div>
																</td>
																<td className='xui-opacity-5'>
																	{
																		data.verified ?
																			<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Verified</span> :
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Verified</span>
																	}
																</td>
																<td className='xui-opacity-5'>
																	{
																		data.declined === null ?
																			<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																			(
																				data.declined ?
																					<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																					<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
																			)

																	}
																</td>
																<td className='xui-opacity-5'>
																	{
																		data.declined_timestamp === null ?
																			<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
																			(
																				data.declined_timestamp ?
																					<span>{data.declined_timestamp.date} at {data.declined_timestamp.time}</span> :
																					"No timestamp"
																			)
																	}
																</td>
																<td className='xui-opacity-5'>
																	{
																		data.status === 1 ?
																			<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> :
																			<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
																	}
																</td>
																<td className=''>
																	<div className="xui-d-flex xui-grid-gap-1">
																		<button title="View User Details" onClick={() => { getUserSschool(data.unique_id, data.pid) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewUserSschoolModal">
																			<EyeOpenAlt width="16" height="16" />
																		</button>
																	</div>
																</td>
															</tr>
														))
													}
												</tbody>
											</table>
										</div>
										:
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllSschools}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllSschools ?
								<Loading width="12" height="12" /> :
								(
									allSecondarySchools && allSecondarySchools.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allSecondarySchools ? allSecondarySchools.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousSschools}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextSschools}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewUserSschoolModal" id="viewUserSschoolModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewUserSschoolModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewSschool
							?
							<center>
								<Loading width="12" height="12" />
							</center>
							:
							(
								viewUserSschool && viewUserSschool.success
									?
									<>
										<h1>{viewUserSschool.data.user_data.firstname + (viewUserSschool.data.user_data.middlename ? " " + viewUserSschool.data.user_data.middlename + " " : " ") + viewUserSschool.data.user_data.lastname}</h1>
										<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Sschool details for this user below</p>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewUserSschool.data.user_data.photo} alt={viewUserSschool.data.user_data.firstname + (viewUserSschool.data.user_data.middlename ? " " + viewUserSschool.data.user_data.middlename + " " : " ") + viewUserSschool.data.user_data.lastname + " Selfie Image"} />
												<center className="xui-mt-1">
													<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewUserSschool.data.data.pid}</b>
													<center>
														<div className="xui-d-inline-flex xui-flex-ai-center">
															<span>
																{
																	viewUserSschool.data.user_data.star === 0 ?
																		<div className='xui-m-half'>
																			<p>No star</p>
																		</div>
																		: ""
																}
																{
																	viewUserSschool.data.user_data.star === 1 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserSschool.data.user_data.star === 2 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserSschool.data.user_data.star === 3 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserSschool.data.user_data.star === 4 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
																{
																	viewUserSschool.data.user_data.star === 5 ?
																		<div className='xui-m-half'>
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																			<Star width="18" height="18" />
																		</div>
																		: ""
																}
															</span>
														</div>
													</center>
													<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">No: <b>{viewUserSschool.data.user_data.phone_number}</b></p>
												</center>
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">School -</span> {viewUserSschool.data.data.name}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">From -</span> {viewUserSschool.data.data.from_year}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">To -</span> {viewUserSschool.data.data.to_year}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Certificate Type -</span> {viewUserSschool.data.data.certificate_type}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
													viewUserSschool.data.data.verified ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined -</span> {
													viewUserSschool.data.data.declined === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserSschool.data.data.declined ?
																<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Declined</span> :
																<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Not Declined</span>
														)

												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Declined Timestamp -</span> {
													viewUserSschool.data.data.declined_timestamp === null ?
														<span className='xui-badge xui-badge-blue xui-font-sz-80 xui-bdr-rad-half'>Unattended</span> :
														(
															viewUserSschool.data.data.declined_timestamp ?
																<span>{viewUserSschool.data.data.declined_timestamp.date} at {viewUserSschool.data.data.declined_timestamp.time}</span> :
																"No timestamp"
														)
												}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status -</span> {
													viewUserSschool.data.data.status === 1
														?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span>
														:
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span>
												}</p>
												<p className="xui-opacity-10 xui-font-sz-100 xui-m-half"><span className="xui-opacity-4 xui-font-w-bold">Proof -</span> {
													viewUserSschool.data.data.proof
														?
														viewUserSschool.data.data.proof.split(".")[3] === 'pdf'
															?
															<a href={viewUserSschool.data.data.proof} rel="noreferrer" target="_blank" > {viewUserSschool.data.user_data.firstname + viewUserSschool.data.user_data.lastname + "pdf_proof"} </a>
															:
															<img className="xui-img-300 xui-max-h-300" src={viewUserSschool.data.data.proof} alt={viewUserSschool.data.user_data.firstname + (viewUserSschool.data.user_data.middlename ? " " + viewUserSschool.data.user_data.middlename + " " : " ") + viewUserSschool.data.user_data.lastname + " Proof"} />
														:
														"Null"
												}</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewUserSschool.data.data.createdAt?.fulldate} | Last Updated - {viewUserSschool.data.data.updatedAt?.fulldate}</p>
											<div className="xui-m-2">
												<button title="Verify User Sschool" onClick={() => { verifyUserSschoolPID(viewUserSschool.data.data.pid); verifyUserSschoolSschoolId(viewUserSschool.data.data.unique_id) }} className="xui-btn xui-mr-3 psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyUserSschoolModal">
													<Check width="16" height="16" />
												</button>

												<button title="Decline User Sschool" onClick={() => { declineUserSschoolPID(viewUserSschool.data.data.pid); declineUserSschoolSschoolId(viewUserSschool.data.data.unique_id) }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineUserSschoolModal">
													<Close width="16" height="16" />
												</button>
											</div>
										</center>
									</>
									:
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewSschool}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>

			<section className='xui-modal' xui-modal="verifyUserSschoolModal" id="verifyUserSschoolModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify User Secondary School</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyUserSschool}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyUserSschool}</span></p>
						<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button onClick={handleVerifyUserSschool} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Yes</span>
									{
										loadingVerifyUserSschool ?
											<Loading width="12" height="12" />
											: <Check width="20" height="20" />
									}
								</button>
							</div>

							<div className="xui-d-inline-flex xui-flex-ai-center">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyUserSschool ? "" : "verifyUserSschoolModal"}>
									<span className="xui-mr-half">No</span>
									<Close width="20" height="20" />
								</button>
							</div>
						</div>
					</center>
				</div>
			</section>

			<section className='xui-modal' xui-modal="declineUserSschoolModal" id="declineUserSschoolModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Decline User Secondary School</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Enter message to continue with this action?</p>
					</center>
					<form className="xui-form xui-mt-2">
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Feedback</label>
							<textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={declineMsg} onChange={(e) => setDeclineMsg(e.target.value)}></textarea>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineSschool}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineSschool}</span></p>
					</form>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeclineSschool} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeclineSschool ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>

						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineSschool ? "" : "declineUserSschoolModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>

			<section className='xui-modal' xui-modal="userSschoolSearchModal" id="userSschoolSearchModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="userSschoolSearchModal">
						<Close width="24" height="24" />
					</div>
					<h1>Search for User</h1>
					<form className="xui-form xui-mt-2" onSubmit={(e) => { e.preventDefault(); getUserSschoolViaPID(sschoolPIDSearch); }}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>PID</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<input style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={"Enter PID"} value={sschoolPIDSearch} onChange={handleSschoolPIDSearch} required />
								<button className="xui-bdr-light-blue xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open={`${sschoolPIDSearch ? "viewUserSschoolModal" : ""}`}>
									<Search width="16" height="16" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}