import { useState } from "react";
import useCookie from "./useCookie";
import { declineUserTinstitution, verifyUserTinstitution } from "../api/tertiaryInstitution";
import { config } from "../config";

function useVerifyUserTinstitution(){
    const { cookie } = useCookie(config.key, "");

    const [loadingVerifyUserTinstitution, setLoadingVerifyUserTinstitution] = useState(false)
    const [removeVeriyUserTinstitutionModal, setRemoveVerifyUserTinstitutionModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [tinstitutionUniqueId, setTinstitutionUniqueId] = useState(null)

    const [errorVerifyUserTinstitution, setErrorVerifyUserTinstitution] = useState(null)
    const [successVerifyUserTinstitution, setSuccessVerifyUserTinstitution] = useState(null)

    const handleVerifyUserTinstitution = ()=>{
        if(!loadingVerifyUserTinstitution){
            if(!userPID || !tinstitutionUniqueId){
                setErrorVerifyUserTinstitution(null)
                setSuccessVerifyUserTinstitution(null)
                setErrorVerifyUserTinstitution(userPID ? "Unique id is required" : "User PID is required")
                setTimeout(function(){
                    setErrorVerifyUserTinstitution(null)
                }, 2500)
            }else{
                setLoadingVerifyUserTinstitution(true)

                const verifyUserTinstitutionRes = verifyUserTinstitution(cookie, {pid: userPID, unique_id: tinstitutionUniqueId})

                verifyUserTinstitutionRes.then((res)=>{
                
                    setLoadingVerifyUserTinstitution(false)
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorVerifyUserTinstitution(error)
                            setTimeout(()=>{
                                setErrorVerifyUserTinstitution(null)
                            }, 2000)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorVerifyUserTinstitution(error)
                            setTimeout(()=>{
                                setErrorVerifyUserTinstitution(null)
                            }, 2000)
                        }
                    } else {
                        setErrorVerifyUserTinstitution(null)
                        setSuccessVerifyUserTinstitution("User tertiary institution verified")

                        setTimeout(()=>{
                            setSuccessVerifyUserTinstitution(null)
                            setRemoveVerifyUserTinstitutionModal(true)
                            setUserPID(null)
                            setTinstitutionUniqueId(null)
                        }, 2500)
                    }
                }).catch(err=>{
                    setLoadingVerifyUserTinstitution(false)
                })
            }

        }
    }

    return{
        cookie, loadingVerifyUserTinstitution, successVerifyUserTinstitution, errorVerifyUserTinstitution, removeVeriyUserTinstitutionModal, setRemoveVerifyUserTinstitutionModal, handleVerifyUserTinstitution,
        userPID, setUserPID, tinstitutionUniqueId, setTinstitutionUniqueId
    }
}

function useDeclineUserTinstitution(){
    const{cookie}= useCookie(config.key, "");

    const [loadingDeclineTinstitution, setLoadingDeclineTinstitution] = useState(false)
    const [removeDeclineTinstitutionModal, setRemoveDeclineTinstitutionModal] = useState(null)
    const [userPID, setUserPID] = useState(null)
    const [tinstitutionUniqueId, setTinstitutionUniqueId] = useState(null)
    const [declineMsg, setDeclineMsg] = useState(null)

    const [errorDeclineTinstitution, setErrorDeclineTinstitution] = useState(null)
    const [successDeclineTinstitution, setSuccessDeclineTinstitution] = useState(null)

    const handleDeclineTinstitution = ()=>{
        if(!loadingDeclineTinstitution){
            if(!userPID || !tinstitutionUniqueId){
                setErrorDeclineTinstitution(null)
                setSuccessDeclineTinstitution(null)
                setErrorDeclineTinstitution(userPID ? "Tinstitution uniqueId is required": "User PID is required")
                setTimeout(()=>{
                    setErrorDeclineTinstitution(null)
                }, 2500)
            }else{
                setLoadingDeclineTinstitution(true)

                const declineUserTinstitutionRes = declineUserTinstitution(cookie, {pid: userPID, unique_id: tinstitutionUniqueId, message: declineMsg})

                declineUserTinstitutionRes.then((res)=>{
                    setLoadingDeclineTinstitution(false)
                
                    if(res.error){
                        if(!res.error.response.data.success){
                            const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
                            setErrorDeclineTinstitution(error)
                            setTimeout(()=>{
                                setErrorDeclineTinstitution(null)
                            }, 2500)
                        }else{
                            const error = `${res.error.code} - ${res.error.message}`;
                            setErrorDeclineTinstitution(error)
                            setTimeout(()=>{
                                setErrorDeclineTinstitution(null)
                            }, 2500)
                        }
                    }else{
                        setErrorDeclineTinstitution(null)
                        setDeclineMsg("")
                        setSuccessDeclineTinstitution("User tertiary institution declined")

                        setTimeout(()=>{
                            setSuccessDeclineTinstitution(null)
                            setRemoveDeclineTinstitutionModal(true)
                            setUserPID(null)
                            setTinstitutionUniqueId(null)
                        }, 2500)
                    }
                })
            }
        }
    }

    return {
        errorDeclineTinstitution, successDeclineTinstitution, loadingDeclineTinstitution, 
        userPID, setUserPID, tinstitutionUniqueId, setTinstitutionUniqueId, declineMsg, setDeclineMsg, handleDeclineTinstitution,
        removeDeclineTinstitutionModal, setRemoveDeclineTinstitutionModal
    }
}

export {useVerifyUserTinstitution, useDeclineUserTinstitution}