import axios from 'axios';
import { config } from '../config';

const getTransferLogs = async function (key, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransferLogsViaStatus = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs/via/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransferLogsViaReceiver = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs/via/receiver`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransferLogsViaEmail = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs/via/email`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransferLogsViaPhone = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs/via/phone`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getTransferLogsViaReference = async function (key, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/transfer/logs/via/reference`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getTransferLogs, getTransferLogsViaStatus, getTransferLogsViaEmail, getTransferLogsViaPhone, getTransferLogsViaReceiver, getTransferLogsViaReference };