import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import {
	grantUserAccess, grantUserAccessViaEmail, revokeUserAccess, revokeUserAccessViaEmail, suspendUserAccess, suspendUserAccessViaEmail, updateUserStar, updateUserBalance
} from "../api/users";

const useGrantUserAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingGrantUserAccess, setLoadingGrantUserAccess] = useState(false);
	const [removeGrantUserAccessModal, setRemoveGrantUserAccessModal] = useState(null);
	const [userPID, setUserPID] = useState(null);

	const [errorGrantUserAccess, setErrorGrantUserAccess] = useState(null);
	const [successGrantUserAccess, setSuccessGrantUserAccess] = useState(null);

	const handleGrantUserAccess = () => {

		if (!loadingGrantUserAccess) {
			if (!userPID) {
				setErrorGrantUserAccess(null);
				setSuccessGrantUserAccess(null);
				setErrorGrantUserAccess("User PID is required");
				setTimeout(function () {
					setErrorGrantUserAccess(null);
				}, 2500)
			} else {
				setLoadingGrantUserAccess(true);

				const grantUserAccessRes = grantUserAccess(cookie, {
					pid: userPID
				})

				grantUserAccessRes.then(res => {
					setLoadingGrantUserAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorGrantUserAccess(error);
							setTimeout(function () {
								setErrorGrantUserAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorGrantUserAccess(error);
							setTimeout(function () {
								setErrorGrantUserAccess(null);
							}, 2000)
						}
					} else {
						setErrorGrantUserAccess(null);
						setSuccessGrantUserAccess(`User access granted!`);

						setTimeout(function () {
							setSuccessGrantUserAccess(null);
							setRemoveGrantUserAccessModal(true);
							setUserPID(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingGrantUserAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingGrantUserAccess, removeGrantUserAccessModal, errorGrantUserAccess, successGrantUserAccess, handleGrantUserAccess, 
		setRemoveGrantUserAccessModal, setUserPID
	};
};

const useSuspendUserAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingSuspendUserAccess, setLoadingSuspendUserAccess] = useState(false);
	const [removeSuspendUserAccessModal, setRemoveSuspendUserAccessModal] = useState(null);
	const [userPID, setUserPID] = useState(null);

	const [errorSuspendUserAccess, setErrorSuspendUserAccess] = useState(null);
	const [successSuspendUserAccess, setSuccessSuspendUserAccess] = useState(null);

	const handleSuspendUserAccess = () => {

		if (!loadingSuspendUserAccess) {
			if (!userPID) {
				setErrorSuspendUserAccess(null);
				setSuccessSuspendUserAccess(null);
				setErrorSuspendUserAccess("User PID is required");
				setTimeout(function () {
					setErrorSuspendUserAccess(null);
				}, 2500)
			} else {
				setLoadingSuspendUserAccess(true);

				const suspendUserAccessRes = suspendUserAccess(cookie, {
					pid: userPID
				})

				suspendUserAccessRes.then(res => {
					setLoadingSuspendUserAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorSuspendUserAccess(error);
							setTimeout(function () {
								setErrorSuspendUserAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorSuspendUserAccess(error);
							setTimeout(function () {
								setErrorSuspendUserAccess(null);
							}, 2000)
						}
					} else {
						setErrorSuspendUserAccess(null);
						setSuccessSuspendUserAccess(`User access suspended!`);

						setTimeout(function () {
							setSuccessSuspendUserAccess(null);
							setRemoveSuspendUserAccessModal(true);
							setUserPID(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingSuspendUserAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingSuspendUserAccess, removeSuspendUserAccessModal, errorSuspendUserAccess, successSuspendUserAccess, handleSuspendUserAccess,
		setRemoveSuspendUserAccessModal, setUserPID
	};
};

const useRevokeUserAccess = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRevokeUserAccess, setLoadingRevokeUserAccess] = useState(false);
	const [removeRevokeUserAccessModal, setRemoveRevokeUserAccessModal] = useState(null);
	const [userPID, setUserPID] = useState(null);

	const [errorRevokeUserAccess, setErrorRevokeUserAccess] = useState(null);
	const [successRevokeUserAccess, setSuccessRevokeUserAccess] = useState(null);

	const handleRevokeUserAccess = () => {

		if (!loadingRevokeUserAccess) {
			if (!userPID) {
				setErrorRevokeUserAccess(null);
				setSuccessRevokeUserAccess(null);
				setErrorRevokeUserAccess("User PID is required");
				setTimeout(function () {
					setErrorRevokeUserAccess(null);
				}, 2500)
			} else {
				setLoadingRevokeUserAccess(true);

				const revokeUserAccessRes = revokeUserAccess(cookie, {
					pid: userPID
				})

				revokeUserAccessRes.then(res => {
					setLoadingRevokeUserAccess(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRevokeUserAccess(error);
							setTimeout(function () {
								setErrorRevokeUserAccess(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRevokeUserAccess(error);
							setTimeout(function () {
								setErrorRevokeUserAccess(null);
							}, 2000)
						}
					} else {
						setErrorRevokeUserAccess(null);
						setSuccessRevokeUserAccess(`User access revoked!`);

						setTimeout(function () {
							setSuccessRevokeUserAccess(null);
							setRemoveRevokeUserAccessModal(true);
							setUserPID(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRevokeUserAccess(false);
				})

			}
		}
	};

	return {
		cookie, loadingRevokeUserAccess, removeRevokeUserAccessModal, errorRevokeUserAccess, successRevokeUserAccess, handleRevokeUserAccess,
		setRemoveRevokeUserAccessModal, setUserPID
	};
};

const useGrantUserAccessViaEmail = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingGrantUserAccessViaEmail, setLoadingGrantUserAccessViaEmail] = useState(false);
	const [removeGrantUserAccessViaEmailModal, setRemoveGrantUserAccessViaEmailModal] = useState(null);
	const [userEmail, setUserEmail] = useState(null);

	const [errorGrantUserAccessViaEmail, setErrorGrantUserAccessViaEmail] = useState(null);
	const [successGrantUserAccessViaEmail, setSuccessGrantUserAccessViaEmail] = useState(null);

	const handleGrantUserAccessViaEmail = () => {

		if (!loadingGrantUserAccessViaEmail) {
			if (!userEmail) {
				setErrorGrantUserAccessViaEmail(null);
				setSuccessGrantUserAccessViaEmail(null);
				setErrorGrantUserAccessViaEmail("User PID is required");
				setTimeout(function () {
					setErrorGrantUserAccessViaEmail(null);
				}, 2500)
			} else {
				setLoadingGrantUserAccessViaEmail(true);

				const grantUserAccessViaEmailRes = grantUserAccessViaEmail(cookie, {
					email: userEmail
				})

				grantUserAccessViaEmailRes.then(res => {
					setLoadingGrantUserAccessViaEmail(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorGrantUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorGrantUserAccessViaEmail(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorGrantUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorGrantUserAccessViaEmail(null);
							}, 2000)
						}
					} else {
						setErrorGrantUserAccessViaEmail(null);
						setSuccessGrantUserAccessViaEmail(`User access granted!`);

						setTimeout(function () {
							setSuccessGrantUserAccessViaEmail(null);
							setRemoveGrantUserAccessViaEmailModal(true);
							setUserEmail(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingGrantUserAccessViaEmail(false);
				})

			}
		}
	};

	return {
		cookie, loadingGrantUserAccessViaEmail, removeGrantUserAccessViaEmailModal, errorGrantUserAccessViaEmail, successGrantUserAccessViaEmail, handleGrantUserAccessViaEmail,
		setRemoveGrantUserAccessViaEmailModal, setUserEmail
	};
};

const useSuspendUserAccessViaEmail = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingSuspendUserAccessViaEmail, setLoadingSuspendUserAccessViaEmail] = useState(false);
	const [removeSuspendUserAccessViaEmailModal, setRemoveSuspendUserAccessViaEmailModal] = useState(null);
	const [userEmail, setUserEmail] = useState(null);

	const [errorSuspendUserAccessViaEmail, setErrorSuspendUserAccessViaEmail] = useState(null);
	const [successSuspendUserAccessViaEmail, setSuccessSuspendUserAccessViaEmail] = useState(null);

	const handleSuspendUserAccessViaEmail = () => {

		if (!loadingSuspendUserAccessViaEmail) {
			if (!userEmail) {
				setErrorSuspendUserAccessViaEmail(null);
				setSuccessSuspendUserAccessViaEmail(null);
				setErrorSuspendUserAccessViaEmail("User PID is required");
				setTimeout(function () {
					setErrorSuspendUserAccessViaEmail(null);
				}, 2500)
			} else {
				setLoadingSuspendUserAccessViaEmail(true);

				const suspendUserAccessViaEmailRes = suspendUserAccessViaEmail(cookie, {
					email: userEmail
				})

				suspendUserAccessViaEmailRes.then(res => {
					setLoadingSuspendUserAccessViaEmail(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorSuspendUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorSuspendUserAccessViaEmail(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorSuspendUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorSuspendUserAccessViaEmail(null);
							}, 2000)
						}
					} else {
						setErrorSuspendUserAccessViaEmail(null);
						setSuccessSuspendUserAccessViaEmail(`User access suspended!`);

						setTimeout(function () {
							setSuccessSuspendUserAccessViaEmail(null);
							setRemoveSuspendUserAccessViaEmailModal(true);
							setUserEmail(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingSuspendUserAccessViaEmail(false);
				})

			}
		}
	};

	return {
		cookie, loadingSuspendUserAccessViaEmail, removeSuspendUserAccessViaEmailModal, errorSuspendUserAccessViaEmail, successSuspendUserAccessViaEmail, handleSuspendUserAccessViaEmail,
		setRemoveSuspendUserAccessViaEmailModal, setUserEmail
	};
};

const useRevokeUserAccessViaEmail = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRevokeUserAccessViaEmail, setLoadingRevokeUserAccessViaEmail] = useState(false);
	const [removeRevokeUserAccessViaEmailModal, setRemoveRevokeUserAccessViaEmailModal] = useState(null);
	const [userEmail, setUserEmail] = useState(null);

	const [errorRevokeUserAccessViaEmail, setErrorRevokeUserAccessViaEmail] = useState(null);
	const [successRevokeUserAccessViaEmail, setSuccessRevokeUserAccessViaEmail] = useState(null);

	const handleRevokeUserAccessViaEmail = () => {

		if (!loadingRevokeUserAccessViaEmail) {
			if (!userEmail) {
				setErrorRevokeUserAccessViaEmail(null);
				setSuccessRevokeUserAccessViaEmail(null);
				setErrorRevokeUserAccessViaEmail("User PID is required");
				setTimeout(function () {
					setErrorRevokeUserAccessViaEmail(null);
				}, 2500)
			} else {
				setLoadingRevokeUserAccessViaEmail(true);

				const revokeUserAccessViaEmailRes = revokeUserAccessViaEmail(cookie, {
					email: userEmail
				})

				revokeUserAccessViaEmailRes.then(res => {
					setLoadingRevokeUserAccessViaEmail(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRevokeUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorRevokeUserAccessViaEmail(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRevokeUserAccessViaEmail(error);
							setTimeout(function () {
								setErrorRevokeUserAccessViaEmail(null);
							}, 2000)
						}
					} else {
						setErrorRevokeUserAccessViaEmail(null);
						setSuccessRevokeUserAccessViaEmail(`User access revoked!`);

						setTimeout(function () {
							setSuccessRevokeUserAccessViaEmail(null);
							setRemoveRevokeUserAccessViaEmailModal(true);
							setUserEmail(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRevokeUserAccessViaEmail(false);
				})

			}
		}
	};

	return {
		cookie, loadingRevokeUserAccessViaEmail, removeRevokeUserAccessViaEmailModal, errorRevokeUserAccessViaEmail, successRevokeUserAccessViaEmail, handleRevokeUserAccessViaEmail,
		setRemoveRevokeUserAccessViaEmailModal, setUserEmail
	};
};

const useUpdateStar = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateStar, setLoadingUpdateStar] = useState(false);
	const [removeUpdateStarModal, setRemoveUpdateStarModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [star, setStar] = useState(null);

	const [errorUpdateStar, setErrorUpdateStar] = useState(null);
	const [successUpdateStar, setSuccessUpdateStar] = useState(null);

	const handleStar = (e) => { e.preventDefault(); setStar(parseInt(e.target.value)); };

	const handleUpdateStar = () => {

		if (!loadingUpdateStar) {
			if (!userPID) {
				setErrorUpdateStar(null);
				setSuccessUpdateStar(null);
				setErrorUpdateStar("User PID is required");
				setTimeout(function () {
					setErrorUpdateStar(null);
				}, 2500)
			} else if (star < 0) {
				setErrorUpdateStar("Star is required");
				setTimeout(function () {
					setErrorUpdateStar(null);
				}, 2500)
			} else if (star < 1 || star > 5) {
				setErrorUpdateStar("Star range 1 - 5");
				setTimeout(function () {
					setErrorUpdateStar(null);
				}, 2500)
			} else {
				setLoadingUpdateStar(true);

				const updateUserStarRes = updateUserStar(cookie, {
					pid: userPID,
					star
				})

				updateUserStarRes.then(res => {
					setLoadingUpdateStar(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateStar(error);
							setTimeout(function () {
								setErrorUpdateStar(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateStar(error);
							setTimeout(function () {
								setErrorUpdateStar(null);
							}, 2000)
						}
					} else {
						setErrorUpdateStar(null);
						setSuccessUpdateStar(`User star updated!`);

						setTimeout(function () {
							setSuccessUpdateStar(null);
							setRemoveUpdateStarModal(true);
							setUserPID(null);
							setStar(0);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateStar(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateStar, removeUpdateStarModal, errorUpdateStar, successUpdateStar, handleUpdateStar,
		setRemoveUpdateStarModal, setUserPID, setStar, star, handleStar
	};
};

const useUpdateBalance = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateBalance, setLoadingUpdateBalance] = useState(false);
	const [removeUpdateBalanceModal, setRemoveUpdateBalanceModal] = useState(null);
	const [userPID, setUserPID] = useState(null);
	const [balance, setBalance] = useState(null);

	const [errorUpdateBalance, setErrorUpdateBalance] = useState(null);
	const [successUpdateBalance, setSuccessUpdateBalance] = useState(null);

	const handleBalance = (e) => { e.preventDefault(); setBalance(parseInt(e.target.value)); };

	const handleUpdateBalance = () => {

		if (!loadingUpdateBalance) {
			if (!userPID) {
				setErrorUpdateBalance(null);
				setSuccessUpdateBalance(null);
				setErrorUpdateBalance("User PID is required");
				setTimeout(function () {
					setErrorUpdateBalance(null);
				}, 2500)
			} else if (balance < 0) {
				setErrorUpdateBalance("Balance is required");
				setTimeout(function () {
					setErrorUpdateBalance(null);
				}, 2500)
			} else {
				setLoadingUpdateBalance(true);

				const updateUserBalanceRes = updateUserBalance(cookie, {
					pid: userPID,
					balance
				})

				updateUserBalanceRes.then(res => {
					setLoadingUpdateBalance(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateBalance(error);
							setTimeout(function () {
								setErrorUpdateBalance(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateBalance(error);
							setTimeout(function () {
								setErrorUpdateBalance(null);
							}, 2000)
						}
					} else {
						setErrorUpdateBalance(null);
						setSuccessUpdateBalance(`User balance updated!`);

						setTimeout(function () {
							setSuccessUpdateBalance(null);
							setRemoveUpdateBalanceModal(true);
							setUserPID(null);
							setBalance(0);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateBalance(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateBalance, removeUpdateBalanceModal, errorUpdateBalance, successUpdateBalance, handleUpdateBalance,
		setRemoveUpdateBalanceModal, setUserPID, setBalance, balance, handleBalance
	};
};

export { useGrantUserAccess, useSuspendUserAccess, useRevokeUserAccess, useGrantUserAccessViaEmail, useSuspendUserAccessViaEmail, useRevokeUserAccessViaEmail, useUpdateStar, useUpdateBalance };
